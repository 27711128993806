import React from 'react'
import Label from '../Label'
import Popup from '../Popup'
import RoundedButton from '../RoundedButton'
import "./WarningBox.scss";

const WarningBox = props => {
    return (
        <Popup handleClose={props.onCancel} contentClass={`!pt-[2.5rem] !pr-[3.5625rem] !pb-[3.8125rem] !pl-[3.5625rem] ${props?.className}`}>
            <div className="warning-popup-details">
                <Label className="mb-[1.25rem] p-[1.125rem] warning-msg">{props.children}</Label>
                <div className='flex justify-around gap-2.5'>
                    {props?.onApprove && <div className="flex-1">
                        <RoundedButton OnClick={props.onApprove} Type="17" Name={props.approveBtn}></RoundedButton>
                    </div>}
                    {props?.cancelBtn && <div className="flex-1">
                        <RoundedButton OnClick={props.onCancel} Type="18" Name={props.cancelBtn}></RoundedButton>
                    </div>}
                </div>
            </div>
        </Popup>
    )
}

export default WarningBox
