import twitterIcon from 'Assets/Icons/social/Twitter.svg';
import discordIcon from 'Assets/Icons/social/Discord.svg';
import youtubeIcon from 'Assets/Icons/social/YouTube.svg'
import instagramIcon from 'Assets/Icons/social/Instagram.svg'
import websiteIcon from 'Assets/Icons/social/websiteIcon.svg'
import styles from '../../../../../../../Pages/Discover/Components/Creators/Components/AboutTab/AboutTab.module.scss'

const DetailsTab = (props) => {
    const formatDate = (inputDate) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const dateObject = new Date(inputDate);
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    return (
        <div className="flex flex-col w-full p-[1rem] pt-0 sm:pb-4 pb-[5rem]">
            {props?.user?.description && <div className="flex flex-col gap-[1rem] pb-[1.75rem] border-b border-[#191D23] pt-[0.5rem]">
                <div className="desc-title">
                    Description
                </div>
                <div className={`text-start text-[0.9rem] text-[var(--cu-white-200)]`}>
                    {props?.user?.description}
                </div>
            </div>}
            {props?.user?.twitter || props?.user?.discord || props?.user?.youtube ? <div className={`flex flex-col gap-[1rem] ${props?.user?.description?"pt-[1.75rem]":"pt-4"} pb-[1.75rem] border-b border-[#191D23]`}>
                <div className="desc-title">
                    Links
                </div>
                <div className='flex flex-col gap-[1rem] items-left sm:pl-[2.5625rem] pl-0 text-[0.9rem]'>
                    {props?.user?.twitter &&
                        <a href={`${props?.user?.twitter}`} target='_blank' rel='noreferrer' className='flex' >
                            <div className='flex gap-7'>
                                <img src={twitterIcon} alt='twitter' className='w-[1rem] ml-1' />
                                <div className={`${styles['social-links']}`}>{props?.user?.twitter}</div>
                            </div>
                        </a>}
                    {props?.user?.discord &&
                        <a href={`${props?.user?.discord}`} rel='noreferrer' target='_blank' className='flex '>
                            <div className='flex gap-6'>
                                <img src={discordIcon} alt='discord' className='w-[1.5rem]' />
                                <div className={`${styles['social-links']}`}>{props?.user?.discord}</div>
                            </div>
                        </a>
                    }
                    {props?.user?.youtube &&
                        <a href={props?.user?.youtube} rel='noreferrer' target='_blank' className='flex ' >
                            <div className='flex gap-6'>
                                <img src={youtubeIcon} alt='' className='w-[1.5rem]' />
                                <div className={`${styles['social-links']}`}>{props?.user?.youtube}</div>
                            </div>
                        </a>
                    }
                    {props?.user?.instagram &&
                        <a href={props?.user?.instagram} rel='noreferrer' target='_blank' className='flex ' >
                            <div className='flex gap-6'>
                                <img src={instagramIcon} alt='' className='w-[1.5rem]' />
                                <div className={`${styles['social-links']}`}>{props?.user?.instagram}</div>
                            </div>
                        </a>
                    }
                    {props?.user?.website &&
                        <a href={props?.user?.website} rel='noreferrer' target='_blank' className='flex ' >
                            <div className='flex gap-6'>
                                <img src={websiteIcon} alt='' className='w-[1.5rem]' />
                                <div className={`${styles['social-links']}`}>{props?.user?.website}</div>
                            </div>
                        </a>
                    }
                </div>
            </div> : null}
            <div className={`flex flex-col gap-[1rem] ${(props?.user?.description||props?.user?.twitter || props?.user?.discord || props?.user?.youtube)?"pt-[1.75rem]":"pt-4"} pb-[1.75rem] `}>
                <div className="desc-title">
                    More Info
                </div>
                <div className="desc-title flex flex-col gap-[1rem]">
                    <div className='mb-2 sm:pl-[2.5625rem] pl-0'>
                        <div className='mb-2'>City/Country</div>
                        <div className={`text-[var(--cu-white-200)]`}>
                            {`${props?.user?.city ? `${props?.user?.city}` : ''} ${props?.user?.nationality}`}
                        </div>
                    </div>
                    <div className='mb-2 sm:pl-[2.5625rem] pl-0'>
                        <div className='mb-2'>Joined</div>
                        <div className={`text-[var(--cu-white-200)]`}>
                            {formatDate(props?.user?.createdAt)}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DetailsTab