import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import InputColor from "App/Components/Ui/InputColor";
import { useCallback, useMemo, useEffect } from "react";
import frameNoneIcon from "Assets/Icons/GalleryControls/frameNoneIcon.svg";
import frameWithTrimIcon from "Assets/Images/Frames/frametrim.svg";
import frameWithDetailLinesIcon from "Assets/Images/Frames/framedetaillines.svg";
import cur8SmallImage from "Assets/Images/cur8-small.png";
import { STATICURL } from "App/Helper/staticfiles";
import InputSlider from "App/Components/Ui/InputSlider";
import PreviewChanges from "../../../PreviewChanges";
import FrameIcon from "App/Components/IconsComponents/GalleryControls/FrameIcon";
import MobileControlClose from "App/Components/Ui/Button/MobileControlClose";

const ScreenStyle = ({className = '', setScreenControl, screenControls, onHandleScreenControls = null, show= false}) => { 
    const styleTypes = useMemo(() => {
        return [
            { id: 1, name: 'Zoomed To Fill', value: 'ful-scr' },
            { id: 2, name: 'Framed To Fit', value: 'frm' },
            { id: 3, name: 'Image Inset', value: 'custom' }
        ]
    }, [])
    const frameTypes = useMemo(() => {
        return [
            { id: 2, name: 'Off', value: 0 },
            { id: 1, name: 'On', value: 1 }                       
        ]
    }, []);    
    const screenFrameTypes = useMemo(() => {
        return [
            { id: 0, name: 'None', value: 0, icon: frameNoneIcon },
            { id: 10, name: 'With Trim', value: 10, icon: frameWithTrimIcon },             
            { id: 20, name: 'Detail Lines', value: 20, icon: frameWithDetailLinesIcon },
            // hide for now, due to conflicting styles
            // { id: 30, name: 'Double Mat', value: 30, icon: frameDoubleMatIcon },
            { id: 100, name: 'Light Wood', value: 'lightwood1', isTexture: true },           
            { id: 150, name: 'Light Wood 2', value: 'lightwood2', isTexture: true },            
            { id: 170, name: 'Brown Wood 1', value: 'brownwood1', isTexture: true },
            { id: 180, name: 'Brown Wood 2', value: 'brownwood2', isTexture: true },
            { id: 110, name: 'Dark Wood', value: 'darkwood1', isTexture: true },            
            { id: 120, name: 'Grey Pebble', value: 'greypebble', isTexture: true },
            { id: 130, name: 'Dark Blue Wood', value: 'darkbluewood', isTexture: true },
            { id: 140, name: 'Light Blue Wood', value: 'lightbluewood', isTexture: true },        
            
        ]
    }, []);
    const handleStyleType = useCallback((e) => {
        let type = e?.currentTarget?.dataset?.value;
        setScreenControl(prev => { return {...prev, styleType: type}})
        getBGColor(screenControls?.screenFrameType, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenControls?.screenFrameType]);
    const onImageBgChange = useCallback((value) => {
        setScreenControl(prev => { return {...prev, imageLayout: {...prev?.imageLayout, bgColor: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleFrameType = (e) => {
        let type = parseInt(e?.currentTarget?.dataset?.value);
        setScreenControl(prev => { return {...prev, frameType: !!type}})
    }
    const handleScreenFrameType = (e) => {
        let type = e?.currentTarget?.dataset?.value??e;
        setScreenControl(prev => { return {...prev, screenFrameType: type}})
        getBGColor(type, screenControls?.styleType);
 }
    const getBGColor = useCallback((type = '', styleType = '') => {

        let col1 = '#000000';
        let col2 = '#000000';
        let colbg = '#eeeeee';
        switch (parseInt(type)) {
            case 0:                                                
                col1 = '#000000';
                col2 = '#000000';
                colbg = '#000000';
                break;
            case 10:
                col1 = '#dddddd';
                col2 = '#ffffff';
                colbg = '#eeeeee';
                break;
            case 20:                                                
                col1 = '#222222';
                col2 = '#ffffff';
                colbg = '#eeeeee';
                break;
            case 30:
                col1 = '#eeeeee';
                col2 = '#e2e2e2';
                colbg = '#f2f2f2';
                break;
            default:
                break;
        }
        if (styleType === 'ful-scr')
        {
            // eslint-disable-next-line no-unused-vars
            colbg = 'transparent';            
        }                             
        setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, bgColor_1: col1, bgColor_2: col2}, imageLayout: {...prev?.imageLayout}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(()=> {        
        handleScreenFrameType(screenControls?.screenFrameType);        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenControls?.screenFrameType])    
    const onImageThickChange = useCallback((value) => {        
        setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, thickness: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <ControlCard className={`${className} pop screen-style-control w-[25rem] ${screenControls?.styleType === 'custom' && 'style-pop'}`}>

            {/* For Mobile only  */}
            <ControlTitleCard className="layout_addition-title_MR">
                <FrameIcon className={`frame__icon`} />
                <span >{`Layout Preview`}</span>
            </ControlTitleCard>

            <div className={`custom-styles flex gap-8 flex-col opacity-1 layouts_inline-box`}> 

                {/* For Mobile only  */}
                {show && <PreviewChanges change={screenControls} />}

                <ControlBody className="bottom-border pb-8">
                    <ControlTitleCard>
                        <span>{`Layout`}</span>
                    </ControlTitleCard>
                    <ControlContent className="flex layout-content">
                        {styleTypes?.map(type => {
                            return  <div data-value={type?.value} key={type?.id} className={`layout-image-container cursor-pointer ${type?.value === screenControls?.styleType && `layout-active`}`} onClick={handleStyleType} title={type?.name}>
                                        <img className={`click-link-tab layout-type-${type?.value}`} 
                                        alt=""
                                            src={cur8SmallImage}>                                        
                                        </img>
                                    </div>                            
                        })}
                        {<div className={`layout-items !items-start mt-[0.8rem] ${screenControls?.styleType!=='ful-scr' ? '' : 'opacity-0'}`}>
                            <InputColor className="layout-slider layout_bg-slider" value={screenControls?.imageLayout?.bgColor ?? "#000"} onChange={onImageBgChange} useGradientSlider={true} />
                        </div>}
                    </ControlContent>
                </ControlBody>
                <ControlBody className={`bottom-border pb-8 ${screenControls?.styleType==='frm' ? '' : ''}`}>
                    <ControlTitleCard className="border-frame-size">{`Border Frame`}
                        {screenFrameTypes.filter(sft=>sft.value === screenControls?.screenFrameType)?.[0]?.isTexture && 
                        <InputSlider step={0.001} decimalDigit={3} onChange={onImageThickChange} value={screenControls?.imageBorder?.thickness??0.03} max={0.08}/>}
                    </ControlTitleCard>
                    <ControlContent className="flex layout-content flex-col">                        
                        <div className="layout-items">                            
                            <div className="flex flex-wrap gap-4 layout_frame-image-box">
                                {screenFrameTypes?.map(type => {
                                    return  <img data-value={type?.value} alt="" className={`cursor-pointer frame-image click-link-tab ${type?.value.toString() === screenControls?.screenFrameType && `frame-active`}`} 
                                            onClick={handleScreenFrameType} key={type?.id} src={type?.icon ? type.icon : `${STATICURL}images/detailframes/${type.value}thumb.jpg`}>
                                            
                                        </img>
                                })}
                            </div>
                        </div>                          
                    </ControlContent>
                </ControlBody>   
                <ControlBody className={`sm:pb-8 ${screenControls?.styleType==='ful-scr' ? 'disable-section' : ''}`} title={`${screenControls?.styleType==='ful-scr' ? 'Switch to a different Layout to enable Inner Frames' : ''}`}>
                    <ControlTitleCard>{`Inner Frame`}</ControlTitleCard>
                    <ControlContent className="layout-content">
                        {frameTypes?.map(type => {
                            return  <span data-value={type?.value} className={`cursor-pointer click-link-tab ${type?.value === Number(screenControls?.frameType) && `text-active`}`} 
                                        onClick={handleFrameType} key={type?.id}>
                                        {type?.name}
                                    </span>
                        })}
                    </ControlContent>
                </ControlBody>                             
            </div>  
            <MobileControlClose onClick={onHandleScreenControls && onHandleScreenControls}>Close</MobileControlClose>                    
        </ControlCard>
    )
}
export default ScreenStyle;