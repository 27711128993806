import "../Icons.scss";

const UserGuideMobileIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color rect-stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="1" width="10" height="10" rx="2.5" stroke="#A0ABBB" />
        <path
          d="M5.244 4.678C5.14 4.678 5.052 4.642 4.98 4.57C4.908 4.498 4.872 4.41 4.872 4.306C4.872 4.202 4.908 4.114 4.98 4.042C5.052 3.97 5.14 3.934 5.244 3.934C5.344 3.934 5.428 3.97 5.496 4.042C5.568 4.114 5.604 4.202 5.604 4.306C5.604 4.41 5.568 4.498 5.496 4.57C5.428 4.642 5.344 4.678 5.244 4.678ZM5.508 5.212V8.5H4.962V5.212H5.508Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default UserGuideMobileIcon;
