import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './GlobalSearch.scss';
import { useDebounce } from 'App/hooks/use-debounce';
import useHttp from 'App/hooks/use-http';
import CommunityResults from './SubComponents/CommunityResults';
import CollectionResults from './SubComponents/CollectionResults';
import { useLocation, useNavigate } from 'react-router-dom';
import LgSearchIcon from 'App/Components/IconsComponents/LgSearchIcon';
import CollectorsResults from './SubComponents/CollectorsResult';
import CloseIcon from 'App/Components/IconsComponents/CloseIcon';
import CuratorsResults from './SubComponents/CuratorsResults';

const GlobalSearch = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { triggerAPI } = useHttp();
    
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const [searchValue, setSearchValue] = useState('');
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [searchFullResults, setSearchFullResults] = useState(null);
    const [expandBox, setExpandBox] = useState('');

    const [loadingResults, setLoadingResults] = useState(true);
    const [loaderCount, setLoadingCount] = useState(10);

    const debouncedValue = useDebounce(searchValue, 600);


    const tabs = useMemo(() => {
        let tabList = [
            { id: "collection", type: "collection", url: "collections", element: 'Collections' },
            { id: "community", type: "community", url: "creators", element: 'Creators' },
            { id: "collectors", type: "collectors", url: "collectors ", element: 'Collectors ' },
            { id: "curators", type: "curators", url: "curators ", element: 'Curators ' },
        ]
        return tabList
    }, []);

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const tabsHandler = (selected) => {
        if (loadingResults || (selected?.id === selectedTab?.id)) { return }
        // setLoadingResults(true);
        setSelectedTab(selected)
    }


    // const getResponse = useCallback((res) => {
    //     const { data } = res;
    //     setTotalItems(data?.pageMeta?.items)
    //     setLoadingCount(10);
    //     setSearchResults(data?.list);
    //     setLoadingResults(false)
    // }, []);
    const getResponse = useCallback((res) => {
        const { data } = res;
        setLoadingCount(10);
        setSearchFullResults(data);
        setLoadingResults(false)
    }, []);

    const getSearchResults = (search, type) => {
        setLoadingResults(true);
        type = type ?? 'collection';
        search = encodeURIComponent(search);
        const company = process.env.REACT_APP_COMPANY === 'anywall' ? 'anyWall' : 'cur8';
        triggerAPI(
            {
                url: `home/global-search-full?search=${search}&company=${company}`,
                method: "get",
            }, getResponse
        );
    }
    useEffect(() => {
        if (debouncedValue?.trim()) {
            getSearchResults(debouncedValue, selectedTab?.type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])

    const handleSearch = (e) => {
        let searchParam = e.target.value;
        setSearchValue(searchParam);
        handleOpenDropdown(searchParam);
    };


    const handleCloseDropdown = () => {
        setIsDropDownOpen(false);
    }
    const handleOpenDropdown = (isSearchText) => {
        if (isSearchText?.trim() && !isDropDownOpen) {
            setIsDropDownOpen(true)
        }
        if (!isSearchText?.trim()) {
            handleCloseDropdown()
        }
    }

    const handleReset = () => {
        setExpandBox(false);
        setSearchFullResults(null)
        props?.searchExpanded?.(false);
        setLoadingResults(true);
        setSearchValue('');
        setSelectedTab(tabs[0]);

        if (props?.showOpenForMob) {
            props?.onOpenForMob && props?.onOpenForMob(false);
        }
    }

    const handleSeeMoreResultsClick = () => {
        const navigateParam = { state: { pageType: 'view' }, replace: true };
        const search = encodeURIComponent(searchValue)
        navigate(`/global/search/${selectedTab?.url}/${search}`, navigateParam);
        handleReset()
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                handleCloseDropdown();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDropDownOpen]);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [expandBox]);

    useEffect(() => {
        if (location?.pathname?.includes('/global/search/')) {
            navigate(`/home`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickExpandBox = () => {
        setExpandBox(true)
        props?.searchExpanded?.(true);
    }
    return (
        <div onClick={() => handleOpenDropdown(searchValue)} ref={dropdownRef} className={`global_search-container ${expandBox ? 'w-[25.05rem]' : 'w-[3rem]'}`}>

            <div className={`global_search-box-wrapper ${expandBox ? 'active' : ''}`}>
                <button onClick={clickExpandBox} className={`global_search-icon-button`}>
                    {/* <img src={searchIcon} alt="icon" className={`global_search-icon img-icon`} /> */}
                    <LgSearchIcon className={"global-search-image pb-[1px]"} />
                </button>
                <input
                    ref={inputRef}
                    value={searchValue}
                    className={`global_search-input-field ${expandBox ? 'w-full' : 'hidden'}`}
                    onChange={handleSearch}
                    placeholder={`Search collections, creators, collectors and curators`}
                />
                {expandBox && <button className='opacity-80 hover:opacity-100 pr-[0.6rem]'
                    onClick={handleReset}>
                    {/* <img src={closeIcon} alt="close" /> */}
                    <CloseIcon />
                </button>}
            </div>

            {((isDropDownOpen && expandBox) || (props?.showOpenForMob)) && <div ref={props?.mobileScreenRef} className={`global_search-result-wrapper`}>
                <div className={`global_search-results`}>

                    {/* search input for mobile */}
                    <div className='global_search-box-wrapper_mobile'>
                        <button className={`global_search-icon-button`}>
                            <LgSearchIcon className={"global-search-image pb-[1px]"} />
                        </button>
                        <input
                            ref={inputRef}
                            value={searchValue}
                            className={`global_search-input-field w-full`}
                            onChange={handleSearch}
                            placeholder={`Search collections, creators, collectors and curators`}
                        />
                        {(!!searchValue) && <button className='opacity-80 hover:opacity-100 pr-[0.6rem]'
                            onClick={()=>setSearchValue('')}>
                            <CloseIcon />
                        </button>}
                    </div>

                    <div className="common_style-tab-container p-[1rem]">
                        <div className="tab-data">
                            {tabs?.map((menu) => {
                                return (
                                    <div
                                        onClick={() => tabsHandler(menu)}
                                        key={menu?.id}
                                        className={`tab ${menu?.id === selectedTab?.id ? "active" : loadingResults ? 'cursor-not-allowed' : "not-active"}`}
                                    >
                                        {menu?.element}{" "}<span>{`(${searchFullResults?.[menu?.id]?.count ? searchFullResults?.[menu?.id]?.count : 0})`}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {(!!searchFullResults || !!searchValue) && <div className='gs_all_results'>
                        {selectedTab?.id === 'collection' && <CollectionResults loading={loadingResults} data={searchFullResults?.collection?.data} totalItems={searchFullResults?.collection?.count} onReset={handleReset} loaderCount={loaderCount} onSeeMoreClick={handleSeeMoreResultsClick} />}

                        {selectedTab?.id === 'community' && <CommunityResults loading={loadingResults} data={searchFullResults?.community?.data} totalItems={searchFullResults?.community?.count} onReset={handleReset} loaderCount={loaderCount} onSeeMoreClick={handleSeeMoreResultsClick} />}

                        {selectedTab?.id === 'collectors' && <CollectorsResults loading={loadingResults} data={searchFullResults?.collectors?.data} totalItems={searchFullResults?.collectors?.count} onReset={handleReset} loaderCount={loaderCount} searchValue={searchValue} />}

                        {selectedTab?.id === 'curators' && <CuratorsResults loading={loadingResults} data={searchFullResults?.curators?.data} totalItems={searchFullResults?.curators?.count} onReset={handleReset} loaderCount={loaderCount} searchValue={searchValue} />}
                    </div>}
                </div>
            </div>}

        </div>
    )
}

export default GlobalSearch