import './SelectBox.scss'
import blueDownArrow from "Assets/Icons/arrow_down_blue.svg"
import arrowDown from "Assets/Icons/arrow_down_B.svg"
import arrowGreyDown from "Assets/Icons/arrow-grey.svg"
import { createRef, useCallback, useEffect, useState } from 'react'
import useScreenSize from 'App/hooks/use-screen-size'
const Select = props => {
    const [selectedItem, setSelectedItem] = useState({ value: "", name: "Select" })
    const [showItems, setShowItems] = useState(false)
    const [selectCount, setSelectCount] = useState(0);
    const select = createRef()
    const screenSize = useScreenSize()?.width; 
    const applyToggle = useCallback(() => {
        const parent = select.current;
        const list = parent?.querySelector('div.select-box-items');
        if (showItems) {
            parent?.classList.remove('select-box-closed');
            parent?.classList.add('select-box-open');
            list.style.maxHeight = `${screenSize > 550 ? list?.scrollHeight : list?.scrollHeight - 150}px`;
        } else {
            parent?.classList.remove('select-box-open');
            parent?.classList.add('select-box-closed');
            list.style.maxHeight = `0px`;
        }
    }, [showItems,screenSize, select])
    const dropDownToggle = () => {
      if(!props?.disabled)  setShowItems(prevState => !prevState);
    };
    const clickHandler = () => {
        if (selectCount === 1) {
            select.current.blur();
        }
    }
    const setSelectCountWithTimer = (value) => {
        setTimeout(() => {
            setSelectCount(value);
        }, 500);
    }
    const onBlur = (e) => {
        setShowItems(false)
        setSelectCountWithTimer(0)
        applyToggle();
        if (props?.validateOnBlur && !(selectedItem?.value || selectedItem?.id)) {
            const value = '';
            const values = {
                [props?.controlName]: value
            }
            props.onChange(values)
        }
    }
    // const selectItem = e => {
    //     dropDownToggle()
    //     let item = e.currentTarget.dataset["item"]
    //     const label = props.controlName ?? 'value'
    //     const value = {
    //         [label]: props.options[item]?.value
    //     }
    //     setSelectedItem(props.options[item]);
    //     console.log('Selected iTEM:', selectedItem); // Log the selected option
    //     if (props.onChange) {
    //         props.onChange(value)
    //     }
    // };
    const selectItem = (e) => {
        dropDownToggle();
        let item = e.currentTarget.dataset["item"];
        const selectedOption = props.options[item];
      
        if (selectedOption && selectedOption.id !== undefined) {
          const label = props.controlName ?? 'value';
          const value = {
            [label]: selectedOption.id, // Use id instead of value
          };
      
          setSelectedItem(selectedOption);
      
          if (props.onChange) {
            props.onChange(value);
          }
        } else {
          console.error('Invalid selected option:', selectedOption);
        }
      };
      
    useEffect(() => {
        applyToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyToggle])

    useEffect(() => {
        if (props.currentId) {
            const found = props?.options?.filter(val => {
                return val?.id === props.currentId
            })
            setSelectedItem(found?.length ? found[0] : { value: "", name: "Select" })
        }else{
            setSelectedItem({ value: "", name: "Select" })
        }
    }, [props?.currentId, props?.options])

    return <div className={`${props?.mainClassName} select-box`} ref={select} onBlur={onBlur} tabIndex="-1">
        <div className={`${!props.borderLess ? "select-box--container" : "flex items-center text-right"} ${props?.disabled && "disabled"}`} onClick={dropDownToggle}>
            <div className={`select-box--selected-item ${props.className}`} onClick={clickHandler}>
                {selectedItem?.name}

            </div>

            {!props.blueArrow && <div className="select-box--arrow">
                <img className={`${props?.disabled?"cursor-no-drop":"cursor-pointer"}`} alt="img" src={props?.disabled?arrowGreyDown:arrowDown} />
            </div>}
            {props.blueArrow && <div className='arrow grid place-items-center w-[.75rem] h-[.75rem]'>
                <img className='cursor-pointer ' alt="img" src={blueDownArrow} />
            </div>}

        </div>
        <div
            className={`select-box-items ${props?.itemsClassName} ${props?.listPosition}`}
        >
            {!props?.disabled && <ul>
                {props?.options?.map((item, index) => (
                    <li
                        data-item={index}
                        key={index}
                        onClick={(e)=>!item?.notActive && selectItem(e)}
                        className={`${selectedItem.value === item.value ? "selected" : ""} ${props.className} ${item?.notActive && 'cursor-not-allowed'} flex justify-start items-center gap-[1rem]`}
                    >
                        {(item?.icon && item?.showIcon) && <img
                            className={`w-[1.2rem]`}
                            alt="Img"
                            src={item?.icon}
                        />}
                        {item.name}
                    </li>
                ))}
            </ul>}
        </div>
    </div>

}

export default Select