import { useState, useCallback, useLayoutEffect, useEffect } from "react";
import useHttp from "App/hooks/use-http";
import GalleryPreviewPlugin from "./Component";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./GalleryPreview.scss";
import useWall from "App/hooks/use-wall";
const GalleryPreview = () => {
    const { triggerAPI } = useHttp();
    const [searchParams] = useSearchParams();
    const [layoutId, setLayoutId] = useState(null);
    const [bgColor, setBgColor] = useState("grey");
    const [multiBg, setMultiBg] = useState(null);
    const [initalTimes, setInitalTimes] = useState(null);
    const [galleryData, setGalleryData] = useState(null);
    const [galleryInfo, setgalleryInfo] = useState(null);
    const [monoStyles, setMonoStyles] = useState(null);
    const [loading, setLoading] = useState(true);
    const [multiQRCodeURL, setMultiQRCodeURL] = useState(null);
    const { getMetaWallFromStore } = useWall();
    const [metaWallList, setMetaWallList] = useState([]);
    const navigate = useNavigate();
    let isAppV1 = searchParams.get("source") === 'appv1';
    useEffect(() => {
        const fetchMetaWall = () => {
          getMetaWallFromStore(data => {
            setMetaWallList(data);
          });
        };
    
        fetchMetaWall();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    const getPageResult = useCallback((res) => {
        const { data } = res;
        if(!!data?.isDiscoverable || !!data?.communitySlug) {
            if(!!data?.communitySlug) {
                setMultiQRCodeURL(`/discover/all-creators/${data?.communitySlug}/gallery/${data?.id}`);
            } else {
                setMultiQRCodeURL(`/discover/collectors/${data?.userData?.username}/my-galleries/${data?.id}`);
            }
        }
        setgalleryInfo({name: data?.name, userName: data?.communityName ?? data?.userData?.username ?? ''})
        setLayoutId(data?.layoutId);
        setMultiBg(data?.thumbnailBackground);
            if(data?.twoDWallBackgroundId&&data?.thumbnailBackground&&(data?.twoDWallBackgroundId===data?.thumbnailBackground?.id)){
                setBgColor(data?.thumbnailBackground?.color)
            }
        const timer = {
            hr: data?.preference?.slideTimerHours ?? "0",
            min: data?.preference?.slideTimerMinutes ?? "0",
            sec: data?.preference?.slideTimerSeconds ?? "10"
        }
        let tempMonoStyles = {styleType: data?.monoStyleType, screenFrameType: data?.screenFrameType, frameType: data?.monoCustomized?.frameType}
        if(['custom', 'frm']?.includes(data?.monoStyleType)) {
            tempMonoStyles = {...tempMonoStyles, ...data?.monoCustomized}
        }
        setMonoStyles(tempMonoStyles)
        setInitalTimes(timer);
        setGalleryData(data);
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getPageData = () => {
        setLoading(true)
        const url = 'gallery/stand-alone/get/';
        const id = searchParams.get("id");
        triggerAPI({
            url: `${url}${id}?isVerified=0&compression=0`, method: 'get'
        }, getPageResult);
    }
    useLayoutEffect(() => {
        if(searchParams.get("id"))
            getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleGalleryView = (type) => {
        navigate(`/preview-gallery?id=${searchParams.get("id")}&type=0&page=${type}`);
    }
    // const handleControlPanelVisibility = useCallback((resp) => {
    // }, []);
    // as now we are hiding music icon from preview, if need update state here and toggle hide-music class
    return (
        <div className={`modern-gallery-preview hide-music`}> 
            {(!loading) &&   
                <GalleryPreviewPlugin 
                    galleryData={galleryData} 
                    galleryType={searchParams.get("page") ?? 'mono'}
                    isAppV1={isAppV1}
                    initalTimes={initalTimes}
                    layoutId={layoutId}
                    bgColor={bgColor}
                    multiBgData={multiBg}
                    metaBgData={metaWallList?.find(item => item?.id === galleryData?.metaBg) ?? null}
                    screenData={monoStyles}
                    galleryInformation={galleryInfo}
                    handleGalleryView={handleGalleryView}
                    multiQRCodeURL={multiQRCodeURL}
                />
            }
        </div>
    )
}

export default GalleryPreview;