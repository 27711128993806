import CollectionCard from 'App/Components/CollectionCard';
import useHttp from 'App/hooks/use-http';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { queryGenerator } from 'App/Helper/utilities';
import DetailsSkeltonCard from 'App/Components/Ui/detailsSkeleton';
import './PreviewGalleryCollections.scss';

const paginationConfig = {
    perPage: 30,
};

const PreviewGalleryCollections = ({ parentScrollContainerQuery, galleryId }) => {

    const navigate = useNavigate();
    const { triggerAPI } = useHttp();

    const [collectionListOriginal, setCollectionListOriginal] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(null);

    const getCollectionList = useCallback(
        (pageNo) => {
            const dataParam = {
                queryData: {
                    page: pageNo ?? page,
                    items: paginationConfig?.perPage,
                },
            };
            setLoading(true);

            let queryList = queryGenerator({ ...dataParam?.queryData});
            triggerAPI({
                url: `gallery/asst-collections/${galleryId}?${queryList}`, method: 'get'
            }, (res => {
                const { list, pageMeta } = res?.data;
                setCollectionListOriginal((prev) =>
                    pageNo === 1 ? [...list] : [...prev, ...list]
                );
                setTotalPage(pageMeta?.totalItems);
                setLoading(false);
            }), (err) => { setLoading(false); });
        },
        [galleryId, page, triggerAPI]
    );



    useEffect(() => {
        if(galleryId){
            getCollectionList(page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, galleryId]);

    useEffect(() => {
        return () => {
            setPage(1)
            setTotalPage(1)
        }
    }, []);

    const cardClick = (e) => {
        const id = parseInt(e?.currentTarget?.dataset?.id);
        const policy = e?.currentTarget?.dataset?.policy;
        let data;
        collectionListOriginal?.forEach((collect) => {
            if (collect?.id === id) data = collect;
        });
        let url = `discover/collection/${data?.category?.slug}/details`
        if (data?.community?.slug) url = `discover/all-creators/${data?.community?.slug}/collection`
        navigate(`/${url}/${policy}`)
    };

    const onScrollHandler = (e, fetchMore) => {
        if (
            e.target.scrollTop + e.target.clientHeight >
            e.target.scrollHeight - 10
        ) {
            if (fetchMore) {
                setPage((pre) => pre + 1);
                setLoading(true);
            }
        }
    }
    useEffect(() => {
        if (parentScrollContainerQuery) {
            let scrollParent = document.querySelector(parentScrollContainerQuery);
            if (scrollParent) {
                scrollParent.onscroll = function (e) {
                    onScrollHandler(e, ((collectionListOriginal?.length < totalPage) && !loading));
                };
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionListOriginal])

    return (
        <div className="preview-gallery-collections-container h-full w-full flex flex-col collector_explore-collection">
            {(<div className="flex-1 h-full flex flex-col justify-between pt-[1rem]">
                <div className={`collection-box relative ${(!collectionListOriginal) && "mt-[3.5rem]"}`}>
                    {[...collectionListOriginal]?.map((list) => {
                        return (
                            <CollectionCard
                                showData={{
                                    profileIcon: true,
                                    collectionName: true,
                                    communityName: true,
                                    count: false,
                                    blockchainIconAndPieceCount: true,
                                    tag: true,
                                }}
                                key={list?.id}
                                row={list}
                                cardClick={cardClick}
                            />
                        );
                    })}

                    {[...Array(10)]?.map((_item, i) => <div
                        key={i}
                        className='skeltonMainClass mb-[1rem]'>
                        <DetailsSkeltonCard
                            show={loading}
                            height={"100%"}
                            count={1}
                            inline={true}
                            details={"img-2-line"}
                            containerClassName={`flex`}
                        /></div>)}
                </div>
            </div>
            )}
            {!loading && loading !== null && collectionListOriginal && !collectionListOriginal?.length && (
                <div className=" flex items-center no-data justify-center">
                    No collections to display.
                </div>
            )}
        </div>
    )
}

export default PreviewGalleryCollections