import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import { memo, useCallback, useEffect, useState } from "react";
import LockComponent from "App/Components/Ui/LockComponent";
import { useStore } from "App/hooks-store/store";
import BackgroundWallFilter from "App/Components/Ui/BackgroundWallFilter";
import MobileControlClose from "App/Components/Ui/Button/MobileControlClose";
import BackgroundIcon from "App/Components/IconsComponents/GalleryControls/BackgroundIcon";
import PreviewChanges from "../../../PreviewChanges";
import { useDevice } from "App/hooks/use-ui";

const MultiBgControl = ({className = '', multiStyleList = [], multiColorList = [], bgWallFilter = null, setBgWallFilter = null, wallList=[], isMeta = false, selectedMultiBg, setSelectedMultiBg, selectedMetaBg, setSelectedMetaBg, onHandleMultiBgList= null, show= false}) => {
    const device = useDevice();
    // const [dropdown, setDropDown] = useState(false);
    const [selectedBg, setSelectedBg] = useState({});
    const {premiumGalleryWallEnabled} = useStore(false)[0];
    
    
    // const onMouseBlur = useCallback(() => {
    //     setDropDown(false);
    //   }, []);
    //   const onMouseFocus = useCallback(() => {
    //     setDropDown(true);
    //   }, []);
    // const onStyleHandler = (type) => {
    //     setBgWallFilter(prev => {
    //         return {...prev, style: bgWallFilter?.style === type ? "" : type}
    //     });
    // };
    // const onColorClose = ()=>{
    //     setBgWallFilter(prev => { return {...prev, color: ''} });
    //     onMouseBlur();
    // }
    // const onColorSelect = (e) => {
    //     setBgWallFilter(prev => {
    //         return {...prev, color: bgWallFilter?.color === e.currentTarget.dataset.id ? "" : e.currentTarget.dataset.id}
    //     });
    //     onMouseBlur();
    // };
    const handleClick = useCallback((e) => {
        if(!(!premiumGalleryWallEnabled&&e.currentTarget.dataset?.types==="premium"&& !isMeta)){
            const id = parseInt(e.currentTarget.dataset?.id);
        let selected = wallList?.find(item => item?.id === id)
        isMeta ? setSelectedMetaBg(selected) : setSelectedMultiBg(selected)
        }

        if(isMeta && device === 'mobile') {
            //closing popup control while switching Venue in mobile
            onHandleMultiBgList && onHandleMultiBgList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallList, isMeta]);
    useEffect(() => {
        let tempBg =  selectedMultiBg;
        if(isMeta)
            tempBg =  selectedMetaBg;
        setSelectedBg(tempBg)
    }, [isMeta, selectedMetaBg, selectedMultiBg])
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 mbg-list-control w-[50rem] ${isMeta && 'meta-venue-lists'}`}>

            {/* For Mobile only  */}
            <ControlTitleCard className="background_List_icon_MR">
                <BackgroundIcon className={``} />
                <span >{isMeta ? 'Venue List' : 'Background List'}</span>
            </ControlTitleCard>
            
            {/* For Mobile only  */}
            {show && <PreviewChanges change={selectedBg} isMulti={true} />}

            <ControlBody className="mbg-list-section">
                <div className="mbg_colors-container">
                    <ControlTitleCard>{isMeta ? 'Venue' : 'Background'}</ControlTitleCard>
                    {!isMeta && 
                        <BackgroundWallFilter color={bgWallFilter?.color} multiColorList={multiColorList} setColor={setBgWallFilter} />

                    }
                </div>                
                <ControlContent className="mbg-content">
                    {wallList?.length ? <div className={`grid mbg-sec-list listing-grid-control-panel`}>

                        {wallList?.map((wall, index) => (
                            <div className={`${(!premiumGalleryWallEnabled&&wall?.types==="premium"&& !isMeta)? "cursor-not-allowed":"cursor-pointer"} relative background-item ${selectedBg?.id === wall?.id ? 'active' : ''}`} data-types={wall?.types} data-id={wall?.id} key={`${wall?.id}${index}`} onClick={handleClick}>
                                <img alt="" src={wall?.imageUrl256} />
                                {!premiumGalleryWallEnabled&&wall?.types==="premium"&& !isMeta&&<LockComponent message="Premium backgrounds only available for Premium Curator subscription plans."/>}

                            </div>
                        ))
                        }</div>
                        :
                        <p className="flex justify-center h-32 w-full items-center no-color non-highlight-color">No background walls found</p>
                    }
                </ControlContent>
            </ControlBody>
            <MobileControlClose onClick={onHandleMultiBgList && onHandleMultiBgList}>Close</MobileControlClose>       
        </ControlCard>
    )
}
export default memo(MultiBgControl);