import React, { useEffect, useState } from 'react';
import './CollectorsResults.scss';
import dummyImage from "Assets/Images/dummypic.svg";
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useStore } from 'App/hooks-store/store';

const CollectorsResults = ({ loading, loaderCount, data, onReset, searchValue, totalItems }) => {

    const navigate = useNavigate();
    const dispatch = useStore()[1];

    const handleNavigate = (collectorId) => {
        onReset();
        dispatch("setFullPageLoader", true);
        navigate(`/discover/collectors/${collectorId}/my-galleries`);

        setTimeout(() => {
          dispatch("setFullPageLoader", false);
        }, 4000);
    }
    const handleSeeMoreClick = () => {
        onReset();
        const params = { state: { pageType: 'view' }, replace: true };
        const search = encodeURIComponent(searchValue)
        navigate(`/discover/collectors?${search}`, params);
    }

    return (
        <div className={`collectors_results-container results-and-btn-container`}>
            <div className={`results-wrapper`}>
                {/* Loader */}
                {loading && [...Array(loaderCount).keys()].map((loader) => <div key={loader} className='collectors_results-details-loader'>
                    <SkeletonLoader width={40} height={40} count={1} inline={true} />
                    <SkeletonLoader width={800} height={20} count={1} inline={true} />
                </div>)}
                {/* Results */}
                {data?.length && !loading ? data?.slice(0, 10)?.map((collector, key) => <CollectorsDetails key={collector?.id ?? key} collector={collector} onNavigate={handleNavigate} />)
                    : null
                }
            </div>
            {!loading && data && (totalItems > 10) ? <div className='collectors_results-see-more gs_see_more-Btn'>
                <button onClick={handleSeeMoreClick}>See More Results</button>
            </div> : null}
            {/* No Results */}
            {!loading && !data?.length ? <div className='collectors_no-results-found no-results-found_message'>
                <p>No Collectors Found</p>
            </div> : null}
        </div>
    )

}

function CollectorsDetails({ collector, onNavigate }) {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(!collector);
    }, [collector]);

    return (<div className={`collectors_results-details-card`} onClick={() => onNavigate(collector?.username)}>
        <div className={`details_column-one basis-5/8`}>
            <div className='basis-2/10 collectors-image-box'>
                <img className={`w-10 h-10 object-cover`} src={collector?.displayPicture256 ?? collector?.profilePicUrl ?? dummyImage} alt={collector?.name} />
            </div>

            <div className={`basis-4/10 collectors-name-box`}>
                {loader ? <SkeletonLoader width={80} height={20} count={1} inline={true} /> :
                    <h2 className='max-w-[12.5rem] truncate'>{collector?.username}</h2>
                }
            </div>

        </div>
    </div>)

}

export default CollectorsResults