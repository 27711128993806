import React, { useEffect } from "react";
import SignIn from "../Pages/AuthLayout/Components/SignIn";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import AuthLayout from "../Pages/AuthLayout";
import Register from "../Pages/AuthLayout/Components/Register";
import Profile from "App/Pages/UserProfile/Components/Profile";
import Account from "App/Pages/UserProfile/Components/Account";
import UserProfile from "App/Pages/UserProfile";
import ForgotPassword from "App/Pages/AuthLayout/Components/ForgotPassword";
import ResetPassword from "App/Pages/AuthLayout/Components/ResetPassword";
import MyVerifiedNFTPolicies from "App/Pages/UserProfile/Components/MyVerifiedNFTPolicies";
import AccessPlans from "App/Pages/UserProfile/Components/AccessPlans";
import Preferences from "App/Pages/UserProfile/Components/Preferences";
import MyNFTCalendar from "App/Pages/UserProfile/Components/MyNFTCalendar";
import NFTResiduals from "App/Pages/UserProfile/Components/NFTResiduals";
import GalariesRentOut from "App/Pages/UserProfile/Components/GalariesRentOut";
import GalariesRent from "App/Pages/UserProfile/Components/GalariesRent";
import InformationDesk from "App/Pages/InformationDesk";
// import InformationDetails from "App/Pages/InformationDesk/Components/InformationDetails";
import NftGallery from "App/Pages/NftGallery";
import ComingSoon from "App/Pages/ComingSoon";
import PublicRoute from "./PublicRoutes";
import Collections from "App/Pages/Collections";
import WalletAuthMesh from "App/Pages/UserProfile/Components/WalletAuthMesh";
import PreviewGallery from "App/Pages/GalleryPreview";
import Purchase from "App/Pages/UserProfile/Components/Purchase";
import Rewards from "App/Pages/UserProfile/Components/Rewards";
import Community from "App/Pages/CommunityCollection/Community";
import CommunityList from "App/Pages/CommunityCollection/Community/Components/Community";
import AuthRoute from "./AuthRoute";
import ReactGA from "react-ga4";
import Discover from "App/Pages/Discover";
import CreatorDetail from "App/Pages/Discover/Components/Creators/Components/CreatorDetail";
import Collectors from "App/Pages/Discover/Components/Collectors";
import CollectorDetail from "App/Pages/Discover/Components/Collectors/Components/CollectorDetail";
import CreatorCommunityDetail from "App/Pages/CommunityCollection/Community/Components/Community/Components/OverView";
import HomeV2 from "App/Pages/HomeV2";
import Subscription from "App/Pages/UserProfile/Components/Subscription";
import NftCollectionsV3 from "App/Pages/Collections/Components/NftCollectionsV3";
import Explore from "App/Pages/Explore";
import ExploreCategoryCollections from "App/Pages/Explore/Components/CategoryCollections";
import SubcategoryCollection from "App/Pages/Explore/Components/SubCategoryCollection";
import Display from "App/Pages/Display";
import DisplayItem from "App/Pages/Display/DisplayItem";
import CommunitiesCategory from "App/Pages/Discover/Communities";
import CommunityListing from './../Pages/Discover/Communities/Components/CommunityListing/index';
import Artists from "App/Pages/Discover/Artists";
import Photographers from "App/Pages/Discover/Photographers";
import DisplayGallery from "App/Pages/DisplayGallery";
import Musicians from "App/Pages/Discover/Musicians";
import SearchResults from "App/Pages/SearchResults";
import Activities from "App/Pages/Activities"
import CollectionCategoryList from "App/Pages/SearchResults/SubComponents/CollectionList/SubComponents/CollectionCategoryList";
import CommunityCategoryList from "App/Pages/SearchResults/SubComponents/CommunitiesList/SubComponents/CommunityCategoryList";
import Rentals from "App/Pages/Display/Components/DisplayList/Components/BannerImage/Rentals";
import Curator from "App/Pages/Curator";
import CreateCuratorCommunity from "App/Pages/Curator/Components/CreateCuratorCommunity";
import CuratorCommunityDetail from "App/Pages/Curator/Components/Details";
import Curators from "App/Pages/Curators";


try {
  ReactGA.initialize(process.env.REACT_APP_G4_MEASUREMENT_ID);
} catch (error) {
  console.log(error);
}

const RoutesMain = () => {
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Custom",
    });
  }, []);
  if (process.env.REACT_APP_COMPANY==='anywall')
  {
    return (
      <Routes>
          <Route path="/" exact element={<Navigate to="/home" replace />} />
          <Route
            path="/auth"
            element={
              <AuthRoute>
                <AuthLayout />
              </AuthRoute>
            }
          >
            <Route path="/auth/sign-in" element={<SignIn />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
          </Route>
          
  
                            
          <Route
            path="/home"
            exact element={<PublicRoute><Display isHome={true}/></PublicRoute>}
          />          
          <Route path="/preview-gallery" element={<PreviewGallery />} />
          
          <Route
            path="/collector"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          >            
            <Route path="/collector/profile/:tab" element={<Profile />} />
            <Route path="/collector/profile/:tab/view" element={<Profile />} />
            <Route path="/collector/subscription" element={<Subscription />} />
            <Route path="/collector/account" element={<Account />} />
            <Route path="/collector/preferences" element={<Preferences />} />
            <Route
              path="/collector/my-verified-nft-policies"
              element={<MyVerifiedNFTPolicies />}
            />
            <Route
              path="/collector/wallet-authentication"
              element={<WalletAuthMesh />}
            />
            <Route path="/collector/access-plans" element={<AccessPlans />} />
            <Route path="/collector/my-nft-calendar" element={<MyNFTCalendar />} />
            <Route path="/collector/nft-residuals" element={<NFTResiduals />} />
            <Route
              path="/collector/galaries-rent-out"
              element={<GalariesRentOut />}
            />
            <Route path="/collector/galaries-rent" element={<GalariesRent />} />
            <Route path="/collector/purchase" element={<Purchase />} />
            <Route path="/collector/rewards" element={<Rewards />} />
          </Route>
          <Route path="/curate/display" exact element={<PublicRoute><Display /></PublicRoute>}/>  
          <Route path="/curate/display/displaygallery" exact element={<PublicRoute><DisplayGallery /></PublicRoute>}/>
          <Route path="/curate/display/:id" element={<PublicRoute><DisplayItem /></PublicRoute>}/>             
            <Route path="/curate/display/my-galleries" exact element={<NftGallery />}>
              <Route path="view/:galleryId" element={<NftGallery />} />
              <Route path="add/:pgaction" element={<NftGallery />} />
              <Route path="add/:pgaction/:policyid" element={<NftGallery />} />
              <Route path="edit/:galleryId/:pgaction" element={<NftGallery />} />
              <Route path="edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
            </Route>
            <Route
              path="/knowledge-base/:tab"
              element={
                <PublicRoute>
                  <InformationDesk />
                </PublicRoute>
              }
            />
            {/* <Route
            path="/knowledge-base/:tab/:id"
            element={
              <PublicRoute>
                <InformationDetails />
              </PublicRoute>
            }
            />             */}
          <Route path="*" element={<ComingSoon />} />
        </Routes> )
  }
  else
  {  
    return (
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route
            path="/auth"
            element={
              <AuthRoute>
                <AuthLayout />
              </AuthRoute>
            }
          >
            <Route path="/auth/sign-in" element={<SignIn />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
          </Route>
          <Route
            path="/:basePathType/frame-shop"
            element={
              <PrivateRoute>
                <Collections />
              </PrivateRoute>
            }
          >
          <Route
            path="/:basePathType/frame-shop"
            element={<NftCollectionsV3 />}
          />
            <Route path="*" element={<Navigate to="/curate/frame-shop" replace />} />
          </Route>
          <Route
            path="/:basePathType/collections"
            exact
            element={
              <PrivateRoute>
                <Collections />
              </PrivateRoute>
            }
          >
            <Route
              path="/:basePathType/collections/:collectionType" exact
              element={<NftCollectionsV3 />}
            />
              <Route
              path="/:basePathType/collections/:collectionType/:policyId" exact
              element={<NftCollectionsV3 />}
            />
          </Route>
          {/* <Route
            path="/discover"
            element={
              <PublicRoute>
                <Explore />
              </PublicRoute>
            }
          >
           
            </Route> */}
          <Route
            path="/discover"
            element={
              <PublicRoute>
                <Discover />
              </PublicRoute>
            }
          >
            {/* <Route
              path="/discover/collections"
              element={
                <PublicRoute>
                  <DiscoverCollections />
                </PublicRoute>
              }
            /> */}
            {/* <Route
              path="/discover/allcollections"
              element={
                <PublicRoute>
                  <DiscoverAllCollections />
                </PublicRoute>
              }
            /> */}
            {/* <Route path="/discover/creators" element={<Creators />} /> */}
            <Route path="/discover/curators" exact element={<Curators />} />
            <Route path="/discover/:communityType" element={<CommunitiesCategory />} />
            <Route path="/discover/:communityType/:categoryId" element={<CommunityListing />} />
            <Route path="/discover/creators/artists" element={<Artists />} />
            <Route path="/discover/creators/photographers" element={<Photographers />} />
            <Route path="/discover/creators/musicians" element={<Musicians />} />
            <Route
              path="/discover/creators/:categoryName/collections/:category"
              element={
                <PublicRoute>
                  <ExploreCategoryCollections />
                </PublicRoute>
              }
            />
            <Route
              path="/discover/creators/:categoryName/collections/:category/:subcategory"
              element={
                <PublicRoute>
                  <SubcategoryCollection />
                </PublicRoute>
              }
            />
            <Route
              path="/discover/collection/:categoryName/details/:dataid"
              element={<CreatorDetail />}
            />
            <Route
              path="/discover/all-creators/:communityId/:tab"
              element={<CreatorDetail />}
            />
            <Route
              path="/discover/all-creators/:communityId/:tab/:dataid"
              element={<CreatorDetail />}
            />
            <Route
              path="/discover/creators/:categoryIdName/:communityId/:tab"
              element={<CreatorDetail />}
            />
            <Route
              path="/discover/creators/:categoryIdName/:communityId/:tab/:dataid"
              element={<CreatorDetail />}
            />
            <Route path="/discover/curators/:curatorId" exact element={<CuratorCommunityDetail />} />
            <Route path="/discover/curators/:curatorId/:tab" exact element={<CuratorCommunityDetail />} />
            <Route path="/discover/curators/:curatorId/:tab/:collectionId" element={<CuratorCommunityDetail />} />
            <Route path="/discover/collectors" element={<Collectors />} />
            <Route
              path="/discover/collectors/:userId"
              element={<CollectorDetail />}
            />
            <Route
              path="/discover/collectors/:userId/:tab"
              element={<CollectorDetail />}
            />
            <Route
              path="/discover/collectors/:userId/:tab/:galleryId"
              element={<CollectorDetail />}
            />
            <Route
              path="/discover/collectors/:userId/:tab/view/:communityCategoryId"
              element={<CollectorDetail />}
            />
            <Route
              path="/discover/collectors/:userId/:tab/:collectionCategoryId/view"
              element={<CollectorDetail />}
            />
          </Route>
          <Route
            path="/home"
            element={
              <PublicRoute>
                <HomeV2 />
              </PublicRoute>
            }
          />
          {/* <Route path="/homeV2" element={<PublicRoute><HomeV2 /></PublicRoute>} /> */}
          <Route path="/preview-gallery" element={<PreviewGallery />} />
          <Route
            path="/knowledge-base/:tab"
            element={
              <PublicRoute>
                <InformationDesk />
              </PublicRoute>
            }
          />
          {/* <Route
            path="/knowledge-base/:tab/:id"
            element={
              <PublicRoute>
                <InformationDetails />
              </PublicRoute>
            }
          /> */}
          <Route
            path="/global/search/:selectedTab/:search"
            element={
              <PublicRoute>
                <SearchResults />
              </PublicRoute>
            }
          />
          <Route
            path="/global/search/collections/:search/:category/view"
            element={
              <PublicRoute>
                <CollectionCategoryList />
              </PublicRoute>
            }
          />
          <Route
            path="/global/search/creators/:search/:categoryId/view"
            element={
              <PublicRoute>
                <CommunityCategoryList />
              </PublicRoute>
            }
          />
          <Route
            path="/collector"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          >
            {/* <Route path="/profile/user" element={<Profile />} /> */}
            <Route path="/collector/profile/:tab" element={<Profile />} />
            <Route path="/collector/profile/:tab/view" element={<Profile />} />
            <Route path="/collector/subscription" element={<Subscription />} />
            <Route path="/collector/account" element={<Account />} />
            <Route path="/collector/preferences" element={<Preferences />} />
            <Route
              path="/collector/my-verified-nft-policies"
              element={<MyVerifiedNFTPolicies />}
            />
            <Route
              path="/collector/wallet-authentication"
              element={<WalletAuthMesh />}
            />
            <Route path="/collector/access-plans" element={<AccessPlans />} />
            <Route path="/collector/my-nft-calendar" element={<MyNFTCalendar />} />
            <Route path="/collector/nft-residuals" element={<NFTResiduals />} />
            <Route
              path="/collector/galaries-rent-out"
              element={<GalariesRentOut />}
            />
            <Route path="/collector/galaries-rent" element={<GalariesRent />} />
            <Route path="/collector/purchase" element={<Purchase />} />
            <Route path="/collector/rewards" element={<Rewards />} />
          </Route>
          <Route path="/curate/display" exact element={<PrivateRoute><Display /></PrivateRoute>}/>  
          <Route path="/curate/display/displaygallery" exact element={<PublicRoute><DisplayGallery /></PublicRoute>}/> {/* this needs to be public as displays will not be logged in*/}
          <Route path="/curate/display/:id" element={<PrivateRoute><DisplayItem /></PrivateRoute>}/>   
          <Route path="/curate/rentals" exact element={<PrivateRoute><Rentals /></PrivateRoute>}/>           
            <Route path="/curate/display/my-galleries" exact element={<PrivateRoute><NftGallery /></PrivateRoute>}>
              <Route path="view/:galleryId" element={<NftGallery />} />
              <Route path="add/:pgaction" element={<NftGallery />} />
              <Route path="add/:pgaction/:policyid" element={<NftGallery />} />
              <Route path="edit/:galleryId/:pgaction" element={<NftGallery />} />
              <Route path="edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
            </Route>
          
          <Route path="/creator" exact element={ <PrivateRoute> <Community /> </PrivateRoute> } >
            <Route path="/creator" exact element={<CommunityList />} />
            {/* <Route path="/creator/update-data" exact element={<CommunityUpdate />} />
            <Route path="/creator/update-data/:id" exact element={<CommunityUpdate />} /> */}
            {/* <Route exact path="/creator/my-galleries/:communityid"  element={<NftGallery />} /> */}
            {/* <Route exact path="/creator/collections/update-collection" element={<UpdateCollection />} />
            <Route exact path="/creator/collections/update-collection/:collectionId" element={<UpdateCollection />} /> */}
            <Route path="/creator/:communityid/:tab" element={<CreatorCommunityDetail />} />
            <Route path="/creator/:communityid/:tab/:collectionId" element={<CreatorCommunityDetail />} />
            <Route path="/creator/:communityid/:tab/view/:galleryId" exact element={<CreatorCommunityDetail />} />
            {/* <Route path="/creator/:communityid/my-galleries/view/:galleryId" exact element={<NftGallery />} /> */}
            <Route path="/creator/:communityid/my-galleries/add/:pgaction" exact element={<NftGallery />} />
            <Route path="/creator/:communityid/my-galleries/add/:pgaction/:policyid" element={<NftGallery />} />
            <Route path="/creator/:communityid/my-galleries/edit/:galleryId/:pgaction" element={<NftGallery />} />
            <Route path="/creator/:communityid/my-galleries/edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
          </Route>
          <Route path="/curator" exact element={ <PrivateRoute> <Curator /> </PrivateRoute> } >
            <Route path="/curator" exact element={<CreateCuratorCommunity />} />
            <Route path="/curator/:mainTab/:curatorId/:tab" element={<CuratorCommunityDetail />} />
            <Route path="/curator/:mainTab/:curatorId/:tab/:collectionId" element={<CuratorCommunityDetail />} />
            <Route path="/curator/:mainTab/:curatorId/:tab/view/:galleryId" exact element={<CuratorCommunityDetail />} />
            <Route path="/curator/:mainTab/:curatorId/my-galleries/add/:pgaction" exact element={<NftGallery />} />
            <Route path="/curator/:mainTab/:curatorId/my-galleries/add/:pgaction/:policyid" element={<NftGallery />} />
            <Route path="/curator/:mainTab/:curatorId/my-galleries/edit/:galleryId/:pgaction" element={<NftGallery />} />
            <Route path="/curator/:mainTab/:curatorId/my-galleries/edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
          </Route>
          <Route
            path="/curate/activities/:tab"
            element={
              <PrivateRoute>
                <Activities />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/curate/activities/posts"
            element={
              <PublicRoute>
                <AnnouncementsList />
              </PublicRoute>
            }
          />
          <Route
            path="/curate/activities/events"
            element={
              <PublicRoute>
                <Events />
              </PublicRoute>
            }
          />          */}
        
          {/* <Route
            path="/listen"
            exact
            element={
              <PublicRoute>
                <Collections />
              </PublicRoute>
            }
          >
            <Route
              path="/listen/collections/:collectionType" exact
              element={<NftCollectionsV3 listen={true}/>}
            />
            <Route
              path="/listen/collections/:collectionType/:policyId" exact
              element={<NftCollectionsV3 listen={true}/>}
            />
          </Route> */}
          <Route path="*" element={<ComingSoon />} />
        </Routes>
    );
  }
};
export default RoutesMain;
