import { useState, useCallback, useEffect, useRef } from 'react';
import useHttp from './use-http';
import useInfiniteScroll from './use-infiniteScroll'; // Your custom useInfiniteScroll hook

const isAnyWall = process.env.REACT_APP_COMPANY === 'anywall';

function useGalleryList(tab, itemsPerPage, elementId = null) {
    const [galleryData, setGalleryData] = useState({
        private: { items: [], totalItems: 1, pageNo: 1, initialLoaded: false, lastFetchedPage: 0 },
        public: { items: [], totalItems: 1, pageNo: 1, initialLoaded: false, lastFetchedPage: 0 },
        'my-rentals': { items: [], totalItems: 0, pageNo: 1, initialLoaded: true } // need to set false when calling api for this
    });
    const [initialLoaded, setInitialLoaded] = useState(false)
    const [totalItems, setTotalItems] = useState({ privateTotalItems: 1, publicTotalItems: 1 });
    const { triggerAPI } = useHttp();
    const containerRef = useRef();

    const fetchMoreData = useCallback(() => {
        const currentTabData = galleryData[tab];
        if (currentTabData.pageNo * itemsPerPage >= currentTabData.totalItems) {
            setIsFetching(false);
            return;
        }
        setGalleryData(prevData => ({
            ...galleryData,
            [tab]: { ...galleryData[tab], pageNo: (galleryData[tab].pageNo + 1) }
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, galleryData]);

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreData, elementId ? {elementId} : {scrollRef: containerRef});

    const getGalleryResult = useCallback((res) => {
        const { pageMeta: { totalItems, page }, list, privateGalleryCount, publicGalleryCount } = res?.data;
        setGalleryData(prevData => ({
            ...prevData,
            [tab]: {
                ...prevData[tab],
                items: [...prevData[tab].items, ...list],
                totalItems,
                lastFetchedPage: page,
                initialLoaded: true
            }
        }));
        setTotalItems({ privateTotalItems: Number(privateGalleryCount), publicTotalItems: Number(publicGalleryCount) });
        setIsFetching(false);
        setInitialLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, galleryData]);

    const getGalleryList = useCallback(() => {
        let filter = {
            sortBy: tab === 'public' ? "priority" : '',
            orderBy: tab === 'public' ? "desc" : ''
        };
        if (!isAnyWall)
            filter = { ...filter, isDiscoverable: tab === 'public' }
        const data = {
            page: galleryData[tab].pageNo,
            items: itemsPerPage,
            filter
        };
        triggerAPI({
            url: `gallery/user/list/get`,
            data,
            method: 'post'
        }, getGalleryResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, galleryData]);

    useEffect(() => {
        if (!galleryData[tab]?.initialLoaded && tab !== 'my-rentals') {
            getGalleryList();
            setIsFetching(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, galleryData]);

    useEffect(() => {
        if (galleryData[tab]?.pageNo > 1 && galleryData[tab]?.pageNo > galleryData[tab]?.lastFetchedPage) {
            getGalleryList();
            setIsFetching(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryData[tab]?.pageNo, galleryData[tab]?.lastFetchedPage]);

    return {
        galleryData,
        totalItems,
        isFetching,
        containerRef,
        setIsFetching,
        initialLoaded,
        setGalleryData
    };
}

export default useGalleryList;
