import "./BackgroundWallFilter.scss";
import SkeletonLoader from "../SkeletonLoader";
import { useCallback } from "react";

const BackgroundWallFilter = ({ setColor,setNoColor=()=>{}, color, passColorId= false, multiColorList=[] }) => {
 
  const onColorSelect = useCallback((e, colorId) => {
    let selectedColor = e.currentTarget?.dataset?.id;
    setNoColor && setNoColor(true)
    console.log('clicking here', passColorId, e.currentTarget?.dataset?.id);
    if(passColorId){
      setColor(prev => ({ ...prev, color: prev?.color === colorId ? '' : colorId}));
    } else {
      if(selectedColor !== color)
      setColor(prev => ({ ...prev, color: e.currentTarget?.dataset?.id }));
    }
    // onMouseBlur();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passColorId, color]);
  const getSelected = useCallback((currentColor = null) => {
    return (color === currentColor?.name || color === currentColor?.id)
  }, [color])
  return (
    <div className="wall-filter flex items-center w-full justify-end">
      <div className="flex gap-2 items-center">
        {/* {styleList?.map((val, i) => (
          <div
            key={i + 1}
            className={`style-box capitalize ${
              style === val?.value && "active"
            }`}
            onClick={() => onStyleHandler(val?.value)}
          >
            {val?.name}
          </div>
        ))} */}
            {
              multiColorList ? 
                  multiColorList?.length?
                  multiColorList?.map((colors) => (
                  <div
                    onClick={(e)=>onColorSelect(e, colors?.id)}
                    key={colors.id}
                    data-id={colors?.name}
                    className={`color-circle ${
                      getSelected(colors) ? "selected" : ""
                    } ${colors.name === "multi-color" ? "multi" : ""}`}
                    style={{ backgroundColor: colors.name }}
                  ></div>
                ))
                :
                <p className="flex justify-center items-center no-color">No colors found</p>
                :
                <div className="flex gap-2">{[...Array(11)]?.map((el, index) => (
                  <div className={"h-full w-full skeleton-wrapper"} key={index + 1}>
                    <SkeletonLoader
                      height={"1rem"}
                      inline={true}
                      containerClassName={""}
                    />
                  </div>
                ))}</div>
            }
      </div>
      {/* <div
        className="color-wrapper w-[4.081875rem] flex items-center relative"
        onMouseOverCapture={onMouseFocus}
        onMouseLeave={onMouseBlur}
      >
        <span className={`color-text ${color ? "w-auto" : "w-full"}`}>
          Color
        </span>
        {!!color && (
          <div
            className={`color-show ${color === "multi-color" ? "multi" : ""}`}
            style={{ backgroundColor: color }}
          ></div>
        )}
        {!!dropdown && (
          <div className="color-list">
            <div className="color-list-wrapper">
            {multiColorList?.length?multiColorList?.map((colors) => (
              <div
                onClick={onColorSelect}
                key={colors.id}
                data-id={colors?.name}
                className={`color-circle ${
                  color === colors?.name ? "selected" : ""
                } ${colors.name === "multi-color" ? "multi" : ""}`}
                style={{ backgroundColor: colors.name }}
              ></div>
            )):<p className="flex justify-center h-full w-full items-center no-color">No colors found</p>}
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default BackgroundWallFilter;
