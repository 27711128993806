import { useStore } from 'App/hooks-store/store';
import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS } from 'react-joyride';
import './Instruction.scss'

const Instruction = ({steps, isRun}) => {
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const dispatch = useStore()[1]
    useEffect(() => {
        setRun(isRun)
    }, [isRun])
    const handleJoyrideClose = () => {
        setRun(false);
        setStepIndex(0);
        dispatch("setShowInstruction", false);
    };
      
    const handleJoyrideCallback = (data) => {
        const { action, index, origin, status, type } = data;
    
        if (action === ACTIONS.CLOSE || origin === ORIGIN.KEYBOARD) {
          // do something
          handleJoyrideClose();
          setRun(false);
        }
    
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
          // Update state to advance the tour
          setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            handleJoyrideClose();
          // You need to set our running state to false, so we can restart if we click start again.
        }
        // console.groupCollapsed(type);
        // console.log(data); //eslint-disable-line no-console
        // console.groupEnd();
      };
    return <Joyride callback={handleJoyrideCallback} 
            stepIndex={stepIndex} steps={steps} hideCloseButton={true} disableOverlayClose={true}
            continuous={true} run={!!run}showProgress={true} />
}
export default Instruction;