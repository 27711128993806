import useResizeObserver from "@react-hook/resize-observer";
import { deepCopy, getMetadata, getSplice, interpolate } from "App/Helper/utilities";
import useFrames from "App/hooks/use-frames";
import useHttp from "App/hooks/use-http";
import { useEffect, useState, useRef, useCallback, useMemo, memo } from "react";
import GallerySlider from "./GallerySlider";
import GalleryCaption from "./GalleryCaption";
import MetaGallery from "App/Pages/MetaGallery";
import GalleryControlPanel from "./GalleryControlPanel";
import { setwaittime, setispaused, setnextimage } from '../../MetaGallery/Component/MetaGalleryState';
import { useDispatch, useSelector } from "react-redux";
import { toMilliseconds } from "App/Helper/getTime";
import useLayoutData from "App/hooks/use-layout";
import { updateCaptionControls, updateCurrentMultiLayoutData, updateFrameControls } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import "./GalleryPreviewPlugin.scss";
import PageLoader from "App/Components/PageLoader";
import { STATICURL } from 'App/Helper/staticfiles';
import useEventListener from "App/hooks/use-eventListener";
import useWall from "App/hooks/use-wall";
import { useDevice } from "App/hooks/use-ui";
let timer;
const GalleryPreviewPlugin = ({galleryData, galleryType = 'mono', bgColor, noEnlargeView, initalTimes = null, multiLayout = null, multiBgData = null, metaBgData = null, settingsData = null, 
    screenData = null, isAppV1 = false, isAppV2 = false, galleryInformation = null, className = '', handleGalleryView = null, getControlPanelVisibility = null, onCollectChanges=null, multiQRCodeURL=null}) => {
    const dispatch = useDispatch();
    const device = useDevice();
    var timerChanged = 0;
    const { getLayoutFromStore } = useLayoutData();
    const { getFrameDetails } = useFrames();
    let ispausedINMeta = useSelector((state) => state.appState.ispaused.payload) ?? false;
    const galleryPreviewRef = useRef(null);
    const gallerySliderRef = useRef(null);
    const isMono = galleryType === 'mono' || !galleryType;
    const isMulti = galleryType === 'multi';
    const isMeta = galleryType === 'meta';
    const [screenDimension, setScreenDimension] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [controlPanelVisibility, setControlPanelVisibility] = useState(false);
    const [toggleControls, setToggleControls] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [galleryInfo, setgalleryInfo] = useState(null);
    const { getMetaWallFromStore } = useWall();
    const [metaWallList, setMetaWallList] = useState([]);
    const { triggerAPI } = useHttp();
    const [galleryList, setGalleryList] = useState([]);
    const [filteredGalleryList, setFilteredGalleryList] = useState([]);
    const [multiGalleryList, setMultiGalleryList] = useState([]);
    const [multiWallList, setMultiWallList] = useState([]);
    const [multiColorList, setMultiColorList] = useState([]);
    const [multiStyleList, setMultiStyleList] = useState([]);
    const [multiLayoutList, setMultiLayoutList] = useState([])
    const [bgWallFilter, setBgWallFilter] = useState({ color: multiBgData?.color ?? 'grey', style: '' });
    const [selectedMultiLayout, setSelectedMultiLayout] = useState({layout: null, size: '1.07'});
    const [selectedMultiBg, setSelectedMultiBg] = useState(null);
    const [selectedMetaBg, setSelectedMetaBg] = useState(null);
    const [screenControls, setScreenControls] = useState({
        styleType: 'ful-scr',
        frameType: 0,
        screenFrameType: 0,
        imageLayout: { size: '2.5', bgColor: '#000'},
        imageBorder: { thickness: 0.03, bgColor_1: '#ccc', bgColor_2: '#fff' }
    });
    const [settingControls, setSettingControls] = useState({
        animation: 'fade',
        captionCard: {
            postition: 'right', 
            time: 0,
            captionCard: false,
            disableControl: false,
            QRCodeShow: false,
            cardStyle: '',
            cardOpacity: '',
        },
        enhancedContent: { 
            show: false,
            onChainRenders: false,
            clickCount: 0
        }
    });
    const initialTime = useMemo(() => {return initalTimes ?? { hr: '0', min: '0', sec: '10' }}, [initalTimes])
    const [currentTimer, setCurrentTimer] = useState({ hr: '0', min: '0', sec: '10' })
    const [slidePlaying, setSlidePlaying] = useState(false);
    const [currentPlayingAsset, setcurrentPlayingAsset] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [musicPlayerList, setMusicPlayerList] = useState([]);
    //end of state declaration
    useEffect(() => {
        if(!!onCollectChanges){
            onCollectChanges({settingControls, slidePlaying, currentTimer, screenControls, selectedMultiLayout, selectedMultiBg, galleryType, selectedMetaBg})
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls, slidePlaying, currentTimer, screenControls, selectedMultiLayout, selectedMultiBg, galleryType, selectedMetaBg])
    
    useEffect(() => {
        setToggleControls(false)
    }, [galleryType])
    
    useEffect(() => {
        if(galleryInformation) {
            setgalleryInfo(galleryInformation)
        }
    }, [galleryInformation])
    
    useEffect(() => {
        if(multiBgData && galleryType === 'multi') {
            setSelectedMultiBg(multiBgData)
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiBgData, galleryType])
    
    useEffect(() => {
        if(!selectedMetaBg && galleryType === 'meta') {
            let selectedMeta = !!metaBgData ? metaBgData : (!!metaWallList?.length ? metaWallList[0] : null)
            setSelectedMetaBg(selectedMeta)
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaBgData, galleryType, metaWallList])
    useEffect(() => {
        if(screenData) {
            if(device === 'mobile') {
                // in mobile default view is ful-scr in mono
                setScreenControls(prev => { return {...prev, ...screenData, styleType: 'ful-scr',}})
            } else {
                setScreenControls(prev => { return {...prev, ...screenData}})
            }
        }
    }, [device, screenData])
    useEffect(() => {
        if(settingsData) {
            setSettingControls(prev => { return {...prev, ...settingsData}})
        }
    }, [settingsData])
    useEffect(() => {
        if(multiLayout?.size) {
            setSelectedMultiLayout(prev => { return {...prev, ...multiLayout?.layout, size: multiLayout?.size}})
        }
    }, [multiLayout])
    useEffect(() => {
        if(initalTimes) {
            setCurrentTimer(initalTimes)
            gallerySliderRef && gallerySliderRef?.current?.toggleSlidePlay(initalTimes);  //intialy start slide to play
            setSlidePlaying(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initalTimes])
    const updatePageData = useCallback((response) => {
        const data = deepCopy(response);
        setSelectedMultiLayout(prev => { return {...prev, layout: multiLayout?.layout ?? data?.Layout}})
        let galleryList = [], frameIds = [], tempMusicPlayerList = [];
        (data?.assets || []).forEach(iteratingItem => {
            const item = {...iteratingItem};
            if (item.details) {
                item.details = {...item.details, image: item.details.nftcdnimage256};

                if (item.frame) {
                    const metaInfo = item.isLandscape ? item.frame.metaInfo?.landscape : item.frame.metaInfo?.portrait;
                    const image = item.isLandscape ? (item.frame.s3ImageUrlLandscape || item.frame.s3ImageUrl): item.frame.s3ImageUrl;
                    item.frame = { ...item.frame, metaInfo, image };
                } else {
                    item.frameId = null;
                }
                const onchainMetadata = getMetadata(item?.details);
                item.details = {...item?.details, onchain_metadata: onchainMetadata?.onchain_metadata || onchainMetadata?.metadata}
                if (onchainMetadata?.files?.length > 0) {
                    let tempFiles = onchainMetadata?.files;
                    let audioFiles = tempFiles?.filter(item => item?.mediaType?.includes("audio"))
                    if(!!audioFiles?.length) {
                        tempMusicPlayerList = [...tempMusicPlayerList, {...item?.details, files: audioFiles}]
                    }
                    let expectAudioFiles = tempFiles?.filter(item => !item.mediaType?.includes("audio")) 
                    if(!!expectAudioFiles) 
                        item.details = {...item?.details, onchain_metadata: {...item?.details?.onchain_metadata, files: expectAudioFiles}}
                    setSettingControls(prev =>  { 
                        return {...prev, enhancedContent: { ...prev.enhancedContent, show: true }}
                    })
                }
                    

                if (item.frameId && !frameIds.includes(item.frameId)) {
                    frameIds.push(item.frameId);
                }
                galleryList.push(item);
            }
        });
        if(frameIds?.length)
            getFrameDetails(frameIds, () => {
                setFilteredGalleryList(galleryList);
                setGalleryList(galleryList); //if frames avaiable in side gallery, this list will set after frames fetched
            });   
        else {
            setGalleryList(galleryList);
            setFilteredGalleryList(galleryList);
        }
        setMusicPlayerList(tempMusicPlayerList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryData, galleryType])

    useEffect(() => {
        if(galleryData)
            updatePageData(galleryData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryData, galleryType])
    //end of inital data update from parent

    const fetchMultiWallListResult = useCallback((res) => {
        const { data } = res;
        const tempWall = data?.map(item => { item.value = item.id; return item });
        setMultiWallList(tempWall);
    }, [])

    const fetchMultiWallList = useCallback(() => {
        const url = `background-thumbnail/user/get`;
        triggerAPI({
            url: url, data: { ...bgWallFilter }, method: "post"
        }, fetchMultiWallListResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMultiWallListResult, triggerAPI]);

    useEffect(() => {
        fetchMultiWallList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgWallFilter])

    const fetchMultiColorListResult = useCallback((res) => {
        const { data } = res;
        let list;
        list = data.map((clr) => {
            return { name: clr.name, id: clr.id };
        });
        setMultiColorList(list);
    }, [])
    const fetchMultiColorList = useCallback(() => {
        triggerAPI({
                url: `frame-asset/color/get`,
                method: "get",
            },fetchMultiColorListResult);
    }, [fetchMultiColorListResult, triggerAPI])

    const fetchMultiStyleListResult = useCallback((res) => {
        const { data: list } = res;
        let result = list?.map((a) => {
            return { value: a.style, name: a.style };
        });
        const newObjec = { value: "", name: "all" };
        result.unshift(newObjec)
        setMultiStyleList(result);
    }, [])
    const fetchMultiStyleList = useCallback(() => {
        triggerAPI({
                url: `background-thumbnail/style/get`,
                method: "get",
            },fetchMultiStyleListResult);
    }, [fetchMultiStyleListResult, triggerAPI])

    const handleZoomIn = () => {
        if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
        } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen();
        } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen();
        }
        setFullScreen(true);
        setSlidePlaying(false);
    }

    const exitHandler = () => {
        if (!(document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement)) {
            setFullScreen(false);
            setSlidePlaying(false);
        }
    }
    useEffect(() => {
        fetchMultiStyleList();
        fetchMultiColorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEventListener('fullscreenchange', exitHandler);
    useEventListener('webkitfullscreenchange', exitHandler);
    useEventListener('mozfullscreenchange', exitHandler);
    useEventListener('MSFullscreenChange', exitHandler);
    //end of api call for fetch lists
    useResizeObserver(galleryPreviewRef, (entry) => {   
        if(screenDimension?.width !== entry.contentRect.width || screenDimension?.height !== entry.contentRect.height) {
            setScreenDimension({ width: entry.contentRect.width, height: entry.contentRect.height});
        }   
    },[]); 
    useEffect(() => {
        getLayoutFromStore((res) => {
            setMultiLayoutList(res);
        })
        getMetaWallFromStore(data => {
            setMetaWallList(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleMouseLeave = useCallback(() => {
        setControlPanelVisibility(false)
    },[]);
    const getMultiGallerySlides = useCallback(() => {
        let splice = selectedMultiLayout?.layout?.count ? selectedMultiLayout?.layout?.count : 8;
        const result = getSplice(galleryList, splice)
        setMultiGalleryList(result)
        dispatch(updateCurrentMultiLayoutData(selectedMultiLayout?.layout));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMultiLayout, galleryList])
    
    useEffect(() => {
        if (galleryList?.length && isMulti) {
            setMultiGalleryList([]);
            setTimeout(() => {
                getMultiGallerySlides();
            }, 10);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryList, isMulti, selectedMultiLayout?.layout]);
    const timerOnchange = useCallback((e) => {
        if (e) {
            const { hr, min, sec } = e;
            setCurrentTimer({ hr, min, sec })
            let ms = toMilliseconds(hr, min, sec);
            if (isMeta) {
                dispatch(setwaittime(ms));
            } else {
                ++timerChanged;
                if(timerChanged > 3 && !isAppV1 && !isAppV2) { //first 3 times for inital setup
                // if(true) { //first 3 times for inital setup
                    gallerySliderRef && gallerySliderRef?.current?.toggleSlidePause(0);
                    setSlidePlaying(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMeta]);
    const toggleSlidePlay = useCallback(() => {
        if (isMeta) {
            dispatch(setispaused(false));
        } else {
            gallerySliderRef && gallerySliderRef?.current?.toggleSlidePlay(currentTimer);
        }
        setSlidePlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTimer, isMeta]);
    const toggleSlidePause = useCallback(() => {
        if (isMeta) {
            dispatch(setispaused(true));
        } else {
            gallerySliderRef && gallerySliderRef?.current?.toggleSlidePause(0);
        }
        setSlidePlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMeta]);
    const handleNextSlide = () => {
        if (isMeta) {
            dispatch(setnextimage(true));
            toggleSlidePlay();
        } else {
            gallerySliderRef && gallerySliderRef?.current.goToNextSlide();
            setSlidePlaying(false);
        }                
    }
    const handlePrevSlide = () => {
        gallerySliderRef && gallerySliderRef?.current.goToPrevSlide();
        setSlidePlaying(false);               
    }
    const slideGoTo = (slideIndex, showLoader = true) => {
        setShowLoader(showLoader);
        gallerySliderRef?.current?.goToSlide(slideIndex);
        setTimeout(() => {
            setShowLoader(false);
        }, 5000);
    }
    const captionUpdater = useCallback((currentTimer) => {

        let captionTiming = currentTimer ?? settingControls?.captionCard?.time;
        if (captionTiming) {
            if (captionTiming !== -1) {
                let timeInterval = parseFloat(captionTiming) * 60000;
                setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                const updateTimings = () => {
                    setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, disableControl: true } } })
                    setTimeout(() => {
                        setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: false, disableControl: false } } })
                        //clear any existing timers, to ensure we don't duplicate them
                        clearInterval(timer);
                        timer = setInterval(() => {
                            setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                            clearInterval(timer);
                            updateTimings();
                        }, timeInterval);
                    }, 5000);
                }
                updateTimings();
            } else {
                setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                clearInterval(timer);
            }
        } else {
            setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: false } } })
            clearInterval(timer);
        }
    }, [settingControls?.captionCard])
    useEffect(() => {
        captionUpdater()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard?.time])
    useEffect(() => {
        //to use caption controls in caption card
        dispatch(updateCaptionControls(settingControls?.captionCard));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard?.captionCard, settingControls?.captionCard?.disableControl, settingControls?.captionCard?.QRCodeShow, settingControls?.captionCard?.cardStyle, settingControls?.captionCard?.cardOpacity])
    useEffect(() => {
        //to use frame controls in mono
        dispatch(updateFrameControls((screenControls?.styleType === 'ful-scr' ? false : screenControls?.frameType)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenControls?.frameType, screenControls?.styleType, galleryType])
    const setPageLoading = useCallback(() => {
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    }, [])
    useEffect(() => {
        if(galleryType === 'mono') {
            setPageLoading();
        }       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryType])
    useEffect(() => {
        if(galleryType === 'multi') {
            setPageLoading();
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMultiLayout?.size, galleryType])

    
    const styles = useMemo(() => {
        let style = {'--image-size-padding': '0%', 
                     '--multi-slide-transform': `scale(1)`,
                     '--gallery-width': screenDimension.width, 
                     '--gallery-height': screenDimension.height}
        if(isMulti) {
            if(!!selectedMultiLayout?.size) 
                style = {...style, '--multi-slide-transform': `scale(${selectedMultiLayout?.size})`}
        }
        if(isMono) {
            // if(screenControls?.styleType === 'custom') {
            const basePadding = 5;
            let newpadding = basePadding - Number(screenControls?.imageLayout?.size ?? 0)
            let col1 = screenControls?.imageBorder?.bgColor_1;
            let col2 = screenControls?.imageBorder?.bgColor_2;
            if (screenControls?.styleType !== 'custom')
            {                    
                newpadding = 0;                    
            }                
            style = {...style,
                '--image-color-background': screenControls?.imageLayout?.bgColor ?? "#000",
                '--image-color-border-1': col1,
                '--image-color-border-2': col2,
                '--image-color-border-3': interpolate(col1, col2, 0.5),
                '--image-size-padding': `${newpadding}%` ?? '0%',
                '--screen-frame-texture': `url(${STATICURL}images/detailframes/${screenControls?.screenFrameType}.jpg)`,
                'background': screenControls?.imageLayout?.bgColor ?? "#000",
                '--frame-thickness': `${screenControls?.imageBorder?.thickness ?? 0.03}px`
            };   
        }
        return style
    }, [isMulti, isMono, screenControls?.styleType, 
        screenControls?.imageBorder, screenControls?.imageLayout?.size, selectedMultiLayout?.size, screenControls?.imageLayout?.bgColor,
        screenControls?.screenFrameType, screenDimension
    ]);

    
    useEffect(() => {
        //updating control visibility to parent
        getControlPanelVisibility && getControlPanelVisibility(controlPanelVisibility)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlPanelVisibility])

    useEffect(() => {
        if(isMeta)
            setSlidePlaying(!ispausedINMeta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMeta, ispausedINMeta])
    return (
        <div ref={galleryPreviewRef}
            onMouseLeave={handleMouseLeave} className={`${className} h-full`}>                
            <div className={`modern-gallery-preview-container inview fram-bg ${isMulti && 'multi-view'} 
                ${isMono && `screen-frame-type-${screenControls?.screenFrameType} mono-screen mono-view ${screenControls?.styleType}
                   ${(isNaN(parseInt(screenControls?.screenFrameType))) && `screen-frame-type-texture`} 
                   ${screenControls?.frameType === 0 && `no-detail-frame`}`} ${screenControls?.screenFrameType === "0" && 'no_frames_selected-MR'} ${isMeta && 'meta-view'} preview_changes-for-MR`}
                style={styles}>
                {showLoader && <PageLoader />}
                <div className="frame-detail-1"></div>
                <div className="frame-detail-2"></div>
                <div className="frame-detail-3"></div>
                <div className="frame-detail-4"></div>
                {galleryType === 'multi' ?
                    <div className="h-full relative">
                        <GallerySlider ref={gallerySliderRef}  sliderList={multiGalleryList} 
                            onChainControl={settingControls?.enhancedContent}
                            // selectedLayout={selectedMultiLayout?.layout}
                            animation={'fade'} //always fade for multi slide
                            isMulti={true}
                            multiInnerClassName={''}
                            bgSrc={selectedMultiBg?.imageUrl}
                            setcurrentPlayingAsset={setcurrentPlayingAsset}
                            key={galleryType}
                        />
                        <GalleryCaption cardData={{ 
                            cardName: galleryInfo?.name,
                            userName: galleryInfo?.userName ?? '' 
                        }} 
                        multiQRCode={multiQRCodeURL}
                        />
                    </div>
                    :
                    isMeta ?
                        <MetaGallery galleryList={galleryList} captionControl={settingControls?.captionCard} key={selectedMetaBg?.id + isMeta} type={isMeta} galleryId={selectedMetaBg?.id} oldGalleryId={null} />
                        :
                        <div className={`slider-container relative h-full`}>
                            {!!filteredGalleryList?.length && <GallerySlider ref={gallerySliderRef}  sliderList={filteredGalleryList} showLoader={showLoader}
                                setShowLoader={setShowLoader} isMulti={false} animation={settingControls?.animation} 
                                setcurrentPlayingAsset={setcurrentPlayingAsset}
                                key={galleryType}              
                            />}
                        </div>
                }
            </div>
            <GalleryControlPanel 
                isMeta={isMeta} isMulti={isMulti} isMono={isMono} galleryType={galleryType}
                galleryList={galleryList}
                setFilteredGalleryList={setFilteredGalleryList}
                timerOnchange={timerOnchange}
                initialTime={initialTime}
                toggleControls={toggleControls}
                setToggleControls={setToggleControls}
                handleNextSlide={handleNextSlide}
                handlePrevSlide={handlePrevSlide}
                toggleSlidePlay={toggleSlidePlay}
                toggleSlidePause={toggleSlidePause}
                slidePlaying={slidePlaying}
                bgColor={bgColor}
                currentPlayingAsset={currentPlayingAsset}
                slideGoTo={slideGoTo}
                setSettingControls={setSettingControls} settingControls={settingControls}
                setScreenControl={setScreenControls} screenControls={screenControls}
                selectedMultiLayout={selectedMultiLayout} setSelectedMultiLayout={setSelectedMultiLayout} multiLayoutList={multiLayoutList?.filter(item => item?.count <= galleryList?.length)}
                multiStyleList={multiStyleList} multiColorList={multiColorList} bgWallFilter={bgWallFilter} setBgWallFilter={setBgWallFilter} multiWallList={multiWallList}
                selectedMultiBg={selectedMultiBg} setSelectedMultiBg={setSelectedMultiBg} metaWallList={metaWallList} selectedMetaBg={selectedMetaBg} setSelectedMetaBg={setSelectedMetaBg}
                controlPanelVisibility={controlPanelVisibility} setControlPanelVisibility={setControlPanelVisibility} isAppV1={isAppV1}
                handleZoomIn={handleZoomIn} zoom={fullScreen || noEnlargeView}
                handleGalleryView={handleGalleryView}
                isAppV2={isAppV2}
                multiQRCode={multiQRCodeURL}
            />
            
        </div>
    )
}

export default memo(GalleryPreviewPlugin);