import React from 'react';
import styles from "./SelectedInformation.module.scss"

const SelectedInformation = ({ selectedData ,setBgImgLoading, mobileView= false }) => {
  const positionStyle = {
    left: selectedData?.metaInfo?.image?.xPercent + "%",
    top: selectedData?.metaInfo?.image?.yPercent + "%",
    width: selectedData?.metaInfo?.image?.widthPercent + "%",
    height: selectedData?.metaInfo?.image?.heightPercent + "%",
  }
  const onLoadHandler=()=>{
  let timer=  setTimeout(()=> setBgImgLoading(false), 500);
  return ()=> clearTimeout(timer)
  }

  if(mobileView) {
    return (
        <div className={`w-full flex flex-col items-start gap-2 h-[30dvh] pr-[1rem]`}>
            {selectedData?.videoUrl &&
              <iframe width="100%" height="90%" src={selectedData.videoUrl} title="Information"></iframe>
            }
            <span className='text-[0.875rem] text-center font-semibold w-full'>{selectedData?.title}</span>
        </div>
    )
  }

  return (
    <div className="h-full select-none">
      <div className={`relative w-full`}>
        <img src={selectedData.imageUrl}  onLoad={onLoadHandler} alt="Information Details" className={`${styles.image} relative`} />
        <div className="absolute" style={positionStyle}>
          {selectedData.videoUrl &&
            <iframe width="100%" height="100%" src={selectedData.videoUrl} title="Information"></iframe>}
        </div>
      </div>

    </div>
  )
}

export default SelectedInformation