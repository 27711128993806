import { useNavigate } from "react-router-dom";
import storage from "App/Helper/storage"
import { useCallback, useEffect, useRef } from "react";
import { destructStore } from "App/hooks-store/store";
import { useMsal } from '@azure/msal-react';
import API from "App/Helper/api";
import { setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import { useDispatch } from "react-redux";
import { collectorProfileRoutes } from "App/Helper/routings";
import { db } from "App/Helper/db";

const { useStore,clearDB } = require("App/hooks-store/store");
//import { dispatch } from "d3";
const useAuth = () => {        
    const { instance } = useMsal();    
    let navigate = useNavigate();
    const store = storage();
    const timeoutRefs = useRef([]);
    const dispatch = useStore()[1];    
    const reduxDispatch = useDispatch()
    const token = store.get('token') ?? '';
    const isGuest = JSON.parse(store.get('isGuest')) ?? '';
    const clearToken = useCallback(async () => {
        reduxDispatch(setShowWalletPop(false));
        store.clearAll();
        await clearDB();
        destructStore();
        await instance.logout();        
        navigate("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, store,reduxDispatch])
    const setToken = useCallback((token) => {
        store.set('token', token);
    }, [store])
    const setUser = useCallback((isGuest) => {
        store.set('isGuest', isGuest);
    }, [store])
    const deleteStore = useCallback((storageName) => {
        store.remove(storageName);
    }, [store])
    const handleB2CLogin = useCallback(async (silent) => {
        try {            
            console.log("msal init")
            dispatch("setFullPageLoader", true); 
            dispatch("setIsLoginInProgress", true); 
            await instance.initialize(); 

            var loginrequest = {                      
                popupWindowAttributes: {
                    popupSize: {
                        height: 750,
                        width: 450
                    }
                },        
                extraScopesToConsent: [`https://${process.env.REACT_APP_B2C_AUTHORITY_NAME}/${process.env.REACT_APP_B2C_API_CLIENT_ID}/${process.env.REACT_APP_B2C_API_PERMISSION}`]               
            };
            
            if(silent===true)
            {   
                try {
                    dispatch("setFullPageLoader", true)
                    dispatch("setIsLoginInProgress", true)
                    await instance.ssoSilent(loginrequest);   
                } catch (error) {
                    dispatch("setFullPageLoader", false)
                    dispatch("setIsLoginInProgress", false)
                }
                
            }
            else
            {
                if (!window.chrome)
                {
                    console.log("msal loginpopup")                
                    await instance.loginPopup(loginrequest);                                
                }
                else
                {
                    console.log("msal loginredirect")                                    
                    await instance.loginRedirect(loginrequest);          
                }
            }
            let accounts = instance.getAllAccounts();                
            if (accounts?.length > 0)
            {        
                let dbstate = await db.state.toArray();
                let lastUser = dbstate?.find(item => item?.id === 'username')    
                
                instance.setActiveAccount(accounts[0]);    
                if(lastUser?.value !== accounts[0].username) {
                    db.state.clear();
                    dispatch("setTriggerCollection", true);
                }     
                clearAllTimeouts(timeoutRefs.current);
                dispatch("setFullPageLoader", true);
                dispatch("setIsLoginInProgress", true);
                dispatch("updateUsername", accounts[0].username);
                var accessrequest = {
                    scopes: [ `https://${process.env.REACT_APP_B2C_AUTHORITY_NAME}/${process.env.REACT_APP_B2C_API_CLIENT_ID}/${process.env.REACT_APP_B2C_API_PERMISSION}` ]                
                }                
                let newtoken = await instance.acquireTokenSilent(accessrequest);                                                    
                setToken(newtoken.accessToken);   
                const res = await API({
                    url: `user/sign-in-b2c`, method: 'post', data: { company: process.env.REACT_APP_COMPANY??'cur8'}
                });
                setUser(false)      
                timeoutRefs.current[1] = setTimeout(() => {
                    dispatch("setIsLoginInProgress", false);
                }, 1000);         
                dispatch("setFullPageLoader", false);
                if (res?.data?.data?.isFirstSignIn) {
                    navigate(collectorProfileRoutes("wallets"))
                    dispatch("setShowInstruction", true);
                } else { 
                    // window.location.reload()
                }
            }
        } catch (error) {
            console.error(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const clearAllTimeouts = (timeoutRefs) => {
        timeoutRefs.forEach(timeout => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
      };
    useEffect(() => {
    const currentTimeouts = timeoutRefs.current;
    return () => {
        clearAllTimeouts(currentTimeouts);
    };
    }, []);
    return [(!!token && !isGuest), clearToken, setToken, setUser, !!isGuest, deleteStore, handleB2CLogin]
}
export default useAuth;