import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ProfileView.scss";
import DiscoverTopPanel from "App/Pages/Discover/Components/SubComponents/DiscoverTopPanel";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Border from "App/Pages/Discover/Components/SubComponents/Border";
import GalleryTab from "./Components/GalleryTab";
import DetailsTab from "./Components/DetailsTab";
import privateGalleryBanner from "Assets/Images/private-gallery-banner.png";
import NoContent from "App/Pages/Collections/Components/NftCollectionsV3/Components/NoContent";
import { collectorProfileRoutes } from "App/Helper/routings";
import DiscoverTopPanelMob from "App/Pages/Discover/Components/SubComponents/DiscoverTopPanelMob";
import { useDevice } from "App/hooks/use-ui";
import MobileTitleHeader from "App/Components/MobileTitleHeader";

const ProfileView = (props) => {
  const { triggerAPI } = useHttp();
  const navigate = useNavigate();
  const { tab } = useParams();
  const { pathname } = useLocation();
  const dispatch = useStore(false)[1];
  const [detailsInfo, setDetailsInfo] = useState({});
  const [user, setUser] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [currentTab, setCurrentTab] = useState("");
  const [noGallery, setNoGallery] = useState(false);
  const device = useDevice();
  const menuTabs = useMemo(() => {
    let tabs = [
      {
        id: "my-galleries",
        element: "Galleries",
        count: user?.createdGalleryCount ?? 0,
      },
    ];
    if (device !== "mobile") {
      tabs.push({ id: "details", element: "Details", count: 0 });
    }
    return tabs;
  }, [user?.createdGalleryCount, device]);
  //Get profile Api
  const getProfileResult = useCallback(
    (res) => {
      // dispatch('hideSpinner');
      const profileData = {
        ...res?.data,
        name: res?.data?.username,
        galleryCount: res?.data?.createdGalleryCount,
      };
      setUser(profileData);
      setTimeout(() => {
        setLoadingUserData(false);
      }, 1600);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  );
  const getProfile = useCallback(() => {
    setLoadingUserData(true);
    // dispatch('showSpinner')
    triggerAPI(
      {
        url: `user/profile/get`,
        method: "get",
      },
      getProfileResult,
      (err) => {
        setLoadingUserData(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getProfileResult, triggerAPI]);

  const clearTabValues = () => {
    dispatch("resetAll");
    dispatch("setTopMenuBarOptions", {
      showMenuIcons: false,
      showTopMenus: true,
    });
    dispatch("setMenuOptions", [
      {
        id: 1,
        element: "Collector Profile",
        link: "profile/my-galleries",
        url: collectorProfileRoutes("profileWithTab", "my-galleries"),
      },
      {
        id: 2,
        element: "Subscription",
        link: "subscription",
        url: collectorProfileRoutes("subscription"),
      },
      {
        id: 4,
        element: "Purchases",
        link: "purchase",
        url: collectorProfileRoutes("purchase"),
      },
      {
        id: 6,
        element: "Preferences",
        link: "preferences",
        url: collectorProfileRoutes("preferences"),
      },
      {
        id: 7,
        element: "Wallets",
        link: "wallet-authentication",
        url: collectorProfileRoutes("wallets"),
      },
    ]);
    dispatch("setActiveMenuOptions", 0);
  };

  const handleTabs = (e) => {
    const selectTab = e.currentTarget?.dataset?.id;
    setCurrentTab(selectTab);
    clearTabValues();
    navigate(collectorProfileRoutes("profileWithTab", selectTab));
  };

  const handleManageClick = () => navigate(`/curate/display/my-galleries`);

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refetchData]);

  useEffect(() => {
    setCurrentTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  const onNavigateToExploreRentals = () => {
    navigate("/curate/rentals");
  };
  const onDetailsInfoHandler = (data) => {
    setDetailsInfo(data);
    setCurrentTab("details");
    clearTabValues();
    navigate(collectorProfileRoutes("profileWithTab", "details"));
  };
  const onBackHandler = () => {
    setDetailsInfo({});
    navigate(-1);
  };
  useEffect(() => {
    if (device === "mobile") {
      if (!pathname?.includes("details")) {
        setDetailsInfo({});
      } else {
        setDetailsInfo(user);
      }
    }
  }, [pathname, device, user]);
  return (
    <div className="profile_view_wrapper-container">
      {!detailsInfo?.id && (
        <>
          <DiscoverTopPanel
            className={"sm:flex hidden"}
            profileData={user}
            fromOther={true}
            learnMoreLink="details"
            loaderClass="mb-[7rem]"
            editClick={props.onEditButtonClick}
            setSelectedTab={handleTabs}
            fetchingDataFromParent={loadingUserData}
          />

          <DiscoverTopPanelMob
            profileData={user}
            onDetailsInfoHandler={onDetailsInfoHandler}
            dontCallApi={true}
            fromOther={true}
            showData={{ description: true, galleries: true }}
            isImageTag={true}
            editClick={props.onEditButtonClick}
            className={"p-[1rem] pt-[1.25rem] sm:hidden flex"}
          />
          <div className="common_style-tab-container px-[1rem] pt-[1rem] sm:block hidden">
            <div className="tab-data">
              {menuTabs?.map((menu) => {
                return (
                  <div
                    onClick={handleTabs}
                    data-id={menu?.id}
                    key={menu?.id}
                    className={`tab ${
                      menu?.id === currentTab ? "active" : "not-active"
                    }`}
                  >
                    {menu?.element}{" "}
                    {!!(menu?.id === 2) ? (
                      <span className=" inline-block">{`(${parseInt(
                        menu?.count
                      )})`}</span>
                    ) : (
                      menu?.id === "my-galleries" && (
                        <span className=" inline-block">{`(${menu?.count})`}</span>
                      )
                    )}
                  </div>
                );
              })}
            </div>
            <Border className={"right-panel-b-border w-auto"} />
          </div>
          <div className="profile_view-tabs">
            {!!(currentTab === "my-galleries") ? (
              !noGallery ? (
                <GalleryTab
                  onManageClick={handleManageClick}
                  setNoGallery={setNoGallery}
                  onBackToGallery={clearTabValues}
                  onNavigateToExploreRentals={onNavigateToExploreRentals}
                />
              ) : (
                <NoContent
                  banner={privateGalleryBanner}
                  className="visibleSlowly"
                  text="Beauty is in The Eye of The Be(holder)"
                  brief="Start curating stunning galleries with your assets here."
                  onClick={handleManageClick}
                  buttonText="Manage Galleries"
                />
              )
            ) : null}
            {!!(currentTab === "details") && (
              <div className="sm:block hidden">
                <DetailsTab user={user} />
              </div>
            )}
          </div>
        </>
      )}
      {!!(currentTab === "details") && (
        <div className="sm:hidden block">
          <MobileTitleHeader
            onClick={onBackHandler}
            className="pl-4 pt-4 pr-4"
            title={"Details"}
          />
          <DetailsTab user={user} />
        </div>
      )}
    </div>
  );
};

export default ProfileView;
