import useScreenSize from "App/hooks/use-screen-size";
import { useEffect, useLayoutEffect, useRef } from "react";
import "./CollectionCard.scss";
import userBanner from 'Assets/Icons/new-logo-text.svg'
import FormatField from "../Ui/FormatField";
import { useStore } from "App/hooks-store/store";
import EditDelete from "../EditDelete";
import Logo from "../Logo";
import { getBlockchainIcon } from "App/Helper/blockchain";
import BlockChainIcon from "../Ui/BlockchainIcon";
import { thousandCommaSeparator } from "App/Helper/utilities";

const CollectionCard = (props) => {
  const screenSize = useScreenSize()?.width;
  const { leftMenuCollapse } = useStore(false)[0];
  const targetRef = useRef(null);
  const updateHeight = () => {
    const root = document.documentElement;
   
    if (targetRef.current&&targetRef?.current?.offsetWidth!==0) {
        root?.style.setProperty(
          "--collection-card-height",
          `${targetRef.current.offsetWidth}px`
        );
        root?.style.setProperty("--discover-collection-card-height",`${targetRef.current.offsetWidth}px`)
    }
  };
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [props?.row, screenSize, leftMenuCollapse]);

  const showBannerHandler = ()=>{
    let bannerImg= props?.row?.community ? props?.row?.filePath256 : props?.row?.showcase || userBanner;
    return bannerImg
  }
  const BannerHandler = ()=>{
    let bannerImg= props?.row?.community ? props?.row?.filePath256 : props?.row?.showcase || false;
    return bannerImg
  }
useLayoutEffect(() => {
  const image = new Image();
  image.src = props?.row?.collage
}, [props?.row?.collage]);

const onEditClickHandler=(e)=>{
  props?.onEditClick && props.onEditClick(e,props?.row?.id, props?.collectionType, props?.row?.nmkrUid)
}

const onDeleteClickHandler=(e)=>{
  props?.onDeleteClick && props.onDeleteClick(e,props?.row?.id)
}
  return (
    <div>
    {
      props.isDiscoverTab ?
      
      (<div ref={targetRef} className={`image-item collection-sq-card flex flex-col cursor-pointer relative ${props?.mainClass}`} 
        data-id={props?.row?.id} key={props?.row?.id} onClick={props?.cardClick} data-policy={`${props?.row?.collectionId}`}>
        <div className={`image-container relative ${!props?.row?.filePath256 && "no-banner"}`} 
            style={{
              backgroundImage: `url(${props?.row?.filePath256 ?? userBanner})`,
            }}
             ></div>                     
      <div className={`${props.isDiscoverTab} ? '' : 'flex justify-between items-center ' }px-[0.375rem] py-[0.625rem]`}>
          <div className={`${props?.className} flex gap-3 justify-start ${props?.isHome ? 'items-start' : ''}`}>
              <div className="community-pic">
                  <img src={props?.row?.community?.displayPicture256 ?? Logo()} alt="" />
              </div>
              <div className="flex flex-col gap-2 mt-[.4rem] w-[80%]">
                  <div className="collection-name text-left items-center" title={props?.row?.name}>
                      <FormatField type="sub" maxWidth={40} value={props?.row?.name ?? ''} />
                  </div>
              </div>
          </div>
          <p className="community-name text-left ml-[2.9rem]">{props?.row?.community?.name}</p>
      </div>
      </div>)
      :(
    <div
      ref={targetRef}
      className={`image-item collection-sq-card flex flex-col cursor-pointer relative ${props?.mainClass}`}
      data-id={props?.row?.id}
      key={props?.row?.id}
      onClick={props?.cardClick}
      data-communityid={props?.row?.communityId}
      data-policy={`${props?.row?.collectionId}`}
    >
      <div className="flip-card">
      <div className="flip-card-inner">
      <div
        className={`image-container relative flip-card-front ${!BannerHandler() && "no-banner"}
         ${(props?.edit||props?.delete) && "edit-delete-on"}`}
        style={{backgroundImage: `url(${showBannerHandler()})`}} 
      >
      </div>
      <div
        className={`image-container relative flip-card-back ${!BannerHandler() && "no-banner"} 
        ${(props?.edit || props?.delete) && "edit-delete-on"}`}
        style={{backgroundImage: `url(${props?.row?.collage ?? showBannerHandler()})`}} 
      >
        {(props?.delete || props?.edit) && <div className={`edit-delete-wrap`}>
              <EditDelete isDeletable={props?.delete} isEditable={props?.edit} 
              editHandlerFn={onEditClickHandler} deleteHandlerFn={onDeleteClickHandler} />
            </div>}
      </div>
      </div>
      </div>
      <div className="card-details flex justify-between items-center px-[0.375rem] py-[0.625rem]">
        <div
          className={`${props?.className} flex gap-3 justify-start ${
            props?.isHome ? "items-start" : "items-center"
          }`}
        >
          {props?.showData?.profileIcon && (
            <div className="community-pic">
              <img
                src={props?.row?.community?.displayPicture256 ?? Logo()}
                alt=""
              />
            </div>
          )}
          <div className="flex flex-col gap-1 items-start justify-start">
            {props?.showData?.collectionName && (
              <div
                className="collection-name text-left"
                title={props?.row?.name}
              >
                <FormatField
                  type="sub"
                  maxWidth={40}
                  value={props?.row?.name ?? ""}
                />
              </div>
            )}
              {!!props?.showData?.communityName && <div className="creator-name" title={props?.row?.community?.name}>
              {props?.row?.community?.name ?? "Unknown"}
              </div>}
              {!!props?.showData?.blockchainIconAndPieceCount && <div className="flex items-center gap-6 mt-2">
                    {props?.row?.blockchainType?.code ? 
                    <img
                        className={`w-[1.2rem]`}
                        alt="Img"
                        src={getBlockchainIcon(props?.row?.blockchainType?.code)}
                    />
                    :
                    <BlockChainIcon classes={`w-[1.2rem]`} loaderH={'1.05rem'} policyId={props?.row?.policy} />
                    }
                    <div className={`nft-price flex`}>
                    <span className={`dot-separator`}>{`${thousandCommaSeparator(props?.row?.assetsCount||props?.row?.assetCount)} piece${(props?.row?.assetsCount!==1||props?.row?.assetCount!==1)?"s":""}`}</span>
                </div>
                    </div>}
            {props?.showData?.tag && (
          <div className={`content-start count-holdr tag-list`}>
            <div
              className={`nft-gallery-head tag-name flex gap-x-3 gap-y-2 flex-wrap items-center  ml-auto`}
            >
              {props?.row?.collectionTags?.slice(0,3)?.map((val)=><span key={val} className="text-xs">#{val}</span>)}
            </div>
          </div>
        )}
          </div>
        </div>
        {props?.showData?.count && (
          <div className={`content-start count-holdr`}>
            <div
              className={`nft-gallery-head nft-gallery-count flex gap-1 items-center  ml-auto`}
            >
              <span>{props?.row?.assetsCount || 0}</span>
            </div>
          </div>
        )}
      </div>
    </div>
    )}
    </div>
  );
};

export default CollectionCard;
