import "./MyGallery.scss"
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "App/hooks-store/store";
import { getAccessGalleryCreation, getAvailableGallery, getPreviewGalleryLink } from "App/Helper/utilities";
import SubscriptionWarning from "../SubscriptionWarning";
import { useNavigate } from "react-router-dom";
import { setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import { useDispatch } from "react-redux";
import AddPlusIconButton from "App/Components/AddPlusIconButton";
import GalleriesList from "./Components/GalleriesList";
import useGalleryList from "App/hooks/use-galleryList";
import useProfileApiHelper from "App/Pages/UserProfile/Components/Apis";
import useHttp from "App/hooks/use-http";
const isAnyWall = process.env.REACT_APP_COMPANY === 'anywall'
const MyGallery = ({galleryBaseUrl, setIsCreating, onClickDeleteGallery, onCancel, clearAll }) => {
    const [warningPopup, setWarningPopup] = useState(false);
    const [isSubscriptionReached, setIsSubscriptionReached] = useState(false);
    const navigate = useNavigate()
    const [{collectionLoadingStatus, collections, tokens, discoverableProfile}, dispatch] = useStore();
    const [mouseEnter, setMouseEnter] = useState(false);
    const [tab ,setTab] = useState(isAnyWall ? "public" : "private");
    const dispatchRedux = useDispatch();
    const { triggerAPI } = useHttp();
    const itemsPerPage = 9;
    const { galleryData, totalItems, isFetching, containerRef, initialLoaded, setGalleryData } = useGalleryList(tab, itemsPerPage);
    const { getProfile } = useProfileApiHelper();
    const onMouseBlur = useCallback(() => {
        setMouseEnter(false)
    }, []);
    const blurHandler = () => {
        setMouseEnter(false)
    }
    const onMouseFocus = useCallback(() => {
        warningPopup && setMouseEnter(true)
    }, [warningPopup]);
    
    const apiResult = (res) => {
        const { message } = res;
        dispatch("hideSpinner");
        setGalleryData(prev => ({...prev, 'public': { items: [], totalItems: 1, pageNo: 1, initialLoaded: false, lastFetchedPage: 0 }}))
        dispatch("showToast", { toast: { toastMode: "success", message } });
      };
    const setPrimaryHandler=(id)=>{
        dispatch("showSpinner");
        triggerAPI(
            {
                url: `gallery/set-primary/${id}`,
                data:{},
                method: "patch",
            },
            apiResult,
            (err) => {
                dispatch("hideSpinner");
                dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message:
                    err?.response?.data?.error?.message || "Setting primary gallery failed",
                },
                });
            }
        ); 
    }

    const onGalleryPreviewHandler = (e, disable) => {
        if(!disable) {
            const galleryId = e?.currentTarget?.dataset?.id;
            let currentArray = galleryData[tab]?.items?.find(item => item.id === parseInt(galleryId))
            dispatch('setCurrentGallery', currentArray)
            navigate(`/curate/display/my-galleries/view/${galleryId}`);
            dispatchRedux(setFormInitiated(true));
        }
    }
    
    const handlePreview = (id, previewType, e) => {
        e.stopPropagation();
        getPreviewGalleryLink(id, previewType);
    }
    const userCollection = useMemo(() => {
        return [
            ...(collections || []),
            ...(tokens || [])
          ]
    }, [collections, tokens])
    
    const handleEdit  = useCallback((e, id) => {
        e.stopPropagation();
        setIsCreating(true)
        if(collectionLoadingStatus === 'finished') {
            let currentArray = galleryData[tab]?.items?.find((gallery) => gallery.id === id );
            let assets = getAvailableGallery(userCollection, currentArray?.assets)
            currentArray = {...currentArray, assets}
            dispatch('setCurrentGallery', currentArray)
            navigate(`${galleryBaseUrl}/edit/${id}/save`)
            dispatchRedux(setFormInitiated(true));
        } else {
            dispatch("showToast", { toast: { toastMode: "warning", message: 'Please wait until Collection loading' } });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[galleryData[tab], collections, tokens, collectionLoadingStatus, galleryBaseUrl]);

    
    const menuTabs = useMemo(()=> {
        if(isAnyWall) return [];
        let galleryTabs=  [
            { id: "private", element: 'Private',count: !initialLoaded ? 0 : totalItems?.privateTotalItems },
            { id: "my-rentals", element: 'My Rentals', count: 0 },
        ]
        if(discoverableProfile && (!!totalItems?.publicTotalItems && initialLoaded)){
            galleryTabs?.splice(1,0,{ id: "public", element: 'Public',count: !initialLoaded ? 0 : totalItems?.publicTotalItems })
        }
        return galleryTabs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[discoverableProfile, totalItems, initialLoaded])


    const handleTabs = (e) => {
        const selectTab = e.currentTarget?.dataset?.id;
        setTab(selectTab)
        const root = document.getElementById('gallery-list');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

    }
    const getProfileAPI = (callBack) => {
        getProfile((data) => {
            let isCreateable = getAccessGalleryCreation(data?.availableGalleriesCount, data?.createdGalleryCount);
            setWarningPopup(!isCreateable)
            setIsSubscriptionReached(isCreateable)
            callBack && callBack(isCreateable);
        })
    }
    useEffect(() => {
        getProfileAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onAddHandler = () => {
        getProfileAPI(access => {
            if(access) {
                setIsCreating(true)
                navigate(`${galleryBaseUrl}/add/collection`)
                dispatchRedux(setFormInitiated(true));
                clearAll();
            } 
        })
    }
    const onNavigateToExploreRentals = () => {
        navigate("/curate/rentals")
    }
    const showGallerySkelton = useMemo(() => {
        return isFetching || !galleryData[tab]?.initialLoaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, galleryData[tab]?.initialLoaded])
    return (
            <div className={`w-full flex flex-col justify-between h-full  pt-4 my-gallery-banner`}>
                <div className="create-gallery-btn_home" onMouseLeave={onMouseBlur}>
                    <div className="create-gallery-btn_home-text">
                        <p className="cgb_title">Galleries</p>
                    </div>
                    <div onMouseOverCapture={onMouseFocus}
                    onBlur={blurHandler}>
                        <AddPlusIconButton className={`${warningPopup&&"warning-btn"} cgb_plus-icon`} disabled={showGallerySkelton} onClick={onAddHandler}/>
                        <button
                            onClick={onAddHandler}
                            className="new-btn_style_001 cgb_title_MR"
                        >Create Gallery</button>
                        <div className={`warning-toolkit ${mouseEnter&&"active"}`}>
                            <SubscriptionWarning content="galleries" isSubscriptionReached={isSubscriptionReached}/>
                        </div>
                    </div>
                </div>   
                {!isAnyWall && <div id="discover-tab" className="common_style-tab-container px-[1rem] mb-2">
                    <div className="tab-data">
                        {menuTabs?.map((menu, index) => {
                            return (
                            <div
                                onClick={handleTabs}
                                data-id={menu?.id}
                                key={menu?.id}
                                className={`tab ${menu?.id === tab ? "active":"not-active"}  ${menu?.id}`}
                            >
                                {menu?.element}{" "}
                                    <span className=" inline-block">{`(${menu?.count})`}</span>
                            </div>
                            );
                        })}
                    </div>
                    </div>
                }        
            

                <div id="gallery-list" className="overflow-y-auto h-full" ref={containerRef}>
                    <GalleriesList   
                        totalItems={galleryData[tab]?.totalItems}
                        showGallerySkelton={showGallerySkelton}
                        galleryList={galleryData[tab]?.items} isInitialLoad={!galleryData[tab]?.initialLoaded}
                        tab={tab === "my-rentals" ? "rentals" : tab}
                        onGalleryPreviewHandler={onGalleryPreviewHandler}
                        setPrimaryHandler={setPrimaryHandler}
                        selectedCollection='My Collections'
                        onCancel={onCancel}
                        onNavigateToExploreRentals={onNavigateToExploreRentals}
                        onGalleryCreateClick={onAddHandler}
                        handleEdit={handleEdit}
                        handlePreview={handlePreview}
                        onClickDeleteGallery={onClickDeleteGallery}
                    />
                </div>
            
            </div>
        )
}

export default MyGallery