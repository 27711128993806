import "./SearchFilter.scss";
import SearchField from "./Components/SearchField";
import LgSearchIcon from "App/Components/IconsComponents/LgSearchIcon";
import SearchMobileIcon from "App/Components/IconsComponents/SearchMobileIcon";
import SearchBar from "./Components/SearchBar";

const SearchFilter = (props) => {
  return (
    <div
      className={`${
        props?.className ?? ""
      } search-filter-wrapper flex items-center`}
    >
      <LgSearchIcon
        onClick={
          !props?.topMenuIcons?.searchDisable ? props?.onClick : () => {}
        }
        className={`img-icon ${
          props?.topMenuIcons?.searchDisable ? "cursor-not-allowed" : ""
        } sm:block hidden`}
      />
      <SearchMobileIcon
        onClick={
          !props?.topMenuIcons?.searchDisable ? props?.onClick : () => {}
        }
        className={`img-icon ${
          props?.topMenuIcons?.searchDisable ? "cursor-not-allowed" : ""
        } sm:hidden flex ${!!props?.showSearchBar && "hidden"} border-wrap`}
      />
      {!!props?.showSearchBar && (
        <>
          <div className="sm:block hidden">
            <SearchField
              hideLabel={props?.hideLabel}
              onSearch={props?.onSearch}
              value={props?.value}
              onClick={props?.onClick}
            />
          </div>
          <div className="sm:hidden block search-mobile-field">
            <SearchBar
              onSearch={props?.onSearch}
              value={props?.value}
              onClick={props?.onClick}
              noFocus
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SearchFilter;
