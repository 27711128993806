import './gradientSlider.scss';

const GradientSlider = ({ colorValue  = '', colorChange, min=0, max=255, width = 200, step = 1, visible = true, className = '', swallowClicks = false, id="gradientslider" }) => {   
    const handleClick = (e) => {
        e.stopPropagation()     
    }

    const onChange = (e) => {
        let value = e?.target?.value;
        let changedValue = {
            target: {
                // value: `rgb(${255 - value * 2.55}, ${255 - value * 2.55}, ${255 - value * 2.55})`
                value: `rgb(${value},${value},${value})`
            }
        }
        colorChange(changedValue);        
    }

    const getValue = (color) => {  
        if (color.startsWith("#"))
        {          
            return Math.abs(Number("0x" + color.substring(1,3)));
        }
        else if (color?.startsWith("rgb("))
        {
            return Math.abs(color?.substring(4)?.split(",")[0])
        }
    }

    return (        
        <input id={id} type="range" min={min} max={max} value={getValue(colorValue)} style={{width:width}} onChange={onChange} step={1} className={`gradient-slider ${visible?'visible':'hidden'} ${className}`} onClick={(e)=>swallowClicks?handleClick(e):null} />
    )
}

export default GradientSlider