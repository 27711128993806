
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Fragment } from "react";
import GalleryImages from "../../../../../../Components/Ui/GalleryFrameImages";
import GalleryTableView from "../../../GalleryTableView";
import RoundedButton from "App/Components/Ui/RoundedButton";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import useCollectionLoader from "App/hooks/use-collectionLoader"
import { numberWithCommas, sortObject } from "App/Helper/utilities";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import { useNavigate, useParams } from "react-router-dom";
import "./SelectedGallery.scss";
import Tilt from "react-parallax-tilt";
import SelectedNftDetails from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails";
import adaIcon from 'Assets/Icons/cardano-sm-icon.svg'
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { getMetadata } from "App/Helper/utilities";
import useHttp from "App/hooks/use-http"
import EditDelete from "App/Components/EditDelete";
import { useLocation } from "react-router-dom";
import BackIcon from "Assets/Icons/back-icon.svg";
import { useSelector } from "react-redux";
import { getFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import { getBlockchainIcon } from "App/Helper/blockchain";
import { creatorRoutes } from "App/Helper/routings";
import defaultImage from 'Assets/Icons/logo-square.svg';
import InformationIcon from "App/Components/IconsComponents/InformationIcon";
// import SkeltonCard from "App/Components/Ui/SkeltonCard";

const SelectedGallery = ({ onClickExit,noDataMessage=false, isSingle = false,hideButtons=false, OnClick, displayMode, value, onClickGallery, onClickHandler, action, hasScroll,
    onFilterChange, sort, onSearch, group, filter, onDemandAssetIds, policyId, imageRatioCalculated, scrollOffset=500, type="", activeTab,
    pageCount=99, isSearchable=true, isSortable=true,loaderNoAbsolute, isFrameable=true, switchable=false, classNames, setCommunityCollectionAssetIds, 
    collectionView = false, pageType = '', pgaction = '', galleryBaseUrl= '', parentScrollContainerQuery = '', refreshAssetIds=false, onDeleteClick=null, 
    showInformationIcon= false, onInfoIconClick=null, showLoading=false, loaderCount = 8 }) => {
    const screenSize = useScreenSize()?.width;
    const { communityid } = useParams();
    const { triggerAPI } = useHttp();
    const [displayChange, setDisplayChange] = useState(displayMode)
    const [newSelected, setNewSelected] = useState([])
    const [{ currentSelectedNfts, currentGalleryList, activeMenuIcons,
        collectionLoadingStatus, leftMenuCollapse, singleCollection } ,dispatch] = useStore(false);
    const [captionToggle, setCaptionToggle] = useState(false)
    const [showSkeltonCard, setShowSkeltonCard] = useState(false)
    const [isFetchCompleted, setIsFetchCompleted] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const [frameToggle, setFrameToggle] = useState(true)
    const [assetLoading, setAssetLoading] = useState(true)
    const [finalList, setFinalList] = useState(null)
    const timer = useRef(null);
    const targetRef = useRef();
    const listRef = useRef();
    const location = useLocation();
    const formInitiated = useSelector(getFormInitiated)
    const isCreator = useMemo(() => {
        return !!((communityid) || collectionView);
    },[communityid, collectionView]);
    const onSelect = (e) => {
        if(!assetLoading) {
            let clickedId = e.currentTarget?.dataset?.id
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(() => {
                onClickHandler({ e: clickedId, click: e?.detail, events: e })
            }, 300);
    
            // setTimeout(() => {
            //     e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
            // }, 500)
            setSelectedNft(e)
        }
    }
    let [page, setPage] = useState(0);
    let [totalPages, setTotalPages] = useState(0);
    const [lastloadedpage, setLastLoadedPage] = useState(-1);
    const [lastLoadedPolicy, setLastLoadedPolicy] = useState('');
    const [lastAssetIds, setLastAssetIds] = useState([]);   
    const [loader, setLoader] = useState(true);
    const { sliceSingleCollectionAPI } = useCollectionLoader();
    const [selectedNft, setSelectedNft] = useState(null)
    const [wrappedSelectedNft,setWrappedSelectedNft] =useState(null)
    const navigate = useNavigate()
    const setDisplay = (e) => {
        setDisplayChange(e)
    }
    const closeAssetHandler = () => {
        setSelectedNft(null)
        setWrappedSelectedNft(null)
    }
    const selectNftHandler = (index) => () => {
        setSelectedNft(finalList[index]);
    };

    const onImageClick = (e) => {
        // if (screenSize < 551)
        //     e.stopPropagation();

        if (action) 
        {
            action(e);
        }
        else
        {
            e.stopPropagation();
        }
    }
    useEffect(() => {
        if(!isCreator) {
            let loading = collectionLoadingStatus;
            let status = true;
            if(loading === 'finished')
                status = false;
            setAssetLoading(status);
        }        
    }, [collectionLoadingStatus, isCreator])
    useEffect(() => {
        if (!isCreator)
        {
            const unique = [...new Map(currentGalleryList?.map((m) => [m.asset, m])).values()];
            if(unique)
                setNewSelected(sortObject(unique, activeMenuIcons?.sort))
        }        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGalleryList, isCreator, activeTab])
    

    //Top Menu starts
    useEffect(() => {
        !collectionView &&dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false});
        dispatch('enableTopIcons', {
            dropDown: false, caption: activeTab!=="Marketplace", sorting: isSortable?!(isPortrait&&screenSize<=550):false, 
            frame: isFrameable, search: isSearchable, lgView: false,
            smView: false, filter: false
        });
        dispatch('setActiveIcons', {
            displayType: displayMode, frame: true, sort: 'Name A-Z', folderOption: isSingle ? 'Assets' : '', search: '', back: false
        });
        dispatch('setSortingOptions', sort);
        return () => {
            dispatch('enableTopIcons', {
                caption: false, frame: false,
                search: false, sorting: false, 
                file: false, folder: false, lgView: false, smView: false
            });
            dispatch("resetSingleCollection")  
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab])

    useEffect(() => {
        setDisplay(activeMenuIcons?.displayType);
    },[activeMenuIcons?.displayType])

    useEffect(() => {
        setCaptionToggle(activeMenuIcons?.caption);
    },[activeMenuIcons?.caption])

    useEffect(() => {
        setFrameToggle(activeMenuIcons?.frame);
    },[activeMenuIcons?.frame])
    
    useEffect(() => {
        if(activeMenuIcons?.back) {
            dispatch('setActiveIcons', {back: false});
            if(pageType && galleryBaseUrl)
                navigate(`${galleryBaseUrl}/${pageType}/${pgaction}`);
            else if (isSingle) {
                onClickExit && onClickExit();
            } else {
                OnClick && OnClick();
            }
            if (setCommunityCollectionAssetIds)
            {
                setCommunityCollectionAssetIds(null)
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeMenuIcons?.back])

    const updateHeight = () => {
        const root = document.documentElement;
        if(targetRef.current) {
            if(targetRef.current) {
                root?.style.setProperty(
                    "--collection-card-height", `${targetRef.current.offsetWidth}px`
                );
            }
        }
    }
    useLayoutEffect(() => {
        updateHeight();
        const handleResize = () => {
          setTimeout(() => {
            updateHeight();
          }, 1000);
        };    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        updateHeight()
    },[displayChange, value.mode]);
    
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse]);

    const getAssetDetails = () => {    
        if(onDemandAssetIds?.length > 0)   {
            let onDemandDataFetch = onDemandAssetIds?.filter(a => a.asset_name !== "");
            setTotalPages(Math.ceil(onDemandDataFetch?.length/pageCount));
            let pageresults = onDemandDataFetch.slice((page * pageCount), ((page * pageCount) + pageCount)).map(a => {return { asset: a.policy_id??policyId + a.asset_name, policy_id: a.policy_id??policyId,  asset_name: a.asset_name, chain: a.chain, contractAddress: a?.contractAddress}});              
            sliceSingleCollectionAPI(pageresults);    
            setLastLoadedPage(page);    
            setLastLoadedPolicy(policyId);   
        }   
    };

    useEffect(() => {
        let blnContinue = true;    
        if (!!onDemandAssetIds)
        {
            if (policyId !== lastLoadedPolicy)
            {
                blnContinue = false;      
                setLastLoadedPage(-1);
                setAssetLoading(true);  
                setPage(0);                 
                setLastLoadedPolicy(policyId);                  
                if (onDemandAssetIds !== lastAssetIds && (!switchable || lastAssetIds.length > 0))
                {
                    blnContinue = true;
                }              
            }        
            if (onDemandAssetIds.length === 0)
            {
                dispatch("resetSingleCollection");
                if (!showLoading) setAssetLoading(false);
            }
            else
            {            
                if (blnContinue)
                {                  
                    if (lastloadedpage < page && onDemandAssetIds?.length > 0)
                    {                     
                        if ((page===0 && lastloadedpage === -1))
                        {             
                        }               
                        setLastAssetIds(onDemandAssetIds);
                        // getAssetDetails();
                    }     
                    else
                    {
                        // setShowSkeltonCard(false);
                        if (!showLoading) setAssetLoading(false);
                    }    
                }
                else
                {   
                    if(typeof onDemandAssetIds === 'object' && !showLoading) setAssetLoading(false);
                }
            }
        } else { setTimeout(() => {
            if (!showLoading) setAssetLoading(false);
        }, 2000); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onDemandAssetIds, page, policyId, activeTab])     
   
    const onScrollHandler = (e, onDemandAssetIds, singleCollection) => {
        // console.log({ 
        //     onDemandAssetIds,
        //     scrollTop: e.target.scrollTop,
        //     clientHeight: e.target.clientHeight,
        //     scrollHeight: e.target.scrollHeight,
        //     scrollOffset,
        //     singleCollection,
        //     page,
        //     pageCount
        // })
        if (onDemandAssetIds?.length > 0 && e.target.scrollTop + e.target.clientHeight > e.target.scrollHeight - scrollOffset)
        {         
          if (singleCollection.length === (page+1)*pageCount)
          {     
              if (isFetchCompleted) {
                  setShowSkeltonCard(false)
              } else {
                  setShowSkeltonCard(true)
              }
            page++;  
            setPage(page);
            getAssetDetails();   
          }
        }      
    }
    
    useEffect(() => {
        let finalListTemp = [];
        if(isCreator) {
            //let temp = [...singleCollection]?.filter(item => item?.collectionCollectionId === policyId) ?? []; //filter will ensure particular policy data only getting
            let temp = [...singleCollection]?.filter(item => {
                return Array.isArray(onDemandAssetIds)  && onDemandAssetIds?.findIndex(a=> 
                    item?.asset?.toLowerCase() === (`${a.chain}${a.asset}${a.asset_name}`)?.toLowerCase()>-1
                )
            }) ?? []; //filter will ensure only matching assets are used            
            //add marketplace properties back in from the ondemandassetids
            if (onDemandAssetIds && (onDemandAssetIds?.[0]?.price!==null || onDemandAssetIds?.[0]?.priceUSD !== null))
            {
                for (let i = 0; i < temp.length; i++) {
                    const item = temp[i];
                    let match = onDemandAssetIds.filter(a=> {
                        if (a.nft_id)
                        {
                            let nft = a.nft_id.split(".");
                            let nftcombinedid = nft[0]+nft[1]+(nft.length>2?nft[2]:a.asset_name);
                            return item.asset?.toLowerCase() === nftcombinedid?.toLowerCase();   
                        }
                        else
                        {
                            return a.asset_name?.toLowerCase() === item.asset_name?.toLowerCase()
                        }                                                
                    })?.[0];
                    if (match)
                    {
                        item.marketplace = match;
                    }
                }
                //removed sorting here, should already be done by the assetids
                //temp = temp.sort((a,b)=>(a.marketplace?.price??9999999999)>(b.marketplace?.price??9999999999)?1:(a.marketplace?.price??9999999999)<(b.marketplace?.price??9999999999)?-1:a.marketplace?.asset_name>b.marketplace?.asset_name?1:-1);
            }
            finalListTemp = [...temp];
        } else {
            finalListTemp = [...newSelected];
        }
        if(activeMenuIcons?.search) {
            finalListTemp = [...finalListTemp]?.filter(item => item?.name?.toLowerCase()?.includes(activeMenuIcons?.search?.toLowerCase()))
        }
        //don't sort ondemand assets, it'll mean ondemand data gets loaded in random places through the collection
        if(!onDemandAssetIds && activeMenuIcons?.sort) {
            finalListTemp = sortObject(finalListTemp, activeMenuIcons?.sort)
        }
        // setIsFetchCompleted(singleCollection.length >= (onDemandAssetIds?.length - 4));
        setFinalList(finalListTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreator, onDemandAssetIds?.length, singleCollection, newSelected, activeTab, activeMenuIcons?.search, activeMenuIcons?.sort])


    useEffect(() => {
        setLoader(true)
        setTimeout(()=>{
        setLoader(false)
        },100)
    }, [isCreator, onDemandAssetIds?.length, singleCollection, newSelected, activeTab, activeMenuIcons?.sort, policyId])
    useEffect(() => {

        if(isCreator && page === 0 && singleCollection?.length) {
            setAssetLoading(false);
        }
    },[page, singleCollection, isCreator])
    useEffect(() => {
        getAssetDetails();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onDemandAssetIds, activeTab])
    useEffect(()=>{
        if(selectedNft)
        {
          if (selectedNft.nfttype === 'nmkr' && !selectedNft.minted && selectedNft.uid)
          {
            const params = new URLSearchParams({
              nftuid: selectedNft.uid
            });  
            triggerAPI(
              {
                url: `nft-maker/project/getNFTDetails?${params}`,            
                method: "get",
              },
              (res) => {    
                if (res?.data?.metadata)
                {
                  let metadata = JSON.parse(res.data.metadata);
                  if (metadata)
                  {
                    let decodedNft = {...selectedNft};
                    decodedNft.onchain_metadata = metadata['721']?.[decodedNft.policy]?.[decodedNft.name];
                    for (let i = 0; i < decodedNft.onchain_metadata.files?.length; i++) {
                      const file = decodedNft.onchain_metadata.files[i];
                      if (file.src.toLowerCase().startsWith("ipfs://"))
                      {                        
                        let lastIndex = decodedNft.nftcdnimage.lastIndexOf("/");
                        if (lastIndex > -1)
                        {
                          file.nftcdnfile = decodedNft.nftcdnimage.substring(0,lastIndex+1) + file.src.substring(7);
                        }
                      }                      
                    }

                    decodedNft = getMetadata(decodedNft);
                    setWrappedSelectedNft({ nft: decodedNft})
                  }                  
                }                
            });
          }
          else
          {
            setWrappedSelectedNft({ nft: selectedNft})
          }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedNft])

    const escPressed = () => {
        closeAssetHandler()
    };
    useEffect(() => {
        const keyDownHandler = event => {

            if (event.key === 'Escape') {
            event.preventDefault();

            escPressed();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function selectNextNft() {
        const currentPolicy = selectedNft.asset;
        const currentIndex = finalList?.findIndex(item => item.asset === currentPolicy);
        
        if (currentIndex !== -1) {
            const nextIndex = currentIndex < finalList.length - 1 ? currentIndex + 1 : 0;
            const selectedAsset = finalList[nextIndex];
            setSelectedNft(selectedAsset);
        } else {
            setSelectedNft(finalList[0]);
        }
    }
    function selectPreviousNft() {
        const currentPolicy = selectedNft?.asset;
        const currentIndex = finalList?.findIndex(item => item.asset === currentPolicy);
    
        if (currentIndex !== -1) {
            const previousIndex = currentIndex > 0 ? currentIndex - 1 : finalList.length - 1;
            const selectedAsset = finalList[previousIndex];
            setSelectedNft(selectedAsset);
        } else {
            setSelectedNft(finalList[0]);
        }
    }
    useEffect(()=> {
        if (parentScrollContainerQuery)
        {
            let scrollParent = document.querySelector(parentScrollContainerQuery);
            if (scrollParent)
            {
                scrollParent.onscroll = function(e) { 
                    onScrollHandler(e, onDemandAssetIds, singleCollection);
                };
            }
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onDemandAssetIds, singleCollection, isFetchCompleted])

    useEffect(() => {
        if(onDemandAssetIds?.length) {
            setIsFetchCompleted((page+1) === totalPages)
        }
    }, [page, totalPages, onDemandAssetIds])

    useEffect(() => {
        if (refreshAssetIds)
        {
            setLastLoadedPage(-1);
            setLastLoadedPolicy('');    
        }
    }, [refreshAssetIds])

    const backToCollection = () => {
        navigate(`${galleryBaseUrl}/${pageType}/${pgaction}`);
    }
    return (
        <>
           <SkeltonCard show={assetLoading} noAbsolute={loaderNoAbsolute} height={"100%"} count={loaderCount} inline={true} containerClassName={`${classNames} gallery-asset-level ${displayChange === 'sm'&&"skeleton-sm-loader"} skelton-card  flex ${type==="discover"&&"asset-listing-loader"} pb-4`} />
            {<div ref={listRef} className={`${hasScroll ? 'nft-gallery-full-card' : 'nft-gallery-full-card-no-scroll' } flex selected-gallery selected-gallery-sm  gallery-second-level`} 
            >

                   <> {(displayChange === 'sm' || displayChange === 'lg') ?
                        <div className={`${assetLoading && 'opacity-0'} ${classNames} flex-1 ${((!singleCollection?.length && !newSelected?.length)||(finalList&&!finalList?.length)) && "hidden"} 
                        ${displayChange === 'lg' ? (value.mode ? 'w-full grid-lg ' : 'grid-lg') : 
                        displayChange === 'sm' ? (value.mode ? 'w-full grid-sm gallery-scroll active' : 'grid-sm') : ''} gallery-new`}>
                            {pageType && galleryBaseUrl && 
                                <div className="relative nft-selected-full empty-box flex justify-center items-center" onClick={backToCollection}>
                                    <img src={BackIcon} alt=""/>
                                </div>
                            }
                            {finalList&&(finalList)?.map((selectedGallery, i) =>
                                <div key={`${selectedGallery?.asset}-${selectedGallery?.frameId ?? -1}-i`} >
                                    <Tilt tiltAngleXInitial={0} tiltAngleYInitial={0} glareEnable={false} glareMaxOpacity={0} tiltEnable={true} tiltMaxAngleX={currentSelectedNfts?.map((val) => val?.asset).includes(selectedGallery?.asset) ? 0 : 10} 
                                tiltMaxAngleY={currentSelectedNfts?.map((val) => val?.asset).includes(selectedGallery?.asset) ? 0 : 10} perspective={1500} 
                                glareColor={"rgba(0,0,0,1)"}>
                                    <div className={`${selectedGallery?.isLandscape && ''}  relative nft-selected-full ${(currentSelectedNfts?.map((val) => val?.asset).includes(selectedGallery?.asset) && formInitiated) ? 'active' : 'hover-effect'} `}>
                                        
                                        <div className={`collection-card flex flex-col `} ref={targetRef} 
                                            data-id={selectedGallery.asset}
                                            onClick={collectionView ? selectNftHandler(i) : onSelect}>
                                            <GalleryImages
                                                isThumbnailImage={true}
                                                hoverlayClass={'collection-card-overlay'}
                                                nftGalleryNew={true}
                                                clearCache={false}
                                                showZoom={true}
                                                name={selectedGallery?.name}
                                                index={i} galleryNew={selectedGallery}
                                                displaySize={displayChange}
                                                src={selectedGallery?.nftcdnimage256 ?? defaultImage}
                                                className="nft-gallery-card-image selected-image"
                                                onClick={collectionView ? selectNftHandler : onImageClick}
                                                frameToggle={frameToggle}
                                                dataId={selectedGallery.asset} 
                                                imageRatioCalculated={imageRatioCalculated}
                                                scrollOffset={1000}
                                                />
                                        </div>
                                        {activeTab==="Minting" && location?.pathname?.startsWith(creatorRoutes()) && selectedGallery.marketplace?.state !== 'sold' && <div className={`edit-delete-wrap`}>
                                            <EditDelete isDeletable={true} isEditable={false} 
                                            deleteHandlerFn={()=>onDeleteClick && onDeleteClick(selectedGallery)} />
                                        </div>}

                                            {showInformationIcon && onInfoIconClick &&
                                                <button className={`info_icon-add-gallery info_icon-selected-gallery`} data-id={selectedGallery?.asset} data-action={'show-info'} onClick={onInfoIconClick}>
                                                    <InformationIcon defaultColor={`#fff`} />
                                                </button>
                                            }
                                    </div>
                                    </Tilt>
                                    {(captionToggle || (activeTab==="Marketplace"||activeTab==="Minting")) && <div className="flex flex-col gap-[0.625rem] text-[.85rem] me mt-[0.465rem] ml-[0.5rem] mr-[0.5rem]">
                                                <span className={`text-left displaying-name`}>
                                                    {selectedGallery?.name || 'No Name'}
                                                </span>
                                        <div className="flex gap-6">
                                        {(activeTab==="Marketplace"||activeTab==="Minting")&&(selectedGallery.marketplace?.priceDisplay??selectedGallery.marketplace?.price) && 
                                        <div className="flex gap-2">
                                                    {!selectedGallery.marketplace?.priceDisplay && <img
                                                        className={`w-[1.2rem]`}
                                                        alt="Img"
                                                        src={getBlockchainIcon(selectedGallery?.blockchainType?.code) || adaIcon}
                                                    />}
                                        <span className={`nft-price ${displayChange === 'sm' ? 'name-sm' : 'name-lg'}`}>
                                            {(selectedGallery.marketplace?.priceDisplay??numberWithCommas(selectedGallery?.marketplace.price/1000000))} 
                                        </span>
                                        </div>}
                                        {(activeTab==="Marketplace"||activeTab==="Minting")&&selectedGallery.marketplace?.priceUSD && 
                                        <div className="flex gap-2">          
                                        {selectedGallery.marketplace?.state === 'sold' && <span className={`${displayChange === 'sm' ? 'name-sm' : 'name-lg'}`}>
                                            SOLD
                                        </span>}
                                        <span className={`${displayChange === 'sm' ? 'name-sm' : 'name-lg'}`}>
                                            $ {selectedGallery?.marketplace.priceUSD}
                                        </span>
                                        </div>}
                                        { activeTab==="Marketplace" &&  
                                        <div className="flex gap-6">
                                        {!selectedGallery?.marketplace?.priceDisplay &&
                                        !selectedGallery?.marketplace?.price &&
                                        selectedGallery.marketplace?.state !== 'sold'&&
                                        !selectedGallery?.marketplace?.priceUSD &&<div className="nft-price text-left">Not listed</div>}
                                        <div className="dot-separator nft-price">{`1/${selectedGallery?.mint_or_burn_count}`}</div>
                                        </div>
                                        }
                                        </div>
                                    </div>}
                                </div>
                                
                            )}
                            {(!isFetchCompleted && showSkeltonCard) && Array.from({ length: pageCount }).map((_, index) => <div key={index} className={``} >
                                <SkeletonLoader className={`aspect-square`} count={1} />
                            </div>)}
                        </div> :
                        <div className={`${value.mode ? 'table-gallery' : 'w-full'} nft-gallery-full-card ${displayChange} ${!newSelected?.length&&"hidden"}`}>
                            <GalleryTableView
                                frameClass="table-image-frame" list={newSelected} onSelect={onSelect} />

                        </div>                    
                    }
                    {noDataMessage&&finalList&&!finalList?.length&&!loader&&
                        <div className="flex items-center w-full justify-center no-data visibleSlowly">
                            {!assetLoading && `No ${noDataMessage||"items"} to display.`}
                        </div>
                    }
                    {wrappedSelectedNft && collectionView &&  
                     <div className="gallery-overlay" onClick={closeAssetHandler}>
                        <div className={` slider-user-full`}  onClick={(e) => e.stopPropagation()}>            
                        <SelectedNftDetails 
                        aspectRatios={wrappedSelectedNft?.nft?.aspectRatios?.split(',').map(Number) ?? []}
                        // onReload={reloadHandler} 
                        onCloseDetails={closeAssetHandler} 
                        nft={wrappedSelectedNft} 
                        // setShowAuthPop={setShowAuthPop}
                        selectNextNft={selectNextNft}
                        selectPreviousNft={selectPreviousNft}  
                        details={finalList[0]?.community}
                        onChainRenders={false}
                        hideApplyButton={type==="discover"}
                        fromCollection={true}
                        hideFrameShopButton={true}
                        />
                        </div>
                        </div>}
                    </>
 
                
            </div>}
            {!hideButtons&&<div className="selected-gallery-btn-sm">
                <RoundedButton className="custom-button" Name={'Add to Gallery'} OnClick={OnClick} Type='8'></RoundedButton>
                <RoundedButton className="custom-button" Name="Curate" Type="7" OnClick={onClickGallery}></RoundedButton>

            </div>}
        </>
    )
}

export default SelectedGallery