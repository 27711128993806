import { numberWithCommas } from "App/Helper/utilities";
import "./CommunityHoverWrapper.scss";
import useFilteredDataWithCount from "App/hooks/use-filtered-list-with-count";
import BlockchainIconsListing from "App/Components/BlockchainIconsListing";

const CommunityHoverWrapper = ({
  collectionCount,
  assetsCount,
  galleryCount,
  blockchains,
  communitiesCount,
  show,
  className
}) => {
  
  const blockchainImagesToList = useFilteredDataWithCount(blockchains,5)
  return (
    <div className={`details-section flex gap-2 ${className}`}>

      {!!show?.collections&&<><div className="details-item">
        <span className="item-name">Collections</span>
        <span className="item-count">
          {numberWithCommas(collectionCount) || "-"}
        </span>
      </div>
      <div className="border-right"></div>
      </>
  }
      {!!show?.pieces&&<><div className="details-item">
        <span className="item-name">Pieces</span>
        <span className="item-count">
          {numberWithCommas(assetsCount) || "-"}
        </span>
      </div>
      <div className="border-right"></div></>}
      {!!show?.galleries&&<><div className="details-item">
        <span className="item-name">Galleries</span>
        <span className="item-count">
          {numberWithCommas(galleryCount) || "-"}
        </span>
      </div>
      {!!(show?.communities||show?.blockchains)&&<div className="border-right"></div>}</>}
      {!!show?.communities&&<><div className="details-item">
        <span className="item-name">Creators</span>
        <span className="item-count">
          {numberWithCommas(communitiesCount) || "-"}
        </span>
      </div>
      {!!show?.blockchains&&<div className="border-right"></div>}
      </>
  }
      {!!show?.blockchains&& <BlockchainIconsListing blockchainImagesToList={blockchainImagesToList} blockchains={blockchains} />}
    </div>
  );
};
export default CommunityHoverWrapper;
