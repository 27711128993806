import styles from "./SignIn.module.scss";
import MailIcon from 'Assets/Icons/email.svg'
import passwordIcon from 'Assets/Icons/password.svg'
import RoundedInput from 'App/Components/Ui/RoundedInput'
import RoundedButton from "App/Components/Ui/RoundedButton";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Validators from "App/Components/Ui/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import useAuth from "App/hooks/use-auth";
import { useMemo } from "react";
import { scrollToTop, togglePaddingMain } from "App/Helper/utilities";
import "../../AuthLayout.scss";
import { setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import { useDispatch } from "react-redux";
import { collectorProfileRoutes } from "App/Helper/routings";

function SignIn() {
  const validatorsList = useMemo(() => ([{
    type: 'Required',
    message: 'Required field'
  }, {
    type: 'Email',
    message: 'Invalid Email'
  }]), [])
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch()
  const [formVaildState, setFormVaildState] = useState({});
  const [registrationStatus,setRegistrationStatus] = useState(true)  
  const login = useAuth()[2]
  const guest = useAuth()[3]
  const formGroup = {
    email: {
      value: undefined,
      isRequired: true
    },
    password: {
      value: undefined,
      isRequired: true
    }
  }
  const [formValues, setFormvalues] = useState(getInitial(formGroup));

  //Login Api
  const loginResponse = useCallback((res) => {
    dispatch('hideSpinner');
    login(res.data.token)
    guest(false)
    dispatch("setProfilePic")
    // dispatch("reFetch")
    scrollToTop();
    togglePaddingMain()    
    if (res.data.isFirstSignIn)
      navigate(collectorProfileRoutes("wallets"))
    else
      navigate("/home")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, login, guest])
  const Login = (formValue) => {
    dispatch('showSpinner')
    triggerAPI({
      url: `user/sign-in`, data: { email: formValue.email, password: formValue.password }, method: 'post'
    }, loginResponse,()=>{
      dispatch("hideSpinner");
  });
  }

  //Form input handler
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e
      }
    })
  }

  //Form submit handler
  const submitHandler = e => {
    const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
    e.preventDefault();
    if (valid)
      Login(formValues)
    else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }

  }

  //Form error handle
  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error
      }
    })
  }, [])
  const handleRegister = () => {
    navigate("/auth/register")
  }
  const getRegistrationStatusResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setRegistrationStatus(res?.data?.allowNewRegistration);
    },
    [dispatch]
  );

  const getRegistrationStatus = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `app-preference/get`,
        method: "get",
      },
      getRegistrationStatusResponse
    );
  }, [dispatch, getRegistrationStatusResponse, triggerAPI]);
  useEffect(() => {
    reduxDispatch(setShowWalletPop(false))
    getRegistrationStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col" >
      <form className="flex flex-col w-[24rem] gap-[2.5rem] h-full" onSubmit={submitHandler}>
        <div className="flex flex-col gap-2">
        <div className="relative">
          <RoundedInput label="Email" className={styles['auth-round']} icon={MailIcon} Id="email" validateOnBlur type="text" controlName="email" Placeholder="Email" ChangeFunction={inputOnChange}
          ></RoundedInput>
          <div className={styles['validator-positon']}>
          <Validators value={formValues.email} controlName="email" onErrors={collectErrors} validators={validatorsList} />
          </div>
          {/* {formValid.email && <p className="text-red-500 text-xs italic">{formValid.email}</p>} */}
        </div>
        <div className="relative">
          <RoundedInput label="Password" config={{ hasEyeIcon: true }} className={`flex items-center ${styles['auth-round']}`} validateOnBlur icon={passwordIcon} type="password" controlName="password" Placeholder="Password" ChangeFunction={inputOnChange}
          ></RoundedInput>
           <div className={styles['validator-positon']}>
          <Validators type="Required" value={formValues.password} message="Required field" controlName="password" onErrors={collectErrors} />
        </div>
        </div>
        <div className="mt-2">
          <RoundedButton OnClick={submitHandler} className="sign-btn" Name="Login" Type="12"></RoundedButton>
        </div>
        </div>             
        <div className="leading-3">
          <a
            className={`${styles['forgot-pass']}`}
            href="forgot-password"
          >
            Forgot Password?
          </a>
        </div> 
        <div className="w-[24rem]">
      {!!registrationStatus&&<div className="flex w-full justify-center items-center gap-2">
          {/* <RoundedButton OnClick={handleRegister} Name="Register" Type="2"></RoundedButton> */}
          <div>Don’t have an account? 
            </div>
            <span className="text-[#14A0FA] hover:underline cursor-pointer" onClick={handleRegister}>Register</span> 
      </div>}
      </div>
      </form>
    </div>
  );
}

export default SignIn