import { useMemo, useState } from "react"
import "./NftGalleries.scss";
import MyGallery from "./Component/MyGallery";
import GalleryCollection from "./Component/MyGallery/Components/GalleryCollection";
import { useEffect } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback } from "react";
import { getInitial } from "App/Helper/formValidation";
import useCollectionLoader from "App/hooks/use-collectionLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import useAuth from 'App/hooks/use-auth';
import DeleteGallery from "App/Components/Ui/DeleteGallery";
import { deepCopy } from "App/Helper/utilities";
import useCollectionDropDown from "App/hooks/use-collection-dropdwon";
import { useDispatch, useSelector } from "react-redux";
import { getFormInitiated, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";
import useFrames from "App/hooks/use-frames";
import { getLoggingOutStatus, getShowWalletPop, setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import WalletsPop from "App/Components/Ui/WalletSelectPop/Wallets";

const NftGallery = () => {
    const { communityid, pgaction, galleryId } = useParams();
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const { triggerAPI } = useHttp();
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [galleryUpdateId, setGalleryUpdateId] = useState('')
    const { sliceCollectionAPI } = useCollectionLoader();
    const [formGroup, setFormGroup] = useState({})
    const [{ tokens, collections, collectionLoadingStatus, currentSelectedNfts,collectionWalletData,
        currentGalleryUpdateId, currentWallet, currentGallery, currentGalleryAssets,discoverableProfile, 
        currentCommunityGallery }, dispatch] = useStore(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [dltId, setDltId] = useState(0);
    const isLoggedIn = useAuth()[0]
    const [collectionList, setCollectionList] = useState([]);
    const [finalCollectionList, setFinalCollectionList] = useState([]);
    const { dropDownFilter, updateCollectionFilter, getDropValueByData } = useCollectionDropDown();
    const [collectionLevelLoading, setCollectionLevelLoading] = useState(true);
    const {getUserFrames} = useFrames();
    const dispatchRedux = useDispatch();
    const formInitiated = useSelector(getFormInitiated)
    const showAuthPop = useSelector(getShowWalletPop);
    const loggingOutStatus=useSelector(getLoggingOutStatus)
    const [isCreating, setIsCreating] = useState(false);
    const [isToken,setIsToken] = useState(false);
    const [galleryListKey, setGalleryListKey] = useState(1)
    const tabs = useMemo(() => {
        let tabList = [
            { id: 0, element: "Galleries", url: "/curate/display/my-galleries" },
            { id: 1, element: "Displays", url: "/curate/display" }            
        ];
        if (process.env.REACT_APP_COMPANY !== 'anywall') {
            tabList.push({ id: 2, element: "Rentals", url: "/curate/rentals" });
        }
        return tabList
      }, []);
    useEffect(()=>{
        setIsToken(pgaction === "token")
    },[pgaction])
    const pageTypeUrls = ['add', 'view', 'edit'];  
    const galleryBaseUrl = useMemo(() =>{
        let offset = 4;
        return pathname.split('/').slice(0, offset).join('/');
    }, [pathname])
    const pagetype = useMemo(() => {
       return pathname?.split('/')[4]
    },[pathname])    

    const galleryid = useMemo(() => {
        return parseInt(galleryId) ?? null;
    },[galleryId]);

    const actionsUrls = useMemo(() => {
        let temp = ['collection', 'save', 'token'];
        if(galleryid) temp?.push(galleryid)
        return temp;
    },[galleryid]);
     
    useEffect(() => {
        if((pgaction && !(actionsUrls?.includes(pgaction))) && (pagetype && !(pageTypeUrls?.includes(pgaction)))) {
            navigate(galleryBaseUrl);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pgaction, pagetype, galleryBaseUrl, actionsUrls])
    
    useEffect(()=>{
        if(!loggingOutStatus && !communityid) 
        dispatchRedux(setShowWalletPop((!isLoggedIn || (collectionWalletData !== null && !collectionWalletData?.length)) && collectionLoadingStatus !== "in-progress"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loggingOutStatus, isLoggedIn, collectionWalletData, collectionLoadingStatus, communityid])

    useEffect(() => {
        if(actionsUrls?.includes(pgaction) && !formInitiated) {
            navigate(galleryBaseUrl, {replace: true});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pgaction, formInitiated, galleryBaseUrl, actionsUrls])

    useEffect(() => {
        if(pathname === '/curate/display/my-galleries') {
            clearAll();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    const isCreator = useMemo(() => {
        return !!(communityid);
    },[communityid]);

    let formGroupMycollection = useMemo(() => {
        let temp = {
            name: {
                value: undefined,
                isRequired: true
            },
            description: {
                value: undefined,
                isRequired: false
            },
            galleryTypeId: {
                value: undefined,
                isRequired: false
            },
            categoryName: {
                value: undefined,
                isRequired: false
            },
            twoDWallBackgroundId: {
                value: undefined,
                isRequired: true
            },
            threeDWallBackgroundId: {
                value: undefined,
                isRequired: false
            },
            assets: {
                value: undefined,
                isRequired: true
            },
            metaBg: {
                value: undefined,
                isRequired: true
            },
            monoStyleType: {
                value: undefined,
                isRequired: true
            },
            monoCustomized: {
                value: undefined,
                isRequired: false
            },
            screenFrameType: {
                value: undefined,
                isRequired: false
            },
            layoutId: {
                value: undefined,
                isRequired: true
            },
            file: {
                value: undefined,
                isRequired: false
            }
        }
        if(communityid) 
            temp = {...temp,
                description: {
                    ...temp.description,
                    isRequired: true
                },
                rentalPrice : {
                    value: undefined, isRequired: true
                },
                rentalSplit : {
                    value: 0, isRequired: false, zeroAllowed:true
                }
            }
        return temp;
    }, [communityid]);
    

    useEffect(() => {
        if (galleryid && !Array.isArray(currentGallery)) { 
            loadAssetCollection();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[galleryid, currentGalleryUpdateId, currentGallery?.assets])
    useEffect(() => {
        if(!Array.isArray(currentGallery) && galleryid && currentGalleryAssets?.length) {
                let selectedListGallery = [] 
                currentGallery && currentGallery?.assets?.map((x, i) => {
                    let tempObj = currentGalleryAssets?.find((val) => val?.asset === x?.asset)
                    if(tempObj) {
                        tempObj = {...tempObj, frameId: x.frameId ?? null, isLandscape: x?.isLandscape ?? false, sequence: x?.sequence}
                    }
                    return selectedListGallery.push(tempObj)
                })
                selectedListGallery.sort(({sequence:a}, {sequence:b}) => a-b);
                selectedListGallery = selectedListGallery.filter(x => x)
                dispatch('setCurrentSelectedNfts', selectedListGallery)
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentGalleryAssets, currentGallery, galleryid])

    /* My Collection Asset Fetching */
    useEffect(() => {        
        if ((!!currentWallet?.address || isLoggedIn) && !isCreator) {
            getNfts()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, currentSelectedNfts, currentWallet?.address])

    const getNfts = useCallback(() => {
        if (collectionLoadingStatus === 'idle') {
            setSpinnerShow(true)
        }
        else {
            buildGalleryCollection(collections, 'my-collect')
            setSpinnerShow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionLoadingStatus, collections, currentSelectedNfts])

    useEffect(() => {
        dispatch("setTopMenuBarOptions", { showMenuIcons: (pathname!=="/curate/display/my-galleries")&&pgaction!=="save",
                   showTopMenus: pathname==="/curate/display/my-galleries"|| pgaction==="save", })
        dispatch('enableTopIcons', { userGuidePopup: !pathname?.includes(`/curate/display/my-galleries/view/`) });
        if(pathname==="/curate/display/my-galleries"){
            dispatch("setMenuOptions", tabs);
            dispatch("setActiveMenuOptions", 0);
        }
        return () => {
          //to reset all icons
          dispatch('resetAll');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname,communityid,pgaction,tabs])
    const buildGalleryCollection = (collection, type) => {
        if (typeof (collection) !== "string" && (!!currentWallet?.address || isLoggedIn)) {
            getFramesList((res) => {
                const collectionArray = deepCopy(collection);
                let newArr = collectionArray?.map(x => {
                    const matchingFrame = res?.find(frame => x.asset === frame.asset);
                
                    if (matchingFrame) {
                        return {...x, frameId: matchingFrame.frameId, isLandscape: matchingFrame.isLandscape ?? false};
                    }
                
                    return x;
                });
                if (currentSelectedNfts?.length) {
                    buildSelectedNftsCollection(newArr)
                } else {
                    setCollectionList(newArr)
                }
            })
        }
        else {
            setCollectionList([])
        }
    }
    const buildSelectedNftsCollection = (value) => {
        const collectionArray = JSON.parse(JSON.stringify(value))
        let newArr = collectionArray?.map(x => {
            const matchingSelected = currentSelectedNfts.find(selected => x.asset === selected.asset);
        
            if (matchingSelected) {
                return {...x, frameId: matchingSelected.frameId, isLandscape: matchingSelected.isLandscape ?? false};
            }
        
            return x;
        });
        
        setCollectionList(newArr);
    }
    /* My Collection Asset Fetching End*/


    const getFramesList = (callBack) => {
        getUserFrames((data)=> {
            callBack(data)
        },true);
    }
    
    
    const deleteResult = useCallback((res) => {
        setDeleteWarning(false)  
        setDltId(0)
        if(res >= 0) { 
            if(res) {
                setGalleryUpdateId('')
                dispatch('setCurrentGalleryUpdateId', {});
                setGalleryListKey(prev => prev + 1)
                navigate(`${galleryBaseUrl}`)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[communityid])
    const onClickDeleteGallery = useCallback((e = '', id) => {
            e && e.stopPropagation();
           setDeleteWarning(true) 
           setDltId(id)       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResult])
    
    useEffect(() => {
        return () => {
            dispatch('resetAll')
            dispatch("setPage",communityid?"community-gallery":"gallery")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid])

    useEffect(() => {
        dispatchRedux(setCurrentFormValues(getInitial(formGroupMycollection)))
        setFormGroup(formGroupMycollection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const closeDeleteWarnHandler = () => {
        setDeleteWarning(false);
    }
    
    const loadAssetCollection = useCallback(() => {
        if(currentGallery?.assets)  {
            //all blockchains having contractAddress as policy id.
            let assets = [...currentGallery?.assets]?.map(item => { let temp = {...item}; temp.policy_id = item?.contractAddress; return temp});
            sliceCollectionAPI(assets, 'setCurrentGalleryAssets', 'updateCurrentGalleryAssetsLoadingStatus');   
        }     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentGallery?.assets]);


    
    useEffect(() => {
        if(currentCommunityGallery?.id) {
            let currentArray = currentCommunityGallery;
            // eslint-disable-next-line array-callback-return
            let assets = currentArray?.assets?.filter(asset => {
                if(asset) {
                    return {...asset, collectionType: 'communityCollection', networkType: 'MAINNET'};
                }
            })
            currentArray = {...currentArray, assets}
            dispatch('setCurrentGallery', currentArray)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currentCommunityGallery?.id])
     
    useEffect(() => {  
        if(isCreator)              
            getCollectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreator])
      
    const getCollectionListResult = useCallback((res) => {
        setCollectionLevelLoading(false)
        const { data } = res;
        let count = 0;
        let mappeddata = data?.list?.map(d => {
            count++;
            return {
                asset:count.toString(),
                collectionImage:d.filePath,
                collectionName:d.name,
                files:[],
                image:d.filePath,
                collectionImage256:d.filePath256,
                lockDate:d.lockDate,
                mintDate:d.mintDate,
                networkType: "MAINNET",
                newName:d.name,
                onchain_metadata:null,
                policy:d.collectionId,
                quantity:d.assetsCount,
                walletId:null,
                categoryId:d.categoryId,
                community:d.community,
                firstSubcategoryId:d.firstSubcategoryId,
                termsId:d.termsId,
                formats:d.formats,
                types:d.types,
                tags:d.tags,
                collectionEditionsId:d.collectionEditionsId,
                collectionStorageId:d.collectionStorageId,
                aspectRatios:d.aspectRatios,
                category:d.category,
                firstSubcategory:d.firstSubcategory,
                subCategoryName:d?.firstSubcategory?.name,
                blockchainType: d?.blockchainType,
            }
        });
        if(communityid){
            dispatch("setCommunityGalleryCollections",mappeddata)
        }
        setCollectionList(mappeddata)          
        //setTotalPage(data?.pageMeta.totalItems)        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid])
  

    const getCollectionList = useCallback(() => {        
        setCollectionLevelLoading(true)
        let filterData = {}        
        filterData = {communityId: communityid, status: ['active','draft']}        
        
        triggerAPI({
            url: `community/collection/user/get`, method: 'post', data: {
                page: 1,
                items: 9999999,
                filter: filterData,
                search: ""
            }
        }, getCollectionListResult);
    }, [getCollectionListResult, triggerAPI, communityid])  
     
    const clearAll = useCallback(() => {
        setGalleryUpdateId('')
        dispatch('setCurrentGalleryUpdateId', '');
        dispatch('setCurrentSelectedNfts', [])
        dispatch('setCurrentList', [])
        dispatch('setCurrentGallery', {})
        dispatch('setCurrentFormValues', getInitial(formGroup))
        dispatchRedux(setCurrentFormValues(getInitial(formGroup)))
        return ()=>{
           
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        if(communityid){
            dispatch("setCommunityGalleryCollections",collectionList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid,collectionList,pathname])
    useEffect(() => {
        let finalList = deepCopy(collectionList);
        if(!isCreator) {
            finalList = getDropValueByData(collectionList, tokens)
        }
        setFinalCollectionList(finalList);
    }, [collectionList, dropDownFilter, isCreator, tokens, getDropValueByData])
    const setShowAuthPop =()=>  dispatchRedux(setShowWalletPop(true))
    useEffect(() => {
        if(pagetype){
            const isView = pagetype === 'view';
            setIsCreating(!isView);
        }
        return () => {
            dispatchRedux(setCurrentFormValues(getInitial(formGroup)))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagetype])
    useEffect(() => {
        dispatch("setPage",communityid?"community-gallery":"gallery")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid,pathname])
    const getFirstPart = (pathname) => {
        const pathParts = pathname.split('/');
        return pathParts[1]; 
      };
    const firstPart = getFirstPart(pathname);
    
    return (
        < div className={` sm:mb-2 md:pt-0 w-full h-full nft-gallery-sm full-gallery curate-gallery  ${isCreating || firstPart==="community" ? 'relative overflow-hidden' : 'overflow-auto'} ${!isCreator  && 'page-shadow'}`
        }>
            <div className={`${((!isLoggedIn||(collectionWalletData !== null && (!collectionWalletData?.length && !isCreator)))&&collectionLoadingStatus !== "in-progress")&&"hidden"} h-full`}>
            {!pagetype && <SkeltonCard show={spinnerShow} height="15.4rem" count={5} inline={true} containerClassName={` grid gap-2.5 home-grid px-4 pt-[1rem]`} />
            }
            {pagetype && <SkeltonCard show={spinnerShow} height="17rem" count={5} inline={true} containerClassName={` grid gap-2.5 gallery-grid-lg px-4 pt-[1rem]`} />
            }
            {!pagetype && !spinnerShow && 
                <div className="h-full">
                    {<MyGallery 
                        setIsCreating={setIsCreating} 
                        key={galleryListKey} onClickDeleteGallery={onClickDeleteGallery} onCancel={closeDeleteWarnHandler} 
                        galleryBaseUrl={galleryBaseUrl} clearAll={clearAll}
                    />}
                </div>}
            {pagetype && !spinnerShow && 
                <GalleryCollection
                    pgaction={pgaction} pageType={pagetype} galleryid={galleryid}
                    collectionLevelLoading={collectionLevelLoading} setCollectionLevelLoading={setCollectionLevelLoading}
                    isToken={isToken} formGroup={formGroup} 
                    showPrimaryDiscoverable={galleryBaseUrl?.includes("curate")}
                    onClickDeleteGallery={onClickDeleteGallery}
                    galleryUpdateId={galleryUpdateId}
                    nftCollections={finalCollectionList}
                    updateCollectionFilter={updateCollectionFilter}
                    setShowAuthPop={setShowAuthPop}  isCreator={isCreator} 
                    setIsCreating={setIsCreating}
                    galleryBaseUrl={galleryBaseUrl}
                    isDiscoverableProfile={discoverableProfile}
                    />
            }
            </div>
            {/* Pop to select wallet in without sign in */}
            {showAuthPop && <WalletsPop show={showAuthPop} />}
            {/* delete pop for listing and preview page */}
            {deleteWarning && <DeleteGallery show={true} dltId={dltId} deleteResult={deleteResult} />}
            
        </div >
    )
}


export default NftGallery
