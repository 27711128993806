const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setMetaWallList: (current, data) => {
            return {
                metaWallList: data
            }
        }
    }
    initStore(actions, {
        metaWallList: []
    });
}
export default configureStore;