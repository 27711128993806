import "../Icons.scss";

const HomeIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8732_12008)">
          <path
            d="M10.4038 16C10.2876 15.9999 10.1761 15.9537 10.0939 15.8715C10.0117 15.7893 9.96553 15.6779 9.96544 15.5616V10.9578C9.96519 10.7015 9.86326 10.4557 9.68202 10.2745C9.50078 10.0933 9.25504 9.99133 8.99872 9.99107H6.99873C6.74241 9.99133 6.49667 10.0933 6.31543 10.2745C6.13419 10.4557 6.03226 10.7015 6.03201 10.9578V15.5616C6.03192 15.6779 5.98571 15.7893 5.90351 15.8715C5.82131 15.9537 5.70985 15.9999 5.59361 16H0.891525C0.77528 15.9999 0.663821 15.9537 0.581623 15.8715C0.499425 15.7893 0.45321 15.6779 0.453125 15.5616V5.65155C0.453446 5.58296 0.469821 5.5154 0.500939 5.45428C0.532056 5.39316 0.577053 5.34017 0.632325 5.29955L7.73953 0.0848326C7.81471 0.029716 7.9055 0 7.99873 0C8.09195 0 8.18274 0.029716 8.25793 0.0848326L15.3651 5.29827C15.4204 5.33889 15.4654 5.39188 15.4965 5.453C15.5276 5.51412 15.544 5.58169 15.5443 5.65027V15.5616C15.5442 15.6779 15.498 15.7893 15.4158 15.8715C15.3336 15.9537 15.2222 15.9999 15.1059 16H10.4038ZM8.99872 8.91715C9.53967 8.91783 10.0583 9.13306 10.4407 9.51563C10.8231 9.8982 11.0382 10.4168 11.0387 10.9578V14.56C11.0389 14.657 11.0775 14.7499 11.146 14.8185C11.2146 14.887 11.3075 14.9256 11.4045 14.9258H14.104C14.2011 14.9258 14.2942 14.8873 14.3629 14.8187C14.4316 14.7501 14.4702 14.6571 14.4704 14.56V6.15267C14.4703 6.0952 14.4567 6.03855 14.4307 5.9873C14.4047 5.93605 14.367 5.89164 14.3206 5.85763L8.21473 1.38659C8.15199 1.34087 8.07636 1.31623 7.99873 1.31623C7.92109 1.31623 7.84546 1.34087 7.78273 1.38659L1.67681 5.85763C1.63047 5.89164 1.59277 5.93605 1.56676 5.9873C1.54075 6.03855 1.52714 6.0952 1.52705 6.15267V14.56C1.52721 14.657 1.5658 14.7499 1.63436 14.8185C1.70292 14.887 1.79585 14.9256 1.8928 14.9258H4.59233C4.68939 14.9258 4.78249 14.8873 4.85118 14.8187C4.91988 14.7501 4.95856 14.6571 4.95873 14.56V10.9578C4.95923 10.4167 5.17439 9.89799 5.55698 9.5154C5.93956 9.13282 6.45831 8.91766 6.99937 8.91715H8.99872Z"
            fill="#A0ABBB"
          />
        </g>
        <defs>
          <clipPath id="clip0_8732_12008">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HomeIcon;
