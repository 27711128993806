import "./subscription.scss";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import useHttp from "App/hooks/use-http";
import SubscriptionCard from "./Components/SubscriptionCard";
import { useStore } from "App/hooks-store/store";
import { useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "App/Helper/utilities";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const Subscription = () => {
  const [currentTab, setCurrentTab] = useState("Collector Benefits");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStatus = searchParams.get("status");
  const [plans, setPlans] = useState([]);
  const [showPaymentMsg, setShowPaymentMsg] = useState(false);
  const [skeltonLoader, setSkeltonLoader] = useState(true);
  const dispatch = useStore(false)[1];
   const tabs = ["Collector Benefits", "Creator Benefits (Coming soon)"];

  const { triggerAPI } = useHttp();

  const connectedSubscriptionPlanResult = useCallback(
    () => {
      triggerAPI(
        {
          url: `user/subscription-plan`,
          method: "get",
        },
        (ressub) => {
          const plans = ressub?.data?.subscriptionPlans;
          setPlans(plans);
          dispatch("hideSpinner");
          setSkeltonLoader(false)
        }
      );
    },
    [triggerAPI, dispatch]
  );
  
  useEffect(() => {
    connectedSubscriptionPlanResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  
  useEffect(() => {
    if (!showPaymentMsg) {
      setShowPaymentMsg(true);
      searchParams.delete("status");
      setSearchParams(searchParams);
      if (searchStatus) {
        if (searchStatus === "success") {
          dispatch("showToast", {
            toast: {
              toastMode: "success",
              message: capitalizeFirstLetter("Thank you for subscribing!"),
            },
          });
        } else if (searchStatus === "failed") {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: capitalizeFirstLetter("Payment Failed"),
            },
          });
        }
      }
    }
  }, [searchStatus, dispatch, showPaymentMsg, searchParams, setSearchParams]);

  const existingPlan = useMemo(() => { //currently existing (higlighted in UI) or on going
    return plans?.find(item => item?.isSubscribed && item?.isHighlighted && !!item?.planPrice) ?? null
  }, [plans])
  const activePlan = useMemo(() => { //in future activating plan or grace period plan
    return plans?.find(item => (item?.activeDate || (item?.isSubscribed && !!item?.isPaid)) || ((item?.isHighlighted && item?.highlightedPlan?.paymentStatus === 'failed'))) ?? null
  }, [plans])
  return (
    <div className="subscriptionmaindiv sm:gap-0 flex flex-col gap-[1rem] sm:mb-0 mb-[2.5rem]">
      <div className="title text-center ">
        <h3>{`Subscription Plans`}</h3>
      </div>
      
        <div className="note-brief">With one Cur8 account, you can create and manage both collector and creator profiles. Enjoy shared benefits and switch between profiles seamlessly at any time.</div>
       
      <div className="flex subscription-tab">
        {tabs &&
          tabs.map((tab) => (
            <div key={tab}>
              <button
                onClick={() => !tab?.includes("Coming soon") && setCurrentTab(tab)}
                className={`${["tab-btn", currentTab === tab ? "active" : ""].join(
                  " "
                )} ${tab?.includes("Coming soon")&&"cursor-not-allowed"}`}
              >
                {tab}
              </button>
            </div>
          ))}
      </div>
      
      <div className={["flex Subscriptionlist", currentTab === "Monet Members" ? "hidemargin" : ""].join(" ")}>
         {skeltonLoader ? 
          [1,2,3,4].map((loader)=><SkeletonLoader key={loader} className={`h-[40rem]`} count={1}/>)
           :
        plans.map((plan) => (
          <SubscriptionCard
            existingPlan={existingPlan}
            activePlan={activePlan}
            currentTab={currentTab}
            plan={plan}
            key={plan.id}
          />
        ))}
      </div>
      {currentTab === "Monet Members" && (
        <p className="block about-monet max-w-[71rem] m-auto">
          Monet is the loyalty and rewards program of the Monet Society. Monet
          Members enjoy benefits and priveleges when using the Cur8 platform and
          with other Monet Society Network partners. To learn more about the
          Monet Society and the Monet Membership program, please
          visit <a
        href="https://monetsociety.io/"
        rel="noreferrer"
        target="_blank"
        className="monet_link"
      >
       The Monet Society
      </a>.
        </p>
      )}
      <a
        href="https://www.monetsociety.io/membership"
        rel="noreferrer"
        target="_blank"
        className="sm:hidden block learn-more clickable-links"
      >
        Learn More
      </a>
    </div>
  );
};
export default Subscription;
