import React, { useEffect, useRef, useState } from "react";
import "./ReusableDropdown.scss";

const ReusableDropdown = (props) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    const handleScroll = () => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(document.activeElement)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  const dropdownList = (
    <div
      id="dropdown"
      className={isOpen ? `reusable_dropdown-list-box` : "hide_dropdown"}
      aria-labelledby="dropdownDefaultButton"
      style={{
        top: props?.dropDownPositionTop,
        left: props?.dropDownPositionLeft,
      }}
    >
      <ul className="reusable_dropdown-ul-box">
        {props?.options?.map((option, key) => (
          <li
            key={option?.id ?? key + 1}
            onClick={() => {
              if (option?.onClick) {
                option?.onClick(option);
              }
              closeDropdown();
            }}
          >
            {option?.label}
          </li>
        ))}
      </ul>
    </div>
  );
  return (
    <div ref={dropdownRef} className="reusable_dropdown-container">
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        type="button"
        className={`${props?.mainButtonClass} ${props?.withMainButtonContent&&"with-dropdown"}`}
      >
        {props?.mainButtonContent}
        {props?.withMainButtonContent && dropdownList}
      </button>

      {!props?.withMainButtonContent && dropdownList}
    </div>
  );
};

export default ReusableDropdown;
