import WarningBox from 'App/Components/Ui/WarningBox'

const DeleteWarning = props => {
    return (
        <WarningBox onApprove={props.onDelete} onCancel={props.onCancel} approveBtn="Delete" cancelBtn="Cancel">
            Are you sure you want to delete gallery?
        </WarningBox>
    )
}

export default DeleteWarning
