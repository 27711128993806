import { Fragment, useCallback, useMemo, useReducer, useRef } from 'react';
import { useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import useHttp from 'App/hooks/use-http';import { useEffect } from 'react';
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import { getFrameFilterList, getHtmlAR } from 'App/Helper/aspectRatioCalc';
import './NFTFrameGallery.scss';
import FramePopup from './FramePopup';
import zoomIcon from "Assets/Icons/enlargeIcon.png";
import SkeltonCard from 'App/Components/Ui/SkeltonCard';
import useScreenSize from 'App/hooks/use-screen-size';
import useFrames from 'App/hooks/use-frames';
import TransparentPopup from 'App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/TransparentPopup';
import SubscriptionWarning from 'App/Pages/NftGallery/Component/SubscriptionWarning';
const frameLoadingReducer = (state, action) => {
    switch (action.type) {
        case 'basic':
            return { ...state, basic: action.value };
        case 'myPremium':
            return { ...state, myPremium: action.value };
        default:
            return { basic: true, myPremium: true };
    }
}
const frameLoader = {
    basic: true,
    myPremium: true
}
const NFTFrameGallery = (props) => {
    const { triggerAPI } = useHttp();
    const [freeFrames, setFreeFrames] = useState([])
    const [nftFrames, setNftFrames] = useState([])
    const [selectedNftFrame, setSelectedNftFrame] = useState('')
    const [selectedFrame, setSelectedFrame] = useState('')
    const [{ currentSelectedNfts }, dispatch] = useStore(false);
    const [filterFreeFrames, setFilterFreeFrames] = useState([])
    const [filterNftFrames, setFilterNftFrames] = useState([])
    const [currentFullFreeFrames, setCurrentFullFreeFrames] = useState([])
    const [currentFullNftFrames, setCurrentFullNftFrames] = useState([])
    const [frameCollapse, setFrameCollapse] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(true)
    const [filterNft, setFilterNft] = useState(props?.selectedNfts || [])
    const [imagesFrame, setImagesFrame] = useState({})
    const [showPopup, setShowPopup] = useState({ mode: false })
    const screenSize = useScreenSize()?.width;
    const [showName, setShowName] = useState(true)
    const [loaderPreview, setLoaderPreview] = useState(false)
    const targetRef = useRef(0)
    const { getFrameList } = useFrames();
    const [activeTab, setActiveTab] = useState(1)
    const [showSubscriptionPopup,setSubscriptionPopup] = useState(false)
    const [subscriptionReached,setSubscriptionReached] = useState(false)
    const frameTab = useMemo(() => {
        return [{
            id: 1,
            name: 'My Frames',
        }, {
            id: 2,
            name: 'Frames',
        }]
    }, []);
    const [frameLoading, setFrameLoading] = useReducer(frameLoadingReducer, frameLoader)
    const addPremiumFrameToMyAccount = (frameAssetId) => {
        try {
            const payload = { frameAssetId }
            triggerAPI({
                url: `frame-asset/add-premium-frame`, data: payload, method: 'post'
            }, () => {
                getFramesFrameList();
                setActiveTab(1); //selected premium frame added to my frames
            });
        } catch (error) {
            // console.log('error', error);
        }
        
    }
    //executed when frames are selected
    const frameSelectedHandler = (e) => {
        setLoaderPreview(true)
        let item = e.currentTarget.dataset["ind"]
        let type = e.currentTarget.dataset["type"]
        let updateFrame = type === 'free' ? setSelectedFrame : setSelectedNftFrame
        let frame = type === 'free' ? freeFrames : nftFrames
        let nonUpdateFrame = type === 'free' ? setSelectedNftFrame : setSelectedFrame
        setFrameCollapse(false);
        if (item) {
            updateFrame(frame[item])
            nonUpdateFrame(null)
            if(type === 'nft')
                addPremiumFrameToMyAccount(frame[item]?.id);
        }
        
    }

    //to update the selected frame with the exisiting frames
    const updateGalleryFrame = ({ frameId, assetId }) => {
        const newArray = [...currentSelectedNfts]?.map((x) => {
            if(x.asset === assetId) {
               return  {...x, frameId: frameId?.id ?? null, isLandscape: frameId?.isLandscape ?? null}
            }
            return x;
        })
        dispatch('setCurrentSelectedNfts', [...newArray])
    }

    useEffect(() => {
        if (props?.frameAssetNew?.asset && selectedNftFrame?.id) {
            applyFrameHandler('nft')
        }
        else if (props?.frameAssetNew?.asset && selectedFrame?.id) {
            applyFrameHandler('free')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNftFrame, selectedFrame])

    const applyFrameHandler = (type) => {
        updateGalleryFrame({ frameId: type === 'nft' ? selectedNftFrame : selectedFrame, assetId: props?.frameAssetNew?.asset })

        if (type === 'nft') {
            props?.onChangeFrame({frameId: selectedNftFrame.id, asset: props?.frameAssetNew?.asset})
        }
        else {
            props?.onChangeFrame({frameId: selectedFrame.id, asset: props?.frameAssetNew?.asset})
        }

    }

    const createFrame = (list) => {
        return list?.map(frame => {
            return {
                id: frame?.id,
                name: frame.name,
                s3ImageUrl: frame.s3ImageUrl,
                s3ImageUrlLandscape: frame.s3ImageUrlLandscape,
                price: frame.price ? frame.price : "00",
                inStock: 30,
                metaInfo: frame.metaInfo,
                isLandscape: frame.isLandscape
            }
        })
    }

    const getFramesFrameList = useCallback(() => {
        const framePayload = {
            "items": 999999,
            filter: { sortBy:"modernWestern", categoryId: [2, 3] }
        }
        setFrameLoading({ type: 'basic', value: true })
        setFrameLoading({ type: 'myPremium', value: true })
        getFrameList({framePayload}, ({list, userAvailablePremiumFrames, userAddedPremiumFrames, isSubscriptionLimitReached})=> {
            let myFrames = [], frames = [];
            list?.forEach(frame => {
                if((frame?.categoryId === 2) || (frame?.categoryId === 3 && !!frame?.isUserAdded)) {
                    myFrames = [...myFrames, frame]
                } else {
                    frames = [...frames, frame]
                }
            });
            setFrameLoading({ type: 'basic', value: false })
            setFrameLoading({ type: 'myPremium', value: false })
            setCurrentFullNftFrames(frames)
            setCurrentFullFreeFrames(myFrames)
            setSubscriptionPopup(userAddedPremiumFrames >= userAvailablePremiumFrames)
              setSubscriptionReached(isSubscriptionLimitReached)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])
    
    useEffect(() => {
        setSelectedFrame(null)
        setSelectedNftFrame(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.frameAssetNew?.asset])

    useEffect(() => {
        setSpinnerShow(true)  
        getFramesFrameList();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        dispatch('setFreeFrames', currentFullFreeFrames)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFullFreeFrames])
    useEffect(() => {
        dispatch('setNftFrames', currentFullNftFrames)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFullNftFrames])

    useEffect(() => {
        setFreeFrames(createFrame(filterFreeFrames))
    }, [filterFreeFrames])
    useEffect(() => {
        setNftFrames(createFrame(filterNftFrames))
    }, [filterNftFrames])

    // const getHtmlAR = (nft) => {
    //     return nft?.files?.[0]?.mType?.indexOf("html") > -1 ? 1 : null;
    // }
    useEffect(() => {
        const found = currentSelectedNfts?.find((x) => x?.asset === props?.frameAssetNew?.asset)
        let value = getFrameFilterList(found?.nftcdnimage256, currentFullFreeFrames, getHtmlAR(found))
        value.then((a) => {
            setFilterFreeFrames([...a])
        });
        setSpinnerShow(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFrame?.id, currentFullFreeFrames, imagesFrame])

    useEffect(() => {
        const found = currentSelectedNfts?.find((x) => x?.asset === props?.frameAssetNew?.asset)
        let value = getFrameFilterList(found?.nftcdnimage256, currentFullNftFrames, getHtmlAR(found))
        value.then((a) => {
            setFilterNftFrames([...a])
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNftFrame?.id, currentFullNftFrames, imagesFrame])

    useEffect(() => {
        const val = currentSelectedNfts?.find((val) => val?.asset === props?.frameAssetNew?.asset)
        setImagesFrame({ ...val })
    }, [props?.frameAssetNew, currentSelectedNfts])

    const onImageClick = (e) => {
        props?.onSmFramClick(e);
        setFrameCollapse(false)
    }

    useEffect(() => {
        setFilterNft(currentSelectedNfts)
    }, [currentSelectedNfts])
    const onClosePopup = (e) => {
        setShowPopup({ mode: false })
    }

    const onClick = () => {
        setShowPopup({ mode: true })
    }
    
    const handleTabs = useCallback((e) => {
        let id = e.currentTarget?.dataset.id;
        setActiveTab(Number(id))
    }, [])
    return (
        < Fragment >
            <div className={`frame-selection-sm ${frameCollapse ? 'active' : ''} ${props?.frameAssetNew?.asset ? '' : 'nft-b-select'}`}>
                <div className="selection-head flex " >
                    <div>
                        Gallery ({currentSelectedNfts?.length})
                    </div>

                </div>

                <div className={`selection-continer`}>
                    <div className={`selection-frames scroller ${props?.frameAssetNew?.asset ? '' : 'active'}`}>
                        {filterNft?.map((selectedNfts, i) =>
                            <div key={(selectedNfts?.asset + (selectedNfts?.frameId ?? -1))} ref={targetRef}>
                                <div className={`${selectedNfts?.asset === props?.frameAssetNew?.asset ? 'active' : ''} py-1 frame-sm-card`} data-network={selectedNfts?.networkType} data-id={selectedNfts?.asset}
                                    onClick={onImageClick}>
                                    <GalleryImages
                                        frameName={`frame${i}`}
                                        clearCache={false}
                                        addedNfts={false}
                                        fileName={`file${i}`}
                                        galleryNew={selectedNfts}
                                        src={selectedNfts?.nftcdnimage256}
                                        setShowName={setShowName}
                                        className="nft-gallery-card-image"
                                        
                                        />
                                    {!showName && <div className="flex absolute nft-selected-name hidden-mb">
                                        <span className={`name name-sm`}>
                                            {selectedNfts?.name ? selectedNfts?.name : 'No Name'}
                                        </span>

                                    </div>}
                                </div>
                            </div>

                        )}
                    </div>
                </div>

            </div>
            {
                props?.frameAssetNew?.asset ?

                    <Fragment>
                        {screenSize < 551 ?
                            <FramePopup onClose={onClosePopup} show={showPopup} nftGalleryNew={true} nftFrames={true} gallery={true} galleryNew={imagesFrame} src={imagesFrame?.image} frameClass="gallery-frame-size" />
                            : <div className='tab-top-container relative'>
                                <SkeltonCard show={loaderPreview} noAbsolute={false} height="100%" inline={true}
                                    containerClassName="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex frame-spinner justify-center items-center z-9" />
                                <div className={loaderPreview ? 'opacity-0 min-h-[15.75rem]' : 'opacity-100 h-full popIntoFrameEffect'}> 
                                    <GalleryImages 
                                        key={imagesFrame?.nftcdnimage512}
                                        setLoaderPreview={setLoaderPreview} className="banner-img-main w-full" 
                                        isDetailedView={true} nftGalleryNew={true} nftFrames={true} gallery={true} 
                                        galleryNew={imagesFrame} src={imagesFrame?.nftcdnimage512} frameClass="" frameName={'details'} 
                                    />
                                </div>
                            </div>}
                        <div className="flex flex-col nftdrop-options frames-listing-gallery items-start gap-4 steps-container">
                            <>
                                <div className='tabs'>
                                    {frameTab?.map(item => <div className={`tab-item ${activeTab === item?.id && 'active'}`}
                                    onClick={handleTabs} key={item?.id} data-id={item?.id}>{item?.name}</div> )}
                                </div>
                                {activeTab === 1 && 
                                    <div className={`relative w-full`}>
                                        <div className="grid !grid-cols-4 w-full relative scroller cursor-pointer side-scroller frame-listing" >
                                            <SkeltonCard show={frameLoading?.basic} height="100%" count={1} noAbsolute={true} inline={true} containerClassName={`aspect-square`} />

                                            {!frameLoading?.basic && filterFreeFrames?.length >= 1 &&
                                                filterFreeFrames?.map((el, index) =>
                                                    <div key={index} data-ind={index} data-type='free' onClick={props?.frameAssetNew?.frameId === el?.id ? ()=> {} : frameSelectedHandler} className={`${props?.frameAssetNew?.frameId === el?.id ? 'add' : ''} frame-bord border-none frame-selection-section`}>
                                                        <GalleryImages showZoom={`${screenSize < 551 ? true : false}`} actionImage={`${screenSize < 551 ? zoomIcon : false}`} onClick={onClick} frameDetailsNew={props?.frameDetailsNew} nftFrames={true} gallery={true} src={el.isLandscape ? (el?.s3ImageUrlLandscape256 ?? el?.s3ImageUrl256) : el?.s3ImageUrl256} className="gallery-preview-image" />
                                                    </div>
                                            )}
                                        </div> 
                                    </div> 
                                }
                                {activeTab === 2 && 
                                    <div className={`relative  w-full ${!!showSubscriptionPopup && `overflow-hidden h-[100%]`}`}>
                                        <div className="grid !grid-cols-4 w-full relative scroller cursor-pointer side-scroller frame-listing" >
                                            <SkeltonCard show={frameLoading?.myPremium} height="100%" count={1} noAbsolute={true} inline={true} containerClassName={`aspect-square`} />
                                            {!spinnerShow && filterNftFrames?.length >= 1 &&
                                                filterNftFrames?.map((el, index) =>
                                                    <div key={index} data-ind={index} data-type='nft' onClick={props?.frameAssetNew?.frameId === el?.id ? ()=> {} : frameSelectedHandler} className={`${props?.frameAssetNew?.frameId === el?.id ? 'add' : ''} frame-bord border-none frame-selection-section`}>
                                                        <GalleryImages showZoom={`${screenSize < 551 ? true : false}`} actionImage={`${screenSize < 551 ? zoomIcon : false}`} frameDetailsNew={props?.frameDetailsNew} nftFrames={true} gallery={true} src={el.isLandscape ? (el?.s3ImageUrlLandscape ?? el?.s3ImageUrl) : el?.s3ImageUrl} className="gallery-preview-image " />
                                                    </div>
                                            )}
                                        </div> 
                                        {!!showSubscriptionPopup &&
                                            <TransparentPopup>
                                                <SubscriptionWarning isSubscriptionReached={subscriptionReached} content="frames" />
                                            </TransparentPopup>
                                        }
                                    </div>
                                }
                                
                            </>
                        </div>
                    </Fragment >
                    :
                    <div className='pt-3 nftdrop-options nft-b-select text-xs'>No items selected</div>
            }

        </Fragment >
    )
}

export default NFTFrameGallery