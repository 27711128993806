import { useEffect } from 'react';

const useEventListener = (eventType, callback, element = window) => {
    useEffect(() => {
        // Make sure element supports addEventListener
        if (!(element && element.addEventListener)) {
          return;
        }
        
        // Create event listener that calls callback function
        const eventListener = (event) => callback(event);
        
        // Add event listener
        element.addEventListener(eventType, eventListener);
        
        // Clean up event listener on component unmount
        return () => {
          element.removeEventListener(eventType, eventListener);
        };
      }, [eventType, callback, element]);
}
export default useEventListener;