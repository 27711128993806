import TimeSelection from "../TimeSelection";
import { useRef, useEffect, useCallback, useState } from "react";
import './GalleryControlPanel.scss';
import SettingsControl from "./Components/SettingsControl";
import DisplayControl from "./Components/DisplayControl";
import ScreenStyle from "./Components/ScreenStyle";
import MultiLayoutsControl from "./Components/MultiLayoutsControl";
import MultiBgControl from "./Components/MultiBgControl";
import LeftArrow from "App/Components/IconsComponents/GalleryControls/LeftArrow";
import PauseIcon from "App/Components/IconsComponents/GalleryControls/PauseIcon";
import PlayIcon from "App/Components/IconsComponents/GalleryControls/PlayIcon";
import RightArrow from "App/Components/IconsComponents/GalleryControls/RightArrow";
import TimerIcon from "App/Components/IconsComponents/GalleryControls/TimerIcon";
import LayoutIcon from "App/Components/IconsComponents/GalleryControls/LayoutIcon";
import BackgroundIcon from "App/Components/IconsComponents/GalleryControls/BackgroundIcon";
import FrameIcon from "App/Components/IconsComponents/GalleryControls/FrameIcon";
import ListIcon from "App/Components/IconsComponents/GalleryControls/ListIcon";
import EnlargeViewIcon from "App/Components/IconsComponents/GalleryControls/EnlargeViewIcon";
import SettingsIcon from "App/Components/IconsComponents/GalleryControls/SettingsIcon";
import useProfileApiHelper from "App/Pages/UserProfile/Components/Apis";
import CcIcon from "App/Components/IconsComponents/GalleryControls/CcIcon";
import CcControl from "./Components/CcControl";
import ThreeDots from "App/Components/IconsComponents/GalleryControls/ThreeDots";
import useAuth from "App/hooks/use-auth";

const GalleryControlPanel = (props) => {
    const isLoggedIn = useAuth()[0];
    const [showMobileControls, setShowMobileControls] = useState(false);

    const listTime = {
        sec: [...Array(60).keys()],
        min: [...Array(60).keys()],
        hr: new Array(24).fill('').map((x, i) => i),
    }
    const {getProfile} = useProfileApiHelper();
    const clickRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        document.addEventListener("mousemove", onMouseMoveHandler);
        document.addEventListener("touchstart", onMouseMoveHandler);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
            document.removeEventListener("mousemove", onMouseMoveHandler);
            document.removeEventListener("touchstart", onMouseMoveHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClickOutside = event => {
        if (clickRef.current && !clickRef.current.contains(event.target)) {
            props?.setToggleControls(false);
        }
    };
    
    const timer = useRef(null);
    const onMouseMoveHandler = useCallback(() => {
        if (!props?.isAppV1 && !props?.isAppV2) {
            if (timer.current) {
                clearTimeout(timer.current);
                props?.setControlPanelVisibility(true)
            }
            timer.current = setTimeout(() => {
                props?.setControlPanelVisibility(false);
                // props?.setToggleControls(false);
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[timer])

    const handleShowTime = () => {
        props?.setToggleControls(prev => { return {timer: !(prev?.timer)}});
        // props?.closeOtherControls('timer');
    }
    
    const handleShowSetting = useCallback(() => {
        props?.setToggleControls(prev => { return {settings: !(prev?.settings)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowDisplayList = useCallback(() => {
        props?.setToggleControls(prev => { return {displayList: !(prev?.displayList)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleShowCCPopup = useCallback(() => {
        props?.setToggleControls(prev => { return {ccPopup: !(prev?.ccPopup)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowScreenStyle = useCallback(() => {
        props?.setToggleControls(prev => { return {screenStyle: !(prev?.screenStyle)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowLayout = useCallback(() => {
        props?.setToggleControls(prev => { return {layouts: !(prev?.layouts)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const handleShowBg = useCallback(() => {
        props?.setToggleControls(prev => { return {backgroundWall: !(prev?.backgroundWall)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(() => {
        if(!!props?.toggleControls && !props?.isMeta)
            props?.toggleSlidePause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.toggleControls, props?.isMeta])
    useEffect(()=>{
        if(isLoggedIn)
         getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoggedIn])
    return (
        <div className={`gallery-control-container relative ${props?.controlPanelVisibility ? 'active-control' : ''} ${props?.zoom ? 'zoom-in' : ''}`} ref={clickRef}>
            {props?.toggleControls?.screenStyle && 
                    <ScreenStyle 
                        show={props?.toggleControls?.screenStyle}
                        setScreenControl={props?.setScreenControl} 
                        screenControls={props?.screenControls} 
                        setToggleControls={props?.setToggleControls}
                        onHandleScreenControls={handleShowScreenStyle}
                    />
            }
            {props?.isMono && <DisplayControl 
                show={props?.toggleControls?.displayList}
                list={props?.galleryList} 
                setFilteredGalleryList={props?.setFilteredGalleryList}
                currentPlayingAsset={props?.currentPlayingAsset}
                slideGoTo={props?.slideGoTo}
                onHandleDisplayList={handleShowDisplayList}
            />}
            {(props?.isMono || props?.isMulti) && <CcControl 
                show={props?.toggleControls?.ccPopup}
                settingControls={props?.settingControls}
                setSettingControls={props?.setSettingControls} 
                isMono={props?.isMono}
                isMulti={(props?.isMulti && props?.multiQRCode)}
            />}
            {props?.toggleControls?.settings && 
                <SettingsControl 
                    showTransition={!props?.isMeta && !props?.isMulti} 
                    showCaption={true} 
                    showEnhanced={props?.isMulti} 
                    settingControls={props?.settingControls} 
                    setSettingControls={props?.setSettingControls} 
                    handleGalleryView={props?.handleGalleryView} 
                    galleryType={props?.galleryType}
                    onHandleSettingsControl={handleShowSetting}
                />
            }
            {props?.toggleControls?.backgroundWall && 
                <MultiBgControl bgColor={props?.bgColor}
                    multiStyleList={props?.multiStyleList} multiColorList={props?.multiColorList} 
                    bgWallFilter={props?.bgWallFilter} setBgWallFilter={props?.setBgWallFilter} 
                    wallList={props?.isMeta ? props?.metaWallList : props?.multiWallList}  setSelectedMetaBg={props?.setSelectedMetaBg}
                    selectedMultiBg={props?.selectedMultiBg} selectedMetaBg={props?.selectedMetaBg} setSelectedMultiBg={props?.setSelectedMultiBg}
                    isMeta={props?.isMeta}
                    onHandleMultiBgList={handleShowBg}
                    show={(props?.toggleControls?.backgroundWall && props?.isMulti)}
                />
            }
            {props?.toggleControls?.layouts && 
                <MultiLayoutsControl 
                    selectedMultiLayout={props?.selectedMultiLayout}
                    setSelectedMultiLayout={props?.setSelectedMultiLayout}
                    multiLayoutList={props?.multiLayoutList}
                    onHandleLayoutList={handleShowLayout}
                    show={props?.toggleControls?.layouts}
                />
            }
            <div className="gallery-control-panel relative">
                <div className="gallery-control gap-[0.29rem]">
                    {!props?.isMeta && <div className="cursor-pointer" onClick={props?.handlePrevSlide}>
                        {/* <img src={sliderPrevIcon} alt='slide prev icon' /> */}
                        <LeftArrow className={`gc_left-arrow`}/>
                    </div>}
                    {props?.slidePlaying ?
                        <div className="cursor-pointer" onClick={props?.toggleSlidePause}>
                            {/* <img src={sliderPauseIcon} alt='pause icon' /> */}
                            <PauseIcon className={`gc_pause-icon`}/>
                        </div>
                        :
                        <div className="cursor-pointer" onClick={props?.toggleSlidePlay}>
                            {/* <img src={sliderPlayIcon} alt='play icon' /> */}
                            <PlayIcon className={`gc_play-icon`}/>
                        </div>
                    }

                    <div className="cursor-pointer" onClick={props?.handleNextSlide}>
                        {/* <img src={sliderNextIcon} alt='slide next icon' /> */}
                        <RightArrow className={`gc_right-arrow`}/>
                    </div>
                </div>
                <div className="gallery-control gap-[1.45rem]">
                    <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowTime}>
                        {/* <img src={props?.toggleControls?.timer ? sliderTimerIconActive : sliderTimerIcon} alt='' /> */}
                        <TimerIcon className={`${props?.toggleControls?.timer&&"activeIcon"}`}/>
                        <TimeSelection
                            listTime={listTime}
                            showTitle={true}
                            show={props?.toggleControls?.timer}
                            hideSelectBar={true}
                            timerOnchange={props?.timerOnchange}
                            initialTime={props?.initialTime}
                        />
                    </div>
                    {props?.isMulti &&
                        <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowLayout}>
                            {/* <img src={props?.toggleControls?.layouts ? layoutIconActive : layoutIcon} alt='' /> */}
                            <LayoutIcon className={`${props?.toggleControls?.layouts&&"activeIcon"}`}/>
                        </div>
                    }
                    {(props?.isMulti || props?.isMeta) &&
                        <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowBg}>
                            {/* <img src={props?.toggleControls?.backgroundWall ? backGroundIconActive : backGroundIcon} alt='' />  */}
                            <BackgroundIcon className={`${props?.toggleControls?.backgroundWall&&"activeIcon"}`}/>                           
                        </div>
                    }
                    {props?.isMono &&
                        <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowScreenStyle}>
                            {/* <img  src={props?.toggleControls?.screenStyle ? frameIconActive : frameIcon} alt='' /> */}
                            <FrameIcon className={`${props?.toggleControls?.screenStyle&&"activeIcon"}`}/>
                        </div>
                    }
                    {props?.isMono &&
                        <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowDisplayList}>
                            {/* <img src={props?.toggleControls?.displayList ? displayListIconActive : displayListIcon} alt='' /> */}
                            <ListIcon className={`${props?.toggleControls?.displayList && "activeIcon"}`}/>
                        </div>
                    }
                    {(props?.isMulti || props?.isMono) &&
                        <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowCCPopup}>
                            <CcIcon className={`${props?.toggleControls?.ccPopup && "activeIcon"}`}/>
                        </div>
                    }
                    <div className="cursor-pointer relative hide_icon_mobile-screen" onClick={handleShowSetting}>
                        {/* <img src={props?.toggleControls?.settings ? settingIconActive : settingIcon} alt='' />    */}
                        <SettingsIcon className={`${props?.toggleControls?.settings && "activeIcon"}`}/>                     
                    </div>
                    {!props?.zoom && (
                        <div className="cursor-pointer hide_icon_mobile-screen" onClick={props?.handleZoomIn}>
                            {/* <img src={enlargeIcon} alt='' /> */}
                            <EnlargeViewIcon/>
                        </div>
                    )}                    
                </div>
                <div className={`gc_three-dots-container-mr ${(showMobileControls && props?.isMeta) ? 'expanded_in-meta' : showMobileControls ? 'expanded' : ''}`}>
                    <TimeSelection
                        listTime={listTime}
                        showTitle={true}
                        show={props?.toggleControls?.timer}
                        hideSelectBar={true}
                        timerOnchange={props?.timerOnchange}
                        initialTime={props?.initialTime}
                        showCloseBtn={true}
                        onHandleTimeControls={handleShowTime}
                    />
                    {showMobileControls && <div className={`gc_other-mobile-controls-container`}>
                        <div className="cursor-pointer relative mobile_gc_icons_open5" >
                            <TimerIcon className={`${props?.toggleControls?.timer && "activeIcon"}`} onClick={handleShowTime}/>
                            
                        </div>

                         {/* For Mono Gallery */}
                        {props?.isMono &&
                            <div className="cursor-pointer relative mobile_gc_icons_open4" onClick={handleShowScreenStyle}>
                                <FrameIcon className={`${props?.toggleControls?.screenStyle && "activeIcon"}`} />
                            </div>
                        }
                        {props?.isMono &&
                            <div className="cursor-pointer relative mobile_gc_icons_open3" onClick={handleShowDisplayList}>
                                <ListIcon className={`${props?.toggleControls?.displayList && "activeIcon"}`} />
                            </div>
                        }
                        {/* {(props?.isMulti || props?.isMono) &&
                            <div className="cursor-pointer relative mobile_gc_icons_open2" onClick={handleShowCCPopup}>
                                <CcIcon className={`${props?.toggleControls?.ccPopup && "activeIcon"}`} />
                            </div>
                        } */}
                        {/* <div className="cursor-pointer relative mobile_gc_icons_open1" onClick={handleShowSetting}>
                            <SettingsIcon className={`${props?.toggleControls?.settings && "activeIcon"}`} />
                        </div> */}

                        {/* For Multi Gallery */}
                        {props?.isMulti &&
                            <div className="cursor-pointer relative mobile_gc_icons_open4" onClick={handleShowLayout}>
                                <LayoutIcon className={`${props?.toggleControls?.layouts && "activeIcon"}`} />
                            </div>
                        }
                        {(props?.isMulti || props?.isMeta) &&
                            <div className="cursor-pointer relative mobile_gc_icons_open3" onClick={handleShowBg}>
                                <BackgroundIcon className={`${props?.toggleControls?.backgroundWall && "activeIcon"}`} />
                            </div>
                        }

                        <div className="cursor-pointer relative mobile_gc_icons_open2" onClick={handleShowSetting}>
                            <SettingsIcon className={`${props?.toggleControls?.settings && "activeIcon"}`} />
                        </div>

                    </div>}

                    <button onClick={()=>setShowMobileControls((prev)=>!prev)} className={`gc_three-dots-box`}>
                        <ThreeDots className={`${showMobileControls && "activeIcon"}`}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GalleryControlPanel;