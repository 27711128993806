import "../Icons.scss";

const RedditIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM18.2456 10.2456C19.214 10.2456 20 11.0316 20 12C20 12.7158 19.5649 13.3333 18.9895 13.614C19.0175 13.7824 19.0316 13.9509 19.0316 14.1333C19.0316 16.828 15.9018 19.0035 12.0281 19.0035C8.15439 19.0035 5.02457 16.828 5.02457 14.1333C5.02457 13.9509 5.0386 13.7684 5.06667 13.6C4.44913 13.3193 4.02808 12.7158 4.02808 12C4.02808 11.0316 4.81404 10.2456 5.78246 10.2456C6.24562 10.2456 6.68071 10.4421 6.98948 10.7368C8.1965 9.85261 9.86667 9.30524 11.7333 9.2491L12.6176 5.06664C12.6456 4.98243 12.6877 4.91226 12.7579 4.87015C12.8281 4.82805 12.9123 4.81401 12.9965 4.82805L15.9018 5.44559C16.0983 5.02454 16.5193 4.74384 17.0105 4.74384C17.6983 4.74384 18.2597 5.30524 18.2597 5.99296C18.2597 6.68068 17.6983 7.24208 17.0105 7.24208C16.3368 7.24208 15.7895 6.70875 15.7614 6.0491L13.1649 5.50173L12.3649 9.2491C14.1895 9.31927 15.8456 9.88068 17.0386 10.7368C17.3474 10.428 17.7684 10.2456 18.2456 10.2456ZM9.24913 12C8.56141 12 8.00001 12.5614 8.00001 13.2491C8.00001 13.9368 8.56141 14.4982 9.24913 14.4982C9.93685 14.4982 10.4983 13.9368 10.4983 13.2491C10.4983 12.5614 9.93685 12 9.24913 12ZM12.014 17.4596C12.4912 17.4596 14.1193 17.4035 14.9754 16.5473C15.1018 16.421 15.1018 16.2245 15.0035 16.0842C14.8772 15.9579 14.6667 15.9579 14.5404 16.0842C13.993 16.6175 12.8561 16.814 12.0281 16.814C11.2 16.814 10.0491 16.6175 9.5158 16.0842C9.38948 15.9579 9.17895 15.9579 9.05264 16.0842C8.92632 16.2105 8.92632 16.421 9.05264 16.5473C9.89474 17.3895 11.5368 17.4596 12.014 17.4596ZM13.5018 13.2491C13.5018 13.9368 14.0632 14.4982 14.7509 14.4982C15.4386 14.4982 16 13.9368 16 13.2491C16 12.5614 15.4386 12 14.7509 12C14.0632 12 13.5018 12.5614 13.5018 13.2491Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default RedditIcon;
