import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import axios from "axios";

const useProfileApiHelper = () => {
  const dispatch = useStore(false)[1];
  let { triggerAPI } = useHttp();

  const getProfile = (callback) => {
    triggerAPI(
      {
        url: `user/profile/get`,
        method: "get",
      },
      (res) => {
        dispatch('updateUsername', res?.data?.email)
        dispatch('setUserId', res?.data?.id);
        dispatch('setGalleryCreateCount', res?.data?.availableGalleriesCount);
        dispatch('setCreatedGalleryCount', res?.data?.createdGalleryCount);
        dispatch("setSubscriptionLimitReached", res?.data?.isSubscriptionLimitReached);
        dispatch("setDiscoverableProfile", res?.data?.discoverableProfile);
        dispatch("setCurator",res?.data?.isCurator);
        dispatch("setPremiumGalleryWallEnabled", res?.data?.isAvailablePremiumWall);
        dispatch("setAnyWallDisplayGalleryCount", res?.data?.anywallDisplayCount);
        dispatch("setGalleryType3D", res?.data?.galleryType3D);
        callback && callback(res?.data);
      }
    );
  };

  const getCurator = (curatorId,callback) => {
    triggerAPI({
        url: `curator/get/${curatorId}`, method: 'get'
    }, (res)=>{
      callback && callback(res);
    },()=>{
        dispatch("hideSpinner");
    });
}
  const getPlaces = async (searchQuery) => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { input: searchQuery },
      headers: {
        'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
        'x-rapidapi-host': process.env.REACT_APP_RAPID_API_HOST,
      }
    };

    try {
      const response = await axios.request(options);
      const placesOptions = [];
      response?.data?.predictions?.forEach((place) => {
        if (place?.terms?.length >= 2 && place?.terms?.length <= 4) {
          placesOptions.push({ id: place?.place_id, name: place?.description, value: place?.description, terms: place?.terms })
        }
      })
      return placesOptions;
    } catch (error) {
      return [];
    }
  }

  const getGeoLocation = async (placeId) => {
    let URL = process.env.REACT_APP_RAPID_API_URL;
    URL = URL?.replace(`/autocomplete/json`, "");

    const options = {
      method: 'GET',
      url: `${URL}/details/json`,
      params: {
        place_id: placeId
      },
      headers: {
        'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
        'x-rapidapi-host': process.env.REACT_APP_RAPID_API_HOST,
      }
    };

    try {
      const { data } = await axios.request(options);
      const userLocation = {
        longitude: data?.result?.geometry?.location?.lng,
        latitude: data?.result?.geometry?.location?.lat
      }
      return { userLocation };

    } catch (error) {
      return '';
    }
  }

  return { getProfile, getPlaces, getGeoLocation,getCurator };
};
export default useProfileApiHelper;
