import useScreenSize from "App/hooks/use-screen-size";
import { useState } from "react";
import "./WalletList.scss";
import EditSection from "./editSection";
import { useStore } from "App/hooks-store/store";
import { copyClipHandler, splitWord } from "App/Helper/utilities";
import FormatField from "App/Components/Ui/FormatField";
import CopyIcon from "App/Components/IconsComponents/CopyIcon";
import EditIcon from "App/Components/IconsComponents/EditIcon";
import CloseIcon from "App/Components/IconsComponents/CloseIcon";
import ReloadIcon from "App/Components/IconsComponents/ReloadIcon";

const Listing = ({ wallet, isEdit, index, onDeleteConfirm, editWallet, onRefreshConfirm }) => {
  const screenSize = useScreenSize()?.width;
  const [edit, setEdit] = useState(false);
  const { blockchains } = useStore()[0];
  const editHandler = () => setEdit((prev) => !prev);
  const onCancel = () => setEdit(() => false);
  const copyAddress = () => {
    copyClipHandler(wallet.walletAddress);
  };
  const handleDelete = (e) => {
    const { dataset } = e.currentTarget;
    onDeleteConfirm(dataset?.wallet, wallet?.blockchainType?.code);
  }
  const handleRefresh = (e) => {    
    onRefreshConfirm(wallet?.id, wallet?.blockchainType?.code);
  }
  return (
    <li
      key={wallet.walletAddress}
      className="justify-between wallet-full flex gap-1 customize-wallet-container"
    >
      <div className="flex flex-col sm:flex-row sm:gap-3 address-wrap w-full items-start sm:items-center justify-between">
        <div className="flex gap-1 wallet-add items-center">
          <img className="wallet-icon"
                src={blockchains?.filter(b=>b.id === wallet?.blockchainTypeId)?.[0]?.icon}
                title={blockchains?.filter(b=>b.id === wallet?.blockchainTypeId)?.[0]?.name} alt=""/>
          
          <span
            className={`${
              isEdit ? "edit-mode" : ""
            } wallet-name min-w-fit wallet-list-count grid items-center`}
          >
            <FormatField
              type="sub"
              maxWidth={20}
              value={wallet.walletName ?? `Wallet Nº${index + 1} `}
              /> 
            &nbsp;:
          </span>
          {splitWord(wallet?.walletAddress, 10)}
          <CopyIcon className={"cursor-pointer w-4 ml-2.5"} onClick={copyAddress} />
        </div>
        <div className="sm:flex hidden">
              <span className=" wallet-nfts items-center">
                {wallet?.nftCount} Tokens
              </span>
            </div>
          {isEdit && (
            <div className="flex mb-6 mt-4 sm:mt-0 sm:mb-0">
              <div className="flex gap-[0.75rem] ml-auto edit-icons">
                <span className="cursor-pointer flex items-center">
                  {/* <img src={editHover} alt="edit"  /> */}
                  <EditIcon onClick={editHandler} className={`wallet_edit-icon`} />
                </span>
                <span className="cursor-pointer grid items-center">
                  <ReloadIcon onClick={handleRefresh}
                    dataWallet={wallet.id} className={`wallet_reload-icon`} />
                </span>
                <span className="cursor-pointer grid items-center">
                  <CloseIcon onClick={handleDelete}
                    dataWallet={wallet.id} className={`wallet_close-icon`} />
                </span>
              </div>
            </div>
          )}
        </div>
      {edit && (
        <EditSection
          onCancel={onCancel}
          wallet={wallet}
          editWallet={editWallet}
          index={index}
        />
      )}
    </li>
  );
};

export default Listing;
