import { Helmet } from "react-helmet";
import logo from 'Assets/Icons/cur8-logo-mini.svg';
const title = process.env.REACT_APP_COMPANY === 'anywall' ? "Anywall: Curate The World’s Digital Assets - Discover, curate, and dis(play) your digital collections"
    :"Cur8: Curate The World’s Digital Assets - Discover, curate, and dis(play) your digital collections";
const description = 'Easily manage NFT collections & curate stunning galleries with custom frames/walls to showcase your digital assets in minutes with Cur8';

const PageMeta = ({pageTitle = '', pageDescription = description, image = logo, url = window.location.href}) => {
    return (
        <Helmet>
            <title>{`${pageTitle ? `${pageTitle} |`:''} ${title}`}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:title" content={`${pageTitle ? `${pageTitle} | `:''} ${title}`} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
        </Helmet>
    )
}
export default PageMeta;