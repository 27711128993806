import { useEffect, useState } from "react"
import styles from "../Styles/Frameit.module.scss"
import FrameDisplayUi from "./Components/FrameDisplayUi"
import { getFrameFilterList } from "App/Helper/aspectRatioCalc"
import FrameFilterTopUI from "./Components/FrameFilterTopUI"

const FrameitTab = props => {
    // eslint-disable-next-line no-unused-vars
    const [myFrames, setMyFrames] = useState({ list: [], totalItems: 0 })
    const [allFrames, setAllFrames] = useState([])
    const [selectedFrame, setSelectedFrame] = useState(props.defaultFrame)
    useEffect(() => {
        setSelectedFrame(props.defaultFrame)
        // getMyFrames()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultFrame, props?.nft?.image])
    useEffect(() => {
        previewFrameHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFrame])
    useEffect(() => {
        if (props?.aspectRatio)
        {
            let filteredFreeFrames = getFrameFilterList(props?.nft?.nftcdnimage256, props?.freeFrameList?.list, props?.aspectRatio);
            filteredFreeFrames.then(data => {
                let allFrames = {
                    list: [...myFrames.list, ...data],
                    totalItems: parseInt(myFrames?.totalItems) + parseInt(props?.freeFrameList?.totalItems)
                };
                setAllFrames(allFrames)
            })
        }
    }, [myFrames, props?.freeFrameList, props?.nft?.nftcdnimage256, props?.aspectRatio])

    const applyFrameHandler = () => {
        props?.onApply(selectedFrame)
    }

    const previewFrameHandler = () => {
        props.onPreview(selectedFrame)
    }
    const frameSelectHandler = e => {
        let item = e.currentTarget.dataset["index"];
        const currentFrame = allFrames?.list[item];
        if (item)
            setSelectedFrame({...currentFrame})
        else
            setSelectedFrame(null)
    }
    return <div className={`${styles["frameit-tab"]}`}>
        {(props?.showTopFilter && props?.fromManageCollection) ?
            <FrameFilterTopUI
                filterAge={props?.filterAge}
                nft={props?.nft}
                onFrameShop={props?.onFrameShop}
                color={props?.color}
                setColor={props?.setColor}
                ageFilter={props?.ageFilter}
                setAgeFilter={props?.setAgeFilter}
                hideFrameShopButton={props?.hideFrameShopButton}
            />
            : null}
        
        <FrameDisplayUi
            reloadFrameHandler={props?.reloadFrameHandler}
            allFrames={allFrames}
            applyFrame={applyFrameHandler}
            onFrameSelect={frameSelectHandler}
            selectedFrame={selectedFrame}
            nft={props.nft}
            freeFrameLoading={props?.freeFrameLoading}
            applyFrameButton={props.applyFrameButton}
            hideNoFrame={props.hideNoFrame}
        />
    </div>
}

export default FrameitTab