import { useMemo, useState } from "react";
import Mono from "Assets/Images/mono.png";
import Multi from "Assets/Images/multi.png";
import "./GalleryPreviewSelection.scss";
import PreviewDummy from "Assets/Images/PreviewDummy.svg";
import { useLocation } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import EditIcon from "App/Components/IconsComponents/EditIcon";
import DeleteIcon from "App/Components/IconsComponents/DeleteIcon";
import ShareUrl from "App/Components/ShareUrl";

const GalleryPreviewSelection = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [previewType, setPreviewType] = useState("");
  const { currentUserId }= useStore(false)[0];
  const handlePreview = (e) => {
    e.stopPropagation();
    if(playGallery){
    setPreviewType(e?.currentTarget?.dataset?.type);
    props?.handlePreview&&props?.handlePreview(
      parseInt(e?.currentTarget?.dataset?.id),
      e?.currentTarget?.dataset?.type,
      e
    );
  }
  };
  const setPrimary=(e)=>{
    e.stopPropagation();
    props?.setPrimaryHandler && props?.setPrimaryHandler(e?.currentTarget?.dataset?.id);
  }
  const location = useLocation();
  const playGallery=useMemo(()=>{
    return (props?.createdBy===currentUserId) || props?.playGallery
  },[props?.createdBy,props?.playGallery,currentUserId])
  return (
    <>
      <div className={`content-details fadeIn-left h-full ${props?.sidebar&&"sidebar-section"}`}>
        <div className="content-wrapper relative">
        {!!(props?.selectedCollection==="My Collections"&&(props?.priority!==1)&&(props?.isDiscoverable))&&<button data-id={props?.galleryID} onClick={setPrimary} className="set-primary">Set as primary</button>}
     
        {(location.pathname === '/curate/display/my-galleries' || props.editGallery ||props?.shareUrl) && (
            <div className="icons-overlay flex gap-4 gallery-action">
              {!!props?.shareUrl && <ShareUrl url={props?.shareUrl} />}
              {(location.pathname === '/curate/display/my-galleries' || props.editGallery)&&<>
                <EditIcon onClick={(e) => props?.handleEdit(e, props?.galleryID)} />
                <DeleteIcon onClick={(e) => props?.onClickDeleteGallery(e, props?.galleryID)} />
              </>}
            </div>
          )}

        {!props.hideDetails && <div className="gallery-selection justify-between gap-[0.875rem]">
          <div
            className={`option basis-0 grow shrink ${!playGallery&&"!cursor-not-allowed"}`}
            data-type="2d"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="img py-[0.1875rem] mono">
              <img src={(props?.files?.files&&props?.files?.files[0])??Mono} alt="mono" />
            </div>
            {/* <p>Mono</p> */}
          </div>
          <div
            className={`option basis-0 grow shrink  ${!playGallery&&"!cursor-not-allowed"}`}
            data-type="2ds"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="my-gallery-card-image card-home img  h-full" style={{
                            backgroundImage: `url(${props?.files?.multiThumbnail?.url256 ? props?.files?.multiThumbnail?.url256 : PreviewDummy})`
                        }}>
              <img src={props?.files?.multiThumbnail?.url256 ?? props?.files?.sliders?.[0] ?? Multi} alt="multi" className="object-cover" />
            </div>
            {/* <p>Multi</p> */}
          </div>
          <div
            className={`option basis-0 grow shrink  ${!playGallery&&"!cursor-not-allowed"}`}
            data-type="3d"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="img">
              <img src={props?.metaBgImage} alt="meta" className="object-cover" />
            </div>
            {/* <p>Meta</p> */}
          </div>
        </div>}
        </div>
        {!props?.noOpenBtn &&
        <button data-id={props?.galleryID}  className="absolute bottom-4 open-btn">Open</button>
        }
      </div>
    </>
  );
};

export default GalleryPreviewSelection;