export function creatorShareUrls(route, contentId, dataId, tab, creatorType) {
  const routes = {
    creatorsDetails: `${process.env.REACT_APP_SITE_URL}/discover/${creatorType}/${contentId}/${tab}`,
    creatorsGalleryDetails:`${process.env.REACT_APP_SITE_URL}/discover/${creatorType}/${contentId}/gallery/${dataId}`,
    default: `${process.env.REACT_APP_SITE_URL}`,
  };

  return routes[route] || routes.default;
}

export function collectorShareUrls(route, contentId, dataId, tab) {
  const routes = {
    collectorsGalleryDetails: `${process.env.REACT_APP_SITE_URL}/discover/collectors/${contentId}/my-galleries/${dataId}`,
    default: `${process.env.REACT_APP_SITE_URL}`,
  };

  return routes[route] || routes.default;
}