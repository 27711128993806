/* eslint-disable react-hooks/exhaustive-deps */
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import EventList from "./Components/EventLists";
import { useStore } from "App/hooks-store/store";
import { useParams } from "react-router-dom";
import CreateEventModal from "./Components/CreateEventModal";
import ButtonWithPlusIcon from "App/Components/ButtonWithPlusIcon";

const SCREENSIZE = window.screen.width;
const paginationConfig = {
  perPage: SCREENSIZE > 550 ? 10 : 50,
};
const Events = (props) => {
  const { communityid } = useParams();
  const [eventList, setEventList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [subjects, setSubjects] = useState([])
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];

  const getDetailedCommunityResult = useCallback(
    (res) => {
      const { data } = res;
      dispatch("setCommunityDetails", data);
    },
    [dispatch]
  );

  const getDetailedCommunity = useCallback(() => {
    triggerAPI(
      {
        url: `community/get-basic/${communityid}`,
        method: "get",
      },
      getDetailedCommunityResult
    );
  }, [dispatch, getDetailedCommunityResult, triggerAPI, communityid]);

  const getEventsSubjects = useCallback(() => {
    triggerAPI(
      {
        url: `community/event/categories/get`,
        method: "get",
      },
      (res)=>setSubjects(res?.data)
    );
  }, [dispatch, triggerAPI]);

  useEffect(() => {
  if(communityid)  getDetailedCommunity();
  }, [communityid]);

  const eventListApiResult = useCallback(
    (res) => {
    //  dispatch("hideSpinner");
    setLoader(false)
      const { upcomingEvents, pastEvents } = res.data;
      setEventList({upcomingEvents, pastEvents});
    },
    [dispatch]
  );

  const getEventList = useCallback(
    (pageNo) => {
     // dispatch("showSpinner");
     setLoader(true)
      triggerAPI(
        {
          url: "community/event/get",
          data: {
            page: pageNo ? pageNo : page,
            items: paginationConfig.perPage,
            filter: {
              communityId: communityid,
            },
            search: "",
          },
          method: "post",
        },
        eventListApiResult
      );
    },
    [page, triggerAPI, eventListApiResult, dispatch, communityid]
  );

  useEffect(() => {
    getEventList();
    getEventsSubjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if(props?.notFull) setLoader(false)
  }, [props?.notFull])

  const newEventHandler = () => {
    if (add) {
      setSelectedData({});
      getEventsSubjects()
    }
    setAdd((pre) => !pre);
  };

  const saveEventApiResult = useCallback(
    (res) => {
      // dispatch("showToast", {
      //   toast: {
      //     toastMode: "success",
      //     message: capitalizeFirstLetter(res.message),
      //   },
      // });
      dispatch("hideSpinner");
      newEventHandler();
      props?.callBackApi && props?.callBackApi()
      getEventList(1);
      setPage(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveEvent = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/event/create",
          data,
          method: "post",
        },
        saveEventApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Event creation failed" },
          });
        }
      );
    },
    [dispatch, saveEventApiResult, triggerAPI]
  );

  const updateEventApiResult = useCallback(
    (res) => {
      setEventList([]) 
      newEventHandler();
      getEventList();  
      props?.callBackApi && props?.callBackApi()
      dispatch("hideSpinner");
      // dispatch("showToast", {
      //   toast: { toastMode: "success", message: res?.message },
      // }); 

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const updateEvent = useCallback(
    (data) => {
      triggerAPI(
        {
          url: `community/event/${selectedData?.id}`,
          data,
          method: "put",
        },
        updateEventApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Event edit failed" },
          });
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, updateEventApiResult, triggerAPI]
  );


  const deleteEventApiResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      getEventList();
      props?.callBackApi && props?.callBackApi()
      // dispatch("showToast", {
      //   toast: { toastMode: "success", message: res?.message },
      // });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const deleteEvent = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `community/event/delete/${data?.id}`,
          method: "delete",
        },
        deleteEventApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Announcement edit failed" },
          });
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, deleteEventApiResult, triggerAPI]
  );

  const onSaveHandler = async(data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      let value = Array.isArray(val) ? JSON.stringify(val) : val;
      formData.append(key, value);
    });
    if (Object.keys(selectedData)?.length) {
      dispatch("showSpinner");
      await updateEvent(formData);

    } else {
      formData.append("communityId", Number(communityid));
      saveEvent(formData);
    }
  };
  const editHandler = (val) => {
    let ids = [];
    val?.categories?.filter((item) => (item?.isSelected && item?.subcategories?.forEach((sub) => (sub?.isSelected ? ids = [item?.id, sub?.id] : ''))));
    val.selectedCategoryId = ids;
    newEventHandler();
    setSelectedData(val);
  };

  // useEffect(()=>{
  //   if(SCREENSIZE<=550){
  //     const discoverContent = document.getElementById("discover-content")
  //     const discoverOverview = document.getElementById("discover-overview")
  //     const discoverTab= document.getElementById("discover-tab")
  //     if(add){
  //       discoverOverview.style.display="none";
  //       discoverTab.style.display="none";
  //       discoverContent.style.padding="0rem 1rem 0rem 1rem";
  //     }else{
  //       discoverOverview.style.display="block";
  //       discoverTab.style.display="block";
  //       discoverContent.style.padding="1.25rem 1rem 0rem 1rem";
  //     }
  //   }
  //     },[add,selectedData])
  return (
    <div
      className={`list-event sm:block flex flex-col gap-[0.875rem] pb-4 ${
        add && "mt-[2rem] sm:mt-0"
      }`}
    >
      <CreateEventModal
        subjects={subjects}
        setSubjects={setSubjects}
        selectedData={selectedData}
        onSaveHandler={onSaveHandler}
        newEventHandler={newEventHandler}
        add={add}
      />
      <>
        {!props?.notFull && (
          <div className="min-h-[2.6rem]">
           {!!eventList?.upcomingEvents?.length&& <div className="sm:flex hidden"><ButtonWithPlusIcon onClick={newEventHandler} className="mr-auto" buttonText="Event" /></div>}
          </div>
        )}

        <EventList
          page="creator"
          from={props?.from}
          type={props?.type}
          tab={props?.tab}
          notFull={props?.notFull}
          data={props?.notFull ? props?.data : eventList}
          editHandler={editHandler}
          eventLoader={loader||props?.eventLoader}
          deleteHandler={deleteEvent}
          onCreateEventsHandler={newEventHandler}
        />
        {!!(!props?.notFull &&eventList?.upcomingEvents?.length)&& (
          <div className={"sm:hidden flex"}>
          <ButtonWithPlusIcon onClick={newEventHandler}  buttonText="Event" />
          </div>
        )}
      </>
    </div>
  );
};

export default Events;
