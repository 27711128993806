import HomeIcon from "App/Components/IconsComponents/HomeIcon";
import "./Footer.scss";
import { NavLink, useLocation } from "react-router-dom";
import DisplayIcon from "App/Components/IconsComponents/DisplayIcon";
import useAuth from "App/hooks/use-auth";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CollectionIcon from "App/Components/IconsComponents/CollectionIcon";
import Logo from "App/Components/Logo";
import useProfileApiHelper from "App/Pages/UserProfile/Components/Apis";
import UserIcon from "App/Components/IconsComponents/UserIcon";
import SidebarDetails from "./Components/SidebarDetails";
import LgSearchIcon from "App/Components/IconsComponents/LgSearchIcon";
import GlobalSearch from "App/Components/Ui/GlobalSearch";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import { hasActivePath } from "App/Helper/utilities";

const Footer = () => {
  const isLoggedIn = useAuth()[0];
  const handleB2CLogin = useAuth()[6];
  const { pathname } = useLocation();
  let { triggerAPI } = useHttp();
  const { currentUserId }= useStore(false)[0];
  const { getProfile } = useProfileApiHelper();

  const refForGSContainer = useRef(null);
  const refForGSButton = useRef(null);

  const [collectorProfileDetails, setCollectorProfileDetails] = useState({
    profilePicUrl256: Logo(),
  });
  const [creatorProfileDetails, setCreatorProfileDetails] = useState({
    profilePicUrl256: Logo(),
  });
  const [curatorProfileDetails, setCuratorProfileDetails] = useState({
    profilePicUrl256: Logo(),
  });
  const [profileDetailsSidebarOpened, setProfileDetailsSidebarOpened] =
    useState(false);
  const [showGlobalSearchContainer, setShowGlobalSearchContainer] =
    useState(false);
  const getCreatorList = useCallback((currentUserId) => {
    if (currentUserId) {
      triggerAPI(
        {
          url: `community/get`,
          method: "post",
          data: {
            page: 1,
            items: 1,
            filter: { userId: currentUserId },
            search: "",
          },
        },
        (res) => {
          setCreatorProfileDetails({
            ...res?.data?.list[0],
            profilePicUrl256: res?.data?.list[0]?.displayPicture256 || Logo(),
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);
  const getCurator = useCallback(() => {
    triggerAPI(
      {
        url: `curator/get-user`,
        method: "get",
      },
      (res) => {
        setCuratorProfileDetails({
          ...res?.data,
          profilePicUrl256: res?.data?.displayPicture256 || Logo(),
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLoggedIn&&currentUserId) {
      getCurator();
      getCreatorList(currentUserId);
      getProfile((res) => {
        setCollectorProfileDetails({
          ...res,
          profilePicUrl256: res?.profilePicUrl256 || Logo(),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn,currentUserId]);

  const onProfileSidebarClickHandler = () => {
    setProfileDetailsSidebarOpened((pre) => !pre);
  };
  const loginHandler = () => {
    handleB2CLogin();
  };
  const handleGlobalContainerShow = () => {
    setShowGlobalSearchContainer((prev) => !prev);
  };
  
  useEffect(() => {
    const handleCloseOnOutsideClickOnMob = (event) => {
      if (refForGSContainer?.current && !refForGSContainer?.current.contains(event.target) && refForGSButton?.current && !refForGSButton?.current.contains(event.target)) {
        handleGlobalContainerShow();
      }
    };
    document.addEventListener('mousedown', handleCloseOnOutsideClickOnMob);
    return () => {
      document.removeEventListener('mousedown', handleCloseOnOutsideClickOnMob);
    };
  }, []);

  const data = useMemo(
    () => {
      let tabs = [
        { id: 1, Icon: HomeIcon, link: "/home" },
        {
          id: 2,
          Icon: true,
          link: false,
          isButton: true,
          onClick: handleGlobalContainerShow,
        },
        {
          id: 3,
          Icon: DisplayIcon,
          link: isLoggedIn ? "/curate/display/my-galleries" : "",
          method: !isLoggedIn ? loginHandler : null,
        },
        {
          id: 4,
          Icon: CollectionIcon,
          link: isLoggedIn ? "/curate/collections/my-collections" : "",
          method: !isLoggedIn ? loginHandler : null,
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
      ];
      if (isLoggedIn) {
        tabs.push({
          id: 5,
          imageSrc: collectorProfileDetails?.profilePicUrl256,
          method: onProfileSidebarClickHandler,
        });
      } else {
        tabs.push({ id: 5, Icon: UserIcon, method: loginHandler });
      }
      return tabs;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoggedIn, collectorProfileDetails?.profilePicUrl256]
  );
  return (
    <>
      <div className="footer">
        {data?.map(
          (
            {
              Icon,
              id,
              newTab,
              link,
              imageSrc,
              activeLinkBase,
              checkFullUrl,
              method,
              isButton,
              onClick,
            },
            i
          ) =>
            Icon ? (
              link ? (
                <NavLink
                  key={id}
                  target={newTab ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className={({ isActive }) =>
                    isActive || hasActivePath(activeLinkBase,pathname)
                      ? checkFullUrl
                        ? pathname?.slice(1) === link
                          ? "link-active"
                          : "active"
                        : "link-active"
                      : "active"
                  }
                  to={link}
                >
                  <Icon key={id} className={``} onClick={method} />
                </NavLink>
              ) : isButton ? (
                <button ref={refForGSButton} key={id} className="" onClick={onClick}>
                  <LgSearchIcon
                    className={`global_search-icon ${
                      showGlobalSearchContainer ? "activeIcon" : ""
                    }`}
                  />
                </button>
              ) : (
                <Icon
                  key={id}
                  className={`${pathname?.slice(1) === link && "activeIcon"}`}
                  onClick={method}
                />
              )
            ) : (
              <img
                key={id}
                className="link-image"
                src={imageSrc}
                onClick={method}
                alt=""
              />
            )
        )}
        {showGlobalSearchContainer && (
          <div className="absolute">
            <GlobalSearch
              mobileScreenRef={refForGSContainer}
              showOpenForMob={showGlobalSearchContainer}
              onOpenForMob={setShowGlobalSearchContainer}
            />
          </div>
        )}
      </div>
      <SidebarDetails
        className={`${profileDetailsSidebarOpened && "active"}`}
        sidebarHandler={onProfileSidebarClickHandler}
        collectorProfileDetails={collectorProfileDetails}
        creatorProfileDetails={creatorProfileDetails}
        curatorProfileDetails={curatorProfileDetails}
      />
    </>
  );
};
export default Footer;
