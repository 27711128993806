import React from 'react';
import cardanoIcon from "Assets/Icons/ada_svg_grey.svg";
import './CollectionResults.scss';
import dummyImage from "Assets/Images/dummypic.svg";
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { numberWithCommas } from 'App/Helper/utilities';
import { useStore } from 'App/hooks-store/store';
import { getBlockchainIcon } from 'App/Helper/blockchain';

const CollectionResults = ({ loading, loaderCount, data, onReset, onSeeMoreClick, totalItems }) => {
    const navigate = useNavigate();
    const dispatch = useStore()[1];

    const handleNavigate = (collectionId, piece) => {
        let url = `discover/collection/${piece?.category?.slug}/details`
        onReset();
        if (!!piece?.community?.slug) {
            url = `discover/all-creators/${piece?.community?.slug}/collection`;
        }
        navigate(`/${url}/${collectionId}`)
        dispatch("setFullPageLoader", true);

        setTimeout(() => {
            dispatch("setFullPageLoader", false);
        }, 4000);
    }
    return (
        <div className={`collection_results-container results-and-btn-container`}>
            <div className={`results-wrapper`}>
                {/* Loader */}
                {loading && [...Array(loaderCount).keys()].map((loader) => <div key={loader} className='collection_results-details-loader'>
                    <SkeletonLoader width={20} height={20} count={1} inline={true} />
                    <SkeletonLoader width={40} height={40} count={1} inline={true} />
                    <SkeletonLoader width={800} height={20} count={1} inline={true} />
                </div>)}
                {/* Results */}
                {data?.length && !loading ? data?.slice(0, 10)?.map((piece, key) => <CollectionDetails key={piece?.id ?? key} piece={piece} onNavigate={handleNavigate} />)
                    : null
                }
            </div>
            {!loading && data && (totalItems > 10) ? <div className='collection_results-see-more gs_see_more-Btn'>
                <button onClick={onSeeMoreClick}>See More Results</button>
            </div> : null}
            {!loading && !data?.length ? <div className='collection_no-results-found no-results-found_message'>
                <p>No Collection Found</p>
            </div> : null}
        </div>
    )
}

function CollectionDetails({ piece, onNavigate }) {



    return (<div className={`collection_results-details-card`} onClick={() => onNavigate(piece?.collectionId, piece)}>
        <div className={`details_column-one basis-5/8`}>
            <div className='basis-2/10 card-icon-box'>
                <img src={getBlockchainIcon(piece?.blockchainType?.code) ?? cardanoIcon} alt='collection icon' />
            </div>

            <div className='basis-2/10 card-piece-image-box'>
                <img className={`w-10 h-10 object-cover`} src={piece?.filePath256 ?? piece?.showcase ?? dummyImage} alt={piece?.name} />
            </div>

            <div className={`basis-4/10 card-piece-name-box`}>
                <h2 className='max-w-[12.5rem] truncate'>{piece?.name}</h2>
                {piece?.community?.name && <h3 className='max-w-[8rem] truncate'>By {piece?.community?.name}</h3>}
            </div>

            {piece?.category?.name && <div className={`basis-2/10 card-piece-category-box`}>
                <h2>{piece?.category?.name
                }{piece?.firstSubcategory?.name
                    && ` - ${piece?.firstSubcategory?.name}`}
                </h2>
            </div>}
        </div>

        {/* details_column-one for mobile */}
        <div className={`details_column-one_mobile basis-5/8`}>
            <div className='basis-2/10 card-icon-box'>
                <img className='w-5 h-5 object-cover' src={getBlockchainIcon(piece?.blockchainType?.code) ?? cardanoIcon} alt='collection icon' />
            </div>

            <div className='flex gap-2 basis-8/10 flex-col'>
                <div className='flex gap-2'>
                    <div className='card-piece-image-box'>
                        <img className={`w-10 h-10 object-cover`} src={piece?.filePath256 ?? piece?.showcase ?? dummyImage} alt={piece?.name} />
                    </div>

                    <div className={`card-piece-name-box`}>
                        <h2 className={`max-w-[13.5rem] break-word`}>{piece?.name} {!!piece?.community?.name && `By ${piece?.community?.name}`}</h2>
                    </div>
                </div>

                {piece?.category?.name && <div className={`card-piece-category-box`}>
                    <h2>{piece?.category?.name
                    }{piece?.firstSubcategory?.name
                        && ` - ${piece?.firstSubcategory?.name}`}
                    </h2>
                </div>}
            </div>
        </div>

        <div className={`details_column-two basis-3/8`}>
            {piece?.metrics[0]?.supply || piece?.assetCount ? <div className={`card-piece-count-box`}>
                <h3>Pieces</h3>
                <h2>{numberWithCommas(piece?.metrics[0]?.supply ?? piece?.assetCount)}</h2>
            </div> : null}
        </div>

    </div>)

}

export default CollectionResults