const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCurrentWallet: (current, wallet) => {
            return {
                currentWallet: wallet
            }
        },
        setWalletConnectionStatus: (current, status) => {
            return{
                walletConnectionStatus: status
            }
        }
    }
    initStore(actions, {
        currentWallet: null,
        walletConnectionStatus: 'idle', //idle, connecting, finished
    });
}
export default configureStore;