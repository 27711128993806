import { useMemo } from "react";

const useFilteredDataWithCount = (list=[], count = 6 ) => {
  const filteredList = useMemo(() => {
    let filterCount = count;
    let totalList = list ? [...list] : [];
    if (totalList?.length > count) {
      filterCount = count - 1;
    }
    let remainingDataCount = list?.length - count;
    return {
      list: [...totalList]?.slice(0, filterCount),
      remainingDataCount:
      remainingDataCount > 0 ? remainingDataCount : 0,
    };
  }, [list, count]);
  return filteredList;
};

export default useFilteredDataWithCount;
