import"./SelectedNftDetails.scss"
import { useCallback, useEffect, useMemo, useState } from "react"
import FrameitTab from "./Components/FrameitTab"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { popupMobileScrollAdj, popupWindow } from "App/Helper/utilities"
import useAuth from 'App/hooks/use-auth';
import storage from 'App/Helper/storage'
import ImagesTab from "./Components/ImagesTab"
import AttributesTab from "./Components/AttributesTab"
import useScreenSize from "App/hooks/use-screen-size"
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import Ada from "Assets/Icons/ada_svg_grey.svg";
import TransparentPopup from "./Components/TransparentPopup"
import SubscriptionWarning from "App/Pages/NftGallery/Component/SubscriptionWarning"
import { useLocation, useNavigate } from 'react-router-dom';
import ListenList from "../ListenList";
import Logo from "App/Components/Logo"
import useFrames from "App/hooks/use-frames"
import CollectionDetailsTab from "./Components/CollectionDetailsTab"
import ChevronLeftIcon from "App/Components/IconsComponents/ChevronLeftIcon"
import ChevronRightIcon from "App/Components/IconsComponents/ChevronRightIcon"
import MdEnlargeViewIcon from "App/Components/IconsComponents/MdEnlargeViewIcon"
import DecenLargeViewIcon from "App/Components/IconsComponents/DecenLargeIcon"
import { useDispatch } from "react-redux"
import { setcurrentView } from "App/ReduxStore/Slicers/userCollectionSlice";
import MobileTitleHeader from "App/Components/MobileTitleHeader"

const SelectedNftDetails = props => {
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const screenSize = useScreenSize()?.width;
    const screenHeight = useScreenSize()?.height;
    const screenRatio = screenSize/screenHeight
    const [freeFrameList, setFreeFrameList] = useState({ list: [], totalItems: 0 })
    const [purchasedList, setPurchasedList] = useState({ list: [], totalItems: 0 })
    const [premiumFrames, setPremiumFrames] = useState({ list: [], totalItems: 0 })
    const [allFrameList, setAllFrameList] = useState({ list: [], totalItems: 0 })
    const [freeFrameLoading, setFreeFrameLoading] = useState(true)
    const [frameApplied, setFrameApplied] = useState(null)
    const [tempDescription, setTempDescription] = useState(null)
    const [{ currentWallet }, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const isLoggedInGuest = useAuth()[4]
    const { triggerAPI } = useHttp();
    const { getFrameList } = useFrames();
    const zoom = false;
    const [aspectRatio, setAspectRatio] = useState(null)
    const [activeTab, setActiveTab] = useState(props?.nft?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio")>-1).length > 0 && process.env.REACT_APP_COMPANY !=='anywall' ?"listen":"details");
    const [previewUrl, setPreviewUrl] = useState(props?.nft?.nft?.nftcdnimage1024||props?.nft?.nft?.nftcdnimage512 || '');
    const [enlargedView, setEnlargedView] = useState(false);
    const [filterShape,setFilterShape] = useState([])
    const [filterAge,setFilterAge] = useState([])
    const [showSubscriptionPopup,setSubscriptionPopup] = useState(false)
    const [subscriptionReached,setSubscriptionReached] = useState(false)
    const location = useLocation();
    const [color, setColor] = useState('');
    const [ageFilter, setAgeFilter] = useState(1);
    const [mouseMoving, setMouseMoving] = useState(false);
    const [localCollectionData, setLocalCollectionData] = useState(null);
    let timeout;

    
    const handleTabChange = (tab) => {
      if(tab==="tab3"){
        checkFrameCount((res)=>{
          setActiveTab(tab)
        })
      }else setActiveTab(tab);
      };
      
    const previewFrameHandler = e => {
        let data = e
        if (e && e.metaInfo?.portrait) {
            data = { ...e }
            data.metaInfo = e && e?.isLandscape ? e?.metaInfo?.landscape : e?.metaInfo?.portrait;
            data.image = (e && e?.isLandscape) ? (e?.s3ImageUrlLandscape ?? e?.s3ImageUrl) : e?.s3ImageUrl;
            data.image512 = (e && e?.isLandscape) ? (e?.s3ImageUrlLandscape512 ?? e?.s3ImageUrl512) : e?.s3ImageUrl512;
            data.image256 = (e && e?.isLandscape) ? (e?.s3ImageUrlLandscape256 ?? e?.s3ImageUrl256) : e?.s3ImageUrl256;
        }
        setFrameApplied(data)
    }
    const applyFrameResult = useCallback((res, tab) => {
        dispatch('hideSpinner');
        // setShowSpiner(false)
        props.onReload && props.onReload(props.nft.nft.asset);
        // dispatch("showToast", {
        //   toast: { toastMode: "success", message: res?.message },
        // });
        if(tab) {
          setActiveTab('tab2');
          getPurchasedList();
          getFreeFrameList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props])

    const checkFrameCount = useCallback((callback) => {
      const triggerFrame = () => {
          const data = {
              page: 1,
              items: 1,
              filter: {categoryId: [3], sortBy: "modernWestern"}
          }
          triggerAPI({
              url: `frame-asset/user/get`, method: 'post', data
          }, (res) => {
              const { userAvailablePremiumFrames,userAddedPremiumFrames,isSubscriptionLimitReached } = res.data;
              setSubscriptionPopup(userAddedPremiumFrames>=userAvailablePremiumFrames)
              setSubscriptionReached(isSubscriptionLimitReached)
              callback()
          },()=>callback());
      }
      triggerFrame();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, triggerAPI])

    const applyFrameHandler = useCallback((e, tab) => {
        if (isLoggedIn) {

            let data = {
                "asset": props.nft.nft.asset,
                "networkType": props.nft.nft.networkType,
                "frameId": e ? e.id : null,
                "isLandscape": e ? e?.isLandscape : false,
            }
            let url
            let method
            if (!!e) {
                url = `user/nft-collection/frame-it/add`
                method = 'post'
                data = tab ? {...data, tab} : data
            }
            else {
                url = `user/nft-collection/frame-it/remove`
                method = 'delete'
                delete data.isLandscape;
            }
            dispatch('showSpinner')
            triggerAPI({
                url: url, data: data, method: method
            }, (res) => applyFrameResult(res, tab),(res)=>{
              dispatch("showToast", {
                toast: { toastMode: "error", message: res?.response?.data?.error?.message || 'Failed' },
              });
              dispatch("hideSpinner");
          });
        } else {
            props?.setShowAuthPop(true);
        }
    }, [dispatch, applyFrameResult, triggerAPI, props, isLoggedIn])
    const reloadFrameHandler = useCallback(() => {
        getFreeFrameList()
        getStyles()
        getAges()
        const token = storage().get('token')
        if ((isLoggedInGuest || isLoggedIn) && token) {
            getPurchasedList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet?.address]);
    useEffect(() => {
        if (!!props.nft)
        {
          setFrameApplied(props.nft?.frame)          
        }
          
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft])

    const getFreeFrameList = useCallback(() => {
        const framePayload = {
          "items": 40,
          filter: {  categoryId: [2], sortBy:"modernWestern"},          
        }
        setFreeFrameLoading(true);
        getFrameList({framePayload}, ({list, pageMeta})=> {
          setFreeFrameList({
            list: list,
            totalItems: pageMeta.totalItems
          })
          setFreeFrameLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])

    useEffect(() => {
        getFreeFrameList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nft?.image])
    
    const getPurchasedList = useCallback(() => {
      const frameTabFilters = (activeTab === 'tab3' && props?.fromCollection) ? {
        color: [color],
        age: [ageFilter]
      } : {};

        const framePayload = {
          "items": 999999,
          filter: {categoryId: [3],sortBy: "modernWestern", isUserAdded: false, ...frameTabFilters}
        }
        setFreeFrameLoading(true);
        getFrameList({framePayload}, ({list, pageMeta})=> {
          setPurchasedList({
            list: list,
            totalItems: pageMeta.totalItems
          })
          setFreeFrameLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])

    useEffect(() => {
        const token = storage().get('token')
        if ((isLoggedInGuest || isLoggedIn) && token) {
            getPurchasedList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nft?.image, currentWallet?.address, frameApplied, activeTab, color, ageFilter])

    useEffect(() => {
        popupMobileScrollAdj(zoom)
    }, [zoom])

    const agesApiResult = (res) => {
      const data = res?.data
      const list = data?.map(age => ({ name: age?.name, id: age?.id }))
      setFilterAge(list)

  }
  const getAges = () => {
      triggerAPI({
          url: `frame-asset/age/get`, method: 'get'
      }, agesApiResult);
  }

  useEffect(() => {
    if(activeTab === 'tab3' && props?.fromCollection && !filterAge?.length){
      getAges();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.nft?.nft, activeTab]);

    //Styles filter populate api
    const getStyles = () => {
      triggerAPI({
          url: `frame-asset/style/get`, method: 'get'
      }, stylesApiResult);

  }
  const stylesApiResult = (res) => {
      const data = res.data
      let list
      list = data.map(style => { return { name: style.name, id: style.id } })
      setFilterShape(prevState => { return { ...list} })

  }
    const calculateAspectRatio = () => {
        if (props?.nft?.nft?.files?.[0]?.mType?.indexOf("html") > -1)
        {
          setAspectRatio(1);
        }
        else if (props?.nft?.nft && props?.nft?.nft.nftcdnimage512) {
          const img = new Image();
          img.src = props?.nft?.nft?.nftcdnimage512;
      
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            setAspectRatio(aspectRatio);
          };
        }
      };
      useEffect(() => {
        let audioTypeRemovedFiles = props?.nft?.nft?.files?.filter(f=>(f.mediaType?.indexOf("audio") || f.mimeType?.indexOf("audio")) === -1)
        let newPreviewUrl = props?.nft?.nft?.nftcdnimage1024;
        //if we're in the enlarged view, or the first file isn't an image, then use the first file, else use the 1024 preview image from above
        if(audioTypeRemovedFiles?.length && (enlargedView || audioTypeRemovedFiles[0].mType !== 'image')) {
          newPreviewUrl = audioTypeRemovedFiles?.[0]?.nftcdnfile;
        }
        if (props?.readBook)
        {
          let epub = props?.nft?.nft?.files?.filter(f=> f.mType === 'epub')?.[0];
          if (epub)
          {
            newPreviewUrl = epub.nftcdnfile;
            // setEnlargedView(true);
          }
          else
          {
            let pdf = props?.nft?.nft?.files?.filter(f=> f.mType === 'pdf')?.[0];
            if (pdf)
            {           
              newPreviewUrl = pdf.nftcdnfile;
              // setEnlargedView(true);
            }
          }            
        }
        setPreviewUrl(newPreviewUrl);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.nft, props.readBook]);
      useEffect(() => {
          calculateAspectRatio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.nft?.nft]);
      useEffect(() => {
        let age = filterAge?.find((el) => el?.name === "modern")?.id;
        let shape = filterShape?.find((el) => el?.name === "western")?.id;
        let myFrame = [
          ...freeFrameList.list,
          ...(purchasedList.list.filter(
            (frame) => frame.isUserAdded === true
          ) || []),
        ];
        let myFrameFilter=myFrame?.filter(el=>el?.ageId===age||el?.shapeId===shape);
        let myFrameWithoutFilter=myFrame?.filter(el=>el?.ageId!==age&&el?.shapeId!==shape);
        setAllFrameList({
          ...freeFrameList,
          list:[...myFrameFilter,...myFrameWithoutFilter]
        });

        if(activeTab === 'tab3'){
          setPremiumFrames({
            list: [
              ...(purchasedList.list.filter(frame => frame.isUserAdded === false) || [])
            ]
          })
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [freeFrameList,purchasedList,filterAge,filterShape]);

    const buyNft = async () => {   
      if (props?.nft?.nft?.marketplace?.nfttype === 'nmkr' && props?.nft?.nft?.marketplace?.paymentlink)
      {
        popupWindow(props?.nft?.nft?.marketplace?.paymentlink, "_blank", window, 450, 880).focus();
      }   
      else if (props?.nft?.nft?.marketplace?.permalink)
      {
        window.open(props?.nft?.nft?.marketplace?.permalink);
      }
      else 
      {
        alert("Coming Soon...")
        // const name = storage().get('currentWallet');
        // console.log("name",name)
        // let wallet = await walletConnector.connectWallet(name);
        // console.log("walletConnector",walletConnector)
        // if (wallet.signTx)
        // {
        //   let tx = await walletConnector.constructMarketplaceTx(wallet, props?.nft?.nft?.marketplace);      
          
        //   await wallet.signTx(tx);
        // }            
      }      
    }

    const handleFrameShopPageRedirection = (piece) => {
      dispatch("setSelectedPiece", piece);  
      reduxDispatch(setcurrentView("frame-shop"))
      dispatch("setActiveIcons", { frameShop: true,displayType:"frame-shop" });
      navigate("/curate/frame-shop");
      return
    }
    useEffect(()=>{
      if(!props?.nft?.nft?.description && !props?.nft?.nft?.description?.length){
        setTempDescription(props?.nft?.nft?.onchain_metadata?.Description ?? props?.nft?.nft?.onchain_metadata?.description)
      }
    },[props?.nft?.nft])
    const handleTabsVisibility = (frameTabAccess) => {
      // setHideFramesTab(frameTabAccess);
    }
    const imageFiles = useMemo(() => {
      let files = props?.nft?.nft?.files?.filter(f=>(f.mediaType??"")?.indexOf("audio") === -1); //removed audio files      
      let isPreviewFileAvailable = files?.some(item => item?.nftcdnfile === props?.nft?.nft?.nftcdnimage1024)
      if(files?.length && !isPreviewFileAvailable)
      files[0].accessFramesTab = true;
      if(!isPreviewFileAvailable) {
        files = [{ nftcdnfile: props?.nft?.nft?.nftcdnimage1024, mType: 'image', accessFramesTab: true}, ...files]
      }
      return files;
    }, [props?.nft?.nft?.files, props?.nft?.nft?.nftcdnimage1024])
    const hideFramesTab = useMemo(() => {
      let accessFramesTab = false
      if(imageFiles?.length) {
        accessFramesTab = !!imageFiles?.find(item => item?.nftcdnfile === previewUrl)?.accessFramesTab;
      }
      return accessFramesTab;
    }, [imageFiles, previewUrl])

    useEffect(() => {
      const handleMouseMove = (e) => {
        e.preventDefault();
        setMouseMoving(true);
        clearTimeout(timeout);
        timeout = setTimeout(() => setMouseMoving(false), 1500);
      };
  
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        clearTimeout(timeout);
      };
    }, []);

    return (
    <>
{ !enlargedView &&     <div className="flex flex-col gap-0 h-[100vh] details-popup p-[1.5rem]">

    <div className={`${props.className} view-gallery-all sm:flex  w-full gap-[2rem] `}>
    <MobileTitleHeader onClick={props?.onCloseDetails} className="pb-4"  />
      <div className={` flex flex-col align-center ${screenRatio > 2 ? 'sm:h-[77vh]' : 'sm:h-[83vh]'} `}>
        <div className="frame-container-gallery flex main-image-container">
          <div className="h-full w-full aspect-square square-container ">
            <GalleryImages
              key={previewUrl}
              clearCache={false}
              frameToggle={props?.frameToggle}
              isDetailedView={true}
              galleryNew={props?.nft?.nft}
              src={previewUrl}
              clicked={true}
              className="h-[100%] overflow-hidden aspect-square"
              nftGalleryNew={true}
              frameName={frameApplied}
              framePreview={true}
              onChainRenders={props?.onChainRenders}
              imageFileToUse={props?.nft?.nft?.files?.filter(f=> f.nftcdnfile === previewUrl)?.[0]}
              playAudio={true}
            />
          </div>
        </div>
        <div className="sm:hidden">
        <ImagesTab nft={{...props?.nft?.nft, files: imageFiles}} className="pop-up-filetype-wrapper"  showFileType  setPreviewUrl={setPreviewUrl} previewUrl={previewUrl}
        onCaptureImageIndex={handleTabsVisibility}
        />
        </div>
      </div>
      <div className="control-container">
          <ChevronLeftIcon className="chevron-container cursor-pointer" onClick={props?.selectPreviousNft}/>
          <MdEnlargeViewIcon className="chevron-container-center cursor-pointer"  onClick={()=>setEnlargedView(true)}/>
          <ChevronRightIcon className="chevron-container cursor-pointer" onClick={props?.selectNextNft}/>
      </div>
      <div className="sm:hidden flex fixed bottom-0 w-[100vw] z-[100] bg-[#202328] ml-[-1.5rem]">
      {!props?.nft?.nft?.marketplace?.priceDisplay && props?.nft?.nft?.marketplace?.price &&
                <div className={`px-[1.5rem] py-[.75rem] flex flex-col w-full  gap-[1rem] `}>
                  <div className="flex w-full justify-between">
                    <div>
                    Purchase
                    </div>
                    <div className="asset-title flex gap-2"><img className={``} alt="Img" src={Ada} />{props?.nft?.nft?.marketplace?.price/1000000}</div>
                  </div>
                  <button className="add-button" onClick={buyNft}>Buy</button>
                </div>
              }
              {props?.nft?.nft?.marketplace?.priceDisplay &&
                <div className={`px-[1.5rem] py-[.75rem] flex flex-col w-full  gap-[1rem] `}>
                <div className="flex w-full justify-between">
                  <div>
                  Purchase
                  </div>
                  <div className="asset-title flex gap-2">{props?.nft?.nft?.marketplace?.priceDisplay}</div>
                  </div>
                  <button className="add-button" onClick={buyNft}>Buy</button>
                </div>
              }
              {props?.nft?.nft?.marketplace?.priceUSD &&
                 <div className={`px-[1.5rem] py-[.75rem] flex flex-col w-full  gap-[1rem] `}>
                 <div className="flex w-full justify-between">
                   <div>
                   Purchase
                   </div>
                  <div className="asset-title flex gap-2">$ {props?.nft?.nft?.marketplace?.priceUSD}</div>
                  </div>
                  {props?.nft?.nft?.marketplace?.state === 'sold' ?
                  <button className="add-button" disabled={true}>SOLD</button>
                  :
                  <button className="add-button" onClick={buyNft}>Buy</button>}
                </div>
              }
      </div>
      {/* {props?.nft?.collectionName ?  */}
      <div className="w-[100%] min-w-[30vw]">
        {!props?.zoom && (
          <div
            className={`${screenRatio > 2 ? 'sm:h-[77vh]' : 'sm:h-[83vh]'} ${
              screenSize <= 550 && props?.type === "collectors" && "bp-none"
            } sm:mb-0 mb-[6rem] flex flex-col gap-[1rem] sm:overflow-hidden sm:calc-height ${!props?.hideFrameTabs&&!props?.hideApplyButton ?  screenRatio > 2 ? 'sm:h-[77vh]' : 'sm:h-[83vh]' : ''}  `}
            >
            <div
              className={`hidden sm:flex flex-col gap-2  `}
            >
              <div className="flex  justify-between">
                <div className="flex gap-4">
                  <div className="flex gap-2 items-center">
                    <img alt=""
                      style={{
                        borderRadius: "50%",
                        height: "1.5rem",
                        width: "1.5rem",
                      }}
                      className="object-cover"
                      src={props?.nft?.nft?.community?.displayPicture256 || Logo()}
                    />
                  </div>
                  <div className="collection-title truncate flex flex-col justify-center">
                    {props?.nft?.nft?.collectionName ?? "NA"}
                  </div>
                  {/* <div className="data flex gap-[0.28571rem] items-center text-[#B8C0CC]">
                    <BlockChainIcon classes={`w-[1.2rem]`} policyId={props?.nft?.nft?.policy}/>
                    <FormatField
                      type="substring"
                      maxLength={20}
                      startLength={4}
                      endLength={4}
                      value={props?.nft?.nft?.contractAddress}
                    />
                    <CopyIcon className={"cursor-pointer"} data-value={props?.nft?.nft?.contractAddress} onClick={() => handleCopy(props?.nft?.nft?.contractAddress)} />
                  </div> */}
                </div>
                <div className="   cursor-pointer absolute top-[1rem] right-[1rem] " >
                  {/* <button className="close-button min-w-[1.5rem]" onClick={props?.onCloseDetails}>

                    {" "}
                    CLOSE{" "}
                  </button> */}
 

      
                </div>
              </div>

              <div className="text-left text-[0.875rem] text-[#F7F8F9] pl-[2.5rem]">
                <p>{props?.nft?.nft?.community?.name ?? "Unknown"}</p>
              </div>
            </div>
            <div className="common_style-tab-container mobileTab ">
            <div
                className={`tab-data flex gap-[1.42857rem]`}
              >
              {props?.nft?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio")>-1).length > 0 && process.env.REACT_APP_COMPANY !=='anywall'&& <div
                className={`tab ${activeTab === "listen" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("listen")}
              >
                Listen
              </div>}
              <div
                className={`tab ${activeTab === "details" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("details")}
              >
                Details
              </div>
              <div
                className={`tab ${activeTab === "collection-details" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("collection-details")}
              >
                Collection
              </div>
              {hideFramesTab &&<>
              <div
                className={`tab ${activeTab === "tab2" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("tab2")}
              >
               {`${isLoggedIn ? "My": ""} Frames`}
              </div>
              {(!!isLoggedIn && hideFramesTab) && <div
                className={`tab ${activeTab === "tab3" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("tab3")}
              >
                Frames
              </div>}
              </>}
            </div>
            </div>
            {activeTab === "details" && (
            <div className={` description-tab`}>
              <div className="asset-title sm:pb-[.75rem]">
                <div className="">{props?.nft?.nft?.name}</div>
              </div>
              {!props?.nft?.nft?.marketplace?.priceDisplay && props?.nft?.nft?.marketplace?.price &&
                <div className={`flex flex-row price-wrapper gap-[2rem] mt-[5px]`}>
                  <div className="asset-title flex gap-2"><img className={``} alt="Img" src={Ada} />{props?.nft?.nft?.marketplace?.price/1000000}</div>
                  <button className="add-button" onClick={buyNft}>Buy</button>
                </div>
              }
              {props?.nft?.nft?.marketplace?.priceDisplay &&
                <div className={`flex flex-row price-wrapper gap-[2rem] mt-[5px]`}>
                  <div className="asset-title flex gap-2">{props?.nft?.nft?.marketplace?.priceDisplay}</div>
                  <button className="add-button" onClick={buyNft}>Buy</button>
                </div>
              }
              {props?.nft?.nft?.marketplace?.priceUSD &&
                <div className={`flex flex-row price-wrapper gap-[2rem] mt-[5px]`}>
                  <div className="asset-title flex gap-2">$ {props?.nft?.nft?.marketplace?.priceUSD}</div>
                  {props?.nft?.nft?.marketplace?.state === 'sold' ?
                  <button className="add-button" disabled={true}>SOLD</button>
                  :
                  <button className="add-button" onClick={buyNft}>Buy</button>}
                </div>
              }
              <div className="description-details border-[#191D23]  border-b pb-4">
                <div className="description-data flex flex-col">
                <div className="title">Description</div>
                <div className="data">
                  {(props?.nft?.nft?.description) ? (
                    Array.isArray(props.nft.nft.description) ? (
                      props.nft.nft.description.map((line, index) => (
                        <span key={index}>
                          {line}{' '}
                        </span>
                      ))
                    ) : (
                      <div>{props.nft.nft.description}</div>
                    )
                  ) : (tempDescription) ? <div>{tempDescription}</div>  : (
                    <span>No description</span>
                  )}
                </div>

                </div>
              </div>
            
              <div className="description-details2">
                <div className="description-details2">
              <div className="header">Information</div>
              <AttributesTab nft={props?.nft?.nft} />  
            </div>
              </div>
              {/* <div className="buy-container">
                  <div className="flex w-full items-center gap-[0.5rem]"> 
                    <img className="w-[2rem]" alt="Img" src={Ada} />
                    <span className="ada-price"> 30 </span>
                  </div>
                  <div className="w-full h-full"> 
                    <RoundedButton className="buy-btn" Name="Buy Now"> </RoundedButton>
                  </div>
                </div> */}
              </div>
       
             )}

             {activeTab === "collection-details" && <CollectionDetailsTab localCollectionData={localCollectionData} setLocalCollectionData={setLocalCollectionData} aspectRatios={props?.aspectRatios} collectionDetails={props?.nft?.nft}/>}

             {activeTab === "tab2" &&
             <div className={`h-[100%] overflow-auto`}>
              <FrameitTab 
              reloadFrameHandler={reloadFrameHandler} 
              defaultFrame={frameApplied} 
              selectedFrame={frameApplied} 
              onApply={applyFrameHandler} 
              onPreview={previewFrameHandler} 
              nft={props.nft.nft} 
              freeFrameList={allFrameList} 
              freeFrameLoading={freeFrameLoading} 
              aspectRatio={aspectRatio}
              applyFrameButton={!props?.hideFrameTabs&&!props?.hideApplyButton}
              />
             </div>
              }
              {activeTab === "tab3" &&
             <div className={`h-[100%] overflow-auto relative`}>
              <FrameitTab 
              reloadFrameHandler={reloadFrameHandler} 
              defaultFrame={frameApplied} 
              selectedFrame={frameApplied} 
              onApply={(e) => applyFrameHandler(e, 'frame')} 
              onPreview={previewFrameHandler} 
              nft={props.nft.nft} 
              freeFrameList={premiumFrames} 
              freeFrameLoading={freeFrameLoading} 
              aspectRatio={aspectRatio}
              applyFrameButton={!props?.hideFrameTabs&&!props?.hideApplyButton}
              hideNoFrame={true}
              showTopFilter={true}
              fromManageCollection={props?.fromCollection}
              filterAge={filterAge}
              onFrameShop={handleFrameShopPageRedirection}
              color={color}
              setColor={setColor}
              ageFilter={ageFilter}
              setAgeFilter={setAgeFilter}
              hideFrameShopButton={props?.hideFrameShopButton}
              />
             {!!showSubscriptionPopup&&!props?.hideFrameTabs&&!props?.hideApplyButton&&<TransparentPopup>
                <SubscriptionWarning isSubscriptionReached={subscriptionReached} content="frames" />
                </TransparentPopup>}
             </div>
              }
              {activeTab === "listen" &&
              <ListenList className={"popup-listen"} collection={[props.nft.nft]} isPreview={location?.pathname?.toLowerCase().startsWith("/discover")}/>
              }
          </div>
      
        )}
      <div className="close-div  absolute top-[1rem] right-[1rem] justify-end h-[1.5rem] w-[1.5rem] z-[100]">
          <div className={`close-icon close-visible`} onClick={props?.onCloseDetails}></div>
        </div>
      </div> 
     
      </div>
      <div className="hidden sm:flex">
      <ImagesTab nft={{...props?.nft?.nft, files: imageFiles}} className="pop-up-filetype-wrapper"  showFileType  setPreviewUrl={setPreviewUrl} previewUrl={previewUrl}
        onCaptureImageIndex={handleTabsVisibility}
        />
      </div>
    </div>}
    {enlargedView &&
      <div className={`[h-100vh] view-gallery-all flex flex-col details-popup`}>
        <div className={` flex flex-col align-center ${props?.readBook ? 'h-[100vh]' : screenRatio > 1.65 ? 'h-[100vh]' : 'h-[100vh]'} `}>
            <div className="frame-container-gallery ">
                <div className="h-full w-full">
                  <GalleryImages
                    clearCache={false}
                    frameToggle={props?.frameToggle}
                    isDetailedView={true}
                    galleryNew={props?.nft?.nft}
                    src={previewUrl}
                    clicked={true}
                    className="h-[100%] overflow-hidden w-full"
                    nftGalleryNew={true}
                    frameName={frameApplied}
                    framePreview={true}
                    onChainRenders={props?.onChainRenders}
                    imageFileToUse={props?.nft?.nft?.files?.filter(f=> f.nftcdnfile === previewUrl)?.[0]}
                    playAudio={true}
                  />
                </div>
          </div>
      </div>
      <div className="z-[100] cursor-pointer absolute top-[1rem] right-[1rem] " onClick={props?.onCloseDetails}>
        <div className="close-div justify-end h-[1.5rem] w-[1.5rem]">
          <div className={`close-icon ${mouseMoving ? 'close-visible' : ''}`} onClick={props?.onCloseDetails}></div>
        </div>
      </div>
      {!props?.readBook && <div className={`chevron-group `}>
        <ChevronLeftIcon className={`chevron-container-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={props?.selectPreviousNft}/>        
        <DecenLargeViewIcon className={`chevron-container-center-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={()=>setEnlargedView(false)}/>
        <ChevronRightIcon className={`chevron-container-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={props?.selectNextNft}/>        
      </div>}
    </div>
    }
    </>
    )
    
}

export default SelectedNftDetails