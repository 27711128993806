import "../Icons.scss";

const DisplayIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Galleries" clipPath="url(#clip0_8732_12011)">
          <path
            id="Vector"
            d="M13.0238 15.3649C12.8815 15.3648 12.745 15.3083 12.6443 15.2077C12.5436 15.1071 12.487 14.9706 12.4868 14.8283V12.9275H10.5864C10.4463 12.9241 10.313 12.866 10.2152 12.7657C10.1173 12.6654 10.0625 12.5308 10.0625 12.3907C10.0625 12.2505 10.1173 12.116 10.2152 12.0157C10.313 11.9154 10.4463 11.8573 10.5864 11.8539H12.4868V9.95436C12.4851 9.88277 12.4977 9.81155 12.5239 9.74489C12.5501 9.67823 12.5893 9.61749 12.6393 9.56623C12.6894 9.51498 12.7491 9.47424 12.8151 9.44643C12.8811 9.41862 12.952 9.4043 13.0236 9.4043C13.0953 9.4043 13.1661 9.41862 13.2321 9.44643C13.2981 9.47424 13.3579 9.51498 13.4079 9.56623C13.4579 9.61749 13.4972 9.67823 13.5234 9.74489C13.5496 9.81155 13.5622 9.88277 13.5604 9.95436V11.8548H15.4606C15.6007 11.8583 15.7339 11.9163 15.8318 12.0166C15.9297 12.1169 15.9844 12.2515 15.9844 12.3916C15.9844 12.5318 15.9297 12.6664 15.8318 12.7667C15.7339 12.867 15.6007 12.925 15.4606 12.9284H13.5604V14.8283C13.5603 14.9706 13.5037 15.107 13.4031 15.2076C13.3025 15.3082 13.1661 15.3648 13.0238 15.3649Z"
            fill="#A0ABBB"
          />
          <path
            id="Vector_2"
            d="M7.80225 12.7362C7.77828 12.7362 7.75433 12.7346 7.73057 12.7314L0.781453 11.8053C0.565239 11.7761 0.366942 11.6695 0.223272 11.5053C0.0796016 11.3411 0.000287696 11.1304 1.29227e-05 10.9122V4.17621C-0.00108823 3.9711 0.0682126 3.77183 0.196342 3.61167C0.324471 3.45151 0.50367 3.34015 0.704013 3.29621L12.512 0.656846C12.5771 0.642187 12.6437 0.634781 12.7104 0.634766C12.9489 0.635613 13.1773 0.73098 13.3455 0.899959C13.5138 1.06894 13.6082 1.29774 13.608 1.53621V6.83445C13.6046 6.97455 13.5465 7.10776 13.4462 7.20563C13.3459 7.30351 13.2114 7.3583 13.0712 7.3583C12.9311 7.3583 12.7965 7.30351 12.6962 7.20563C12.5959 7.10776 12.5378 6.97455 12.5344 6.83445V1.75125L1.07329 4.31445V10.7615L7.87201 11.6677C8.00675 11.6859 8.12959 11.7545 8.2157 11.8597C8.30181 11.9649 8.34478 12.0989 8.33592 12.2345C8.32706 12.3702 8.26704 12.4974 8.16798 12.5906C8.06892 12.6837 7.93821 12.7357 7.80225 12.7362Z"
            fill="#A0ABBB"
          />
        </g>
        <defs>
          <clipPath id="clip0_8732_12011">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DisplayIcon;
