
import { useEffect, useRef } from "react";
import "./SearchBar.scss";
import SearchMobileIcon from "App/Components/IconsComponents/SearchMobileIcon";

const SearchBar = ({ hideLabel, onSearch, value = "",noFocus, onClick }) => {
  const inputOnchange = (e) => {
    onSearch(e.target.value);
  };
  let searchRef = useRef(null);
  useEffect(() => {
    if(!noFocus) searchRef?.current?.focus();
  }, [noFocus]);

  return (
    <div className="search-bar global_search-box-wrapper_mobile">
      <button className={`global_search-icon-button`}>
        <SearchMobileIcon className={"global-search-image pb-[1px]"} />
      </button>
      <input
        className={`global_search-input-field w-full`}
        type="text"
        ref={searchRef}
        value={value}
        onChange={inputOnchange}
        onKeyUp={inputOnchange}
        placeholder="Search"
      />
    </div>
  );
};
export default SearchBar;
