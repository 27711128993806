import CloseIcon from "App/Components/IconsComponents/CloseIcon";
import "./SidebarDetails.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import UserIcon from "App/Components/IconsComponents/UserIcon";
import SubscriptionIcon from "App/Components/IconsComponents/SubscriptionIcon";
import PurchaseIcon from "App/Components/IconsComponents/PurchaseIcon";
import PreferencesIcon from "App/Components/IconsComponents/PreferencesIcon";
import WalletSmIcon from "App/Components/IconsComponents/WalletSmIcon";
import signout from "Assets/Icons/logout-blue.svg";
import {
  collectorProfileRoutes,
  creatorRoutes,
  curatorRoutes,
} from "App/Helper/routings";
import { useLocation, useNavigate } from "react-router-dom";
import LogOutWarn from "App/Layouts/Header/Components/LogOutWarn/LogOutWarn";
import useLogout from "App/hooks/use-logout";
import { useSelector } from "react-redux";
import { getShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";

const SidebarDetails = ({
  collectorProfileDetails,
  creatorProfileDetails,
  curatorProfileDetails,
  sidebarHandler,
  className,
}) => {
  const [logOutWarning, setLogOutWarning] = useState(false);
  const [curatorId, setCuratorId] = useState(null);
  const [walletAuthPop, setWalletAuthPop] = useState(false);
  const [selectedTab, setSelectedTab] = useState("collector");
  const [communityId, setCommunityId] = useState(null);
  const { currentUserId,isCurator } = useStore(false)[0];
  const [profileData, setProfileData] = useState(null);
  const { logoutHandler, logOutWarningCloseHandler } = useLogout();
  const showWalletAuthPop = useSelector(getShowWalletPop);
  let { triggerAPI } = useHttp();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setProfileData(
      selectedTab === "collector"
        ? collectorProfileDetails
        : selectedTab === "creator"
        ? creatorProfileDetails
        : curatorProfileDetails
    );
  }, [
    selectedTab,
    collectorProfileDetails,
    creatorProfileDetails,
    curatorProfileDetails,
  ]);
  const getCurator = useCallback(() => {
    triggerAPI(
      {
        url: `curator/get-user`,
        method: "get",
      },
      (res) => {
        setCuratorId(res?.data?.id);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCommunityList = useCallback(() => {
    triggerAPI(
      {
        url: `community/get`,
        method: "post",
        data: {
          page: 1,
          items: 1,
          filter: { userId: currentUserId },
          search: "",
        },
      },
      (res) => {
        setCommunityId(res?.data?.list[0]?.id);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);
  const menuTabs = useMemo(
    () => {
      let galleryTabs = [
        { id: "collector", element: "Collector" },
        { id: "creator", element: "Creator" },
        
      ];
      if(isCurator){
        galleryTabs?.push({ id: "curator", element: "Curator" })
      }
      if (process.env.REACT_APP_COMPANY === "anywall") {
        galleryTabs?.splice(1, 2);
      }
      return galleryTabs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [process.env.REACT_APP_COMPANY,isCurator]
  );
  const collectorsTabs = useMemo(() => {
    let tablist = [
      {
        id: 1,
        text: "Profile",
        link: collectorProfileRoutes("profileWithTab", "my-galleries"),
        icon: <UserIcon color="#F7F8F9" />,
      },
      {
        id: 2,
        text: "Subscription",
        link: collectorProfileRoutes("subscription"),
        icon: <SubscriptionIcon />,
      },
      {
        id: 3,
        text: "Purchases",
        link: collectorProfileRoutes("purchase"),
        icon: <PurchaseIcon />,
      },
      {
        id: 4,
        text: "Preferences",
        link: collectorProfileRoutes("preferences"),
        icon: <PreferencesIcon />,
      },
      {
        id: 5,
        text: "Wallets",
        link: collectorProfileRoutes("wallets"),
        icon: <WalletSmIcon />,
      },
    ];
    return process.env.REACT_APP_COMPANY === "anywall"
      ? [tablist[1], tablist[4]]
      : tablist;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_COMPANY]);

  const creatorTabs = useMemo(() => {
    let tablist = [
      {
        id: 1,
        text: "Profile",
        link: communityId
          ? creatorRoutes("overviewWithId", communityId)
          : creatorRoutes(),
        icon: <UserIcon color="#F7F8F9" />,
      },
    ];
    if (communityId) {
      tablist?.push({
        id: 2,
        text: "Wallets",
        link: creatorRoutes(
          "idWithTab",
          communityId,
          null,
          "wallet-authentication"
        ),
        icon: <WalletSmIcon />,
      });
    }
    return tablist;
  }, [communityId]);

  const curatorTabs = useMemo(() => {
    let tablist = [
      {
        id: 1,
        text: "Profile",
        link: curatorId
          ? curatorRoutes("curatorIdWithTab", curatorId, "collections")
          : curatorRoutes(),
        icon: <UserIcon color="#F7F8F9" />,
      },
    ];
    return tablist;
  }, [curatorId]);

  const onTabClickHandler = (url) => {
    navigate(url);
    sidebarHandler();
  };
  const logOutWarnCloseHandler = (params) => {
    logOutWarningCloseHandler(walletAuthPop, setLogOutWarning);
  };
  const logoutPopupHandler = () => {
    setWalletAuthPop(showWalletAuthPop);
    setLogOutWarning(true);
    sidebarHandler();
  };
  const logoutHandlerMethod = () => {
    logoutHandler(walletAuthPop, setLogOutWarning);
  };
  const handleTabs = (e) => {
    const selectTab = e.currentTarget?.dataset?.id;
    setSelectedTab(selectTab);
  };

  useEffect(() => {
    if (currentUserId) {
      getCurator();
      getCommunityList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const contentTabBasedonMainTab = useMemo(() => {
    return selectedTab === "collector"
      ? collectorsTabs
      : selectedTab === "creator"
      ? creatorTabs
      : curatorTabs;
  }, [selectedTab, collectorsTabs, creatorTabs, curatorTabs]);
  return (
    <>
      <div className={`sidebar-details ${className}`} onClick={sidebarHandler}>
        <div
          className="details-wrapper flex flex-col gap-4 justify-between h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col gap-[0.625rem]">
            <div className="flex w-full justify-end">
              <CloseIcon onClick={sidebarHandler} />
            </div>
            <div
              id="discover-tab"
              className="common_style-tab-container px-[1rem] mb-2"
            >
              <div className="tab-data">
                {menuTabs?.map((menu, index) => {
                  return (
                    <div
                      onClick={handleTabs}
                      data-id={menu?.id}
                      key={menu?.id}
                      className={`tab ${
                        menu?.id === selectedTab ? "active" : "not-active"
                      }`}
                    >
                      {menu?.element}{" "}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-center flex-col items-center gap-3 w-full">
              <div className="profile-img-wrapper">
                <img src={profileData?.profilePicUrl256} alt="" />
              </div>
              <h1>{profileData?.name}</h1>
            </div>
            <div className="flex flex-col gap-8">
              {!!profileData?.description && (
               <p className="description-wrapper">{profileData?.description}</p>
              )}
              <div className="sidebar-links flex flex-col items-start justify-start gap-[1.5rem]">
                <h4>Settings</h4>
                <ul className="flex flex-col gap-2 w-full">
                  {contentTabBasedonMainTab?.map((list, i) => (
                    <li
                      key={list?.id}
                      className={`flex w-full gap-4 items-center ${
                        list?.link === pathname && "active"
                      }`}
                      onClick={() => onTabClickHandler(list?.link)}
                    >
                      {list?.icon} <span>{list?.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="sign-out flex gap-4 items-center"
            onClick={logoutPopupHandler}
          >
            <img src={signout} alt="" /> <span>Sign Out</span>
          </div>
        </div>
      </div>
      {logOutWarning && (
        <LogOutWarn
          onLogOut={logoutHandlerMethod}
          onCancel={logOutWarnCloseHandler}
        />
      )}
    </>
  );
};
export default SidebarDetails;
