import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import { memo, useEffect, useState } from "react";
import InputSlider from "App/Components/Ui/InputSlider";
import LayoutImageCard from "App/Pages/NftGallery/Component/MyGallery/Components/AddedNfts/Components/GalleryPreview/Components/LayoutImageCard";
import MobileControlClose from "App/Components/Ui/Button/MobileControlClose";
import LayoutIcon from "App/Components/IconsComponents/GalleryControls/LayoutIcon";
import PreviewChanges from "../../../PreviewChanges";

const MultiLayoutsControl = ({className = '', multiLayoutList = [], selectedMultiLayout, setSelectedMultiLayout, onHandleLayoutList= null, show= false}) => {
    const [captureChange, setCaptureChange] = useState(false);
    // const { loadingLayout } = useStore(false)[0];
    // const isImageLoading = useMemo(() => {
    //     return loadingLayout?.some(item => item?.loading)
    // }, [loadingLayout])
    //above need to enable if any one of slide loading then needs to disable layout selection
    const [layoutSize, setLayoutSize] = useState(selectedMultiLayout?.size);
    const handleClick = (e) => {
        const id = e.currentTarget.dataset?.id;
        setSelectedMultiLayout(prev => { return {...prev, layout: multiLayoutList?.find(item => item?.id === parseInt(id))}});
        setCaptureChange((prev) => !prev)
    }
    const onChange = (value) => {
        setLayoutSize(value);
        setCaptureChange((prev) => !prev)
    }
    useEffect(() => {
        let timer = setTimeout(() => {
            setSelectedMultiLayout(prev => { return {...prev, size: layoutSize}});
        }, 600);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutSize])
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 layout-list-control w-[50rem]`}>

            {/* For Mobile only  */}
            <ControlTitleCard className="multi_layout_title_MR">
                <LayoutIcon className={`multi_layout_icon`} />
                <span >{`Layouts Preview`}</span>
            </ControlTitleCard>

            {/* For Mobile only  */}
            {show && <PreviewChanges change={captureChange} renderDelay={1000} isMulti={true}/>}

            <ControlBody className="layout-list-section">
                <div className="flex gap-2 justify-between items-center layout-list_title">
                    <ControlTitleCard>{`Layouts`}</ControlTitleCard>
                    <div className="layout-filter flex gap-2 items-end">
                        <InputSlider value={layoutSize} min={1} max={1.15} step={0.01} onChange={onChange} />
                    </div>
                </div>
                
                <ControlContent className="layout-content">
                    <div className="grid layout-sec-list listing-grid-control-panel">
                        {multiLayoutList?.map((layout) => (
                            <LayoutImageCard key={layout?.id} design={layout} selected={selectedMultiLayout?.layout} handleLayoutClick={handleClick} />
                        ))}
                    </div>
                </ControlContent>
            </ControlBody>
            <MobileControlClose onClick={onHandleLayoutList && onHandleLayoutList}>Close</MobileControlClose> 
        </ControlCard>
    )
}
export default memo(MultiLayoutsControl);