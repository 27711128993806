import "./DropdownSelect.scss";
import blueDownArrow from "Assets/Icons/arrow_down_blue.svg";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "App/hooks-store/store";
import DownArrow from "Assets/Images/DownArrow.svg";
import useScreenSize from "App/hooks/use-screen-size";
import FormatField from "../FormatField";
import { useLocation, useNavigate } from "react-router-dom";
import PackIcon from "App/Components/IconsComponents/PackIcon";
const DropdownSelect = (props) => {
  const [{collectionLoadingStatus, blockchains}, dispatch] = useStore();
  const [selectedItem, setSelectedItem] = useState({ value: "" });
  const [showItems, setShowItems] = useState(false);
  const [sortDisable, setSortDisable] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [{ currentSelectedTab }] = useStore(false);
  const screenSize = useScreenSize()?.width;;
  const select = createRef();
  let timeOut = useRef(null);
  const navigate = useNavigate()
  const { pathname } = useLocation();
  useEffect(() => {
    if (props.value) setSelectedItem({ value: props.value });
    else if (props?.options?.length)
      setSelectedItem({ value: props.options[0]?.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, currentSelectedTab]);
  useEffect(() => {
    const clearTimer = setTimeout(() => setLoadingOptions(false), 400);
    return () => {
      setLoadingOptions(true)
      clearTimeout(clearTimer);
    }
  }, [])

  useEffect(() => {
   if(collectionLoadingStatus === 'finished') {
    setSortDisable(false)
   } else {
    setSortDisable(true)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionLoadingStatus]);

  const applyToggle = useCallback(() => {
    const parent = select.current;
    const list = parent?.querySelector("div.dropdown-select-box-items");
    if (showItems && list) {
      parent?.classList.remove("dropdown-select-box-closed");
      parent?.classList.add("dropdown-select-box-open");
      list.style.maxHeight = `${ screenSize > 550 ? list?.scrollHeight + 200 : list?.scrollHeight-150}px`;
    } else {
      parent?.classList.remove("dropdown-select-box-open");
      parent?.classList.add("dropdown-select-box-closed");
      if (list) list.style.maxHeight = `0px`;
    }
  }, [select, showItems]);

  const dropDownToggle = () => {
    setShowItems((prevState) => !prevState);
  };

  const selectItem = (e) => {    
    let item = e.currentTarget.dataset["item"];
    let selectedOption = props?.options[item];
    if(selectedOption?.url) {
      navigate(selectedOption?.url);
    } else {
      const label = props.controlName ?? "value";
      const value = {
        [label]: props.options[item]?.value,
      };
      setSelectedItem(props.options[item]);
      if (props.onChange) {
        props.onChange(value);
      }
      dispatch("setActiveIcons", { filterByWallet: '' });
    }
    dropDownToggle();
  };

  const onBlur = useCallback(() => {
    setShowItems(false);
    applyToggle();
  }, [applyToggle]);
  useEffect(() => {
    applyToggle();
    if (props.currentId) {
      const found = props?.list?.filter((val) => {
        return val?.id === props.currentId;
      });
      setSelectedItem(found?.length ? found[0] : { name: null, value: null });
    }
  }, [applyToggle, props?.currentId, props?.list]);
  const onMouseToggle = useCallback(
    (mode) => {
      if (screenSize > 551) {
        if (timeOut.current) {
          clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
          if (mode) {
            setShowItems(true);
          } else {
            onBlur();
          }
        }, 500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBlur]
  );
  const onMouseFocus = useCallback(() => {
    onMouseToggle(true);
  }, [onMouseToggle]);
  const onMouseBlur = useCallback(() => {
    onMouseToggle(false);
  }, [onMouseToggle]);
  const handleWalletClick = useCallback((e) => {
    if(!sortDisable) {
      let checkUrl = props?.options?.find(item => item?.showSubDrop)?.url
      if(checkUrl) {
        navigate(`${checkUrl}?walletid=${e}`)
      } else {
        dispatch("setActiveIcons", { filterByWallet: e }); 
        setSelectedItem({value: parseInt(e)});
        const label = props.controlName ?? "value";
        const value = {
          [label] : e,
        };
        props.onChange(value);
      }      
    }     
    dropDownToggle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options, pathname, sortDisable]);
  return (
    <div onMouseOverCapture={!props?.disableSort && onMouseFocus} onMouseLeave={onMouseBlur} className={`${props?.mainClassName} dropdown-select-box  dropdown-select-box-sm`} ref={select} onBlur={onBlur} tabIndex="-1">
{/* <div> */}
      <div
        className={`${props?.mainClassName} dropdown-select-box  dropdown-select-box-sm`}
        ref={select}
        tabIndex="-1"
      >
        <div
          className={
            !props.borderLess
              ? "dropdown-select-box--container"
              : "flex items-center text-left"
          }
          onClick={dropDownToggle}
        >
          {!props?.image && (
            <div className={`select-box--selected-item ${props.className}`}>
              {screenSize < 551 && props?.title
                ? props?.title
                : selectedItem.value}
            </div>
          )}
          {props.blueArrow && (
            <div className="arrow grid place-items-center w-[.75rem] h-[.75rem]">
              <img className="cursor-pointer img-icon" alt="img" src={blueDownArrow} />
            </div>
          )}
          {props?.smArrow && (
            <div className="arrow grid place-items-center ">
              <img
                className={`img-icon ${
                  !showItems ? "rot" : ""
                } cursor-pointer w-[.75rem] h-[.75rem]`}
                alt="img"
                src={DownArrow}
              />
            </div>
          )}

          {props?.smArrow && (
            <div className="arrow grid place-items-center ">
              <img
                className={`img-icon ${
                  !showItems ? "rot" : ""
                } cursor-pointer w-[.75rem] h-[.75rem]`}
                alt="img"
                src={DownArrow}
              />
            </div>
          )}
          {props?.image && (
            <div className="arrow grid place-items-center ">
              {/* <img
                className={`cursor-pointer img-icon`}
                alt="img"
                src={`${!showItems ? props?.image : props?.image}`}
              /> */}
              <PackIcon className={showItems&&"activeIcon"}/>
            </div>
          )}
        </div>
        <div
          className={loadingOptions ? 'hidden' : `dropdown-select-box-items ${props?.itemsClassName} ${props?.listPosition}`}
        >
          <ul>
            {props?.options?.map((option, index) => (
              <div key={index}>
                <li
                  data-item={index}
                  key={index}
                  onClick={selectItem}
                  className={`${props?.children ? "min-w-[14rem]" : ""} ${
                    selectedItem.value === option.value ? "selected" : ""
                  } ${props.className}`}
                >
                  <div className="flex justify-between w-full items-center gap-4">
                    <div>{option.value}</div>
                    {props?.showCount && <div className="flex gap-2 count">
                      <span>{option?.count}</span>
                      {props?.children}
                    </div>}                  
                  </div>
                  
                </li>
                {(option?.showSubDrop)  &&
                  <div className="drop-wallet-list">
                   <div className={`child-wallet ${sortDisable ? 'disabled' : ''}`}>
                        {option?.walletData?.map((item, index) => (
                          <div key={" wallet_" + index} className={`wallet_wrap ${selectedItem.value?.toString() === item.walletId?.toString() ? "selected" : ""} ${item.walletId} ${selectedItem.value} ${selectedItem.value === item.walletId}`} onClick={() => handleWalletClick(item?.walletId)}>
                            <div className="flex gap-3 items-center">
                            <img src={blockchains?.filter(b=>b.id == item?.blockchainTypeId)?.[0]?.icon??''} className="w-6" alt="" />
                            <div className="wallet">     
                            <FormatField
                            type="sub"
                            maxWidth={15}
                            value={item?.walletName??`Wallet ${index + 1}`}
                            /> 
                            </div>
                            </div>
                            <div className="count"><span>{item?.assetCount}</span></div>
                          </div>
                        ))}
                      </div>
                  </div>
                }
              </div>
            ))}
          </ul>
          
        </div>
      </div>
    </div>
  );
};

export default DropdownSelect;