import "../Icons.scss";

const SubscriptionIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons">
          <path
            id="Vector"
            d="M15.75 3.5H2.25C1.42157 3.5 0.75 4.17157 0.75 5V14C0.75 14.8284 1.42157 15.5 2.25 15.5H15.75C16.5784 15.5 17.25 14.8284 17.25 14V5C17.25 4.17157 16.5784 3.5 15.75 3.5Z"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M0.75 8H17.25"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default SubscriptionIcon;
