const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setShowInstruction: (current,status) => {
            return {
                showInstruction: status
            }
        }
    }
    initStore(actions, {
        showInstruction: false
    });
}
export default configureStore;