import TimeSelector from "App/Components/Ui/TimeSelector";
const TimeSelection = (props) => {
     return (
        <div className={`time-selection-container ${props?.show ? '' : 'invisible'} ${props?.hideSelectBar ? 'hide-bg' : ''}`}>
            <TimeSelector 
            selectedBrdColor={'#000000'}
            wheelBgColor={'#30333A'}
            showTitle={props?.showTitle} 
            list={props?.listTime} 
            onSelect={props?.timerOnchange} 
            value={props?.initialTime} 
            hideSelectBar={props?.hideSelectBar} 
            show={props?.show} 
            showCloseBtn={props?.showCloseBtn}
            onHandleTimeControls={props?.onHandleTimeControls}
            />
        </div>
     )
}    
export default TimeSelection;