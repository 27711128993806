const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setSharePopUp: (current,status) => {
            return {
                showSharePopup: status
            }
        },
        setShareUrl: (current, url) => {
            return {
                shareUrl: url
            }
        }
    }
    initStore(actions, {
        showSharePopup: false,
        shareUrl:null
    });
}
export default configureStore;