/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback, memo } from "react";
import useWallet from "App/hooks/use-wallet";
import "./Wallets.scss";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http"
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import useWalletConnect from "App/hooks/use-wallet-connect";
import { useLocation, useNavigate } from "react-router-dom";
import activeWallet from "Assets/Icons/active-wallet.svg";
import WalletSelectPop from "..";
import useWalletEVM from "App/hooks/use-wallet-evm";
import useWalletBitcoin from "App/hooks/use-wallet-bitcoin";
import useWalletTezos from "App/hooks/use-wallet-tezos";
import useWalletSolana from "App/hooks/use-wallet-solana";
import useBlockchain from "App/hooks/use-blockchain";
import useConnectedWallet from "App/hooks/use-connect-wallet";

const WalletsPop = memo((props) => {
    const [{ currentWallet, blockchains }, dispatch] = useStore(false);
    const navigate = useNavigate();
    const [selectedWallet, setSelectedWallet] = useState();
    const walletConnector = useWallet();
    const { triggerAPI } = useHttp();
    const { walletAddHandler } = useWalletConnect();
    const [isLoggedIn] = useAuth();
    const [isLinking, setisLinking] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [showWalletAuthPop, setShowWalletAuthPop] = useState(false);
    const removeItem = useAuth()[5];
    const walletConnectorEVM = useWalletEVM();
    const walletConnectorBitcoin = useWalletBitcoin();
    const walletConnectorTezos = useWalletTezos();
    const walletConnectorSolana = useWalletSolana();
    const { pathname } = useLocation();
    const { getEVMBlcokchainByElement } = useBlockchain()
    const {disConnectConnectedWallet} = useConnectedWallet();
    // eslint-disable-next-line no-unused-vars
    const onSelect = useCallback(async (walletName, isEvmChain) => {
        if (isEvmChain)
        {
            await walletConnectorEVM.connectWallet(walletName);
        }
        else if (walletName === 'Bitcoin Ordinals')
        {            
            await walletConnectorBitcoin.connectWallet();
        }
        else if (walletName === 'Tezos')
        {
            await walletConnectorTezos.connectWallet();
        }
        else if (walletName === 'Solana')
        {
            await walletConnectorSolana.connectWallet();
        }
        else
        {
            await walletConnector.connectWallet(walletName);
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletConnector, walletConnectorEVM])
    
    useEffect(() => {
        const name = storage().get('currentWallet');
        if (name) {
            onSelect(name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRegistrationStatusResponse = useCallback(
        (res) => {

            // setRegistrationStatus(res?.data?.allowNewRegistration);
        },
        []
    );

    const getRegistrationStatus = useCallback(() => {

        triggerAPI(
            {
                url: `app-preference/get`,
                method: "get",
            },
            getRegistrationStatusResponse
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getRegistrationStatusResponse, triggerAPI]);
    useEffect(() => {
        getRegistrationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const selectWallet = (e) => {
         e && e.stopPropagation();
        const { dataset: { value, id } } = e.currentTarget;
        if (id) {
            window.open(id);
        } else {
            let currentWalletName = storage().get('currentWallet') || selectedWallet?.walletName;
            if(currentWalletName?.toLowerCase() !== value?.toLowerCase()){
                if(value?.toLowerCase() === "cardano")
                {
                    setShowWalletAuthPop(true);
                }
                else
                {
                    removeItem('cwa');
                    const isEvmChain = getEVMBlcokchainByElement(value);
                    onSelect(value, isEvmChain);
                    if(!isEvmChain && !pathname.includes("curate/collections/my-collections") && process.env.REACT_APP_COMPANY !== 'anywall'){
                        setTimeout(()=>{
                        navigate("/curate/collections/my-collections")
                        },500)
                    }
                }
            }
            else {
                disConnectConnectedWallet();
                if(!isLoggedIn && pathname.includes("curate/collections/my-collections")) navigate("/home")
            }
           
        }
    }

    const connectedWalletstResult = useCallback((res) => {
        const val = res?.data;
        dispatch('hideSpinner');
        let connectedWallets = [];
        val?.forEach((element, index) => {
            let temp = {
                assetCount: element?.asset_details?.length ?? 0,
                id: element?.id,
                walletName: element?.walletName,
                walletId: index,
            }
            connectedWallets = [...connectedWallets, temp];
        });
        dispatch('updateCollectionWalletData', connectedWallets)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getConnectedWallets = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/connected-wallet/get`, method: 'get'
        }, connectedWalletstResult);
    }, [triggerAPI, connectedWalletstResult, dispatch])

    useEffect(() => {
        if (!currentWallet) {
            setSelectedWallet({})
        } else {
            if (isLinking) {
                walletAddHandler(currentWallet.address, currentWallet.chain, getConnectedWallets);
                setisLinking(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet])

    const walletLinkClicked = () => {
        setShowWalletAuthPop(false);
    }


    useEffect(() => {
        let walletList = [];
        if (blockchains?.length) {
            walletList.push(...blockchains?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map(c => {
                return {
                    name: c.name,
                    icon: c.icon
                }
            }));
            setWallets(walletList);
        }
    }, [blockchains]);

    if(!isLoggedIn){ return null}

    return (
        <div className={props?.show ? 'relative wallet-popup' : 'hidden'}>
            <div className={`wallet-content`} >
                <div className={`w-full flex items-center justify-center`}>
                    <div className="head flex flex-col gap-4">
                            <div>
                                <p className="font-normal text-[var(--cu-white-900)]">Select Blockchain</p>
                            </div>
                    </div>
                </div>

                {/* Old cardano box */}
                {/* {<div className="wallet-pop-container flex flex-col items-center gap-[2rem] mt-[2rem]">
                    {isLoggedIn && 
                        <>
                            <div className="body flex gap-4">
                                {installedWallets.map((wallet, index) => {
                                    return  <div className="flex flex-col items-center gap-[10px]" key={index}>
                                                <div className="wallet-icon">
                                                    <img src={wallet.icon} alt={wallet.name} />
                                                </div>
                                                <div className="wallet-label capita">
                                                    {(wallet.active && !isLinking) ? selectedWallet?.balance : nameHandler(wallet.name)}
                                                </div>
                                                <button className="wallet-button" data-id={wallet?.installUrl} onClick={linkWalletHandler} value={wallet.name}>
                                                {((wallet?.status) ? 'Connecting' : 'Connect')}
                                                </button>
                                            </div>
                                })}
                            </div>
                        </>
                    }
                </div>
            } */}

            {/* New list of wallets UI */}
             <div className="wallets_container">
                    {wallets?.map((wallet) => {
                        return (
                            <div key={wallet.name} data-id={wallet?.installUrl} data-value={wallet.name} className={`${wallet?.name?.toLowerCase() === selectedWallet?.walletName?.toLowerCase() ? 'activeWallet' : ''} wallet_box`}>
                                <div className="flex items-center gap-[1.2rem]">
                                    <div className="wallet-select-available-icon">
                                        <img src={wallet.icon} alt={wallet.name} />
                                    </div>
                                    <div className="wallet-select-available-label mb-[2px]">{wallet.name}</div>
                                </div>

                                <div key={wallet.name} data-id={wallet?.installUrl} onClick={selectWallet} data-value={wallet.name} className="flex items-center gap-[1rem]">
                                    <button className="wallet-button">
                                        Link
                                    </button>
                                    {!!(wallet?.name?.toLowerCase() === selectedWallet?.walletName?.toLowerCase()) && <div className="active-icon"><img src={activeWallet} alt="" /></div>}
                                </div>
                                
                            </div>
                        );
                    })}
                </div>
            </div>
            <WalletSelectPop show={showWalletAuthPop} onClose={() => setShowWalletAuthPop(false)} className="walletcardanopopup" linkClicked={walletLinkClicked} />
        </div>
    )
})
export default WalletsPop;