import "../Icons.scss";

const ShareIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 14.6667C13.1046 14.6667 14 13.7713 14 12.6667C14 11.5622 13.1046 10.6667 12 10.6667C10.8954 10.6667 10 11.5622 10 12.6667C10 13.7713 10.8954 14.6667 12 14.6667Z"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72656 9.00659L10.2799 11.6599"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 5.33325C13.1046 5.33325 14 4.43782 14 3.33325C14 2.22868 13.1046 1.33325 12 1.33325C10.8954 1.33325 10 2.22868 10 3.33325C10 4.43782 10.8954 5.33325 12 5.33325Z"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2732 4.34009L5.72656 6.99342"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ShareIcon;
