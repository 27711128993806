import "./DiscoverGallery.scss";
import "../DiscoverCollection/DiscoverCollection.scss";
import Card from "App/Components/Card";
// import PreviewDetailRight from "App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery/Components/PreviewDetailRight";
import { useCallback, useEffect, useMemo, useState } from "react"
import Pagination from "App/Components/Ui/Pagination";
import useScreenSize from "App/hooks/use-screen-size";
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import CollectionDetailRight from "../DetailRightPanel/Components/CollectionDetailRight";
import { useStore } from "App/hooks-store/store";
import Slider from "react-slick";
import { getPreviewGalleryLink } from "App/Helper/utilities";
import NoContent from "App/Pages/Collections/Components/NftCollectionsV3/Components/NoContent";
import galleryBanner from "Assets/Images/private-gallery-banner.png";
import rentalsGalleryBanner from "Assets/Images/rentals-gallery-banner.png";
import { collectorShareUrls, creatorShareUrls } from "App/Helper/shareUrls";

const DiscoverGallery = (props) => {
  const settings = useMemo(() =>{
    return {
      className: 'mob-gallery',
        centerMode: true,
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        initialSlide: 0,
        responsive: [ {
            breakpoint: 550,
            settings: {
                centerMode: false,
                slidesToShow: 1,
                 slidesToScroll: 2,

            }
        }
    ]
    }
},[])
  const screenSize = useScreenSize()?.width;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [{ spinnerMode}] = useStore(false);
  const [isLoading, setIsLoading] = useState(true);
  const closeDetailGallery = () => {
    props?.setSelectedGallery(null);
  };
  // const openPreivew = () => {
  //   props?.openPreviewGallery();
  // };
  const onPageChangeHandler = (e) => {
    props?.onPageChangeHandler && props?.onPageChangeHandler(e);
  };
  const handlePreview = (id, type) => {
    getPreviewGalleryLink(id, type);
  };
  
  useEffect(()=>{
    let clearSetTime;
    if(!spinnerMode){ 
       clearSetTime = setTimeout(()=>setIsLoading(false),500);
    }
    return ()=>clearTimeout(clearSetTime)
  },[spinnerMode]);
const shareUrl=useCallback((galleryId,communitySlug)=>{
return props?.shareUrl ? props?.shareUrlPage==="creators" ?
  creatorShareUrls("creatorsGalleryDetails",communitySlug,galleryId,"gallery",props?.creatorsUrlType) :
  collectorShareUrls("collectorsGalleryDetails",props?.userId,galleryId) : ""
},[props?.shareUrl,props?.shareUrlPage,props?.creatorsUrlType,props?.userId])
  return (
    <div className="flex flex-col gap-[0.5rem] h-full justify-between">
      <div className=" h-full gap-[1.42857rem]">
        <div
          className={`flex w-full flex-1 flex-col ${props?.galleryList&&!props?.galleryList?.length&&!spinnerMode ? '' :'gap-4'} `}
        >
          {props?.selectedGallery &&
          <div className="sm:sticky top-[-12px] sm:z-9">
            <div className="details-content">
              <CollectionDetailRight
                selectedCollection={props?.selectedGallery}
                className="w-[75%]"
                playGallery={props?.playGallery}
                backHandler={closeDetailGallery}
                handlePreview={handlePreview}
              />
            </div>
          </div>
          }
          {!props.showSlider ? 
          <div className={`${props?.className} grid home-grid w-full gap-[.5rem]`}>
            {props?.galleryList && !props?.selectedGallery &&
              props?.galleryList?.map((info) => {
                return (
                  <Card
                    key={info.id}
                    cardProp={info}
                    type="Gallery"
                    shareUrl={shareUrl(info?.id,info?.community?.slug)}
                    playGallery={props?.playGallery}
                    preview={true}
                    handleCardClick={props?.handleCardClick}
                    handlePreview={handlePreview}
                  />
                );
              })}
          </div>
          :
         <>
          <div className={`${props?.className} dis-gallery-container w-full web-gallery`}>
          {props?.galleryList && !props?.selectedGallery && !props.communityOverview &&
            props?.galleryList?.map((info) => {
              return (
                <Card
                  key={info.id}
                  cardProp={info}
                  type="Gallery"
                  shareUrl={shareUrl(info?.id,info?.community?.slug)}
                  playGallery={props?.playGallery}
                  preview={true}
                  handleCardClick={props?.handleCardClick}
                  handlePreview={handlePreview}
                />
              );
            })}
            {props?.galleryList && !props?.selectedGallery && props.communityOverview &&
            props?.galleryList.map((info) => {
              return (
                <Card
                  key={info.gallery.id}
                  cardProp={info.gallery}
                  type="Gallery"
                  shareUrl={shareUrl(info?.id,info?.community?.slug)}
                  playGallery={props?.playGallery}
                  preview={true}
                  handleCardClick={props?.handleCardClick}
                  handlePreview={handlePreview}
                />
              );
            })}
          </div>
          <>{props?.galleryList?.length >0 && !props?.selectedGallery &&
          <Slider {...settings} > 
          
            <div className={`${props?.className} dis-gallery-container w-full`}>
              <div className="flex w-full gap-2 gallery-slider">
              
                {props?.galleryList?.map((info) => {
                  return (
                    <Card
                      key={info.id}
                      cardProp={info}
                      type="Gallery"
                      shareUrl={shareUrl(info?.id,info?.community?.slug)}
                      preview={true}
                      playGallery={props?.playGallery}
                      handleCardClick={props?.handleCardClick}
                      handlePreview={handlePreview}
                      className={'small-card'}
                    />
                  );
                })}
              </div>
            </div>
           
          </Slider> }
          </>
        </>
  
          }
          <div className="gallery-images-container">
          {props?.selectedGallery && 
           props?.selectedGallery?.assets.map((info, i) => {
            return (
              <GalleryImages 
              compress={true}  
              nftGalleryNew={true}
              onClick={props.openPreviewGallery} 
              index={i} 
              playGallery={props?.playGallery}
              key={i} 
              src={info.details.nftcdnimage512} 
              galleryNew={{frameId:info.frameId}}
              showBorder={true} 
              className="gallery-image-item " />         
            );
          })
          }
          </div>
          {!Number(props?.totalItems) && !isLoading && <>
              <NoContent banner={props?.galleryType==="rentals"?rentalsGalleryBanner:galleryBanner}
              multiCollectionCreateOptions={props?.multiCollectionCreateOptions} 
              className={`visibleSlowly sub-content-section ${props?.tab==="overview" && "swap"}`} 
              text={props?.from==="community"?"Get your creator to showcase iconic pieces!" :
                  props?.galleryType==="rentals" ? "Access the World's Collections" :
                  "Beauty is in The Eye of The Be(holder)"} 
              brief={props?.from==="community" ? "Rental proceeds can be shared with holders." : 
                props?.galleryType==="rentals" ? "Rent beautiful galleries from top creators to display whatever  you want  whenever you want." : 
                "Start curating stunning galleries with your assets here"} 
              buttonText={props?.galleryType?props?.galleryType==="rentals" ? 
                "Explore Rentals" : "Manage Galleries" : ""}
                onClick={props?.galleryHandlerWithNoData}
              />
          </>}
        </div>
        {/* {props?.selectedGallery && (
          <div
            className={`relative h-[100vh] ${
                (isPortrait&&screenSize<=550)
                ? props?.selectedGallery && "w-full mobile-view-gallery"
                : "gallery-right-dis-creator"
            } view-gallery-detail-mob  w-[32rem]`}
          >
               <div className="h-full">
              {!(isPortrait&&screenSize<=550) && (
                <div
                  className="absolute right-4 top-2 z-3 cursor-pointer"
                  onClick={closeDetailGallery}
                >
                  <img src={closeIcon} alt="" className="cursor-pointer" />
                </div>
              )}
                 
            </div> 
          </div>
        )} */}
      </div>
      {!props?.hidePagination && (
        <div
          className={`mt-2 mb-2 nftdrops-btn ${
            (isPortrait&&screenSize<=550) ? props?.selectedGallery && "hidden" : ""
          }`}
        >
          <Pagination
            itemsPerPage={props?.perPage}
            total={props?.totalItems}
            rows={props?.galleryList?props?.galleryList?.length:0}
            onPageChange={onPageChangeHandler}
            active={props?.page}
          />
        </div>
      )}
    </div>
  );
};
export default DiscoverGallery;
