import "../Icons.scss";

const PurchaseIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons" clipPath="url(#clip0_8077_20982)">
          <path
            id="Vector"
            d="M15 17C15.4142 17 15.75 16.6642 15.75 16.25C15.75 15.8358 15.4142 15.5 15 15.5C14.5858 15.5 14.25 15.8358 14.25 16.25C14.25 16.6642 14.5858 17 15 17Z"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6.75 17C7.16421 17 7.5 16.6642 7.5 16.25C7.5 15.8358 7.16421 15.5 6.75 15.5C6.33579 15.5 6 15.8358 6 16.25C6 16.6642 6.33579 17 6.75 17Z"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M0.75 1.25H3.75L5.76 11.2925C5.82858 11.6378 6.01643 11.948 6.29066 12.1687C6.56489 12.3895 6.90802 12.5067 7.26 12.5H14.55C14.902 12.5067 15.2451 12.3895 15.5193 12.1687C15.7936 11.948 15.9814 11.6378 16.05 11.2925L17.25 5H4.5"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8077_20982">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PurchaseIcon;
