import { useStore } from 'App/hooks-store/store';
import React, { useEffect, useState } from 'react'
import { sortObject, sortValueChangeHandlerForGroupByFilter } from 'App/Helper/utilities';
import GalleryImages from 'App/Components/Ui/GalleryFrameImages';
import { getBlockchainIcon } from 'App/Helper/blockchain';
import BlockChainIcon from 'App/Components/Ui/BlockchainIcon';
import { thousandCommaSeparator } from 'App/Helper/utilities';

const GroupByGalleryCollections = ({ listRef, nftList, hoverlayClass, className, targetRef, displayChange, onClickHandler, clicked, isToken, wordLimit, userAvatar }) => {

    const [{ activeMenuIcons }] = useStore();

    const [loading, setLoading] = useState(true);
    const [groupedData, setGroupedData] = useState(null);
    const [seeAll, setSeeAll] = useState(null);
    const [prevFilter, setPrevFilter] = useState('')

    const handleSortData = (data, lastObjectName) => {
        let sortData = [];
        let lastObject = {};
        Object.entries(data).forEach(([key, value]) => {
            if (value?.data?.length) {
                value.data = sortObject(value?.data, sortValueChangeHandlerForGroupByFilter(activeMenuIcons?.sort[0]))
            }
            if (value?.header === lastObjectName) {
                lastObject = value;
            } else {
                sortData.push(value)
            }
        })
        sortData = sortData?.sort((a, b) => a["header"] ? b["header"] ? a["header"]?.toLowerCase() > b["header"]?.toLowerCase() ? 1 : -1 : -1 : 1);
        sortData?.push(lastObject);
        return sortData;
    }

    const handleCategoryGrouping = (data) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { category } = piece;

            if ((category && category.name) && (category.name !== 'Other')) {
                const categoryName = category.name;

                if (!filteredData[categoryName]) {
                    filteredData[categoryName] = { header: categoryName, data: [] };
                }

                filteredData[categoryName].data.push(piece);
            } else {
                const othersCategory = 'Other';

                if (!filteredData[othersCategory]) {
                    filteredData[othersCategory] = { header: othersCategory, data: [] };
                }

                filteredData[othersCategory].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'Other')
        setGroupedData(sortedData);
        setLoading(false)
    };

    const handleSubCategoryGrouping = (data, skipMainCategory) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { category, subCategoryName } = piece;
            const checkCondition = skipMainCategory ? subCategoryName : category.name && subCategoryName;
            if (category && checkCondition) {
                const categoryName = skipMainCategory ? subCategoryName : `${category.name} - ${subCategoryName}`;

                if (!filteredData[categoryName]) {
                    filteredData[categoryName] = { header: categoryName, data: [] };
                }

                filteredData[categoryName].data.push(piece);
            } else if (category?.name && !subCategoryName && !skipMainCategory) {
                const categoryName = category.name;
                if (!filteredData[categoryName]) {
                    filteredData[categoryName] = { header: categoryName, data: [] };
                }

                filteredData[categoryName].data.push(piece)
            } else {
                const othersCategory = 'Other - Unclassified';

                if (!filteredData[othersCategory]) {
                    filteredData[othersCategory] = { header: othersCategory, data: [] };
                }

                filteredData[othersCategory].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'Other - Unclassified')
        setGroupedData(sortedData);
        setLoading(false)
    };

    const handleCommunityGrouping = (data) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { community } = piece;

            if (community && community?.name) {
                const communityName = community.name;

                if (!filteredData[communityName]) {
                    filteredData[communityName] = { header: communityName, data: [] };
                }

                filteredData[communityName].data.push(piece);
            } else {
                const otherCommunity = 'Unknown';

                if (!filteredData[otherCommunity]) {
                    filteredData[otherCommunity] = { header: otherCommunity, data: [] };
                }

                filteredData[otherCommunity].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'Unknown')
        setGroupedData(sortedData);
        setLoading(false)
    };

    useEffect(() => {
        setLoading(true);
        if (activeMenuIcons?.sort) {
            if (listRef.current && prevFilter !== activeMenuIcons?.sort[1]) {
                setSeeAll(null)
                listRef.current.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            }
            if (activeMenuIcons?.sort?.length) {

                if (activeMenuIcons?.sort[1] === 'Category') {
                    handleCategoryGrouping(nftList)
                }
                if (activeMenuIcons?.sort[1] === 'Sub-Category') {
                    handleSubCategoryGrouping(nftList)
                }

                if (activeMenuIcons?.sort[1] === 'Creator') {
                    handleCommunityGrouping(nftList)
                }

                setPrevFilter(activeMenuIcons?.sort[1])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons, nftList, displayChange]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [listRef])

    return (<>
        <div>
            {!loading && groupedData?.length && groupedData.map((item, key) => {
                const initialCardCount = displayChange === "lg" ? 8 : 12;
                const sliceNumber = seeAll === item?.header ? item?.data?.length : initialCardCount;
                return (
                    <div className='flex flex-col' key={`${item?.header}${key}`}>

                        <div className={`w-full flex items-center justify-between ${key !== 0 && 'pt-[1rem]'} pl-4`}>
                            <div className='text-[1.25rem] '>{item?.header}</div>

                            {item?.data?.length > initialCardCount && <div className='see-all-style cursor-pointer clickable-links' onClick={() => setSeeAll((prev) => {
                                return item?.header === prev ? '' : item?.header;
                            })} >{item?.header === seeAll ? 'See less' : 'See all'}</div>}
                        </div>

                        <div className={`${displayChange === 'lg' ? 'grid-lg' : 'grid grid-sm'} full-album pl-4 pt-4 ${(groupedData?.length -1 !== key) && 'sm:pb-4'} overflow-y-auto gap-2`}>
                            {item?.data?.slice(0, sliceNumber).map((gallery, index) => (
                                <div key={`${gallery?.asset}-${gallery?.frameId ?? -1}`} className="cursor-pointer flex flex-col grid-card">
                                    <div className="collection-lvl-gallery" ref={targetRef}>
                                        <GalleryImages
                                            gallery={true}
                                            nftGalleryNew={true}
                                            src={gallery?.collectionImage256 || gallery?.nftcdnimage256}
                                            hoverlayClass={hoverlayClass}
                                            className={className}
                                            showBorder={true}
                                            policy={gallery?.policy} onClick={onClickHandler} clicked={clicked} />
                                    </div>
                                    <div className="flex flex-col px-[.25rem] py-1 gap-[.375rem]">
                                        <div className="flex  gap-[.75rem]">
                                            {!isToken && <img style={{ borderRadius: "50%", height: "1.875rem", width: "1.875rem" }} src={gallery?.community?.displayPicture256 || userAvatar} alt="icon" />}
                                            <div className="mt-[.2rem]">
                                                <div className="nft-gallery-head mr-3  nft-name-gallery">
                                                    {gallery?.collectionName ?
                                                        (gallery?.collectionName?.length > wordLimit ? `${gallery?.collectionName.substring(0, (wordLimit - 1))}...` : gallery?.collectionName)
                                                        :
                                                        (gallery?.name?.length > wordLimit ? `${gallery?.name.substring(0, (wordLimit - 1))}...` : gallery?.name)
                                                    }
                                                </div>
                                                {!isToken && <div className="text-left text-[#A0ABBB] text-[.875rem]">
                                                    {gallery?.community?.name || 'Unknown'}
                                                </div>}
                                                {!isToken && <div className="flex items-center gap-6 mt-2">
                    {gallery?.blockchainType?.code ? 
                    <img
                        className={`w-[1.2rem]`}
                        alt="Img"
                        src={getBlockchainIcon(gallery?.blockchainType?.code)}
                    />
                    :
                    <BlockChainIcon classes={`w-[1.2rem]`} loaderH={'1.05rem'} policyId={gallery?.policy} />
                    }
                    <div className={`nft-price flex`}>
                    <span className={`dot-separator`}>{`${thousandCommaSeparator(gallery?.quantity)} piece${gallery?.quantity!==1?"s":""}`}</span>
                </div>
                    </div>}
                                            </div>
                                            {/* {!isToken && <div className="flex gap-1  ml-auto">
                                                <div className="nft-gallery-head nft-gallery-count mt-2">
                                                    <span>{gallery?.quantity}</span>
                                                </div>
                                            </div>} */}
                                        </div>
                                    </div>
                                </div>)
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    </>)
}

export default GroupByGalleryCollections