import { useCallback } from "react";
import "./LayoutDesign.scss";
import LayoutImageCard from "../LayoutImageCard";
import useEventListener from "App/hooks/use-eventListener";

const LayoutDesign = ({layoutList = [], selected = {}, setSelectedLayout}) => {
    const handleLayoutClick = useCallback((e) => {
        let id = parseInt(e.currentTarget?.dataset?.id);
        let temp = layoutList?.find(item => item?.id === id);
        setSelectedLayout(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[layoutList]);
    
    const handleWheel = (event) => {
        const container = document.getElementById('scroll-element');
        if (container) {
          event.preventDefault();
          container.scrollLeft += event.deltaY;
        }
    };
    useEventListener('wheel', handleWheel, document?.getElementById('scroll-element'))
    return (
        <>
            <div className="flex justify-between items-center w-full layout-design-header">
                <span className="background-text">Layouts</span>
            </div>
            <div className="layout-design-body w-full">
                <div className="layout-section" id="scroll-element">
                    {layoutList?.map((design) => {
                       return <LayoutImageCard key={design?.id} design={design} selected={selected} handleLayoutClick={handleLayoutClick} />
                    })}
                </div>
            </div>
        </>
    )
}
export default LayoutDesign;