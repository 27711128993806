import React, { useCallback, useEffect, useState } from 'react';
import './FrameFilterTopUI.scss';
import BackgroundWallFilter from 'App/Components/Ui/BackgroundWallFilter';
import useHttp from 'App/hooks/use-http';

const FrameFilterTopUI = (props) => {
    const { triggerAPI } = useHttp();
    const [multiColorList, setMultiColorList] = useState([]);
    const [bgWallFilter, setBgWallFilter] = useState({ color: props?.color ?? 'grey', style: '' });

    const fetchMultiColorListResult = useCallback((res) => {
        const { data } = res;
        let list;
        list = data.map((clr) => {
            return { name: clr.name, id: clr.id };
        });
        setMultiColorList(list);
    }, []);

    const fetchMultiColorList = useCallback(() => {
        triggerAPI({
                url: `frame-asset/color/get`,
                method: "get",
            },fetchMultiColorListResult);
    }, [fetchMultiColorListResult, triggerAPI]);

    useEffect(() => {
        fetchMultiColorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        props?.setColor(bgWallFilter?.color || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgWallFilter?.color]);

    return (
        <div className="frame-shop_filter-container">
            <div className='flex sm:gap-[4rem] gap-2 sm:flex-row flex-col items-center'>
                <div className='filter_type-box'>
                    {props?.filterAge?.length ?
                        props?.filterAge.map((option, index) => <button onClick={() => props?.setAgeFilter(option?.id)} className={`${option?.id === props?.ageFilter && 'active'}`} key={index}>{option.name}</button>)
                        : <span />}
                </div>

                <div>
                    <div className="filter_colors-box">
                        {/* <span className="background-text">Background</span> */}
                        <BackgroundWallFilter multiColorList={multiColorList}  color={bgWallFilter?.color} setColor={setBgWallFilter} passColorId={true} />
                    </div>
                </div>
            </div>

            <div className='filter_frameshop-btn-box hidden sm:block'>
                {props?.hideFrameShopButton ? null : <button className='new-btn_style_001' onClick={() => {
                    props?.onFrameShop && props.onFrameShop(props?.nft);
                }}>Frame Shop</button>}
            </div>
        </div>
    )
}

export default FrameFilterTopUI