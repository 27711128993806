import configureSpinnerStore from "App/hooks-store/spinner-store";
import configureTitleStore from "App/hooks-store/title-store";
import configureToastStore from "App/hooks-store/toast-store";
import configureCollectionsStore from "App/hooks-store/collection-store"
import profilepicStore from "App/hooks-store/profile-pic-store"
import allCollectionStore from "App/hooks-store/all-collection-store"
import { Fragment } from "react";
import walletStore from "App/hooks-store/mesh-wallet-store";
import galleryStore from "App/hooks-store/gallery-store";
import frameStore from "App/hooks-store/frame-store";
import topMenuStore from "App/hooks-store/topMenu-store";
import communityStore from 'App/hooks-store/community-store';
import layoutStore from 'App/hooks-store/layout-store';
import token from "App/hooks-store/token-store";
import configureSingleCollectionStore from "App/hooks-store/single-collection-store";
import galleryPreviewStore from "App/hooks-store/gallery-preview-store";
import { updateDocumentDbStates } from "../Helper/db";
import configureMusicStore from "App/hooks-store/music-store";
import blockchainStore from "App/hooks-store/blockchain-store";
import categoryStore from "App/hooks-store/category-store";
import shareStore from "App/hooks-store/all-share-store";
import debugWalletStore from "App/hooks-store/debug-wallet-store";
import instructionStore from "App/hooks-store/instruction-store";
import wallStore from "App/hooks-store/wall-store";

updateDocumentDbStates();
const Store = (props) => {
    configureSpinnerStore();
    configureToastStore();
    configureTitleStore();
    configureCollectionsStore();
    profilepicStore();
    walletStore();
    galleryStore();
    frameStore();
    allCollectionStore();
    topMenuStore();
    communityStore();
    layoutStore();
    token();
    configureSingleCollectionStore();
    galleryPreviewStore();
    configureMusicStore();
    blockchainStore();
    categoryStore();
    debugWalletStore();
    shareStore();
    instructionStore();
    wallStore();
    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
export default Store;