import { getBlockchainIcon } from "App/Helper/blockchain";
import "./BlockchainIconsListing.scss";

const BlockchainIconsListing = ({blockchainImagesToList, blockchains}) => {
  return (
    <div className="details-item">
      <div className="blockchains">
        {blockchainImagesToList?.list?.map((el, i) => {
          return (
            <div className="blockchain-image" key={i}>
              <img
                key={i + 1}
                className="blockchain-image"
                src={getBlockchainIcon(el)}
                alt=""
              />
            </div>
          );
        })}
        {!!blockchainImagesToList?.remainingDataCount && (
          <div className="blockchain-image remaining-count-box">
            <img src={getBlockchainIcon(blockchains[5])} alt="" />
            <div className="count-text">{`+${blockchainImagesToList?.remainingDataCount}`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockchainIconsListing;
