import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import './MultiFilter.scss';
import MenuDrop from "./Components/Menu";
import { useStore } from "App/hooks-store/store";
import arrow from "Assets/Icons/arrow-gray.svg";
import useHttp from 'App/hooks/use-http'
import { useLocation, useSearchParams } from "react-router-dom";
import FilterIcon from "App/Components/IconsComponents/FilterIcon";
import FilterMobileIcon from "App/Components/IconsComponents/FilterMobileIcon";

const MultiFilter = ({image, options = [], disableSort = false, mainClassName = '', onSelected=()=>{}}) => {
    const [{      
            activeMenuIcons, currentCollectionTab, filterData  ,filterContent ,pageName ,collections,tokens ,
            singleCollection,currentWallet,communityGalleryCollections, blockchains
        }, dispatch, ] = useStore(false);
    const popupRef = useRef();
    let { triggerAPI } = useHttp();
    const [show, setShow] = useState(false);
    const [isFetchingFilterData, setIsFetchingFilterData] = useState(false);
    const [shapes, setShapes] = useState([]);        
    const [blockchainList, setBlockchainList] = useState([]);        
    const [selectedCategoryIds, setSelectedCategoryIds] = useState(activeMenuIcons.filter?.categoryIds);    
    const [selectedShapes, setSelectedShapes] = useState(activeMenuIcons.filter?.selectedShapes);        
    const [selectedBlockchains, setSelectedBlockchains] = useState(activeMenuIcons.filter?.blockchains);        
    const [selectedTypeIds, setSelectedTypeIds] = useState(activeMenuIcons.filter?.typeIds);    
    const [selectedEditionIds, setSelectedEditionIds] = useState(activeMenuIcons.filter?.editionIds);    
    const [selectedFormatIds, setSelectedFormatIds] = useState(activeMenuIcons.filter?.formatIds);    
    const [selectedStorageIds, setSelectedStorageIds] = useState(activeMenuIcons.filter?.storageIds);    
    const [selectedTagIds, setSelectedTagIds] = useState(activeMenuIcons.filter?.tagIds);    
    const [selectedTermsIds, setSelectedTermsIds] = useState(activeMenuIcons.filter?.termsIds);    
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);   
    const [walletHasNFTs,setwalletHasNFTs] = useState(false);
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    let walletNo=searchParams.get("walletid");
     const optionsData= useMemo(()=>options,[options]);
   
     useEffect(() => {
        if (currentWallet?.NFTs?.length)
        {            
            setwalletHasNFTs(true);         
        }
    }, [currentWallet])

    useEffect(()=>{
    let categoryListOptions=optionsData?.filter(o=>o.name !== "Unclassified") ?? [];
    if(categoryListOptions?.length){
        if(pageName==="collections" || pageName==="gallery" || pageName==="community-gallery"){
            let collectionsList = pageName==="community-gallery" ? communityGalleryCollections : (walletHasNFTs && currentCollectionTab === 'my-connected-wallet') ? singleCollection : collections;
            let isToken= pathname.includes('my-tokens')
            let walletId = walletNo || activeMenuIcons?.filterByWallet;
            let data = (isToken || activeMenuIcons?.dropDown === "My Tokens") ? 
            tokens ?? [] : walletId ? 
            collectionsList?.filter(item => item?.walletId === parseInt(walletId)) : collectionsList ?? [];
            if(!!activeMenuIcons?.favourite)
                data = [...data]?.filter(item => !!item?.isCollectionFavourite)
            const categoriesWithData = [];
            categoryListOptions.forEach(category => {
                const { name, subcategories } = category;
                const categoryData = data?.find(item => item.category.name === name);
                
                if (categoryData) {
                    const subcategoriesWithData = subcategories?.filter(subcategory => {
                        const subcategoryData = data?.find(item => (item.subCategoryName === subcategory.name&&item?.categoryId===subcategory?.parentCategoryId));
                        return subcategoryData;
                    });
                    categoriesWithData.push({ ...category, subcategories: subcategoriesWithData });
                }
            });
            setCategoryOptions(categoriesWithData)
        }else setCategoryOptions(categoryListOptions)
    }
    },[optionsData,pageName,walletHasNFTs,singleCollection,collections,communityGalleryCollections,tokens,walletNo, currentCollectionTab, pathname,activeMenuIcons?.dropDown,activeMenuIcons?.filterByWallet, activeMenuIcons?.favourite])

    const getBlockchains = (collectionBlockchains) => {
        let blockchainObject = {};
        collectionBlockchains?.forEach((collection) => {
            if (collection && !!collection?.blockchainType) {
                if (!!collection?.blockchainType?.code && !!collection?.blockchainType?.name) {
                    const uniqueCode = collection?.blockchainType?.code
                    blockchainObject = { ...blockchainObject, [uniqueCode]: { id: collection?.blockchainType?.code, name: collection?.blockchainType?.name } }
                }
            }
        });
        return blockchainObject;
    }

    useEffect(() => {
        let blockchainData = [];
        let blockchainObject = {};
        if (pageName==="community-gallery" && (communityGalleryCollections?.length > 0)) {
            blockchainObject = getBlockchains(communityGalleryCollections);
        } else if (pageName === 'discover' || pageName === 'explore') {
            blockchainData = blockchains?.map((item)=>({ id: item?.id, name: item?.name }))
        } else {
            blockchainObject = getBlockchains((walletHasNFTs && currentCollectionTab === 'my-connected-wallet') ? singleCollection : collections)
        }
        Object?.keys(blockchainObject)?.forEach(function (key) {
            blockchainData?.push(blockchainObject[key]);
        });
        setBlockchainList(blockchainData)
    }, [collections, communityGalleryCollections, blockchains, pathname, walletHasNFTs, currentCollectionTab, singleCollection, pageName])

    const toggleCategoryOpen = () => {
        setIsCategoryOpen(!isCategoryOpen);
    };
    const [isShapesOpen, setIsShapesOpen] = useState(false);
    const [isBlockchainOpen, setIsBlockchainOpen] = useState(false);
    const toggleShapesOpen = () => {
        setIsShapesOpen(!isShapesOpen);
    };
    const toggleBlockchainOpen = () => {
        setIsBlockchainOpen(!isBlockchainOpen);
    };
    const [isFormatOpen, setIsFormatOpen] = useState(false);
    const toggleFormatOpen = () => {
        setIsFormatOpen(!isFormatOpen);
    };
    const [isEditionsOpen, setIsEditionsOpen] = useState(false);
    const toggleEditionsOpen = () => {
        setIsEditionsOpen(!isEditionsOpen);
    };
    const [isStorageOpen, setIsStorageOpen] = useState(false);
    const toggleStorageOpen = () => {
        setIsStorageOpen(!isStorageOpen);
    };
    const [isTagOpen, setIsTagOpen] = useState(false);
    const toggleTagOpen = () => {
        setIsTagOpen(!isTagOpen);
    };
    const [isTypeOpen, setIsTypeOpen] = useState(false);
    const toggleTypeOpen = () => {
        setIsTypeOpen(!isTypeOpen);
    };
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const toggleTermsOpen = () => {
        setIsTermsOpen(!isTermsOpen);
    };

    
    useEffect(() => {
        if (!filterData?.category && !isFetchingFilterData)
        {
            setIsFetchingFilterData(true);
            triggerAPI({
                url: `category/getfilterdata`, method: 'get'
            }, ((response)=>{            
                dispatch('setFilterData' ,response.data.filterData)
                setIsFetchingFilterData(false);
            }));
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData?.category, isFetchingFilterData])    
    const blurHandler = () => {
        setShow(false);
    }

    const onMouseBlur = useCallback(() => {
        setShow(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setShow(()=>true);
    }, []);
    const onClicked = () => {
          setShow(!show);
    };
    const clearAll = () => {
        setSelectedCategoryIds([]);          
        setSelectedBlockchains([]);               
        setSelectedShapes([]);               
        setSelectedEditionIds([]);               
        setSelectedFormatIds([]);               
        setSelectedStorageIds([]);               
        setSelectedTagIds([]);               
        setSelectedTypeIds([]);                   
        setSelectedTermsIds([]);     
    }

    useEffect(() => {
        let newFilter = {
            categoryIds: selectedCategoryIds,
            blockchains: selectedBlockchains,
            shapes: selectedShapes,
            editionIds: selectedEditionIds,
            formatIds: selectedFormatIds,
            storageIds: selectedStorageIds,
            tagIds: selectedTagIds,
            typeIds: selectedTypeIds,
            termsIds: selectedTermsIds
        }
        dispatch("setActiveIcons", { filter: newFilter });  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCategoryIds, selectedShapes, selectedEditionIds, selectedFormatIds, selectedStorageIds, selectedTagIds, selectedTypeIds, selectedTermsIds, selectedBlockchains]);

    const updateSelectedCategoryIds = useCallback((e) => {  
        setSelectedCategoryIds(e);           
    },[]);

    const updateSelectedBlockchain = useCallback((e) => {    
        setSelectedBlockchains(e)          
    },[]);
    const updateSelectedShapes = useCallback((e) => {     
        setSelectedShapes(e);           
    },[]);

    const updateSelectedEditionIds = useCallback((e) => {     
        setSelectedEditionIds(e);           
    },[]);

    const updateSelectedFormatIds = useCallback((e) => {     
        setSelectedFormatIds(e);           
    },[]);

    const updateSelectedStorageIds = useCallback((e) => {     
        setSelectedStorageIds(e);           
    },[]);

    const updateSelectedTagIds = useCallback((e) => {     
        setSelectedTagIds(e);           
    },[]);    

    const updateSelectedTypeIds = useCallback((e) => {     
        setSelectedTypeIds(e);           
    },[]);    

    const updateSelectedTermsIds = useCallback((e) => {     
        setSelectedTermsIds(e);           
    },[]);    

    
    if (!activeMenuIcons.filter)
    {
        activeMenuIcons.filter = {};
    }
    if (!activeMenuIcons.filter.categoryIds)
    {
        activeMenuIcons.filter.categoryIds = [];
    }        
    if (!activeMenuIcons.filter.blockchains)
    {
        activeMenuIcons.filter.blockchains = [];
    }        
    if (!activeMenuIcons.filter.formatIds)
    {
        activeMenuIcons.filter.formatIds = [];
    }
    if (!activeMenuIcons.filter.editionIds)
    {
        activeMenuIcons.filter.editionIds = [];
    }
    if (!activeMenuIcons.filter.storageIds)
    {
        activeMenuIcons.filter.storageIds = [];
    }
    if (!activeMenuIcons.filter.tagIds)
    {
        activeMenuIcons.filter.tagIds = [];
    }
    if (!activeMenuIcons.filter.shapes)
    {
        activeMenuIcons.filter.shapes = [];
    }
    if (!activeMenuIcons.filter.typeIds)
    {
        activeMenuIcons.filter.typeIds = [];
    }
    if (!activeMenuIcons.filter.termsIds)
    {
        activeMenuIcons.filter.termsIds = [];
    }

    if (!shapes.length)
    {
        setShapes(
            [
                { id:'square', name: 'Square' },
                { id:'portrait', name: 'Portrait' },
                { id:'landscape', name: 'Landscape' }
            ]);
    }            
    return (
        <div
            className={`filter-select ${show ? 'open-popup' : 'close-popup'} `}
            onMouseOverCapture={onMouseFocus}
            onMouseLeave={onMouseBlur}
            onBlur={blurHandler}
            tabIndex={-1}
            ref={popupRef}
        >
            <>
                <div className={'filter-box'}
                    onMouseOverCapture={onMouseFocus}
                    onMouseLeave={onMouseBlur}
                    onClick={onClicked}
                >
                    {<div className={`filter-icon cursor-pointer`}>
                    <FilterIcon className={`${show&&"activeIcon"} sm:block hidden`} />
                    <FilterMobileIcon className={`${show&&"activeIcon"} sm:hidden flex border-wrap`} />
                        {/* <img className={'imghgt'} src={image} alt="icon"/> */}
                    </div>}
                </div>
                <div className={`filter-selection ${(isCategoryOpen||isBlockchainOpen||isShapesOpen||isFormatOpen||isTypeOpen||isEditionsOpen||isStorageOpen||isTermsOpen||isTagOpen)&&"active"}`} style={{ display:  'block' }}>
                    <ul className="drop-down-items item-root-level w-full">
                        <li className="flex flex-col false pointer-events-none">
                            <div className="link clickable-links pointer-events-auto" onClick={clearAll}>Clear all</div>
                        </li>
                   {!!(filterContent?.category && categoryOptions?.length) && !activeMenuIcons.hideCategoriesFilter && <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleCategoryOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Categories</div>
                                </div>                                      
                                <div className={`item-arrow ${isCategoryOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isCategoryOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={categoryOptions} selected={[...activeMenuIcons.filter.categoryIds]} onClick={updateSelectedCategoryIds}/>
                                </ul>                         
                            }
                        </li>}
                        {blockchainList?.length > 1 ? <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleBlockchainOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Blockchain</div>
                                </div>                                      
                                <div className={`item-arrow ${isBlockchainOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isBlockchainOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={blockchainList} selected={[...activeMenuIcons.filter.blockchains]} onClick={updateSelectedBlockchain} />
                                </ul>                         
                            }
                        </li> : null}
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleShapesOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Shapes</div>
                                </div>                                      
                                <div className={`item-arrow ${isShapesOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isShapesOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={shapes} selected={[...activeMenuIcons.filter.shapes]} onClick={updateSelectedShapes} />
                                </ul>                         
                            }
                        </li>
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleFormatOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Formats</div>
                                </div>                                      
                                <div className={`item-arrow ${isFormatOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isFormatOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionFormat} selected={[...activeMenuIcons.filter.formatIds]} onClick={updateSelectedFormatIds} />
                                </ul>                         
                            }
                        </li>
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level `} onClick={toggleTypeOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Types</div>
                                </div>                                      
                                <div className={`item-arrow ${isTypeOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isTypeOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionType} selected={[...activeMenuIcons.filter.typeIds]} onClick={updateSelectedTypeIds} />
                                </ul>                         
                            }
                        </li>                        
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleEditionsOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Editions</div>
                                </div>                                      
                                <div className={`item-arrow ${isEditionsOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isEditionsOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionEditions} selected={[...activeMenuIcons.filter.editionIds]} onClick={updateSelectedEditionIds} />
                                </ul>                         
                            }
                        </li>
                        {!!filterContent?.collectionStorage&&<li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleStorageOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Storage</div>
                                </div>                                      
                                <div className={`item-arrow ${isStorageOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isStorageOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionStorage} selected={[...activeMenuIcons.filter.storageIds]} onClick={updateSelectedStorageIds} />
                                </ul>                         
                            }
                        </li>}
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleTermsOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Terms</div>
                                </div>                                      
                                <div className={`item-arrow ${isTermsOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isTermsOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionTerms} selected={[...activeMenuIcons.filter.termsIds]} onClick={updateSelectedTermsIds} />
                                </ul>                         
                            }
                        </li>                       
                        <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleTagOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Tags</div>
                                </div>                                      
                                <div className={`item-arrow ${isTagOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isTagOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={filterData.collectionTag} selected={[...activeMenuIcons.filter.tagIds]} onClick={updateSelectedTagIds} />
                                </ul>                         
                            }
                        </li>
                    </ul>                    
                </div>
            </>
        </div>
    )
}
 export default MultiFilter;
