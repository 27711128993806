import { useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import SkeltonCard from 'App/Components/Ui/SkeltonCard';
import { getCurrentFormValues, setCurrentFormValues } from 'App/ReduxStore/Slicers/gallerySlice';
import { useDispatch, useSelector } from 'react-redux';
import './MonoSettings.scss';
import ScreenStyle from 'App/Pages/GalleryPreview/Component/GalleryControlPanel/Components/ScreenStyle';
import { interpolate } from 'App/Helper/utilities';
import useResizeObserver from '@react-hook/resize-observer';
import { STATICURL } from 'App/Helper/staticfiles';

const MonoSettings = (props) => {
    const [screenDimension, setScreenDimension] = useState({width: 0, height: 0});
    const galleryPreviewRef = useRef(null);
    const whiteColor = '#ffffff';
    const [{ currentSelectedNfts }] = useStore(false);
    const [imagesFrame, setImagesFrame] = useState({})
    const [loaderPreview, setLoaderPreview] = useState(false)
    const currentForm = useSelector(getCurrentFormValues)
    const tempscreenControls = useMemo(() => { 
        return {
            frameType: !!currentForm?.monoCustomized?.frameType, 
            imageBorder: currentForm?.monoCustomized?.imageBorder, 
            imageLayout: currentForm?.monoCustomized?.imageLayout, 
            screenFrameType: currentForm?.screenFrameType ?? 0, 
            styleType: currentForm?.monoStyleType ?? 'ful-scr'
        }
}, [currentForm?.monoCustomized?.frameType, currentForm?.monoCustomized?.imageBorder, currentForm?.monoCustomized?.imageLayout, currentForm?.screenFrameType, currentForm?.monoStyleType])
const [screenControls, setScreenControl] = useState(tempscreenControls)
    const dispatchRedux = useDispatch();
    
    useEffect(() => {
        const val = currentSelectedNfts?.find((val) => val?.asset === props?.frameAssetNew?.asset)
        setImagesFrame({ ...val })
    }, [props?.frameAssetNew, currentSelectedNfts])
    
    useResizeObserver(galleryPreviewRef, (entry) => {   
        if(screenDimension?.width !== entry.contentRect.width || screenDimension?.height !== entry.contentRect.height) {
            setScreenDimension({ width: entry.contentRect.width, height: entry.contentRect.height});
        }   
    },[]); 
    const styles = useMemo(() => {
        let style = {'--image-size-padding': '0%', 
                     '--multi-slide-transform': `scale(1)`,
                     '--gallery-width': screenDimension.width, 
                     '--gallery-height': screenDimension.height}
                     
        const basePadding = 5;
        let newpadding = basePadding - Number(screenControls?.imageLayout?.size ?? 0)
        let col1 = screenControls?.imageBorder?.bgColor_1 ?? whiteColor;
        let col2 = screenControls?.imageBorder?.bgColor_2 ?? whiteColor;
        if (screenControls?.styleType !== 'custom')
        {                    
            newpadding = 0;                    
        }                
        style = {...style,
            '--image-color-background': screenControls?.imageLayout?.bgColor ?? "#000",
            '--image-color-border-1': col1,
            '--image-color-border-2': col2,
            '--image-color-border-3': interpolate(col1, col2, 0.5),
            '--image-size-padding': `${Number(newpadding)/2}%` ?? '0%',
            '--screen-frame-texture': `url(${STATICURL}images/detailframes/${screenControls?.screenFrameType}.jpg)`,
            'background': screenControls?.imageLayout?.bgColor ?? "#000",
            '--frame-thickness': `${screenControls?.imageBorder?.thickness??0.03}px`
        }; 
        return style
    }, [screenControls?.styleType, 
        screenControls?.imageBorder, screenControls?.imageLayout?.size, screenControls?.imageLayout?.bgColor,
        screenControls?.screenFrameType, screenDimension
    ]);
    useEffect(() => {
        const {frameType, imageBorder, imageLayout, screenFrameType, styleType} = screenControls;
        dispatchRedux(setCurrentFormValues({...currentForm, 
            monoCustomized: {imageLayout, imageBorder, frameType}, monoStyleType: styleType, screenFrameType
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenControls])
    const showFrame = useMemo(() => {
        return currentForm?.monoStyleType === 'ful-scr' || (['frm', 'custom']?.includes(currentForm?.monoStyleType) && !currentForm?.monoCustomized?.frameType);
    }, [currentForm?.monoStyleType, currentForm?.monoCustomized?.frameType])
    return (
        <>
            <div className='tab-top-container relative mono-preview'>
                <SkeltonCard show={loaderPreview} noAbsolute={false} height="100%" inline={true}
                    containerClassName="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex frame-spinner justify-center items-center z-9" />
                <div ref={galleryPreviewRef} className={`${loaderPreview ? 'opacity-0' : `opacity-100 h-full`}
                mono-view ${screenControls?.styleType} mono-screen screen-frame-type-${screenControls?.screenFrameType} ${(screenControls?.screenFrameType && isNaN(parseInt(screenControls?.screenFrameType))) && `screen-frame-type-texture`} ${screenControls?.frameType === 0 && `no-detail-frame`}`} style={styles}> 
                    <div className="frame-detail-1"></div>
                    <div className="frame-detail-2"></div>
                    <div className="frame-detail-3"></div>
                    <div className="frame-detail-4"></div>
                    <div className='mono-image-container h-full'>
                        <GalleryImages 
                            key={imagesFrame?.nftcdnimage512+showFrame}
                            setLoaderPreview={setLoaderPreview} className="h-full w-full" 
                            isDetailedView={true} nftGalleryNew={true} noFrame={showFrame} gallery={true} 
                            galleryNew={imagesFrame} src={imagesFrame?.nftcdnimage512} frameClass="" frameName={'Details'} 
                        />                    
                    </div>
                </div>
            </div>
            <div className={`steps-container mono-settings-body gallery-control-container`}>
                <ScreenStyle className='' screenControls={tempscreenControls} setScreenControl={setScreenControl} />
            </div>
        </>
    )
}

export default MonoSettings