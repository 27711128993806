import useHttp from "./use-http";
import { useCallback } from "react";

const useGallery = () => {
    const { triggerAPI } = useHttp();

    const getAvailableCreatorAssets = useCallback((galleryId, communityId, callback) => {
        triggerAPI({
            url: `gallery/available-assets/${galleryId}/${communityId}`, method: 'get'
        }, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return {
        getAvailableCreatorAssets,
    }
}
export default useGallery