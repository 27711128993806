import { useEffect, useState } from "react"

const VideoThumbnail = ({ video = '', list = {}, active, mobileView= false }) => {
    const [videoImage, setVideoImage] = useState('')
    const positionStyle = {
        left: list?.metaInfo?.image?.xPercent + "%",
        top: list?.metaInfo?.image?.yPercent + "%",
        width: list?.metaInfo?.image?.widthPercent + "%",
        height: list?.metaInfo?.image?.heightPercent + "%",
    }
    const positionStyleForMob = {
        width: "100%",
        height:"100%",
        'border-radius': '6px',
        overflow:'hidden'
    }
    useEffect(() => {
        if (video) {
            const extractCodeFromURL =() => {
                const regex = /embed\/(.*?)\?/;
                const match = video.match(regex);
                return match ? match[1] : null;
            };
            setVideoImage(extractCodeFromURL)
        }

    }, [video])
    return (
        <div
            className={`${active} flex items-center relative w-full h-full`}
            style={mobileView ? {} : { 'backgroundImage': "url(" + (list?.imageUrl) + ")" }}>
            {video?.includes("youtube") &&
                <div className="absolute" style={mobileView ? positionStyleForMob : positionStyle}>
                    < img className="w-full h-full"
                        src={`https://img.youtube.com/vi/${videoImage}/maxresdefault.jpg`
                        } alt="Noimage" />
                </div>}
        </div>

    )
}

export default VideoThumbnail