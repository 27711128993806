import "./RoundedButton.scss";

const RoundedButton = (props) => {
  return (
    <>
      {props?.type === "not-button-element" ? (
        <div
          data-a={props.dataA}
          className={`btn-dark type-${props.Type} ${props.className}`}
          type="submit"
          onClick={props.OnClick}
          disabled={props.disabled}
        >
          {props.Name}
        </div>
      ) : (
        <button
          data-a={props.dataA}
          className={`btn-dark type-${props.Type} ${props.className}`}
          type="submit"
          onClick={props.OnClick}
          disabled={props.disabled}
        >
          {props.Name}
        </button>
      )}
    </>
  );
};

export default RoundedButton;
