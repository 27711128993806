import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setCollections: (current, collections) => {
            let currentData = current?.collections ?? [] ;
            const data = [...currentData, ...collections.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                collectionsLoaded: 0,
                collectionsLoading: collections.loading,
                collections: finalData,
                collectionsCount: collections.count
            }
        },
        deleteCollections: (current, collectionsToDelete) => {
            let currentData = current?.collections ?? [] ;
            if (collectionsToDelete?.length > 0)
            {                
                for (let i = 0; i < collectionsToDelete.length; i++) {
                    const collectionToDelete = collectionsToDelete[i];
                    const collectionIndex = currentData.indexOf(collectionToDelete);
                    
                    if (collectionIndex>-1)
                    {
                        currentData.splice(collectionIndex,1);    
                    }                
                }
            }
            
            const finalData = removeDuplicateByElement(currentData, 'asset');
            return {
                collectionsLoaded: 0,
                collectionsLoading: currentData.loading,
                collections: finalData,
                collectionsCount: currentData.count
            }
        },
        resetCollections: (current, collections) => {
            return {
                collectionsLoaded: 0,
                collectionsLoading: false,
                collections: [],
                collectionsCount: 0
            }
        },
        setAssetsList: (current, assetsList) => {
            return {
                collectionAssetList: assetsList,

            }
        },
        updateCount: (current) => {
            if (current.collectionsLoaded < current.collectionsCount)
                return {
                    collectionsLoaded: current.collectionsLoaded + 1
                }
        },
        updateCollectionLoadingStatus: (current,status) => {
            return {
                collectionLoadingStatus: status
            }
        },   
        updateCollectionLoadTotal: (current,total) => {
            return {
                collectionLoadTotal: total
            }
        },       
        updateCollectionLoadProgress: (current,progress) => {
            return {
                collectionLoadProgress: progress
            }
        },       
        updateCollectionRawData: (current,data) => {
            return {
                collectionRawData: data
            }
        },        
        updateCollectionAssetCount: (current, data) => {
            return {
                collectionAssetCount: data
            }
        },        
        updateCollectionWalletData: (current, data) => {
            return {
                collectionWalletData: data
            }
        },        
        reFetch: (current) => {
            return {
                reFetchCollections: {mode:true}
            }
        },
        favToggle: (current, object) => {
            const updatedCollections = current?.collections.map(collec => {
                if (collec.policy === object.policy) {
                    return {
                        ...collec,
                        isCollectionFavourite: object?.value
                    };
                }
                return collec;
            });
            return {
                collections: updatedCollections
            }
        },
        favAssetToggle: (current, object) => {
            let find = current.collections.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                collections: current.collections
            }
        },
        updateUsername: (current, newUsername) => {
            return {
                username: newUsername
            }
        },
        setTriggerCollection: (current, value) => {
            return {
                triggerCollection: value
            }
        },
        setCurrentCollectionTab: (current, tab) => {
            return {
                currentCollectionTab: tab
            }
        },
        setIsLoginInProgress: (current, value) => {
            return {
                isLoginInProgress: value
            }
        }
    }
    initStore(actions, {
        currentCollectionTab: 'all-wallets',
        isLoginInProgress: false,
        triggerCollection: false,
        collectionAssetList: [],
        collectionsLoading: false,
        collectionsLoaded: 0,
        collectionAssetCount: 0,
        collections: [],
        collectionsCount: 0,
        collectionLoadingStatus:'finished', //defaulted to finished so cached collections will display
        collectionRawData:[],
        collectionWalletData: null,
        reFetchCollections: {mode:false},
        username:'',
        schemaVersionNumber: 10 //increment this number when you change the collection or token schema, it will trigger local collection data to be refetched
    });
}
export default configureStore;