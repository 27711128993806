import "./GalleryPreviewImages.scss"
import { findNumberFromString, getBLOBUrl } from "App/Helper/utilities"
import { memo, useCallback, useMemo } from "react"
import { useRef } from "react"
import React, { useEffect, useState } from "react"
import LayoutCard1 from "./Components/LayoutCard1"
import { useStore } from "App/hooks-store/store"
import useLayoutData from "App/hooks/use-layout"
import { Html2Canvas } from "App/Helper/html2canvas"

const GalleryPreviewImage = ({ className, layoutCount, clearCache = false, addedGallery = false, selectedNfts = '', 
onScreenCapture, fileName, layoutType, src, multiViewClass= '', imageRatioCalculated, selectedLayout = {}, isMultiGallery = false, 
onChainRenders = false, loadSmallSize = false, onChainReRender = 0 }) => {
    const [image, setImage] = useState()
    const exportRef = useRef(); 
    const [cntr, setCntr] = useState(0)
    const [frameCount, setFrameCount] = useState(0)
    const [allLoad, setAllLoad] = useState(false)
    const [nftCntr, setNftCntr] = useState(0)
    const [failsafeScreenshot, setFailsafecreenshot] = useState(null)
    const [countAssetFrame, setCountAssetFrame] = useState([])
    const [{ screenShotTake }, dispatch] = useStore(false);
    const timer = useRef(null);
    const { getLayoutFromStore } = useLayoutData();
    const buildCards = useCallback((layoutCount, list) => {
        switch (layoutCount) {
            default:
                setImage(list)
                break;
        }
    }, [])
    useEffect(() => {
        if (selectedNfts?.length) {
            buildCards(layoutCount, selectedNfts)
        }
    }, [selectedNfts, layoutType, layoutCount, buildCards])

    const screenshotImage = (timer) => {
        const htmlCanvas = () => {
            const input = exportRef.current
            Html2Canvas(input, (blob) => {
                const obj = {
                    [fileName]: { blob, url: getBLOBUrl(blob) },
                }
                if (fileName === 'file0') {
                    emitScreenshot(obj)
                }
            });            
        }
        if (failsafeScreenshot)
        {
            clearTimeout(failsafeScreenshot);
        }

        const timeoutid = setTimeout(() => {
            htmlCanvas();
        }, timer);

        if (timer === 2000)
        {
            setFailsafecreenshot(timeoutid);
        }        
    }
    const emitScreenshot = (obj) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            onScreenCapture(obj);
        }, 3000);
    }
    const onNFTloads = useCallback((e) => {
        setNftCntr(c => c + 1)
    }, [])
    const onFrameLoaded = useCallback((e) => {
        setCntr(c => c + 1)
    }, [])
    
    useEffect(() => {
        if (countAssetFrame.length && countAssetFrame.length ===  cntr && !screenShotTake?.frame) {
            if (onScreenCapture && fileName) {
                dispatch('setScreenShotTake', {frame: true})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr, screenShotTake])
   
    useEffect(() => {
        if (countAssetFrame.length === cntr && selectedNfts.length === nftCntr) {
            if (onScreenCapture && fileName) {
                if (failsafeScreenshot)
                {
                    clearTimeout(failsafeScreenshot) 
                }
                screenshotImage(2000);
            }
        }
        else if (onScreenCapture && fileName && !failsafeScreenshot && cntr === 0 && nftCntr === 0)
        {
            screenshotImage(2000);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr,nftCntr, countAssetFrame?.length])
    

    useEffect(() => {
        let values = []
        selectedNfts?.filter((x) => {
            if (x?.frameId && x?.frameId !== null) {
                values?.push(x)
            }
            return values
        })
        if (fileName === 'file0') {
            setCountAssetFrame(values)
        }
        
        setFrameCount(values?.length)
    }, [selectedNfts, fileName])  
    useEffect(() => {
        setNftCntr(0);
        setCntr(0);
    }, [selectedNfts?.length, onChainReRender])
    const layoutList = useMemo(() => {
        let list = [];
        getLayoutFromStore((res) => list = res)
        return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayout])
    const selectedList = useMemo(() => {
        let tempArr = [];
        if(image?.length) {
            let tempSelectedLayout = selectedLayout ? {...selectedLayout} : {};
            if(!selectedLayout?.metaInfo?.length || (image?.length !== selectedLayout?.count)) {
                tempSelectedLayout = layoutList?.find((item) => item?.count === image?.length)
            }
            image?.forEach((obj, index) => {
                let tempObj = {...obj, metaPos: tempSelectedLayout?.metaInfo[index] !== undefined ? tempSelectedLayout?.metaInfo[index] : null}
                tempArr.push(tempObj)
            });    
        }
        
        return tempArr;
    }, [image, selectedLayout, layoutList])
    useEffect(()=> {
        // console.log('loaded image', fileName, nftCntr, selectedNfts.length);
        // console.log('loaded frame', fileName, cntr, frameCount);
        let loading = (frameCount === cntr) && (selectedNfts.length === nftCntr);
        if(isMultiGallery)
            dispatch('setLoadingLayout', {loading: (!loading), loadingIndex: fileName ? findNumberFromString(fileName) : 0})
        setAllLoad(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr, nftCntr, frameCount, selectedNfts, fileName])
    return (
        <div className={`${className}  preview-frame relative preview-gallery-new rounded-[.5rem]`}>
            <div ref={exportRef} className={`${addedGallery ? 'added-frame-image' : 'wall-image'} frame-image rounded-[.5rem`} style={{backgroundImage: `url(${src})`}}>
                <div className={`relative rounded-[.5rem] ${selectedNfts?.length >= 8 ? 'gallery-frame-wall' : `layout-card-${selectedNfts?.length}`} grid-frame gallerylayout ${multiViewClass}`} >
                    <LayoutCard1 
                        isMultiGallery={isMultiGallery} loadingData={allLoad}
                        loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                        clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                        imageRatioCalculated={imageRatioCalculated} 
                        onChainRenders={onChainRenders} onChainReRender={onChainReRender} 
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(GalleryPreviewImage)