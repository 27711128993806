import "./WalletList.scss"
import { Fragment } from "react"
import WarningBox from "App/Components/Ui/WarningBox"
import { useState } from "react"
import Listing from "./listing"

const WalletList = ({ list = [], isEdit = true, onDelete,editWallet,onRefresh }) => {
    const [show, setShow] = useState(false);
    const [showRefresh, setShowRefresh] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState({walletId: null, chainName: null});
   
    const onDeleteConfirm = (walletId, chainCode = '') => {
        setShow(true)
        setSelectedWallet({walletId, chainCode})
    }
    const onRefreshConfirm = (walletId, chainCode = '') => {        
        setShowRefresh(true)
        setSelectedWallet({walletId, chainCode})
    }
    const cancelPopup = () => {
        setShow(false)
        setShowRefresh(false)
    }
    const onDeleteHandler = (e) => {
        setShow(false)
        onDelete(selectedWallet)
    }
    const onRefreshHandler = (e) => {
        setShowRefresh(false)
        onRefresh(selectedWallet)
    }
    return (
        <Fragment>
            <div className="flex w-full gap-4 linked-wallet">
                {list?.length ?
                    <ul className="flex justify-between items-start w-full flex-wrap mob-connected gap-4 py-4">
                        {list?.map((wallet, index) => <Listing editWallet={editWallet} onDeleteConfirm={onDeleteConfirm} onRefreshConfirm={onRefreshConfirm} key={wallet?.id} wallet={wallet} isEdit={isEdit} index={index} />
                      )}
                    </ul> : <p className="p-4 no-data w-full">No wallets available</p>}
            </div>
            {show && <WarningBox className="disconnect-wallet-warning-popup" onApprove={onDeleteHandler} onCancel={cancelPopup} approveBtn="Disconnect" cancelBtn="Cancel">
                {`${list?.length===1?"If the wallet is unlinked, all the galleries will be deleted.":""} Are you sure you want to disconnect this wallet?`}
            </WarningBox>}
            {showRefresh && <WarningBox className="disconnect-wallet-warning-popup" onApprove={onRefreshHandler} onCancel={cancelPopup} approveBtn="Refresh" cancelBtn="Cancel">
                {`Are you sure you want to refresh this wallet? This will update all NFTs in your wallet from the blockchain.`}
            </WarningBox>}
        </Fragment>
    )
}
export default WalletList;