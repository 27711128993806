import RoundedButton from "App/Components/Ui/RoundedButton";
import "./NoContent.scss";
import CustomImage from "App/Components/Ui/CustomImage";
import MultiOptionsDropDown from "App/Pages/Discover/Components/SubComponents/MultiOptionsDropDown";

const NoContent = ({
  text,
  brief,
  buttonText,
  className,
  banner,
  onClick,
  multiCollectionCreateOptions,
  buttonsList,
}) => {
  return (
    <div className={`no-content-wrapper pt-16 pb-4 ${className}`}>
      <div className="flex flex-col gap-4 text-wrapper">
        <h1>{text}</h1>
        <p>{brief}</p>
      </div>
      <CustomImage src={banner} skeltonCardHeight={"100%"} />
      {!!buttonText &&
        !multiCollectionCreateOptions &&
        !multiCollectionCreateOptions?.length &&
        !buttonsList && (
          <RoundedButton
            className="mt-4"
            Name={buttonText}
            Type="14"
            value={buttonText}
            OnClick={onClick}
          />
        )}
      {!!buttonsList?.length && (
        <div className="flex gap-4 items-center justify-center w-full">
          {buttonsList?.map((list, i) => (
            <RoundedButton key={i+1}
              className="mt-4"
              Name={list?.text}
              Type="14"
              value={list?.text}
              OnClick={list?.onClick}
            />
          ))}
        </div>
      )}
      {!!buttonText &&
        multiCollectionCreateOptions &&
        multiCollectionCreateOptions?.length &&
        !buttonsList && (
          <MultiOptionsDropDown
            dropDownPositionTop="0.2rem"
            dropDownPositionLeft={`0`}
            options={multiCollectionCreateOptions}
            mainButtonContent={
              <RoundedButton
                type="not-button-element"
                className="mt-4"
                Name={buttonText}
                Type="14"
                value={buttonText}
                OnClick={() => {}}
              />
            }
          />
        )}
    </div>
  );
};

export default NoContent;
