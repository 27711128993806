import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DiscoverGallery from 'App/Pages/Discover/Components/SubComponents/DiscoverGallery';
import PreviewGallery from 'App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery';
import DetailsSkeltonCard from 'App/Components/Ui/detailsSkeleton';
import useHomeApiHelper from 'App/Pages/HomeV2/Components/API/apiHelper';
import RentalGalleries from 'App/Pages/Display/Components/RentalGalleries';
import useProfileApiHelper from 'App/Pages/UserProfile/Components/Apis';
import { collectorProfileRoutes } from 'App/Helper/routings';
import useGalleryList from 'App/hooks/use-galleryList';
const isAnyWall = process.env.REACT_APP_COMPANY === 'anywall'
const paginationConfig = {
    perPage: 100
}

const GalleryTab = (props) => {

    const navigate = useNavigate();
    const { tab } = useParams();
    const { pathname } = useLocation();
    const [{ discoverableProfile}] = useStore(false);
    // Gallery related
    // const [galleryData, setGalleryData] = useState(null);
    const [previewGallery, setPreviewGallery] = useState({ mode: false, data: [] });
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [assetPreviewGallery, setAssetPreviewGallery] = useState({ mode: false, data: [] });
    const [page, setPage] = useState(1);
    const [rentalsGalleries, setRentalsGalleries] = useState([]);
    const [rentalGalleriesloading, setRentalsGalleriesLoading] = useState(true);
    const {getProfile} = useProfileApiHelper();
    const { getCreatorGallery } = useHomeApiHelper();
    const [gallerytab ,setGalleryTab] = useState(isAnyWall ? "public" : "private");
    const itemsPerPage = 9;
    const { galleryData, totalItems, isFetching, initialLoaded } = useGalleryList(gallerytab, itemsPerPage, 'collector-container');
    useEffect(() => {
        props?.setNoGallery(!(totalItems?.privateTotalItems + totalItems?.publicTotalItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[totalItems, gallerytab])
    const onPageChangeHandler = (page) => {
        setPage(page)
    }
    const openPreviewGallery = (e) => {
        setPreviewGallery({ mode: true, data: { id: e, type: 0 } })
    }

    const handlePreviewGalleryCard = (e) => {
        openPreviewGallery(selectedGallery?.id);
    }

    const openAssetPreviewGallery = (e) => {
        setAssetPreviewGallery({ mode: true, data: { id: e?.id, type: 0 } })
    }
    const handleGalleryCardClick = (e) => {
        let selected = galleryData[gallerytab]?.items && galleryData[gallerytab]?.items?.find((gal => gal?.id === parseInt(e)));
        setPreviewGallery({ mode: true, data: { id: e, type: 0, user : selected?.user } })
        navigate(collectorProfileRoutes("profileViewWithTab",tab))
    }
    const handleBackToGallery = () => {
        setPreviewGallery({ mode: false, data: [] });
        props.onBackToGallery()
        navigate(-1)
    }
    useEffect(() => {
        if(!pathname?.includes('my-galleries/view')) {
            setPreviewGallery({ mode: false, data: [] });
            props.onBackToGallery()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    
    //maybe its used in future
    // eslint-disable-next-line no-unused-vars
    const getRentalGalleriesData = useCallback(() => {
        const req = {
          page: 1,
          items: 1000,
          filter: {
            showOnHomePageDisplay: true,
          },
        };
        setRentalsGalleriesLoading(true);
        getCreatorGallery(req, (res) => {
          const temp = res?.list?.map((item) => {
            const temp = item?.gallery;
            temp.community = item?.community;
            temp.userIcon = item?.community?.displayPicture;
            return temp;
          });
          setRentalsGalleriesLoading(false);
          setRentalsGalleries(temp);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      useEffect(() => {
        getProfile()
        //   getRentalGalleriesData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      
    const menuTabs = useMemo(()=>{
        if(isAnyWall) return [];
        let galleryTabs=  [
            { id: "private", element: 'Private',count: !initialLoaded ? 0 : totalItems?.privateTotalItems },
            { id: "my-rentals", element: 'My Rentals', count: 0 },
        ]
        if(discoverableProfile && (!!totalItems?.publicTotalItems && initialLoaded)){
            galleryTabs?.splice(1,0,{ id: "public", element: 'Public',count: !initialLoaded ? 0 : totalItems?.publicTotalItems })
        }
        return galleryTabs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[discoverableProfile, totalItems, initialLoaded])
    const handleTabs = (e) => {
        const selectTab = e.currentTarget?.dataset?.id;
        setGalleryTab(selectTab)
        const root = document.getElementById('gallery-list');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

    }
    return (
        <div className='profile_view-gallery-tab'>
            {!previewGallery?.mode && 
                <div className='profile_view-manage-btn-box'>
                    <button onClick={props.onManageClick} className='new-btn_style_001 profile_view-manage-btn'>
                        {' '} Manage Galleries</button>
                </div>
            }
            {previewGallery?.mode ?
                <div className="">
                    <PreviewGallery
                        galleryUpdateId={previewGallery?.data?.id}
                        viewFromOther={true}
                        standalone={true}
                        enableEdit={false}
                        selectedWall={''}
                        galleryType={previewGallery?.data?.type}
                        fromDiscover={false}
                        onClickExit={handleBackToGallery}
                        applyFrameButton={false}
                        className={'top-0 px-[1rem] pt-0 pb-0'}
                        onClose={handleBackToGallery}
                        shareUrl={gallerytab==="public"&&`${process.env.REACT_APP_SITE_URL}/discover/collectors/${previewGallery?.data?.user?.username}/my-galleries/${previewGallery?.data?.id}`}

                    />
                </div>
                :
                <div className={``}>
                    <div id="discover-tab" className="common_style-tab-container mb-2 px-[1rem]">
                        {process.env.REACT_APP_COMPANY !== 'anywall' &&
                            <div className="tab-data">
                                {menuTabs?.map((menu, index) => {
                                    return (
                                    <div
                                        onClick={handleTabs}
                                        data-id={menu?.id}
                                        key={menu?.id}
                                        className={`tab ${menu?.id === gallerytab ? "active":"not-active"}`}
                                    >
                                        {menu?.element}{" "}
                                            <span className=" inline-block">{`(${menu?.count})`}</span>
                                    </div>
                                    );
                                })}
                            </div>
                        }
                    </div> 
                    <div id="gallery-list">
                        {gallerytab==="rentals" ?
                            <div className={`p-4 ${rentalGalleriesloading?"pt-0":"pt-2"}`}>
                                <RentalGalleries loading={rentalGalleriesloading} gallery={rentalsGalleries}/>
                            </div>
                            :
                                <DiscoverGalleryWrapper
                                    galleryList={galleryData[gallerytab]?.items}
                                    handleGalleryCardClick={handleGalleryCardClick}
                                    selectedGallery={selectedGallery}
                                    page={page}
                                    assetPreviewGallery={assetPreviewGallery}
                                    hidePagination={true}
                                    totalGalleryCount={gallerytab==="my-rentals"? 0 : (gallerytab === 'private' ? totalItems?.privateTotalItems : totalItems?.publicTotalItems)}
                                    openAssetPreviewGallery={openAssetPreviewGallery}
                                    handlePreviewGalleryCard={handlePreviewGalleryCard}
                                    onPageChangeHandler={onPageChangeHandler}
                                    setSelectedGallery={setSelectedGallery}
                                    showGallerySkelton={isFetching || !galleryData[gallerytab]?.initialLoaded}
                                    galleryType={gallerytab==="my-rentals"?"rentals":gallerytab}
                                    galleryHandlerWithNoData={gallerytab==="my-rentals"?props?.onNavigateToExploreRentals:props?.onManageClick}
                                />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

function DiscoverGalleryWrapper(props) {

    return (<>
        {/* <div className="w-full text-start px-[1rem]">{props?.title} <span className="text-[var(--cu-black-5)]">({props?.galleryList?.length})</span>
        </div> */}
                <div className="px-[1rem] profile-gallery-list">
                    <DiscoverGallery
                        galleryList={props.galleryList}
                        handleCardClick={props.handleGalleryCardClick}
                        selectedGallery={props.selectedGallery}
                        page={props.page}
                        type="collectors"
                        galleryType={props?.galleryType}
                        previewGallery={props.assetPreviewGallery}
                        hidePagination={true}
                        totalItems={props.totalGalleryCount}
                        openAssetPreviewGallery={props.openAssetPreviewGallery}
                        perPage={paginationConfig?.perPage}
                        openPreviewGallery={props.handlePreviewGalleryCard}
                        onPageChangeHandler={props.onPageChangeHandler}
                        setSelectedGallery={props.setSelectedGallery}
                        galleryHandlerWithNoData={props?.galleryHandlerWithNoData}
                    />
                    {props?.showGallerySkelton && 
                        <div className={`pt-0 pr-sm `}>
                            <DetailsSkeltonCard
                                show={props.showGallerySkelton}
                                height={"100%"}
                                count={9}
                                inline={true}
                                details={"1-line"}
                                noAbsolute
                                parentDiv="grid home-grid mt-0 aspect-pt-six  details-gap"
                                containerClassName={`flex`}
                            />
                        </div>
                    }
                </div>
    </>)

}
export default GalleryTab