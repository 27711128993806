import "../Icons.scss";

const XIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color rect-fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5405_7704)">
          <path
            d="M10.5697 8.61162L16.7122 1.625H15.2567L9.92313 7.69139L5.66326 1.625H0.75L7.19176 10.7985L0.75 18.125H2.20566L7.838 11.7187L12.3367 18.125H17.25L10.5697 8.61162ZM8.57602 10.8793L7.92333 9.9658L2.73015 2.69724H4.96596L9.15691 8.5632L9.8096 9.47667L15.2573 17.1015H13.0215L8.57602 10.8793Z"
            fill="#A0ABBB"
          />
        </g>
        <defs>
          <clipPath id="clip0_5405_7704">
            <rect
              width="18"
              height="18"
              fill="#A0ABBB"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default XIcon;
