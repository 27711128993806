import { useNavigate } from "react-router-dom";
import BackIcon from "../IconsComponents/BackIcon";
import "./MobileTitleHeader.scss";

const MobileTitleHeader = ({ title, onClick, className }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };
  return (
    <div
      className={`${className} mobile-title-header sm:hidden flex justify-between items-center gap-2 `}
    >
      <BackIcon className="mt-[2px]" onClick={onClickHandler} />
      <h2 className="capitalize">{title}</h2>
      <div></div>
    </div>
  );
};
export default MobileTitleHeader;
