import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
// import displayPortIcon from "Assets/Icons/GalleryControls/display-port-d.svg";
// import displayPortIconActive from "Assets/Icons/GalleryControls/display-port-s.svg";
// import displayLandIcon from "Assets/Icons/GalleryControls/display-land-d.svg";
// import displayLandIconActive from "Assets/Icons/GalleryControls/display-land-s.svg";
// import displaySqIcon from "Assets/Icons/GalleryControls/display-sq-d.svg";
// import displaySqIconActive from "Assets/Icons/GalleryControls/display-sq-s.svg";
import displayPlayIcon from "Assets/Icons/GalleryControls/display-play.svg";
import eyeVisibleIcon from "Assets/Icons/GalleryControls/eyeVisibleIcon.svg"
import eyeInvisibleIcon from "Assets/Icons/GalleryControls/eyeInvisibleIcon.svg"
import GalleryImages from "App/Components/Ui/GalleryFrameImages";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { removeDuplicateByElement } from "App/Helper/utilities";
import LandscapeIcon from "App/Components/IconsComponents/GalleryControls/LandscapeIcon";
import PotraitIcon from "App/Components/IconsComponents/GalleryControls/PotraitIcon";
import SquareIcon from "App/Components/IconsComponents/GalleryControls/SquareIcon";
import { useSelector } from "react-redux";
import { getSlideChangeInProgress } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import ListIcon from "App/Components/IconsComponents/GalleryControls/ListIcon";
import PreviewChanges from "../../../PreviewChanges";
import MobileControlClose from "App/Components/Ui/Button/MobileControlClose";

const DisplayControl = ({className = '', list = [], setFilteredGalleryList, currentPlayingAsset = null, slideGoTo, show = false,onHandleDisplayList=false}) => {
    const  clickAccess = useSelector(getSlideChangeInProgress);
    const [playingList, setPlayingList] = useState([]);
    const [selectedRatios, setSelectedRatios] = useState([]);
    const [allAspectRatio, setAllAspectRatio] = useState([]);
    const [availAspectRatio, setAvailAspectRatio] = useState([]);
    const [invisibleAssets, setInvisibleAssets] = useState([]);

    const getShapes = (aspectRatio) => {
        let hasSquare = aspectRatio?.filter(ar => ar === 1).length > 0;
        let hasLandscape = aspectRatio?.filter(ar => ar > 1).length > 0;
        let hasPortrait = aspectRatio?.filter(ar => ar !== "" && ar < 1).length > 0;   
        return { hasSquare, hasLandscape, hasPortrait}
    }
    const shapes = useMemo(() => {
        let tempShape = getShapes(availAspectRatio)

        let tempSelectedShapes = [];
        if(tempShape?.hasLandscape)
            tempSelectedShapes = [...tempSelectedShapes, 'land']
        if(tempShape?.hasPortrait)
            tempSelectedShapes = [...tempSelectedShapes, 'port']
        if(tempShape?.hasSquare)
            tempSelectedShapes = [...tempSelectedShapes, 'sq']
        setSelectedRatios(tempSelectedShapes);
        return tempShape;
    }, [availAspectRatio])
    const newList = useMemo(() => {
        let newlist = removeDuplicateByElement(list, 'asset');
        if(list?.length === allAspectRatio?.length){
            newlist = newlist?.map(item => {
                let temp = allAspectRatio?.find(ratio => ratio?.index === item?.asset)
                let tempShape = getShapes([temp?.ratio])
                let shape = tempShape?.hasLandscape ? 'land' : (tempShape?.hasPortrait ? 'port' : 'sq')
                if(temp)
                    item = {...item, ratio: temp?.ratio, shape: shape}
                return item
            })
        }
        return newlist;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[list, allAspectRatio, invisibleAssets])
    useEffect(() => {
        let filterList = [...newList], showLoader = true;
        if(selectedRatios?.length){
            filterList = filterList?.filter(item => selectedRatios?.includes(item?.shape));
        }
        if(invisibleAssets?.length){
            showLoader = false
            filterList = filterList?.filter(item => !invisibleAssets?.includes(item?.asset));
        }
        setFilteredGalleryList(filterList);
        setPlayingList(filterList);
        if(newList?.length !== filterList?.length){
            slideGoTo(0, showLoader) //any changes after filter slider needs to start with 0th index
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newList, selectedRatios])
    const imageRatioCalculated = useCallback((ratio, index) => {
        setAllAspectRatio(prevState => [...prevState, { ratio, index }]);
        if (!availAspectRatio.includes(ratio))
            setAvailAspectRatio(prev => [...prev, ratio]);
    },[availAspectRatio])

    const handleRemoveInvisibility =  useCallback((type, shapesAlreadyIn) => {
        if(!invisibleAssets?.length){ return }
        const isIn = shapesAlreadyIn?.includes(type);
        const filteredListSet = new Set(invisibleAssets);
        if(!isIn){
            newList?.forEach((item)=>{
                if(item?.shape === type){
                    if(invisibleAssets?.includes(item?.asset)){
                        filteredListSet.delete(item?.asset)
                    }
                }
            })
        } 
        const filteredList = Array.from(filteredListSet);
        setInvisibleAssets(filteredList);
    },[invisibleAssets, newList]);

    const handleShapesClick = useCallback((e) => {
        let type = e?.currentTarget?.dataset?.type;
        handleRemoveInvisibility(type, selectedRatios)
        let newAspects = selectedRatios?.includes(type) ?
                                //atleast one selected
                                 selectedRatios?.length > 1 ? selectedRatios?.filter(item => item !== type) : selectedRatios 
                             :  [...selectedRatios, type];
        setSelectedRatios(newAspects);
    },[handleRemoveInvisibility, selectedRatios]);

    const selectToPlay = useCallback((asset) => {
        let slideIndex = playingList?.findIndex(item => item?.asset === asset) ?? 0; //find index with filtered list
        slideGoTo(slideIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playingList])

    const handleShowShapeFilter = useCallback((hidedAssetIDs) => {
        let totalShapesObject = {
            land: 0,
            port: 0,
            sq: 0,
        };
        let availableShapesObject = {
            land: 0,
            port: 0,
            sq: 0,
        };
        let clickableShapesObject = {};
        const filteredNewList = newList?.filter((item) => !hidedAssetIDs?.includes(item?.asset));

        newList?.forEach((item) => {
            const shapeName = item?.shape;
            if (shapeName) {
                totalShapesObject[shapeName] = ++totalShapesObject[shapeName];
            }
        });
        
        filteredNewList?.forEach((item) => {
            const shapeName = item?.shape;
            if (shapeName) {
                availableShapesObject[shapeName] = ++availableShapesObject[shapeName];
                clickableShapesObject[shapeName] = shapeName;
            }
        });

        const checkShapesAvailabilityFn = (totalShapeCount, availableShapeCount, type) => {
            if(!totalShapeCount || !availableShapeCount) {
                if(!availableShapeCount && totalShapeCount && selectedRatios?.includes(type)){
                    const allShapes = selectedRatios?.filter(item => item !== type);
                    setSelectedRatios(allShapes)
                }
                return true
            } else if ((availableShapeCount < totalShapeCount && availableShapeCount > 0) || (totalShapeCount === availableShapeCount)) {
                // if (selectedRatios?.length !== Object.keys(shapes)?.length) {
                //     const allShapes = selectedRatios?.filter(item => item !== type);
                //     setSelectedRatios(allShapes)
                // }
                return false
            }
        }

        Object.entries(totalShapesObject).forEach(([k])=>{
            checkShapesAvailabilityFn(totalShapesObject[k], availableShapesObject[k], k) 
        })
          
    }, [newList, selectedRatios]);

    const handleVisibility = useCallback((asset) => {
        
        const filteredListSet = new Set(invisibleAssets);
        if (filteredListSet.has(asset)) {
            filteredListSet.delete(asset);
        } else {
            filteredListSet.add(asset);
        }
    
        const filteredList = Array.from(filteredListSet);
    
        handleShowShapeFilter(filteredList);
        setInvisibleAssets(filteredList);
    }, [handleShowShapeFilter, invisibleAssets]);

    return (
        <ControlCard className={`${className} pop display_pop flex-col gap-5 display-list-control w-[50rem] ${show ? 'show-display-control' : 'hidden-display-control'}`}>
            <ControlBody className="display-list-section">
                <div className="flex gap-2 justify-between pr-[1.143rem]">
                    <ControlTitleCard><ListIcon className={`display_list_icon`}/> <span>{`Display List`}</span></ControlTitleCard>
                    <div className="display-filter flex gap-2 items-end">
                        {(shapes?.hasLandscape)&&
                            <button className={`filter-img disabled:cursor-not-allowed`} disabled={clickAccess} data-type={'land'} onClick={handleShapesClick}>
                                {/* <img src={(selectedRatios?.includes('land') && shapesBoxes?.land) ? displayLandIconActive : displayLandIcon} alt="" /> */}
                                <LandscapeIcon className={(selectedRatios?.includes('land')) ? "active" : "inactive"} />
                            </button>
                        }
                        {(shapes?.hasPortrait) &&
                            <button className="filter-img disabled:cursor-not-allowed" disabled={clickAccess} data-type={'port'} onClick={handleShapesClick}>
                                {/* <img src={(selectedRatios?.includes('port') && shapesBoxes?.port) ? displayPortIconActive : displayPortIcon} alt="" /> */}
                                <PotraitIcon className={(selectedRatios?.includes('port')) ? "active" : "inactive"} />
                            </button>
                        }
                        {(shapes?.hasSquare) &&
                            <button className="filter-img disabled:cursor-not-allowed" disabled={clickAccess} data-type={'sq'} onClick={handleShapesClick}>
                                {/* <img src={(selectedRatios?.includes('sq') && shapesBoxes?.sq) ? displaySqIconActive : displaySqIcon} alt="" /> */}
                                <SquareIcon className={(selectedRatios?.includes('sq')) ? "active" : "inactive"} />
                            </button>
                        }
                    </div>
                </div>
                {show && <PreviewChanges change={currentPlayingAsset}/>}
                <ControlContent className="display-content listing-grid-control-panel">
                        {newList?.map((item, index) => {
                            let currentPlaying = currentPlayingAsset === item?.asset;
                            let disabledSinglePiece = invisibleAssets?.includes(item?.asset);
                            let hasInPlayingList = selectedRatios?.includes(item?.shape) && !disabledSinglePiece;
                            let notDisabledItem = selectedRatios?.includes(item?.shape);
                            return (
                                <div className={`display-list-item collection-card min-view ${currentPlaying && 'selected'}
                                flex flex-col aspect-square w-full relative`} key={item?.asset}> 
                                    <GalleryImages
                                        action='close'
                                        galleryNew={item}
                                        src={item?.details?.nftcdnimage256}
                                        dataId={item?.asset}
                                        className="w-full" nftGalleryNew={true} 
                                        imageRatioCalculated={(e) => imageRatioCalculated(e, item?.asset)}
                                    />
                                    {!currentPlaying && 
                                        <div className={`control-overlay flex items-center gap-2 ${(!hasInPlayingList)&& 'disabled'} play-hover`}>
                                            {(hasInPlayingList) && 
                                                <div className="play-image opacity-0" onClick={()=> selectToPlay(item?.asset)}>
                                                    <img src={displayPlayIcon} alt="hover" />
                                                </div>
                                            }                        
                                            {notDisabledItem && <button disabled={clickAccess}  className="disabled:cursor-not-allowed play-image opacity-0" onClick={()=> handleVisibility(item?.asset)}>
                                                <img src={disabledSinglePiece ? eyeVisibleIcon : eyeInvisibleIcon} alt="hover" />
                                            </button>}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                </ControlContent>
            </ControlBody>
            <MobileControlClose onClick={onHandleDisplayList && onHandleDisplayList}>Close</MobileControlClose>
        </ControlCard>
    )
}
export default memo(DisplayControl);