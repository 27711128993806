import { LogLevel } from "@azure/msal-browser";

let url = window.location.href;
let subpathIndex = url.replace("http://","").replace("https://","").indexOf("/");
if (subpathIndex > -1)
{
  url = url.substring(0,subpathIndex+(url.startsWith("https://")?8:7));
}

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_SPA_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: `https://${process.env.REACT_APP_B2C_TENANT_NAME}/${process.env.REACT_APP_B2C_AUTHORITY_NAME}/b2c_1_${process.env.REACT_APP_B2C_USERFLOW?.replace("b2c_1_","")}`, // Choose SUSI as your default authority.
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT_NAME}`], // Mark your B2C tenant's domain as trusted.
    redirectUri: `${url}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: `${url}`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //       loggerCallback: (
  //           level,
  //           message,
  //           containsPii
  //       ) => {           
  //         console.log(message);          
  //       },
  //       logLevel: LogLevel.Verbose,
  //       piiLoggingEnabled: false,
  //   }    
  // }
}