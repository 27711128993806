import "./subscriptionCard.scss";
import { Fragment, useCallback, useMemo, useState } from "react";
import ok from "Assets/Icons/ok.svg";
import monettok from "Assets/Images/Monet_round.png";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import { formatDate } from "App/Helper/dateTime";
import WarningBox from "App/Components/Ui/WarningBox"
import InformationIcon from "App/Components/IconsComponents/InformationIcon";
import ToolTip from "App/Components/ToolTip";

const SubscriptionCard = ({ plan, currentTab, activePlan, existingPlan }) => {
  const {isHighlighted, planPrice, isPaid, highlightedPlan, activeDate, id: planId, gracePeriodDate} = plan
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [showWarning, setShowWarning] = useState({show: false, text: '', isCancel: false});
  // const [warningText, setWarningText] = useState('');
  const warningText = useMemo(() => {
    return {
      downGrade: `Downgrading your plan will result in losing content from your ${existingPlan?.name} plan. Are you sure you want to continue?`,
      upGrade: `Activate ${plan?.name}?`
    }
  }, [existingPlan?.name, plan?.name])
  const checkList = useMemo(() => {
    return {
      isActiveCard: isHighlighted,
      isExistingPlan: planId === existingPlan?.id,
    }
  }, [isHighlighted, planId, existingPlan?.id])
  
  const Price = ({ price, requiredMonetHolding }) => {
    if (currentTab !== "Monet Members") {
      return (
        <span className="pricespan">
          ${Number(price)?.toFixed(2)}
          <span className="year">/month</span>
        </span>
      );
    } else {
      return (
        <span className="monet">
          <span className="hold">Hold</span>
          <img alt="monettok" src={monettok} />
          <span>{parseInt(requiredMonetHolding).toLocaleString()}</span>
        </span>
      );
    }
  };
  const handleChangePlan = useCallback(() => {
    dispatch("showSpinner");      
    let data = {
      subscriptionPlan: planId
    }
    if(!!activePlan)
      data = {...data, subscriptionToCancel: activePlan?.id}
    triggerAPI({
        url: `stripe/cur8subscriptionsession`,    
        data,      
        method: "post",
    }, (result) => {          
        window.location = result.data;
        dispatch("hideSpinner");
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId])
  const fiatSubscribe = useCallback(() => {
      if (!planPrice) {
        if (!!activePlan) {
          setShowWarning({show: true, text: warningText?.downGrade, isCancel: true});
        }        
      } else {
        let isDownGrade = !!activePlan && (Number(activePlan?.planPrice) > Number(planPrice))
        setShowWarning({show: true, text: isDownGrade ? warningText?.downGrade : warningText?.upGrade, isCancel: false});
      }      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningText, activePlan, planPrice]);
  
  const fiatCancel = useCallback(() => {
      dispatch("showSpinner");      
      triggerAPI(
        {
          url: `stripe/cur8subscriptioncancel`,    
          data: {
            subscriptionPlan: activePlan?.id            
          },      
          method: "post",
        },
        (result) => {
          if (!!result?.data) {
            window.location.reload();
          };
          dispatch("hideSpinner");
        }
      );
    },
    [dispatch, triggerAPI, activePlan?.id]
  );

  
  const approved = () => {  
    if(showWarning?.isCancel)
      fiatCancel()
    else 
      handleChangePlan();
  }

  const cancelled = () => {
    setShowWarning({show: false, text: ''});
  }

  const disabledButton = useMemo(() => {
    return {
      disable: ((!!activePlan?.activeDate && activePlan?.planPrice > planPrice) || (activePlan?.id === planId) || (!planPrice && !!isHighlighted)) ?? false,
      text: (!!activePlan?.activeDate && activePlan?.planPrice > planPrice) ? 'Plan selection disabled' : 'Activated plan'
    }
 }, [planPrice, activePlan?.activeDate, activePlan?.planPrice, activePlan?.id, planId, isHighlighted])

  const subscriptionButton = useCallback(() => {
      // let disableButton = (!!planPrice && isPaid && (!!activeDate || highlightedPlan?.endDate)) || (isHighlighted && !planPrice);
      return <button onClick={fiatSubscribe} disabled={disabledButton?.disable} data-text={disabledButton?.text}
      className={`Subscribed-btn Subscribed-btn-fiat ${disabledButton?.disable && 'relative inset-0 common-tooltip-with-arrow-tip cursor-not-allowed'}`} >
            {
              ( (checkList?.isActiveCard) 
                ? (
                    !planPrice 
                    ? `Current Plan` 
                    : (
                        isPaid ? (
                          checkList?.isExistingPlan && highlightedPlan?.endDate ? 
                            `Auto-renews on ${formatDate(highlightedPlan?.endDate,'ll')}`
                          :
                            'Current Plan'
                        ) : (
                          highlightedPlan?.paymentStatus === 'canceled' && highlightedPlan?.endDate ?
                            `Expires on ${formatDate(highlightedPlan?.endDate,'ll')}` :
                            ((highlightedPlan?.paymentStatus === 'failed' && highlightedPlan?.endDate) ? 
                              (
                                <>
                                  Payment failed &nbsp;&nbsp;
                                  <ToolTip
                                    className={`wide-tip-wrapper h-fit upgrade-tooltip`}
                                    message={ <FailedToolTipDesc gracePeriodDate={gracePeriodDate} plan={plan?.name} /> }
                                      >
                                          <InformationIcon />
                                  </ToolTip>
                                </>
                              ) : `Current Plan`) 
                        )
                      )
                  )
                :
                (!(checkList?.isActiveCard) && !!planPrice && isPaid && !!activeDate) ? `Active on ${formatDate(activeDate,'ll')}`  
                  : (!(checkList?.isActiveCard) && !planPrice && !!activeDate && !!existingPlan) ? `Active on ${formatDate(activeDate,'ll')}`  : `Select ${plan.name} Plan`                  
              )
            }
          </button>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className={`subscriptioncard ${checkList?.isActiveCard ? "active" : ""}`}>
        <div className="flex gap-[1.5rem] flex-col">
          <div className="flex flex-col gap-4">
          <h3>{plan.name}</h3>
            <p className="about-subscription">{`${plan.planPrice === 0 ? 
              "Available anytime" : 
              plan?.regularPlanPrice ? `Regular price $${Number(plan?.regularPlanPrice)?.toFixed(2)} /month` : ""}`}
            </p>
          </div>
          <h4>
            {plan.planPrice === 0 ? (
              `Free`
            ) : (
              <Price
                requiredMonetHolding={plan.requiredMonetHolding}
                price={plan.planPrice}
              />
            )}
          </h4>
          <div className="flex flex-col gap-[1.5rem]">
            <div className="subscribe-buttons">
            {!!(currentTab!=="Monet Members") && 
              subscriptionButton()
            }        
            </div>
          </div>
          <div className="items flex">
            <div className="each-options flex ">
              <label>Platform Access</label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>Collection management</label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                <span className="font-bold">{plan?.walletLimit||0}{" "}</span>
                {`viewable wallet${+plan?.walletLimit > 1 ?"s":""}`}
              </label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                {plan.devices === 1
                  ? 
                  <span>Use on <span className="font-bold">1</span> device</span>
                  : 
                  <span>Use on <span className="font-bold">{plan.devices}</span> devices at once</span>
                  }
              </label>
            </div>
            <div className="header-options flex gap-3">
              <label>Curation Features</label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                <span className="font-bold">{plan.availableGalleries}</span>
                {plan.availableGalleries > 1 ? " galleries" : " gallery"}
              </label>
            </div>
           
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                <span className="font-bold">{plan?.availablePremiumFrame}</span>{" "}
                premium{" "}{+plan?.availablePremiumFrame > 1 ? 'frames' : 'frame'}
              </label>
            </div>
            <div className="each-options flex ">
                <img alt="ok" src={ok} />
                <label>
                <span>
              <span className="font-bold">{plan?.isAvailablePremiumWall ? "Premium" : "Basic"}</span>
              </span>{" "}
              background walls
              </label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                <span className="font-bold">{plan.galleryType3D === "All" ? "Premium" : plan.galleryType3D}</span> {" "}
                {plan.galleryType3D === "All" ? `meta venues` : `meta venue`}
              </label>
            </div>
          {!!plan?.discoverableProfile&& 
          <>
            <div className="header-options flex gap-3">
              <label>Sharing & Display  </label>
            </div>
            <div className="each-options flex ">
              <img alt="ok" src={ok} />
              <label>
                <span className="font-bold">
                  {plan?.discoverableProfile ? "Discoverable" : "Undiscoverable"}
                </span>{" "}
                profile
              </label>
            </div>
            </>}
            {plan.anywallDisplayCount !==0 && (
              <div className="each-options flex ">
                <img alt="ok" src={ok} />
                <label>
                  Cast to <span className="font-bold">{plan.anywallDisplayCount}</span> Anywall{" "}
                  {plan.anywallDisplayCount > 1 ? `displays` : `display`}
                </label>
              </div>
            )}
            {/* {plan.name === `Premium Collector` && (
              <div className="each-options flex ">
                <img alt="ok" src={ok} />
                <label>Discoverable profile</label>
              </div>
            )} */}
          </div>
        </div>
      </div>      
      {showWarning?.show && 
        <WarningBox onApprove={approved} onCancel={cancelled} approveBtn="Yes" cancelBtn="No">
            {showWarning?.text}
        </WarningBox>
      }
    </>
  );
};
export default SubscriptionCard;

const FailedToolTipDesc = ({gracePeriodDate, plan}) => {
  return (
    <div>
      Due to a problem with your payment 
      method, we have been unable to charge your subscription fee. If you 
      don’t update your payment before {formatDate(gracePeriodDate,'ll')}, your subscription will 
      be cancelled and you risk losing content from your {plan} Plan.
    </div>
  )
}