import "../Icons.scss";

const UserIcon = ({ className, dataIndex,size=16, color="#A0ABBB", onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Profile">
          <path
            id="Vector"
            d="M9.0401 1.44617C8.23324 1.44617 7.44451 1.68545 6.77365 2.13375C6.1028 2.58206 5.57996 3.21924 5.27127 3.96472C4.96259 4.71019 4.88191 5.53047 5.03945 6.32179C5.19699 7.11312 5.58567 7.83996 6.15633 8.41037C6.727 8.98077 7.454 9.36913 8.2454 9.52633C9.0368 9.68352 9.85704 9.60248 10.6024 9.29346C11.3477 8.98444 11.9847 8.46133 12.4327 7.79028C12.8807 7.11923 13.1196 6.33038 13.1193 5.52353C13.1176 4.44233 12.6873 3.40591 11.9226 2.64155C11.1579 1.8772 10.1213 1.44731 9.0401 1.44617ZM9.0401 8.63105C8.42549 8.63105 7.82468 8.44879 7.31365 8.10734C6.80262 7.76588 6.40433 7.28055 6.16913 6.71272C5.93392 6.1449 5.87238 5.52008 5.99229 4.91728C6.11219 4.31448 6.40816 3.76077 6.84275 3.32618C7.27735 2.89158 7.83105 2.59562 8.43385 2.47572C9.03665 2.35581 9.66147 2.41735 10.2293 2.65255C10.7971 2.88775 11.2824 3.28605 11.6239 3.79708C11.9654 4.30811 12.1476 4.90892 12.1476 5.52353C12.1466 6.34737 11.8188 7.13717 11.2363 7.71972C10.6537 8.30226 9.86394 8.63 9.0401 8.63105Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M6.97331 11.872H11.0319C12.0343 11.8732 12.9953 12.2717 13.7042 12.9803C14.4131 13.6889 14.8121 14.6497 14.8137 15.652V16.2478C14.8137 16.3286 14.8458 16.4061 14.903 16.4632C14.9601 16.5203 15.0375 16.5524 15.1183 16.5524H15.4585C15.5393 16.5524 15.6167 16.5203 15.6739 16.4632C15.731 16.4061 15.7631 16.3286 15.7631 16.2478V15.6535C15.7631 13.0449 13.6391 10.9224 11.0319 10.9224H6.97331C4.36439 10.9224 2.24219 13.0464 2.24219 15.6535V16.2493C2.24219 16.3301 2.27427 16.4075 2.33139 16.4646C2.38851 16.5218 2.46597 16.5538 2.54675 16.5538H2.88695C2.96772 16.5538 3.04519 16.5218 3.1023 16.4646C3.15942 16.4075 3.19151 16.3301 3.19151 16.2493V15.6535C3.19275 14.6509 3.59159 13.6897 4.30056 12.9808C5.00953 12.2719 5.97073 11.8732 6.97331 11.872Z"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

export default UserIcon;
