import styles from '../../Profile.module.scss'
import Input from 'App/Components/Ui/Input'
import Label from 'App/Components/Ui/Label'
import Validators from 'App/Components/Ui/Forms/Validators'
import TextArea from 'App/Components/Ui/TextArea'
import ProfilePic from '../ProfilePic'
import RoundedButton from 'App/Components/Ui/RoundedButton'
import { useMemo } from 'react'
import ProfileBanner from '../ProfileBanner'
import ProfilePicBannerMobile from '../ProfilePicBannerMobile'
import useScreenSize from 'App/hooks/use-screen-size'
import PlacesInput from 'App/Components/Ui/PlacesInput'
import './collectorEditProfile.scss';

const EditProfile = props => {
    const screenSize = useScreenSize()?.width;
    // const navigate = useNavigate()
    // const walletConnectHandler = () => {
    //     navigate("wallet-authentication")
    // }
    const userNameValidatorsList = useMemo(() => ([{
        type: 'Required',
        message: 'Username is Required field'
      }, {
        type: 'Username',
        message: 'Invalid Username'
      }, {
        type: 'minLength',
        message: 'Username should be Minimum 3 characters'
      }]), []);
    return (
    <div className='collector_edit_profile_container'>

<div className='profile_form-inputs-section'>
            <div className='w-5/5 sm:w-3/5 flex flex-col gap-4'>
            <div className={`${styles["info-head"]} justify-start flex py-1`}>
                User information
            </div>

            <div className={`${styles["profile"]} w-full flex flex-col sm:flex-row justify-between gap-2`}>
                <div className={`flex flex-col edit-mob w-full sm:w-1/2 max-w-[31.25rem]`}>
                    <Label className={`${styles["label-color"]} text-left mb-[0.375rem]`}>Username</Label>
                    <Input maxlength={35} type="text" placeholder="Username" label="username" validateOnBlur onChange={props.onInputChange} controlName="username" value={props.user.username}>
                        <Validators value={props.user.username} controlName="username" onErrors={props.collectErrors} validators={userNameValidatorsList} minLength={3} />
                    </Input>
                </div>

                <div className={`${styles["profile-input"]} name-input w-full sm:w-1/2`}>
                    <Label className={`${styles["label-color"]} text-left  mb-[0.375rem]`}>Email
                    </Label>
                    <Input placeholder="Email" readonly={true} type="text" value={props.user.email}></Input>
                </div>
            </div>

                <div className={`w-full flex flex-col text-start`}>
                    <Label className={`${styles["label-color"]} text-left  mb-[0.375rem]`}>City/Country
                    </Label>
                    <PlacesInput
                        onSelectPlace={(value) => {
                            props.onInputChange({ nationality: value?.country })
                            props.onInputChange({ city: value?.city })
                            props.onInputChange({ cityId: value?.cityId })
                            props.onInputChange({ userLocation: value?.userLocation })
                        }}
                        defValue={`${props?.user?.city ? `${props?.user?.city}` : ''} ${props?.user?.nationality}`}
                    />
                    <Validators
                        type="Required"
                        value={props.user.nationality}
                        message="Required"
                        controlName="nationality"
                        onErrors={props.collectErrors} 
                    />
                </div>

            <div>
                {/* <ExclamationLine >Set up your account and receive Monet</ExclamationLine> */}
                <div className={`flex flex-col font-14 profile-mob ${styles.profile} `}>

                    <div className={`${styles["profile-row"]} flex flex-col mt-[1rem]`}>
                        <div className={`${styles['label-box']} flex justify-start items-start text-left `}>
                            <Label className={`${styles["label-color"]}  mb-[0.375rem]`}>About Me
                            </Label>
                        </div>
                        <div className={`${styles["light-txt"]} ${styles["profile-input"]}  flex justify-start`}>
                            <TextArea placeholder="Describe yourself... (1000 words max)" label="description" showTextCount={true} validateOnBlur type="text" onChange={props.onInputChange} maxLength={300} controlName="description" value={props.user.description}>
                            </TextArea>

                        </div>

                    </div>
                    {/* <div className={`${styles["info-head"]} py-1 pt-3  justify-start flex`}>
                    Personal information
                </div> */}
                    <div className={`${styles["info-head"]} justify-start pt-[1rem] py-1 flex`}>
                        Social information
                    </div>
                    <div className='flex gap-[1rem] sm:gap-[1.25rem]  pt-[1rem] flex-col sm:flex-row'>
                        <div className='flex  w-full max-w-[31.25rem] edit-info'>

                            <div className={`${styles["profile-row"]} w-full flex flex-col edit-mob`}>
                                <div className={`${styles['label-box']} flex justify-start items-center`}>
                                    <Label className={`${styles["label-color"]} text-left mb-[0.375rem]`}>X
                                    </Label>
                                </div>
                                <div className={`${styles["light-txt"]}  ${styles["profile-input"]}  flex justify-start`}>
                                    <Input label="twitter" validateOnBlur placeholder="X URL" type="text" onChange={props.onInputChange} controlName="twitter" value={props.user.twitter}>
                                        <Validators type="url" value={props.user.twitter} message="URL not valid" controlName="twitter" onErrors={props.collectErrors} />
                                    </Input>
                                </div>
                            </div>

                        </div>


                        <div className={`${styles["profile-row"]} flex flex-col w-full max-width-[31.25rem] edit-mob`}>
                            <div className={`${styles['label-box']} flex justify-start items-center`}>
                                <Label className={`${styles["label-color"]} text-left mb-[0.375rem]`}>Instagram
                                </Label>
                            </div>
                            <div className={`${styles["light-txt"]} ${styles["profile-input"]} flex justify-start`}>
                                <Input label="instagram" validateOnBlur placeholder="Instagram URL" type="text" onChange={props.onInputChange} controlName="instagram" value={props.user.instagram}>
                                    <Validators type="url" value={props.user.instagram} message="URL not valid" controlName="instagram" onErrors={props.collectErrors} />
                                </Input>
                            </div>

                        </div>
                    </div>
                    <div className='flex mt-[1rem] gap-[1rem] sm:gap-[1.25rem] flex-col sm:flex-row'>
                        <div className={`${styles["profile-row"]} flex flex-col w-full max-w-[31.25rem] edit-mob`}>
                            <div className={`${styles['label-box']} flex justify-start items-center`}>
                                <Label className={`${styles["label-color"]} text-left mb-[0.375rem]`}>Youtube
                                </Label>
                            </div>
                            <div className={`${styles["light-txt"]}  ${styles["profile-input"]}  flex justify-start`}>
                                <Input label="youtube" placeholder='Youtube URL' validateOnBlur type="text" onChange={props.onInputChange} controlName="youtube" value={props.user.youtube}>
                                    <Validators type="url" value={props.user.youtube} message="URL not valid" controlName="youtube" onErrors={props.collectErrors} />
                                </Input>
                            </div>
                        </div>
                        <div className={`${styles["profile-row"]} flex flex-col w-full max-w-[31.25rem] edit-mob`}>
                            <div className={`${styles['label-box']} flex justify-start items-center`}>
                                <Label className={`${styles["label-color"]} text-left mb-[0.375rem]`}>Website
                                </Label>
                            </div>
                            <div className={`${styles["light-txt"]} ${styles["profile-input"]}  flex justify-start`}>
                                <Input label="website" validateOnBlur placeholder="https://www.website.com" type="text" dataA="website" onChange={props.onInputChange} controlName="website" value={props.user.website}>
                                    <Validators type="url" value={props.user.website} message="URL not valid" controlName="website" onErrors={props.collectErrors} />
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

                {/* Upload section */}
                <div className='profile_form-upload-section'>
                    <div className="text-left items-start">
                        <Label className={`${styles["label-color"]}`}>Profile
                        </Label>
                    </div>
                    <div className='h-[6.8rem] sm:h-[8rem]'>
                        <ProfilePic readOnly={false} onEdit={props.onEditButtonClick} className={`${styles["profile-pic-size"]}`} />
                    </div>
                    <div className="text-left items-start ">
                        <Label className={`${styles["label-color"]}`}>Banner Image
                        </Label>
                    </div>
                    <div className={`${styles["profile-banner"]}`}>
                        <ProfileBanner readOnly={false} onEdit={props.onEditButtonClick} className={`${styles["profile-pic-size"]}`} bannerClass={`!w-full !rounded-xl`} />
                    </div>
                </div>

</div>
            {/* Button section */}
            <div className="m-auto w-full sm:w-[400px] flex flex-col sm:flex-row justify-center gap-4 pt-5">
                <RoundedButton Name="Cancel" Type="18" OnClick={props.onCancelEdit}></RoundedButton>
                <RoundedButton Name="Save" Type="17" OnClick={props.onUpdateButtonClick}></RoundedButton>
            </div>
    </div >)

}

export default EditProfile