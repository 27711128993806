import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback } from "react";
import { useState, useMemo } from "react";
import useHttp from './use-http';
import { DAppClient, ColorMode } from '@airgap/beacon-sdk';

let dAppClientInstance = null; // Singleton instance of DAppClient

const useWalletTezos = () => {    
    const [walletConnected, setWalletConnected] = useState(false);    
    const [walletNameConnected, setWalletNameConnected] = useState('');
    const [connectedAddress, setConnectedAddress] = useState('');        
    const dispatch = useStore(false)[1];
    const store = useStore()[0];    
    const { triggerAPI } = useHttp();
    let { blockchains, debugWalletAddress } = store;
    const [wallet, setWallet] = useState(null);   

    // Initialize DAppClient if not already created
    if (!dAppClientInstance) {
        dAppClientInstance = new DAppClient({ name: 'Cur8' });
        // Listen for all the active account changes
        dAppClientInstance.setColorMode(ColorMode.DARK);
        dAppClientInstance.subscribeToEvent("ACTIVE_ACCOUNT_SET", async (account) => {
            // An active account has been set, update the dApp UI
            console.log(`${"ACTIVE_ACCOUNT_SET"} triggered: `, account);
        });
    }

    const connectWallet =  useCallback(async () => {        
        try {                                                
            let address = storage().get('cwa');
            if (address) {
                const decoder = new TextDecoder();                
                address = decoder.decode(new Uint8Array(Object.values(JSON.parse(address))).buffer);
            }                        
            address = debugWalletAddress??address;
            if (!address) {
                console.log('Requesting permissions...');
                const permissions = await dAppClientInstance.requestPermissions();
                console.log('Got permissions:', permissions.address);

                address = permissions.address;
                
                storage().set('currentWallet', "Tezos");            
                const encoder = new TextEncoder();
                storage().set('cwa', JSON.stringify(encoder.encode(address)));
            }
            
            triggerAPI({
                url: `user/wallet/nftids?chain=${"tezos"}&wallet_address=${address}`, method: 'get'
            }, (res) => {
                let assets = res.data.map(d => { 
                    let data = d.split("."); 
                    return { 
                        asset: data[1] + data[2], 
                        asset_name: data[2], 
                        policy_id: data[1], 
                        contractAddress: data[1], 
                        chain: data[0]
                    }
                });
                
                dispatch('setCurrentWallet', { walletName: "Tezos", address: address, chain: "tezos", NFTs: assets});
                dispatch('setWalletUpdatingStatus', true);   
                dispatch('setWalletConnectionStatus', 'finished')         
                //setWalletNameConnected(wallet.label);
                setWalletConnected(true);
                setConnectedAddress(address);
            });                                 
        } catch (error) {
            dispatch('showToast', { toast: { toastMode: 'error', message: error?.message } });
        }                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, blockchains, debugWalletAddress]);

    const memoedValue = useMemo(
        () => ({
            wallet,            
            walletNameConnected,
            walletConnected,
            connectWallet,
            connectedAddress,
            debugWalletAddress
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet, walletNameConnected, walletConnected, connectedAddress, blockchains, debugWalletAddress]
    );

    return memoedValue;
};

export default useWalletTezos;
