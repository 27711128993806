import styles from "./InformationCard.module.scss";
import { Fragment} from "react";

const InformationCard = (props) => {

    const categoryDetailsHandler = (e) => {
        const id = props.categoryInfo.id;
        props?.onOpenPopup && props?.onOpenPopup(id, props?.categoryInfo?.name);
    }

    return  (<div id={props?.isSelected ? `selectedInfo` : null} className={`${styles['info-cat-wrp']} ${props?.isSelected && `border border-[white]`}`}>
            <div className={`flex flex-col ${styles.info} ${props?.fromPopup && `max-h-[18rem]`} `} key={props.categoryInfo.id}  onClick={categoryDetailsHandler}>
                <Fragment>
                    <div className={`flex justify-center items-center cursor-pointer ${styles['image-container']}`}
                        data-infoid={props?.categoryInfo?.id} 
                        onClick={categoryDetailsHandler}
                        >
                            <div className={`${styles['image-cls']}`}  style={{'backgroundImage': "url("+props?.categoryInfo.imageUrl+")" }}></div>
                    </div>
                    {/* <div className={`pt-2 text-left flex flex-col ${styles['card-text']}`}>
                        <span  className={`${styles['info-title']}`}>{props?.categoryInfo?.name}</span>
                    </div> */}
                </Fragment>
            </div>
        </div>
    )
}

export default InformationCard