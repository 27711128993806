import { useCallback, useState } from "react";
import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import InformationIcon from "App/Components/IconsComponents/InformationIcon";
import ToolTip from "App/Components/ToolTip";
import "./SettingsControl.scss"
import MobileControlClose from "App/Components/Ui/Button/MobileControlClose";
import SettingsIcon from "App/Components/IconsComponents/GalleryControls/SettingsIcon";

const SettingsControl = ({className = '',showEnhanced, showTransition, showCaption, setSettingControls, settingControls, galleryType, handleGalleryView, onHandleSettingsControl=null}) => {
    const [disableAnimationClick, setDisableAnimationClick] = useState(false);
    const handlEnhancedClick = (e) => {
        let value = parseInt(e?.currentTarget?.dataset?.value);
        setSettingControls(prev => { return {...prev, enhancedContent: {...prev?.enhancedContent, onChainRenders: !!value, clickCount: (prev?.enhancedContent?.clickCount + 1)}}})
    }
    const handleAnimationChange = useCallback((e) => {
        let value = e?.currentTarget?.dataset?.value;
        if(!disableAnimationClick) {
            setDisableAnimationClick(true)
            setSettingControls(prev => { return {...prev, animation: value}})
            setTimeout(() => {
                setDisableAnimationClick(false) //should give 5sec to update animation in UI
            }, 5000);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableAnimationClick]);
    const handlePreview = (e) => {
        let value = (e?.currentTarget?.dataset?.value);
        handleGalleryView && handleGalleryView(value);
    }
    const handleCaptionChange = useCallback((e) => {
        if(!settingControls?.captionCard?.disableControl) {
            let value = Number(e?.currentTarget?.dataset?.value);
            setSettingControls(prev => { return {...prev, captionCard: {...prev?.captionCard, time: value}}})
        }       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard])

    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 settings-control`}>
            {/* For Mobile only  */}
            <ControlTitleCard className="settings_icon_MR">
                <SettingsIcon className={``} />
                <span >{`Settings Control`}</span>
            </ControlTitleCard>
            {showEnhanced && 
                <ControlBody className="enhanced-section">
                    <ControlTitleCard>
                        <div className="flex gap-4 items-center">Enhanced Content
                            <ToolTip className="tooltip-container-right-aligned mt-1" message={'Playing multiple pieces simultaneously can slow down your device. Keep Off to display static images.'}>
                                <InformationIcon/>
                            </ToolTip>
                        </div>
                </ControlTitleCard>
                    <ControlContent>
                        <span onClick={!settingControls?.enhancedContent?.onChainRenders ? () => {} : handlEnhancedClick} data-value={0}
                        className={`click-link-tab cursor-pointer ${!settingControls?.enhancedContent?.onChainRenders && 'text-active'}`}>{`Off`}</span>
                        <span  onClick={settingControls?.enhancedContent?.onChainRenders ? () => {} : handlEnhancedClick} data-value={1}
                        className={`click-link-tab cursor-pointer ${settingControls?.enhancedContent?.onChainRenders && 'text-active'}`}>{`On`}</span>
                    </ControlContent>
                </ControlBody>
            }
            {showTransition &&
                <ControlBody className={` transition-section`}>
                    <ControlTitleCard>{`Transition`}</ControlTitleCard>
                    <ControlContent>
                        <span data-value={'fade'} onClick={handleAnimationChange}
                        className={`click-link-tab ${disableAnimationClick ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.animation === 'fade' && 'text-active'}`}>{`Fade`}</span>
                        <span data-value={'slide'} onClick={handleAnimationChange}
                        className={`click-link-tab ${disableAnimationClick ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.animation === 'slide' && 'text-active'}`}>{`Slide`}</span>
                    </ControlContent>
                </ControlBody>
            }
            {(showCaption && galleryType === 'meta') &&
                <ControlBody className="caption-section">
                    <ControlTitleCard>
                        <div className="flex gap-4 items-center">Caption Card
                        <ToolTip className="tooltip-container-right-aligned mt-1" message={'Cycle mode displays a caption card for a few seconds every minute. Keep On to display indefinitely.'}>
                            <InformationIcon/>
                        </ToolTip>
                        </div>
                    </ControlTitleCard>
                    <ControlContent>
                        <span data-value={0} onClick={handleCaptionChange}
                         className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === 0 && 'text-active'}`}>{`None`}</span>
                        <span data-value={1} onClick={handleCaptionChange}
                        className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === 1 && 'text-active'}`}>{`Cycle`}</span>
                        <span data-value={-1} onClick={handleCaptionChange}
                        className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === -1 && 'text-active'}`}>{`On`}</span>
                    </ControlContent>
                </ControlBody>
            }
            <ControlBody className="caption-section">
                    <ControlTitleCard>{`View`}</ControlTitleCard>
                    <ControlContent>
                        <span data-value={'mono'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'mono' && 'text-active'}`}>{`Mono`}</span>
                        <span data-value={'multi'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'multi' && 'text-active'}`}>{`Multi`}</span>
                        <span data-value={'meta'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'meta' && 'text-active'}`}>{`Meta`}</span>
                    </ControlContent>
                </ControlBody>
                <MobileControlClose onClick={onHandleSettingsControl && onHandleSettingsControl}>Close</MobileControlClose>          
        </ControlCard>
    )
}
export default SettingsControl;