import Card from "App/Components/Card";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import { Fragment, useCallback } from "react";
import "./RentalGalleries.scss";
import { useNavigate } from "react-router-dom";
import { creatorShareUrls } from "App/Helper/shareUrls";
import { getPreviewGalleryLink } from "App/Helper/utilities";

const RentalGalleries = ({ loading, gallery }) => {
  const navigate = useNavigate();
  const handleCardClick=(id,slug,communitySlug)=>{
  navigate(`/discover/all-creators/${communitySlug}/gallery/${id}`)
  }
  const shareUrl = useCallback((communitySlug,id)=>{
  return creatorShareUrls("creatorsGalleryDetails",communitySlug,id,"gallery","all-creators")
  },[])
  const handlePreview = (id, previewType, e) => {
    e.stopPropagation();
    getPreviewGalleryLink(id, previewType);
}
  return (
    <div
      className={`w-full overflow-flex-auto flex flex-col justify-between rental-galleries`}
    >
      {loading ? (
        <DetailsSkeltonCard
          show={true}
          height={"100%"}
          count={9}
          inline={true}
          details={"1-line"}
          noAbsolute
          parentDiv="grid home-grid  aspect-pt-five-six details-gap"
          containerClassName={`flex`}
        />
      ) : gallery?.length ? (
        <div
          className={`sm:pt-0 pt-4 cursor-pointer grid gap-2.5 display-grid`}
        >
          {gallery?.map((info, i) => (
            <Fragment key={i}>
              <div key={i} data-id={info?.id} className={`cursor-pointer`}>
                <Card
                  key={i}
                  cardProp={{ ...info, name: info.name }}
                  type="Gallery"
                  handlePreview={handlePreview}
                  preview={true}
                  playGallery
                  shareUrl={shareUrl(info?.community?.slug,info?.id)}
                  handleCardClick={handleCardClick}
                  displayPreview={true}
                />
              </div>
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="visibleSlowly flex items-center no-data justify-center">{`There are currently no rentals galleries.`}</div>
      )}
    </div>
  );
};
export default RentalGalleries;
