import GradientSlider from 'App/Components/GradientSlider';
import './InputColor.scss';
import { useEffect, useRef, useState } from "react";
const InputColor = ({className = '', onChange, value, useGradientSlider = false}) => {
    const [colorValue, setColorValue] = useState(value);
    const [gradientSliderVisible, setGradientSliderVisible] = useState(false);
    const inputRef = useRef(null)
    useEffect(() => {
        setColorValue(value);
    }, [value])
    const handleClick = () => {
        if (useGradientSlider)
        {
            setGradientSliderVisible(!gradientSliderVisible);
        }
        else
        {
            inputRef?.current?.click();    
        }        
    }
    const colorChange = (e) => {
        let value = e?.target?.value;
        setColorValue(value);
        onChange(value);
    }
    return (
        <div className={`input-color cursor-pointer relative ${className}`} style={{background: colorValue}} onClick={handleClick}>
            {useGradientSlider && <GradientSlider visible={gradientSliderVisible} swallowClicks={true} colorValue={colorValue} colorChange={colorChange} >

            </GradientSlider>}
            {!useGradientSlider && <input type="color" className="opacity-0"  ref={inputRef} onChange={colorChange}
                value={colorValue}
            />}
        </div> 
    )
}
export default InputColor;