import ReusableDropdown from "App/Components/Ui/DropDown/ReusableDropDown";

const MultiOptionsDropDown = (props) => {
  return (
    <ReusableDropdown
      options={props?.options}
      mainButtonClass={`plus_button-style`}
      withMainButtonContent={props?.withMainButtonContent}
      dropDownPositionTop={props?.dropDownPositionTop}
      dropDownPositionLeft={props?.dropDownPositionLeft}
      mainButtonContent={
       props?.mainButtonContent || <img src={props?.icon} alt="plus" className={"mt-1"} />
      }
    />
  );
};

export default MultiOptionsDropDown;
