import { Fragment, useCallback, useEffect } from "react";
import { useState } from "react";
import './DropDown.scss'
import { useRef } from "react";

const DropDown = (props) => {
    const popupRef = useRef();
    const [show, setShow] = useState(false);
    const focusHandler = () => {
        setShow(prev => !prev);
    }

    const blurHandler = () => {
        setShow(false);
    }

    const onMouseBlur = useCallback(() => {
        setShow(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.onAction]);

    const onSelectHandler = e => {
        props.onSelect(e.target.dataset['elem'])
    }
    const profileImageMouseEnter = () => {
        props?.onAction && props?.onAction()
      };
    
    return (
        <div
            className={`dropdown-select ${show ? 'open-popup' : 'close-popup'} ${props?.className}`}
            onClick={focusHandler}
            onMouseOverCapture={onMouseFocus}
            onMouseLeave={onMouseBlur}
            onBlur={blurHandler}
            tabIndex={-1}
            ref={popupRef}
        >
            <Fragment>
                <div className={'user-avatar-box'}
                    onMouseEnter={profileImageMouseEnter}
                >
                    {<div 
                        className={`profile-box ${!props.profilePicUrl ? 'no-profile-box' : ""} cursor-pointer`}>
                        <div className={`profile-pic ${!props?.data?.profilePicUrl256&&"no-pro-pic"}`} style={{ backgroundImage: `url(${props.profilePicUrl ?? props.userAvatar})` }}>
                        </div>
                    </div>}
                </div>

                <div className="wallet-select-available drop-down" style={{ display:  'block' }}>
                    <ul className="drop-down-items">
                        {props?.elements.map((ele, index) => {
                            return (
                                <li key={ele?.name} data-elem={ele?.value} onClick={onSelectHandler} className='font-13 flex items-center'>
                                    {ele?.icon && 
                                        <>
                                            <div className='non-hover-icon wallet-select-available-icon'><img data-elem={ele?.value} src={ele?.icon} alt='icon' className='' /></div>
                                            <span className='hover-icon wallet-select-available-icon'><img data-elem={ele?.value}  src={ele?.activeIcon} alt='icon' className='' /></span>                                    
                                        </>
                                    }
                                    <span className="wallet-select-available-label" data-elem={ele?.value} >{ele?.name}</span>
                                </li>
                            )    
                        })}
                    </ul>
                </div>
            </Fragment>
        </div>
    );
};

export default DropDown;



// import './DropDown.scss'
// import { createRef, useCallback, useEffect } from 'react'

// const DropDown = props => {
//     const select = createRef()
//     useEffect(() => {
//         applyToggle()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [props.open])
//     const applyToggle = useCallback(() => {
//         const parent = select.current;
//         const list = parent.querySelector('.drop-down-items');
//         if (props.open) {
//             parent.classList.remove('drop-down-closed');
//             parent.classList.add('drop-down-open');
//             list.style.maxHeight = `${list.scrollHeight}px`;
//         } else {
//             parent.classList.remove('drop-down-open');
//             parent.classList.add('drop-down-closed');
//             if (list)
//                 list.style.maxHeight = `0px`;
//         }
//     }, [props.open, select])

//     const onSelectHandler = e => {
//         props.onSelect(e.target.dataset['elem'])
//         onBlur()
//     }

//     const onBlur = () => {
//         props.onBlur()
//     }
//     useEffect(() => {
//         applyToggle();
//     }, [applyToggle])

//     return <div>
//         {props.open && <div className='overlay' onClick={onBlur}></div>}
//         <div className={`drop-down ${props?.className}`} ref={select} onBlur={onBlur} tabIndex="-1">
//             <ul className="drop-down-items">
//                 {props?.elements.map((ele, index) => {
//                     return (
//                             <li key={ele?.name} data-elem={ele?.value} onClick={onSelectHandler} className='font-14 flex items-center gap-2'>
//                                 {ele?.icon && 
//                                  <>
//                                     <div className='non-hover-icon'><img data-elem={ele?.value} onClick={onSelectHandler} src={ele?.icon} alt='icon' className='drop-icon' /></div>
//                                     <span className='hover-icon'><img data-elem={ele?.value} onClick={onSelectHandler} src={ele?.activeIcon} alt='icon' className='drop-icon' /></span>                                    
//                                  </>
//                                 }
//                                 <span data-elem={ele?.value} onClick={onSelectHandler}>{ele?.name}</span>
//                             </li>
//                     )    
//                 })}
//             </ul>
//         </div>
//     </div>
// }

// export default DropDown