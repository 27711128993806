import DisplayUpdate from "./Components/DisplayUpdate";
import DisplayList from "./Components/DisplayList";
import "./Display.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import useProfileApiHelper from "../UserProfile/Components/Apis";
import { getAccessGalleryCreation } from "App/Helper/utilities";
import useInfiniteScroll from "App/hooks/use-infiniteScroll";

const Display = ({isHome}) => {   
  const [pairShow, setPairShow] = useState(false);
  const [displayList, setDisplayList] = useState([]);
  const [anyWallGalleryCountWarningPopup, setAnyWallGalleryCountWarningPopup] = useState(false);
  const [isSubscriptionReached, setSubscriptionReached] = useState(false);
  const { getProfile } = useProfileApiHelper();
  const [{ currentUserId }, dispatch] = useStore(false);
  const { triggerAPI } = useHttp();
  const isLoggedIn = useAuth()[0];
  const itemsPerPage = 9;
  const [initialLoading, setInitialLoading] = useState(true); 
  const [totalItems, setTotalItems] = useState(1); 
  const [pageNo, setPageNo] = useState(1); 
  const tabs = useMemo(() => {
    let tabList = [
        { id: 0, element: "Galleries", url: "/curate/display/my-galleries" },
        { id: 1, element: "Displays", url: "/curate/display" }            
    ];
    
    if (process.env.REACT_APP_COMPANY !== 'anywall')
    {
        tabList.push({ id: 2, element: "Rentals", url: "/curate/rentals" });
    }
    return tabList
  }, []);

  const fetchMoreData = useCallback(() => {
    if (pageNo * itemsPerPage >= totalItems) {
        setIsFetching(false);
        return;
    }
    setPageNo(prev => prev + 1);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [pageNo, totalItems]);

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreData, {elementId: 'diplay-list-pagei'});
  const getDisplaysForUserResult = useCallback((res) => {
    const { list , pageMeta: {totalItems}} = res.data;
    setDisplayList(prev => [...prev, ...list]);
    setTotalItems(totalItems)
    getProfileDetails(res?.data?.length,{noPairing:true})   
    setInitialLoading(false); 
    setIsFetching(false); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisplaysForUser = useCallback(() => {
    setIsFetching(true)
    triggerAPI(
      {
        url: `display/listforuserid?userId=${currentUserId}&page=${pageNo}&items=${itemsPerPage}`,
        method: "get"        
      },
      getDisplaysForUserResult
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDisplaysForUserResult, triggerAPI, currentUserId, pageNo]);

  useEffect(() => {
    if (currentUserId) {
      getDisplaysForUser();
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, pageNo]);
  useEffect(() => {
    dispatch("setTopMenuBarOptions", {showMenuIcons:false , showTopMenus: true})
    dispatch('enableTopIcons', { userGuidePopup: !isHome });
    dispatch("setMenuOptions", isLoggedIn?tabs:[]);
    dispatch("setActiveMenuOptions", isHome?-1:isLoggedIn?1:0);
    return () => {
      //to reset all icons
      dispatch('resetAll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs,isLoggedIn]);

  const pairClick=()=> {
    setPairShow(true);
  }
  const reseData = () => {
    setDisplayList([]);
    setInitialLoading(true);
    if(pageNo === 1)
      getDisplaysForUser();
    setPageNo(1);
  }
  const onClose=()=> {
    setPairShow(false);
    reseData();
  }

  const getProfileDetails=(galleryLength,status)=>{
    getProfile((res)=>{
      setSubscriptionReached(res?.isSubscriptionLimitReached);
      let anyWallGalleryListedLength =galleryLength || displayList?.length || 0;
      let access = getAccessGalleryCreation(anyWallGalleryListedLength, res?.anywallDisplayCount);
      if(access){
        setAnyWallGalleryCountWarningPopup(true);
        }else{
          setAnyWallGalleryCountWarningPopup(false);
         !status?.noPairing && pairClick();
      }
    })
  }
  return (
    <>
      <div className="display-page page-shadow h-full pb-16 sm:pb-2 pl-4 pr-4 sm:pr-4 page-shadow pt-4 overflow-auto"id={`diplay-list-pagei`}>
        { (<DisplayList loader={isFetching || initialLoading} initialLoading={initialLoading}
        anyWallGalleryCountWarningPopup={anyWallGalleryCountWarningPopup} totalItems={totalItems}
        isSubscriptionReached={isSubscriptionReached} displayList={displayList} 
        pairClick={getProfileDetails} currentUserId={currentUserId} isHome={isHome}></DisplayList>)} 
      </div>
      {pairShow && (<DisplayUpdate onClose={onClose}></DisplayUpdate>)}
    </>
  );
};

export default Display;