const moment = require("moment");
const getDateTime = (value) => {
    const dateTime = new Date(value).toDateString() + ',' + new Date(value).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    return dateTime
}
export const formatDate = (date, format) => {
    return moment(date).format(format)
}

export const getDisplayDate = (date) => {
    return formatDate(date, 'DD MMM YYYY')
}

export default { getDateTime, formatDate, getDisplayDate }

