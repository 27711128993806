import { useCallback, useEffect, useState } from "react";
import GalleryImages from "../../../../Components/Ui/GalleryFrameImages";
import "./ViewGallery.scss";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import useScreenSize from "App/hooks/use-screen-size";
import ImagesTab from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/ImagesTab";
import FrameitTab from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/FrameitTab";
import storage from 'App/Helper/storage'
import useAuth from 'App/hooks/use-auth';
import AttributesTab from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/AttributesTab";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import Logo from "App/Components/Logo";
import CollectionDetailsTab from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/CollectionDetailsTab";
import ChevronLeftIcon from "App/Components/IconsComponents/ChevronLeftIcon";
import ChevronRightIcon from "App/Components/IconsComponents/ChevronRightIcon";
import MdEnlargeViewIcon from "App/Components/IconsComponents/MdEnlargeViewIcon";
import DecenLargeViewIcon from "App/Components/IconsComponents/DecenLargeIcon";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const ViewGallery = (props) => {
  const screenSize = useScreenSize()?.width;
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const { triggerAPI } = useHttp();
  const [activeTab, setActiveTab] = useState("details");
  const [frameApplied, setFrameApplied] = useState(null)
  const screenHeight = useScreenSize()?.height;
  const screenRatio = screenSize/screenHeight
  const [freeFrameList, setFreeFrameList] = useState({ list: [], totalItems: 0 })
  const [purchasedList, setPurchasedList] = useState({ list: [], totalItems: 0 })
  const [allFrameList, setAllFrameList] = useState({ list: [], totalItems: 0 })
  const [previewUrl, setPreviewUrl] = useState('')
  const [aspectRatio, setAspectRatio] = useState(null)
  const [{ currentWallet }, dispatch] = useStore(false);
  const [freeFrameLoading, setFreeFrameLoading] = useState(true)
  const [enlargedView, setEnlargedView] = useState(false);
  const [metaFilesArray, setMetaFilesArray] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState(null);
  const isLoggedIn = useAuth()[0]
  const isLoggedInGuest = useAuth()[4]
  const [mouseMoving, setMouseMoving] = useState(false);
  let timeout;

  const getCollectionDataResult = (res) => {
    const result = res?.data;
    const collectionName = result?.name;
    const category = result?.category;
    const description = result?.description;

    setCollectionDetails(result);
    
    const data = props?.selectedListView[0];
    data.collectionName = data?.collectionName ?? collectionName;
    data.category = data?.category ?? category;
    data.description = data?.description ?? description;
    data.community = result?.community;
    data.blockchainType = result?.blockchainType;
    data.aspectRatio = result?.shapes.map(item => item.aspectRatio) ?? [];
    setData(data);
    setTimeout(()=>{setLoader(false)},400)
  };
  const getCollectionData = (policy) => {    
    setLoader(true);
    const params = new URLSearchParams({
      collectionId: policy
      });        
    triggerAPI(
      {
          url: `community/collection/get-one?${params}`,            
          method: "get",
      },
      getCollectionDataResult, (err)=>{setLoader(false)}
    );
  };
  // useEffect(() => {
  //   if (props?.selectedListView?.length) {
  //     getCollectionData(props?.selectedListView[0]?.policy);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.selectedListView]);
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
const applyFrameResult = useCallback((res) => {
  dispatch('hideSpinner');
  props?.onReload(props?.selectedListView[0]?.asset)
}, [dispatch, props])
const getPurchasedList = useCallback(() => {

  let page = 1;
  let temp = [];
  const triggerFetchFreeFrame = () => {
      const data = {
          page: page,
          items: 999999,
          filter: {categoryId: [3],sortBy:"modernWestern", isUserAdded: true}
      }
      triggerAPI({
          url: `frame-asset/user/get`, method: 'post', data
      }, (res) => {
          const { list, pageMeta } = res.data;
          let arr_temp = list.map(frame => {
              return {
                  id: frame.id,
                  name: frame.name,
                  imageUrl: frame.s3ImageUrl,
                  imageUrlLand: frame?.s3ImageUrlLandscape,
                  imageUrl256: frame.s3ImageUrl256,
                  imageUrlLand256: frame?.s3ImageUrlLandscape256,
                  imageUrl512: frame.s3ImageUrl512,
                  imageUrlLand512: frame?.s3ImageUrlLandscape512,
                  price: frame.price ? frame.price : "00",
                  inStock: 30,
                  metaInfo: frame.metaInfo,
                  isLandscape: false
              }
          })
          temp = [...temp, ...arr_temp]
          if (temp.length < pageMeta.totalItems) {
              page++;
              triggerFetchFreeFrame();
          } else {
              setPurchasedList({
                  list: temp,
                  totalItems: pageMeta.totalItems
              })
              setFreeFrameLoading(false);
          }

      });
  }

  triggerFetchFreeFrame();


  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, triggerAPI])

const getFreeFrameList = useCallback(() => {

  let page = 1;
  let temp = [];
  const triggerFetchFreeFrame = () => {
      const data = {
          "page": page,
          "items": 999999,
          filter: { categoryId: [2],sortBy:"modernWestern" }
      }
      triggerAPI({
          url: `frame-asset/user/get`, data: data, method: 'post'
      }, (res) => {
          const { list, pageMeta } = res.data;
          let arr_temp = list.map(frame => {
              return {
                  id: frame.id,
                  name: frame.name,
                  imageUrl: frame.s3ImageUrl,
                  imageUrlLand: frame?.s3ImageUrlLandscape,
                  imageUrl256: frame.s3ImageUrl256,
                  imageUrlLand256: frame?.s3ImageUrlLandscape256,
                  imageUrl512: frame.s3ImageUrl512,
                  imageUrlLand512: frame?.s3ImageUrlLandscape512,
                  price: frame.price ? frame.price : "00",
                  inStock: 30,
                  metaInfo: frame.metaInfo,
                  isLandscape: false
              }
          })
          temp = [...temp, ...arr_temp]
          if (temp.length < pageMeta.totalItems) {
              page++;
              triggerFetchFreeFrame();
          } else {
              setFreeFrameList({
                  list: temp,
                  totalItems: pageMeta.totalItems
              })
              setFreeFrameLoading(false);
          }
          setFreeFrameList({
            list: temp,
            totalItems: pageMeta.totalItems
        })
      });
  }

  triggerFetchFreeFrame();


  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, triggerAPI])

const reloadFrameHandler = useCallback(() => {
  getFreeFrameList()
  const token = storage().get('token')
  if ((isLoggedInGuest || isLoggedIn) && token) {
      getPurchasedList()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentWallet?.address]);
useEffect(() => {

  if (!!props.selectedListView[0])
      setFrameApplied(props?.selectedListView[0]?.frameId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.nft])
const applyFrameHandler = useCallback((e) => {
  if (isLoggedIn) {

      let data = {
          "asset": props?.selectedListView[0]?.asset,
          "networkType": "MAINNET",
          "frameId": e ? e.id : null,
          "isLandscape": e ? e?.isLandscape : false,
      }
      let url
      let method
      if (!!e) {
          url = `user/nft-collection/frame-it/add`
          method = 'post'
      }
      else {
          url = `user/nft-collection/frame-it/remove`
          method = 'delete'
          delete data.isLandscape;
      }
      dispatch('showSpinner')
      triggerAPI({
          url: url, data: data, method: method
      }, applyFrameResult,()=>{
        dispatch("hideSpinner");
    });
  } else {
      props?.setShowAuthPop(true);
  }
}, [dispatch, applyFrameResult, triggerAPI, props, isLoggedIn])
useEffect(() => {
  getFreeFrameList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props?.nft?.image])
useEffect(() => {
  const token = storage().get('token')
  if ((isLoggedInGuest || isLoggedIn) && token) {
      getPurchasedList();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props?.nft?.image, currentWallet?.address])
const calculateAspectRatio = () => {
  if (props?.selectedListView && props?.selectedListView[0]?.nftcdnimage512) {
    const img = new Image();
    img.src = props.selectedListView[0].nftcdnimage512;

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      setAspectRatio(aspectRatio);
    };
  }
};

useEffect(() => {
  if (props?.selectedListView?.length) {
    getCollectionData(props?.selectedListView[0]?.policy);
    calculateAspectRatio(); // Call the function to calculate aspect ratio
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props?.selectedListView]);


const previewFrameHandler = e => {
        let data = e
        if (e && e.metaInfo?.portrait) {
            data = { ...e }
            data.metaInfo = e && e?.isLandscape ? e?.metaInfo?.landscape : e?.metaInfo?.portrait;
            data.image = (e && e?.isLandscape) ? (e?.imageUrlLand ?? e?.imageUrl) : e?.imageUrl;
            data.image512 = (e && e?.isLandscape) ? (e?.imageUrlLand512 ?? e?.imageUrl512) : e?.imageUrl512;
            data.image256 = (e && e?.isLandscape) ? (e?.imageUrlLand256 ?? e?.imageUrl256) : e?.imageUrl256;
        }
        setFrameApplied(data)
    }
    useEffect(() => {
      let audioTypeRemovedFiles = props?.galleryNew?.[0]?.assetMetadata?.files?.filter(f=>f.mediaType?.indexOf("audio") === -1)
      let newPreviewUrl = props?.galleryNew?.[0]?.nftcdnimage512;
      if(audioTypeRemovedFiles?.length) {
        setMetaFilesArray(audioTypeRemovedFiles)
        newPreviewUrl = audioTypeRemovedFiles?.[0]?.nftcdnfile;
      }
      setPreviewUrl(newPreviewUrl);
    }, [props?.galleryNew]);
    useEffect(() => {
      setAllFrameList({ list: [...purchasedList?.list, ...freeFrameList?.list], totalItems: freeFrameList?.totalItems + purchasedList?.totalItems })
  }, [purchasedList, freeFrameList]);

  // const showFrame = useMemo(()=>{
  //   return pathname?.includes(`/discover/collectors/details`) ? false : pathname?.includes(`/community`) ? false : true;
  // },[pathname]);
  useEffect(() => {
    const handleMouseMove = (e) => {
      e.preventDefault();
      setMouseMoving(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => setMouseMoving(false), 1500);
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      { !enlargedView &&     <div className="flex flex-col gap-0 h-[100vh] p-[1.5rem]">
      <div className={`${props.className} view-gallery-all flex  w-full gap-[2rem]`}>
        <div className={` flex flex-col align-center ${screenRatio > 2 ? 'h-[77vh]' : 'h-[83vh]'}  `}>
          <div className="frame-container-gallery flex  ">
            <div className="h-full w-full aspect-square square-container">
              <GalleryImages
                key={previewUrl}
                clearCache={false}
                frameToggle={props?.frameToggle}
                isDetailedView={true}
                galleryNew={props?.nft?.nft ?? props?.galleryNew?.[0]}
                src={previewUrl}
                clicked={true}
                className="h-[100%] overflow-hidden aspect-square"
                nftGalleryNew={true}
                framePreview={true}
                onChainRenders={props?.onChainRenders}
                imageFileToUse={metaFilesArray?.length ? metaFilesArray?.filter(f=> f.nftcdnfile === previewUrl)?.[0] : null}
                playAudio={true}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-[3rem] absolute bottom-[1rem] justify-center items-center  w-[100vw] ml-[-1.5rem]">
          <ChevronLeftIcon className="chevron-container" onClick={props?.selectPreviousNft}/>
          <MdEnlargeViewIcon className="chevron-container-center" onClick={()=>setEnlargedView(true)}/>
          <ChevronRightIcon className="chevron-container" onClick={props?.selectNextNft}/>
        </div>
        <div className="w-[100%] min-w-[30vw]">
        {!props?.zoom && (
          <div className={`${screenSize <= 550 && props?.type === "collectors" && "bp-none"}  flex flex-col gap-[1rem] overflow-hidden h-[77vh]  `}>
            <div className={`description-tab  flex flex-col  min-h-[3.3125rem]`}>
              <div className="flex  justify-between">
                {!!(props?.selectedListView[0]?.collectionName!==undefined&&props.selectedListView[0]?.community!==undefined)
                ?
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <div className="flex gap-2 items-center">
                      <img
                        style={{
                          borderRadius: "50%",
                          height: "1.5rem",
                          width: "1.5rem",
                        }}
                        className="object-cover"
                        src={props?.selectedListView[0]?.community?.displayPicture256 || Logo()}
                      alt="" />
                    </div>
                    <div className="collection-title truncate flex flex-col justify-center">
                      {props?.selectedListView[0]?.collectionName || (props?.selectedListView[0]?.collectionName!==undefined&&"NA")}
                    </div>
                    {/* <div className="data flex gap-[0.28571rem] items-center text-[#B8C0CC]">
                      <BlockChainIcon classes={`w-[1.2rem]`} policyId={props?.selectedListView[0]?.policy}/>
                      <FormatField
                        type="substring"
                        maxLength={20}
                        startLength={4}
                        endLength={4}
                        value={props?.selectedListView[0]?.contractAddress}
                      />
                      <CopyIcon className={"cursor-pointer"} onClick={handleCopy} />
                    </div> */}
                  </div>
                  <div className="text-left text-[0.875rem] text-[#F7F8F9] pl-[2.5rem]">
                    <p>{props.selectedListView[0]?.community?.name || (props.selectedListView[0]?.community!==undefined&&"Unknown")}</p>
                  </div>
                </div>
                :  
                <DetailsSkeltonCard
                show={true}
                height={"100%"}
                count={1}
                inline={true}
                noAbsolute
                parentDiv="profile-img-with-2-lines"
                details="profile-img-with-2-lines"
                containerClassName={` flex pt-[1rem]`}
                /> }
                <div className="close-div  absolute top-[1rem] right-[1rem] justify-end h-[1.5rem] w-[1.5rem]">
                  <div className="close-icon close-visible" onClick={props?.onClose}></div>
                </div>
              </div>
            </div>
            <div className="common_style-tab-container mobileTab">
              <div className={`tab-data flex gap-[1.42857rem]`}>
                <div className={`tab ${activeTab === "details" ? "active" : "not-active"}`} onClick={() => handleTabChange("details")}>
                  Details
                </div>
                <div className={`tab ${activeTab === "collection-details" ? "active" : "not-active"}`} onClick={() => handleTabChange("collection-details")}>
                  Collection
                </div>
                {/* <div
                  className={`tab ${activeTab === "tab2" ? "active" : ""}`}
                  onClick={() => handleTabChange("tab2")}
                >
                  Frames
                </div> */}
                {/* <div
                  className={`tab ${activeTab === "tab3" ? "active" : ""}`}
                  onClick={() => handleTabChange("tab3")}
                >
                  Trading History
                </div> */}
              </div>
            </div>
            {activeTab === "details" && (
            <div className={` description-tab overflow-hidden`}>
              <div className="asset-title sm:pb-[.75rem] pb-[7px]">
                <div className="">{props?.selectedListView[0]?.name}</div>
              </div>
              <div className="description-details border-[#191D23] border-b pb-4">
                <div className="description-data flex flex-col w-full">
                  <div className="title">Description</div>
                  {loader 
                    ? 
                    <div className="w-full flex flex-col">
                      <SkeletonLoader className={`w-[95%] h-3 mr-[5%] rounded-lg`} count={1} />
                    </div> 
                    : 
                    <div className="data">
                      {!!(data?.description) ? (
                        Array.isArray(data?.description) ? (
                          data?.description.map((line, index) => (
                            <span key={index}>
                              {line}{' '}
                            </span>
                          ))
                        ) : (
                          <div>{data?.description}</div>
                        )
                      ) :
                        <span className="visibleSlowly">No description</span>
                      }
                    </div>}
                </div>
              </div>
                <div className="description-details2 overflow-y-scroll overflow-x-hidden">
                  <div className="description-details2">
                    <div className="header">Information</div>
                    <AttributesTab nft={props.selectedListView[0]} isGallery={true} />
                  </div>
                </div>
              {/* <div className="buy-container">
                  <div className="flex w-full items-center gap-[0.5rem]"> 
                    <img className="w-[2rem]" alt="Img" src={Ada} />
                    <span className="ada-price"> 30 </span>
                  </div>
                  <div className="w-full h-full"> 
                    <RoundedButton className="buy-btn" Name="Buy Now"> </RoundedButton>
                  </div>
                </div> */}
              </div>
              )}
              {activeTab === "collection-details" && <CollectionDetailsTab localCollectionData={collectionDetails} aspectRatios={data?.aspectRatio} collectionDetails={data}/>}
              {activeTab === "tab2" &&
              <div className={`h-[100%]`}>
                  <FrameitTab 
                  reloadFrameHandler={reloadFrameHandler} 
                  defaultFrame={frameApplied} 
                  selectedFrame={frameApplied} 
                  onApply={applyFrameHandler} 
                  onPreview={previewFrameHandler} 
                  nft={props.selectedListView[0]} 
                  freeFrameList={allFrameList} 
                  freeFrameLoading={freeFrameLoading} 
                  aspectRatio={aspectRatio}
                  applyFrameButton={props.applyFrameButton}
                  />
              </div>}
            </div>)}
          </div>
        </div>
        <ImagesTab showFileType className="pop-up-filetype-wrapper"  nft={props?.selectedListView[0].assetMetadata} previewUrl={previewUrl}
        setPreviewUrl={setPreviewUrl}/>              
      </div>}
      {enlargedView &&
      <div className="h-[100vh] view-gallery-all flex flex-col ">
        <div className={` flex flex-col align-center  h-[100vh] `}>
          <div className="frame-container-gallery enlarged-content">
            <div className="h-full w-full">
              <GalleryImages
                key={previewUrl}
                clearCache={false}
                frameToggle={props?.frameToggle}
                isDetailedView={true}
                galleryNew={props?.nft?.nft}
                src={previewUrl}
                clicked={true}
                className="h-[100%] overflow-hidden w-full"
                nftGalleryNew={true}
                framePreview={true}
                onChainRenders={props?.onChainRenders}
                imageFileToUse={metaFilesArray?.length ? metaFilesArray?.filter(f=> f.nftcdnfile === previewUrl)?.[0] : null}
                playAudio={true}
              />
            </div>
          </div>
          <div className="close-div  absolute top-[1rem] right-[1rem] justify-end h-[1.5rem] w-[1.5rem] z-[100]">
              <div className={`close-icon ${mouseMoving ? 'close-visible' : ''}`} onClick={props?.onClose}></div>
          </div>
        </div>
        <div className="chevron-group">
          <ChevronLeftIcon className={`chevron-container-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={props?.selectPreviousNft}/>        
          <DecenLargeViewIcon className={`chevron-container-center-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={()=>setEnlargedView(false)}/>
          <ChevronRightIcon className={`chevron-container-enlarged cursor-pointer ${mouseMoving ? 'chevron-visible' : ''}`} onClick={props?.selectNextNft}/>  
        </div>
      </div>}
    </>
)
};

export default ViewGallery;