import styles from "./UserProfile.module.scss";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, NavLink } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import Banner from "App/Components/Banner";
import { hasActivePath, scrollToTop } from 'App/Helper/utilities'
import { collectorProfileRoutes } from "App/Helper/routings";
export default function UserProfile() {
  const { pathname } = useLocation();
  const screenSize = useScreenSize()?.width;
  // eslint-disable-next-line no-unused-vars
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [profileNavToggle, setProfileNavToggle] = useState(false);
  const [{ topMenuList }, dispatch] = useStore();
  const store = useStore()[0];
  const { pageTitle } = store;
  let navElements = useMemo(()=>{
    let tabLists=[];
    if (process.env.REACT_APP_COMPANY === 'anywall')
      {
        tabLists = [
          {
            id: 2,
            element: "Subscription",
            url:collectorProfileRoutes("subscription")
          },      
          {
            id: 7,
            element: "Wallets",
             url:collectorProfileRoutes("wallets")
          }      
        ]
      }
      else
      {
        tabLists = [{
            id: 1,
            element: "Collector Profile",
            activeLinkBase:[collectorProfileRoutes("profileWithTab","my-galleries"),collectorProfileRoutes("profileWithTab","details")],
             url:collectorProfileRoutes("profileWithTab","my-galleries")
          },
          {
            id: 2,
            element: "Subscription",

             url:collectorProfileRoutes("subscription")
          },
          {
            id: 4,
            element: "Purchases",

            url:collectorProfileRoutes("purchase")
          },
          {
            id: 6,
            element: "Preferences",

             url:collectorProfileRoutes("preferences")
          },
          {
            id: 7,
            element: "Wallets",

             url:collectorProfileRoutes("wallets")
          },
        ]
      }  
      return tabLists
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[process.env.REACT_APP_COMPANY])


  //Menu options
  useEffect(() => {
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!topMenuList?.length) {
      if (screenSize > 550) {
        dispatch("setTopMenuBarOptions", {
          showMenuIcons: false,
          showTopMenus: true,
        });
        dispatch("setMenuOptions", navElements);
        const index = navElements.findIndex((obj) =>
          pathname?.includes(obj.url) || hasActivePath(obj?.activeLinkBase,pathname)
        );
        dispatch("setActiveMenuOptions", index);
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topMenuList,navElements]);

  // //Menu options
  // useEffect(() => {
  //   const currentMenu = navElements[topActiveMenu];
  //   if (currentMenu && currentMenu?.element === 'Profile') {
  //     navigate(`${currentMenu?.link}/${tab ?? `my-galleries`}`);
  //     if(pathname?.includes(`my-galleries`)){
  //       navigate(-1);
  //     }
  //   } else if (currentMenu) {
  //     navigate(`${currentMenu?.link}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [topActiveMenu,navElements]);
  const profileNavToggleHandler = () => {
    setProfileNavToggle(prevState => { return !prevState })
    if (screenSize < 551) {
        setTimeout(() => {
            scrollToTop();
        }, 1000);
    }

}
  return (
    <div
      className={`${styles["main-frame"]} ${pageTitle?.replace(/\s+/g, "-")} `}
    >
  <div className={`${styles["baner"]} min-h-[1.43rem] sm:hidden block`}>
        <Banner className="h-full  ">
            <div className='flex h-full '>
                <div className={`${styles["mobile-head"]} flex  justify-start items-center`}>
                    {
                        (navElements)?.map(elem => (<div key={elem.id}>
                            <NavLink className={({ isActive }) => (isActive||hasActivePath(elem?.activeLinkBase,pathname) ? styles['linkActive'] : '') + " w-full p-2.5 pb-0 nav-link"}
                                onClick={profileNavToggleHandler} to={elem?.url}>
                                {elem.element}
                            </NavLink></div>))
                    }
                </div>
            </div>
        </Banner>
      </div>
      
      <div
        className={`${styles["frame"]}   
           mt-[1.43rem] sm:mt-0
        `}
      >
        <div className={styles.profile}>
          <div
            className={`${styles["sub-content"]} h-full flex gap-2.5 relative`}
          >
            <div id={'collector-container'}
              className={`${styles.content} ${!pathname?.includes('collector/profile') && 'p-[1rem] sm:pr-[1.8125rem]'} ${
                profileNavToggle && styles["content-display"]
              }`}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
