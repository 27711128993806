/* eslint-disable react-hooks/exhaustive-deps */
import "./CollectionDetailRight.scss";
import Border from "../../../Border";
import userAvatar from "Assets/Images/Logo.svg";
import DetailRightPanel from "../..";
import { useState, useEffect, useCallback } from "react";
import Card from "App/Components/Card";
import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import useShapes from "App/hooks/use-shapes";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { formatLargeNumber, numberWithCommas, truncateText } from "App/Helper/utilities";
import useAuth from "App/hooks/use-auth";
import ShareUrl from "App/Components/ShareUrl";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Logo from "App/Components/Logo"
import BlockChainIcon from "App/Components/Ui/BlockchainIcon"
import { getBlockchainIcon } from "App/Helper/blockchain"
import { thousandCommaSeparator } from 'App/Helper/utilities';

const CollectionDetailRight = (props) => {
  const [shapes, setShapes] = useState(shapesEmpty);
  const [isCollectionCurated, setIsCollectionCurated] = useState(false); // whether the collection is curated or not
  const [isUserCurate, setIsUserCurate] = useState(false); // whether the user is curated or not
  const isLoggedIn = useAuth()[0]
  const { getShapes } = useShapes();
  const { triggerAPI } = useHttp();
  const [
    {           
       isCurator    
    },dispatch
  ] = useStore(false);
  const [loader, setLoader] = useState(true);
  const getCurator = useCallback(() => {
        triggerAPI({
            url: `curator/get-user`, method: 'get',
        }, (res)=>{
            const curatorId = res?.data?.id;
            setIsUserCurate(!!curatorId)
        });

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [triggerAPI]);

useEffect(() => {
    if(isLoggedIn){
        getCurator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isLoggedIn])
  useEffect(() => {
    setIsCollectionCurated(props?.selectedCollection?.isCurated)
    let clearTimer;
    if(!!props?.selectedCollection){
      clearTimer = setTimeout(()=>setLoader(false), 1200);
    }
    return ()=> clearTimeout(clearTimer)
  }, [props?.selectedCollection]);


  useEffect(() => {    
    let ratios = props?.selectedCollection?.shapes?.length ? props?.selectedCollection?.shapes?.map(shape => Number(shape.aspectRatio)) : props?.selectedCollection?.aspectRatios?.split(','); 
    setShapes(getShapes(ratios));
  }, [props?.selectedCollection])
  const showBannerHandler = ()=>{
    let bannerImg= props?.selectedCollection?.community ? props?.selectedCollection?.filePath256 : props?.selectedCollection?.showcase || false;
    return bannerImg
  }
  const handleCurate= (collection) => {
  
        const data = {
            collectionId: collection?.id,
        }
        const url = isCollectionCurated ? 'remove' : 'add';
        const method = 'POST';
        dispatch('showSpinner');
        triggerAPI({
            url: `curator/collection/${url}`, method, data
        }, (res) => {
            dispatch('hideSpinner');
            props?.resetCollectionHandler && props.resetCollectionHandler()
            setIsCollectionCurated(!isCollectionCurated)
        }, () => {
            dispatch("hideSpinner");
        });
    
}
  return (
    <DetailRightPanel>
        <div className= {` pt-4 bg-[#0D0F11] w-full`}>
            <div className= {` sm:flex gap-4  collection-details-right`}>
                
                    {props?.isCollection ? 
                    <img src={props?.selectedCollection?.community ? props?.selectedCollection?.filePath256 : props?.selectedCollection?.showcase || userAvatar} alt='collection' className={`w-[100%] sm:w-[11rem] sm:h-[11rem] aspect-square rounded-md ${showBannerHandler()?"object-cover":"object-contain"}`}/>
                    :
                    <div className="h-[11rem] aspect-square rounded-md">
                    <Card
                    cardProp={props.selectedCollection}
                    type="Gallery"
                    playGallery={props?.playGallery}
                    preview={true}
                    hideDetails={props?.hideDetails}
                    className='no-info'
                    handlePreview={props?.handlePreview}
                  />
                  </div>
                 }
                <div className={` ${props.className} collection-detail-container`}>
                    <div className='flex justify-between items-center min-h-[2.09375rem]'>
                        <div>
                            <div className='collection-title sm:mt-0 mt-4 flex gap-[1rem] items-center'>
                                <span>{props?.selectedCollection?.name}</span>

                             {props?.selectedCollection?.community?.name &&   <div className='items-end'>
                                    <p className="community-name ">by {props?.selectedCollection?.community?.name}</p>
                            </div>}
                            {!!props?.shareUrl && <ShareUrl url={props?.shareUrl} />}
                            {!!(isLoggedIn&&isCurator&&props?.selectedCollection?.community?.id&&isUserCurate)&&
                                <div className='flex'>
                                    <button className='action-small-container  ' onClick={()=>handleCurate(props?.selectedCollection)}>
                                        {isCollectionCurated ? 'Curated' : 'Curate'}
                                    </button>
                                </div>
                            }
                            </div>
                            <Border className={'right-panel-v-border'} />
                        </div>
                        <div className='flex gap-[0.625rem] items-start'>
                            
                        {!props?.hideCloseOptn&&                   
                        <div className="clickable-links"
                        style={{
                          
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          textDecorationLine: "underline",
                          cursor: "pointer",}} onClick={props?.backHandler}>
                            {props?.isCollection ? 'All Collections' : 'All Galleries'}
                            </div>}
                        </div>
                    </div>
                    <div className='collection-attributes-container flex flex-col'>
                        <div className='collection-attributes flex w-full max-h-[3.5rem]'>

                            {/* Floor box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Floor</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      {props?.selectedCollection?.metrics[0]?.floorPrice ? <span className={`flex items-center gap-1`}> <img
                                          className={`common_image_style`}
                                          alt="Img"
                                          src={adaIcon}
                                      /> {numberWithCommas(props?.selectedCollection?.metrics[0]?.floorPrice)}</span> : <p className="data-value">-</p>}
                                  </div>)}
                              </div>
                              {/* Volume box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Volume</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      {props?.selectedCollection?.metrics[0]?.allVolume ? <span className={`flex items-center gap-1`}> <img
                                          className={`common_image_style`}
                                          alt="Img"
                                          src={adaIcon}
                                      /> {formatLargeNumber(props?.selectedCollection?.metrics[0]?.allVolume) ?? '-'}</span> : <p className="data-value">-</p>}
                                  </div>)}
                              </div>
                              {/* Pieces box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Pieces</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value flex`}>
                                      {numberWithCommas(props?.selectedCollection?.metrics[0]?.supply ?? props?.selectedCollection?.assetCount) ?? "-"}
                                  </div>)}
                              </div>
                              {/* Owners box */}
                              {props?.isCollection &&
                                  <div className={`common_coll__data-box`}>
                                      <p className={`data-title`}>Owners</p>
                                      {loader ? (
                                          <SkeletonLoader height={15} count={1} inline={true} />
                                      ) : (<div className={`data-value flex`}>
                                          {numberWithCommas(props?.selectedCollection?.metrics[0]?.owners ?? props?.selectedCollection?.ownerCount) ?? "-"}
                                      </div>)}
                                  </div>
                              }
                              {/* Shapes box */}
                              <div className={`common_coll__data-box data-box-last`}>
                                  <p className={`data-title`}>Shapes</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      <img
                                          className={`shapes_box-style`}
                                          alt="Img"
                                          src={shapes}
                                      />
                                  </div>)}
                              </div>
                        </div>
                    </div>
                    <div className='collection-description'>
                        {props?.selectedCollection?.description ?
                            <>
                                {truncateText(props?.selectedCollection?.description, 50)}..
                                <span 
                                    className="learn-more-link ml-[.5rem] clickable-links" 
                                    onClick={() => props?.setActiveTab("tab2")}
                                >
                                    Learn More
                                </span>
                            </>
                            : <></>
                        }
                    </div>
               </div>
               <div className="sm:hidden flex flex-col px-[.25rem] py-1 gap-[.375rem] w-full">
                    <div className="flex gap-[.75rem] w-full">
                        <img style={{borderRadius: "50%", height:"1.875rem", width:"1.875rem"}} src={props?.selectedCollection?.community?.displayPicture256 || Logo()} alt="icon" />
                        <div className="mt-[.2rem] w-full">
                            <div className="nft-gallery-head mr-3  nft-name-gallery">
                                {props?.selectedCollection?.name}
                            </div>
                            <div className="w-full flex justify-between text-left text-[#A0ABBB] text-[.875rem]">
                                <div className="pt-2">
                                    {props?.selectedCollection?.community?.name || 'Unknown'}
                                </div>
                                <div className="flex items-center gap-6 mt-2">
                            {props?.selectedCollection?.blockchainType?.code ? 
                                <img
                                    className={`w-[1.2rem]`}
                                    alt="Img"
                                    src={getBlockchainIcon(props?.selectedCollection?.blockchainType?.code)}
                                />
                                :
                                <BlockChainIcon classes={`w-[1.2rem]`} loaderH={'1.05rem'} policyId={props?.selectedCollection?.contractAddress} />
                                }
                                <div className={`nft-price flex`}>
                                <span className={`dot-separator`}>{`${thousandCommaSeparator(props?.selectedCollection?.assetCount)} piece${(props?.selectedCollection?.assetCount!==1)?"s":""}`}</span>
                                </div>
                                </div>
                            </div>   
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </DetailRightPanel>
)}


export default CollectionDetailRight;