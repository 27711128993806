import { TwitterShareButton, FacebookShareButton, FacebookIcon, TelegramShareButton, 
  WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon,
  RedditShareButton } from "react-share";
import "./ShareSocialMedia.scss";
import CopyIcon from "../IconsComponents/CopyIcon";
import { copyClipHandler } from "App/Helper/utilities";
import TelegramIcon from "../IconsComponents/TelegramIcon";
import RedditIcon from "../IconsComponents/RedditIcon";
import XIcon from "../IconsComponents/XIcon";

const ShareSocialMedia = ({ share,handleClose }) => {
  const copyHandler = (e) => {
    copyClipHandler(share);    
    handleClose(e)
  };
  return (
    <div className="flex items-center gap-4 share-wrapper">
      <div className="share-box" onClick={handleClose}>
        <TwitterShareButton url={share}>
         <XIcon/>
        </TwitterShareButton>
      </div>
      <div className="share-box" onClick={handleClose}>
        <FacebookShareButton url={share}>
          <FacebookIcon round={true} />
        </FacebookShareButton>
      </div>
      <div className="share-box" onClick={handleClose}>
        <TelegramShareButton url={share}>
          <TelegramIcon/>
        </TelegramShareButton>
      </div>
      <div className="share-box" onClick={handleClose}>
        <WhatsappShareButton url={share}>
          <WhatsappIcon round={true} />
        </WhatsappShareButton>
      </div>
      <div className="share-box" onClick={handleClose}>
        <LinkedinShareButton url={share}>
          <LinkedinIcon round={true} />
        </LinkedinShareButton>
      </div>
      <div className="share-box" onClick={handleClose}>
        <RedditShareButton url={share}>
          <RedditIcon/>
        </RedditShareButton>
      </div>
      <div>
        <div className="copy-box">
          <CopyIcon className={"cursor-pointer"} onClick={copyHandler} />
        </div>
      </div>
    </div>
  );
};
export default ShareSocialMedia;
