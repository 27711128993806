/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Main.module.scss";
import Header from "../Header";
import NavBar from "App/Components/Navbar";
import RoutesMain from "../../MainRoutes";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Toaster from "App/Components/Ui/Toaster";
import Spinner from "App/Components/Ui/Spinner";
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import homeMenu from "Assets/Icons/TopMenu/home-icon-menu.svg";
import userInfo from "Assets/Icons/TopMenu/userInfo.svg";
import wallet from "Assets/Icons/TopMenu/mob-wallet.svg";
import { categorySort, getAccessGalleryCreation, scrollToTop } from "App/Helper/utilities";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenSize from "App/hooks/use-screen-size";
import collectionIcon from 'Assets/Icons/my-collection-icon.svg'
import collectorsIcon from 'Assets/Icons/collectors-icon.svg'
import useHttp from "App/hooks/use-http";
import displayIcon from 'Assets/Icons/display-icon.svg'
import Twitter from "Assets/Icons/x.svg";
import Youtube from "Assets/Icons/utube.svg";
import Discord from "Assets/Icons/w-discord.svg";
import activites from "Assets/Icons/activities.svg";
import Listen from 'Assets/Icons/listen-icon.svg'
import company from "Assets/Images/cur8-sm.svg";
import knowledgeBase from "Assets/Images/knowledge.svg";
import anywall from "Assets/Images/anywall.png";
import artist from 'Assets/Icons/Art.svg';
import photographer from "Assets/Icons/Photography.svg";
import music from "Assets/Icons/music.svg";
import profile from "Assets/Icons/profile.svg";
import createAccount from "Assets/Icons/indication.svg";
import curatorsIcon from "Assets/Icons/curators.svg";
import { useDispatch, useSelector } from "react-redux";
import { getColelctionSpinner } from "App/ReduxStore/Slicers/spinSlice";
import MusicPlayer from "App/Components/MusicPlayer";
import Draggable from 'react-draggable';
import UserGuidePopup from "App/Components/Ui/UserGuidePopup";
import PageLoader from "App/Components/PageLoader";
import TemporaryRoutes from "App/MainRoutes/TemporaryRoutes";
import FloatingCreateGallery from "./Components/Status/FloatingCreateGallery";
import { setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import splashWhiteIcon from "Assets/Icons/splash-white-icon.svg";
import instagramIcon from "Assets/Icons/instagram-icon.svg";
import videoIcon from "Assets/Icons/utube.svg";
import { getInitial } from "App/Helper/formValidation";
import PageMeta from "App/Components/PageMeta";
import Footer from "../Footer";
import Popup from "App/Components/Ui/Popup";
import ShareSocialMedia from "App/Components/ShareSocialMedia";
import Instruction from "App/Components/Instruction";
import useProfileApiHelper from "App/Pages/UserProfile/Components/Apis";
import privacyIcon from "Assets/Icons/lock-icon.svg";
import termsIcon from "Assets/Icons/file-icon.svg";

const Main = () => {
  const isLoggedIn = useAuth()[0];
  const { pathname } = useLocation();
  const headerRef = useRef();
  const sideMenuRef = useRef();
  const [sidebarTransitionCompleted,setSidebarTransitionCompleted] = useState(true)
  const collectionSpinner = useSelector(getColelctionSpinner);
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const { getProfile } = useProfileApiHelper();
  const { triggerAPI } = useHttp();
  const [{fullPageLoader, spinnerMode, toast, leftMenuCollapse, currentWallet, collections, showInstruction,createdGalleryCount, galleryCreateCount,
    collectionLoadingStatus, collectionWalletData, showMusicPlayerIcon ,showSharePopup,shareUrl}, dispatch] = useStore();

    const shareModalClose = (e) => {
      e.stopPropagation()
      if(showSharePopup){
        dispatch("setShareUrl","")
      }
      dispatch("setSharePopUp",!showSharePopup)
    };
  const [category, setCategory] = useState([]);
  const [walletHasNFTs, setwalletHasNFTs] = useState(false);  
  const handleB2CLogin = useAuth()[6];    
  useEffect(() => {          
    setwalletHasNFTs(!!currentWallet?.NFTs?.length);          
}, [currentWallet])
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list])
      setCategory(cateList);
    },
    [dispatch]
  );
  
  const getProfileAPI = (callback) => {
    getProfile((data) => {
      if(data?.availableGalleriesCount > data?.createdGalleryCount){
        callback && callback(true)
      }
    })
  }


  useEffect(()=>{
  if(isLoggedIn && pathname.includes("my-galleries/view")){
    getProfileAPI();
  }
  },[isLoggedIn, pathname]);
  useEffect(()=>{
  if(isLoggedIn){
    getProfileAPI();
  }
  },[isLoggedIn]);

  const showCreateGalleryFloatingButton= useMemo(()=>{
    if(!pathname?.includes("my-galleries/add") && !pathname?.includes("my-galleries/edit")){
      if(!!createdGalleryCount && !!galleryCreateCount && collectionWalletData?.length){
        return getAccessGalleryCreation(galleryCreateCount, createdGalleryCount);
      }
      return false
        }
    return false
  },[galleryCreateCount, createdGalleryCount,pathname,collectionWalletData])

const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

useEffect(()=>{
    if (!pathname?.includes("displaygallery"))
    {
      getCategoryList()
    }    
},[pathname])
  const navElements = useMemo(()=>{

  // const showSpecificExploreItems = ['art', 'photography', 'music', 'literature']
    
  // let categoryNav= category?.sort((a, b) => {
  //   return showSpecificExploreItems.indexOf(a.slug) - showSpecificExploreItems.indexOf(b.slug);
  // })?.filter(el=>showSpecificExploreItems?.includes(el?.slug))?.map((val,i)=>{
  //   let link={
  //     id: 1000+i,
  //     element: val?.name,
  //     link: `explore/${val?.slug}`,
  //     icon: val?.icon,
  //     activeLinkBase:`discover/collection/${val?.slug}/details`
  //   }
  //   if(i===0){
  //     link.header= "Explore Collections"
  //   }
  //   return link
  // })    
  let fSet=[];
  let sSet=[];    
  let resources = []
  let partners = []
  let links = []

  if (process.env.REACT_APP_COMPANY === 'anywall' )
  {
    fSet = [{
        id: 1,
        element: "Home",
        link: "home",
        icon: homeMenu,
      },     
      // {
      //   id: 2,
      //   element: "Manage",
      //   header: "My Collection",
      //   icon: collectionIcon,
      //   walletCheck:true,
      //   link: "curate/collections/my-collections",      
      // },  
      
      // {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",      
      // },    
      {
        id: 7,
        element: "Display",
        header: "My Collection",
        //divider:true,
        icon: displayIcon,
        link: isLoggedIn?"curate/display/my-galleries":"curate/display",
        activeLinkBase:["curate/display"],
      },   
      // {
      //   id: 40,
      //   element: "Activities",
      //   link: "curate/activities/posts",
      //   icon: activites,
      //   activeLinkBase:"curate/activities"
      // },   
    ]  

    if(!isLoggedIn) {  // removing tabs except display and adding login when the user is not logged in
   
      fSet=fSet.filter((el)=>el.element==="Home");
      fSet.splice(1,0, {
        id: 2,
        element: "Login/Sign up",
        header: "My Collection",
        icon: profile,
        method: handleB2CLogin,
      })
    }
    if(!isLoggedIn && walletHasNFTs)  { // adding collection  if the user is not logged in but connected wallet
        fSet.splice(2,0,{
          id: 3,
          element: "Manage",
          icon: collectionIcon,
          walletCheck:true,
          link: "curate/collections/my-collections",
        })
    }

    sSet = []               
    //   {
    //     id: 2003,
    //     header: "Discover",
    //     element: "Artists",
    //     link: "discover/artists",
    //     icon: artist,
    //   },      
    //   {
    //     id: 2005,
    //     element: "Photographers",
    //     link: "discover/photographers",
    //     icon: photographer,
    //   }
    // ]
    resources = [
      {
        id: 2008,
        header: "Resources",
        element: "Company Site",
        link: "https://anywall.io",
        icon: anywall,
        newTab:true
      },
      {
        id: 2009,
        element: "User Guide",
        link: "/knowledge-base/user-guide",
        icon: knowledgeBase,
      }, 
      // {
      //   id: 20090,
      //   element: "Tutorials",
      //   link: "/knowledge-base/tutorials",
      //   icon: videoIcon,

      // },
      {
        id: 20009,
        element: "Create an Account",
        link: "/knowledge-base/create-an-account",
        icon: createAccount,
        checkFullUrl:true
      }    ,
      {
        id: 20010,
        element: "Terms of Use",
        link: "knowledge-base/terms-of-use",
        icon: termsIcon,
        checkFullUrl:true
      },
      {
        id: 20011,
        element: "Privacy Policy",
        link: "knowledge-base/privacy-policy",
        icon: privacyIcon,
      } 
    ]
    
    links = [
      {
        id: 2012,
        header: "Follow Us",
        verticalAlign:true,
        linkLists:[
          { id:1,
            link: "https://twitter.com/AnyWall_Art",
            icon: Twitter,
            newTab:true
          }
        ]      
      }
    ]
  }
  else
  {
    fSet=[{
        id: 1,
        element: "Home",
        link: "home",
        icon: homeMenu,
      },
      // {
      //   id: 1,
      //   element: "Collections",
      //   link: "collections/my-collections",
      //   icon: collectionIcon,
      // },
      // {
      //   id: 2,
      //   element: "Galleries",
      //   link: "curate/my-galleries",
      //   icon: curateSvg,
      // },
      // {
      //   id: 3,
      //   element: "Announcements",
      //   link: "announcements",
      //   icon: announcementsIcon,
      // },
      // {
      //   id: 4,
      //   element: "Events",
      //   link: "events",
      //   icon: eventsSvg,
      // },
      // {
      //   id: 5,
      //   element: "Home",
      //   link: "home",
      //   icon: homeMenu,
      // },
      // {
      //   id: 6,
      //   element: "Collections",
      //   link: "collections/my-collections",
      //   icon: collectionIcon,
      // },
      {
        id: 2,
        element: "Manage",
        header: "My Collection",
        icon: collectionIcon,
        walletCheck:true,
        activeLinkBase:["curate/frame-shop"],
        link: "curate/collections/my-collections",
        class:"manage-joy"
        // subNav: [
        //   {
        //     element: "My Collections",
        //     link: "collections/my-collections",
        //   },
        //   {
        //     element: "My Galleries",
        //     link: "curate/my-galleries",
        //   },
        //   {
        //     element: "Frames",
        //     link: "frame-shop",
        //   },
        // ],
      },
     
      // {
      //   id: 11,
      //   element: "CollectionV2",
      //   icon: collectionIcon,
      //   link: "collections/collectionsv2",
      //   // subNav: [
      //   //   {
      //   //     element: "My Collections",
      //   //     link: "collections/my-collections",
      //   //   },
      //   //   {
      //   //     element: "My Galleries",
      //   //     link: "curate/my-galleries",
      //   //   },
      //   //   {
      //   //     element: "Frames",
      //   //     link: "frame-shop",
      //   //   },
      //   // ],
      // },
      {
        id: 7,
        element: "Display",
        // header: "Play",
        //divider:true,
        icon: displayIcon,
        link: isLoggedIn?"curate/display/my-galleries":"curate/display",
        activeLinkBase:["curate/display"],
        class:"galleries-joy"
      },
      //  {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",
      //   // subNav: [
      //   //   {
      //   //     element: "Collections",
      //   //     link: "discover/collections",
      //   //   },
      //   //   {
      //   //     element: "Creators",
      //   //     link: "discover/creators",
      //   //   },
      //   //   {
      //   //     element: "Collectors",
      //   //     link: "discover/collectors",
      //   //   },
      //   // ],
      // },
      // {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",
      //   // subNav: [
      //   //   {
      //   //     element: "Collections",
      //   //     link: "discover/collections",
      //   //   },
      //   //   {
      //   //     element: "Creators",
      //   //     link: "discover/creators",
      //   //   },
      //   //   {
      //   //     element: "Collectors",
      //   //     link: "discover/collectors",
      //   //   },
      //   // ],
      // },
      // {
      //   id: 4,
      //   element: "Frames",
      //   link: "curate/frame-shop",
      //   icon: framesIcon,
      // },
      // {
      //   id: 5,
      //   element: "Posts",
      //   link: "curate/posts",
      //   walletCheck:true,
      //   // externalLink: true,
      //   icon: postsIcon,
      // },
      // {
      //   id: 6,
      //   element: "Events",
      //   link: "curate/events",
      //   walletCheck:true,
      //   // externalLink: true,
      //   icon: eventsSvg,
      // },
    
      // {
      //   id: 7,
      //   element: "Display",
      //   // header: "Play",
      //   divider:true,
      //   icon: displayIcon,
      //   link: "display",
      // },
      {
        id: 9,
        element: "Listen",
        link: "listen/collections/my-collections",
        icon: Listen,
        walletCheck:true
      },
      // {
      //   id: 8,
      //   element: "Read",
      //   link: "read/collections/my-collections",
      //   icon: Read,
      //   walletCheck:true      
      // },
      {
        id: 40,
        element: "Activities",
        link: "curate/activities/posts",
        icon: activites,
        activeLinkBase:["curate/activities"],
        walletCheck:true
      },
      // {
      //   id: 5,
      //   element: "Community",
      //   icon: communityMenu,
      //   link: "",
      //   // section: true,
      //   subNav: [
      //     {
      //       element: "Announcements",
      //       link: "announcements",
      //     },
      //     {
      //       element: "Events",
      //       link: "events",
      //     },
      //   ],
      // },]
    ]  

    if(!isLoggedIn) {  // removing tabs except display and adding login when the user is not logged in

      fSet=fSet.filter((el)=>el.element==="Home");
      fSet.splice(1,0, {
        id: 2,
        element: "Login/Sign up",
        header: "My Collection",
        icon: profile,
        method: handleB2CLogin,
      })
    }
    if(!isLoggedIn && walletHasNFTs)  { // adding collection  if the user is not logged in but connected wallet
        fSet.splice(2,0,{
          id: 3,
          element: "Manage",
          icon: collectionIcon,
          walletCheck:true,
          link: "curate/collections/my-collections",
        })
    }

    sSet = [
      //   {
      //   id: fSet?.length+categoryNav?.length+1,
      //   element: "All",
      //   link: "discover/collections",
      //   icon: allSvg,
      //   header: "Explore"
      // },
   
      {
        id: 2002,
        header: "Discover",
        // element: "All Creators",
        // link: "discover/all-creators",
        // icon: Community,
        parentClass:"discover-joy"
      },
      {
        id: 2003,
        element: "Artists",
        link: "discover/creators/artists",
        icon: artist,
        activeLinkBase:["discover/creators/artists","discover/collection/art"],
      },
      // {
      //   id: 2004,
      //   element: "Onchain",
      //   link: "discover/onchain",
      //   icon: onchain,
      // },
      {
        id: 2005,
        element: "Photographers",
        link: "discover/creators/photographers",
        icon: photographer,
        activeLinkBase:["discover/creators/photographers","discover/collection/photography"],
      },
      {
        id: 2006,
        element: "Musicians",
        link: "discover/creators/musicians",
        icon: music,
        activeLinkBase:["discover/creators/musicians","discover/collection/music"],
      },
      {
        id: 2007,
        element: "Collectors",
        link: "discover/collectors",
        icon: collectorsIcon,
      },
      {
        id: 20070,
        element: "Curators",
        link: "discover/curators",
        icon: curatorsIcon,
      }
      // {
      //   id: 2011,
      //   element: "Information",
      //   link: "/information-desk",
      //   icon: infoIcon,
      // },
      // {
      //     id: 5,
      //     element: "Recycler",
      //     link: "https://portal.theartbank.io/recycle",
      //     externalLink: true,
      //     icon: recyleMenu
      // },
    ]
    resources = [
      // {
      //   id: 2008,
      //   header: "Resources",
      //   element: "Corporate Site",
      //   link: process.env.REACT_APP_COMPANY_URL,
      //   icon: company,
      //   newTab:true,
      //   parentClass: "resources-joy"
      // },
      {
        id: 2011,
        header: "Resources",
        element: "Anywall",
        link: "https://www.anywall.io",
        icon: anywall,
        newTab:true
      },
      {
        id: 20090,
        element: "Video Tutorials",
        link: "/knowledge-base/tutorials",
        icon: videoIcon,

      },
      {
        id: 2009,
        element: "User Guide",
        link: "/knowledge-base/user-guide",
        icon: knowledgeBase,
      }, 

      {
        id: 20009,
        element: "Create an Account",
        link: "knowledge-base/create-an-account",
        icon: createAccount,
      },
      {
        id: 20010,
        element: "Terms of Use",
        link: "knowledge-base/terms-of-use",
        icon: termsIcon,
        checkFullUrl:true
      },
      {
        id: 20011,
        element: "Privacy Policy",
        link: "knowledge-base/privacy-policy",
        icon: privacyIcon,
      }
    ]
    // partners = [
    //   // {
    //   //   id: 2010,
    //   //   header: "Partners",
    //   //   element: "Monet Society",
    //   //   link: "https://www.splash.club/organization/the-monet-society",
    //   //   icon: monet,
    //   //   newTab:true
    //   // },

    //   {
    //     id: 20111,
    //     element: "Splash",
    //     link: "https://www.splash.club",
    //     icon: splashIcon,
    //     newTab:true
    //   }
    // ]
    links = [
      {
        id: 2012,
        header: "Follow Us",
        verticalAlign:true,
        linkLists:[
          { id:1,
            link: "https://twitter.com/Cur8Labs",
            icon: Twitter,
            newTab:true
          },
          { id:2,
            link: "https://www.instagram.com/cur8_io?igsh=OGQ5ZDc2ODk2ZA==",
            icon: instagramIcon,
            newTab:true
          },
          {
            id:3,
            link: "https://www.youtube.com/@cur8app",
            icon: Youtube,
            newTab:true
          },{
            id:4,
            link: "https://discord.com/invite/vHqfQ5vPFd",
            icon: Discord,
            newTab:true
          },
          // {
          //   id:4,
          //   link: "https://www.splash.club/community/cur8",
          //   icon: splashWhiteIcon,
          //   newTab:true
          // },
        ]      
      }
    ]
  }
  
  if (process.env.REACT_APP_COMPANY === 'anywall')
  {    
    //categoryNav = categoryNav.filter(el=>el.element?.toLowerCase()==='art' || el.element?.toLowerCase()==='photography');    
    // categoryNav = [];
  }
   return [
    ...fSet,...sSet,...resources,...partners,...links    
  ]},[category, isLoggedIn, walletHasNFTs, collections])

  const [navExpand, setNavExpand] = useState(false);
  const [mainNavNotExpanded, setMainNavNotExpanded] = useState(false);
  const [sidebarNotPinned, setSibebarNotPinned] = useState(false);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  // const [mobileNavElements, setMobileNavElements] = useState([])
  
  // useEffect(() => {
  //   if (isPortrait && screenSize <= 550) {
  //     let navEl = navElements;
  //     let fIndex = navEl.findIndex((el) => el.element === "Community");
  //     navEl.splice(fIndex);
  //     if (isLoggedIn) {
  //       navEl = [
  //         ...navEl,
  //         {
  //           id: 10,
  //           header: true,
  //           element: "Collectors",
  //           link: "discover/collectors",
  //           icon: collectorsIcon,
  //         },
  //         {
  //           id: 11,
  //           element: "User",
  //           subNav: [
  //             {
  //               element: "Collector",
  //               link: "collector/profile/my-galleries",
  //             },
  //             {
  //               element: "Creators",
  //               link: "community",
  //             },
  //           ],
  //           icon: userInfo,
  //         },
  //         {
  //           id: 12,
  //           element: "Wallets",
  //           subNav: [
  //             {
  //               element: "Manage",
  //               link: "collector/wallet-authentication",
  //             },
  //             // {
  //             //   element: "Refresh",
  //             //   link: "",
  //             // },
  //           ],
  //           icon: wallet,
  //         },
  //       ];
  //     }
  //     setMobileNavElements(navEl);
  //   } 
  //   // else {
  //   //   let el = navElements;
  //   //   // let fIndex = el.findIndex((ele) => ele.element === "Collectors");
  //   //   // el.splice(fIndex + 1, 2);

  //   //   el = [
  //   //     ...el,
  //   //   ];

  //   //   setNavElements(el);
  //   // }
  // }, [isPortrait, screenSize, isLoggedIn]);
  
  // useEffect(() => {
  //   if (isPortrait && screenSize <= 550) {
  //     document.getElementById("mainContent").style.filter = navExpand
  //       ? `grayscale(100%)`
  //       : "none";
  //   }else{
  //     document.getElementById("mainContent").style.filter =  "none";
  //   }
  // }, [navExpand,isPortrait,screenSize]);

  const navExpandHandler = () => {
    setTimeout(() => {
      scrollToTop();
    }, 1000);
    setNavExpand((prevState) => {
      return !prevState;
    });
  };
  const mainNavExpandHandler = () => {
   if(sidebarNotPinned){
    setMainNavNotExpanded((prevState) => {
      return !prevState;
    });
   }
  };
  
  const mainNavExpandHandleShow = () => {
    setMainNavNotExpanded(false);
  };
  useEffect(() => {
    dispatch("setLeftMenuCollapse", mainNavNotExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainNavNotExpanded]);
  const checkFilter = useMemo(() => {
    return (
      Number(document.getElementById("header-bar")?.offsetHeight || 0) > 60
    );
  }, [document.getElementById("header-bar")?.offsetHeight]);
  const updateHeight = useCallback(() => {
    const root = document.documentElement;
    if (headerRef.current) {
      root?.style.setProperty(
        "--top-menu-height",
        `${headerRef.current.offsetHeight}px`
      );
    }
    if (sideMenuRef.current) {
      let sideWidth = sideMenuRef.current.offsetWidth;
      root?.style.setProperty("--right-menu-width", `${sideWidth}px`);
    }
  }, [leftMenuCollapse]);
  useLayoutEffect(() => {
    updateHeight();
    const handleResize = () => {
      setTimeout(() => {
        updateHeight();
      }, 1000);
    };    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [leftMenuCollapse]);

  useEffect(() => {
    setNavExpand(false);
  }, []);

  const onPinSidebarHandler=()=>{
    setSibebarNotPinned((pre)=>!pre)
  }

//   useEffect(()=>{
// let mainNav=document.getElementById("sidebar");
// if(mainNavNotExpanded){
//   mainNav.style.position="static"
// }else {
//   mainNav.style.position="relative"
// }
//   },[mainNavNotExpanded])
const mouseEnteronSidebarHandler = () => {
  if(sidebarNotPinned&&sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return false;
    });
    let timer= setTimeout(()=>{
      setSidebarTransitionCompleted(true)
    },500)
    return ()=>clearTimeout(timer)
   }
}
const mouseLeavefromSidebarHandler = () => {
  if(sidebarNotPinned&&sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return true;
    });
    let timer= setTimeout(()=>{
      setSidebarTransitionCompleted(true)
    },500)
    return ()=>clearTimeout(timer)
   }else if(sidebarNotPinned&&!sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return true;
    });
      setSidebarTransitionCompleted(true)
   }
}
const createdGalleryButtonDisabled= useMemo(()=>{
if(collectionLoadingStatus!=="finished"){
  return true
}
return false
},[collectionLoadingStatus])

const clearAll = useCallback(() => {
  dispatch('setCurrentGalleryUpdateId', '');
  dispatch('setCurrentSelectedNfts', [])
  dispatch('setCurrentList', [])
  dispatch('setCurrentGallery', {})
  dispatch('setCurrentFormValues', getInitial({}))
  dispatchRedux(setCurrentFormValues(getInitial({})))
  return ()=>{
     
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
const createGalleryButtonHandler=()=>{
  getProfileAPI((status)=>{
     if(status&&!createdGalleryButtonDisabled){
            navigate("/curate/display/my-galleries/add/collection");
            dispatchRedux(setFormInitiated(true));
            clearAll();
     }
  });
}

const instructingSteps = [
  {
    title: 'Profile Setup',
    content: 'Select Creator or Collector, or set up both profiles.',
    target: '.profile-dropdown',
    disableBeacon: true,
  },  {
    title: 'Wallet Connection',
    content: 'Connect and link your wallet to your profile to access all of your collections..',
    target: '.wallet-select',
    disableBeacon: true,
  }, {
    title: 'Synchronize',
    content: 'Sync your wallets when collections don’t seem to load properly.',
    target: '.reload-icon-joy',
    disableBeacon: true,
  }, {
    title: 'Manage Assets',
    content: 'View and organize your collections however you’d like.',
    target: '.manage-joy',
    disableBeacon: true,
    placement: 'right'
  }, {
    title: 'Discover',
    content: 'See what different communities are up to, including artists, photographers, musicians, collectors, and curators.',
    target: '.discover-joy',
    disableBeacon: true,
    placement: 'right'
  }, {
    title: 'Create Galleries',
    content: 'Use Display to set up Galleries in Mono, Multi, or Meta view. Share them or cast to screens..',
    target: '.galleries-joy',
    disableBeacon: true,
    placement: 'right'
  }, {
    title: 'Resources',
    content: 'Visit here for more tutorials and guides.',
    target: '.resources-joy',
    disableBeacon: true,
    placement: 'right'
  }]
  return (
    <>
     <Instruction steps={instructingSteps} isRun={!!showInstruction} />
    {/* <div className="sm:hidden block h-full">
      <TemporaryRoutes/>
      </div> */}
    <div className={`flex-col h-full`}>
      <PageMeta />
      <Spinner show={!!spinnerMode || !!collectionSpinner} className="spinner" size={150} />
      <Toaster {...toast} />
      <UserGuidePopup/>
      {fullPageLoader && <PageLoader className={styles['full-page-loader']} />}
      <div
        className={`header-wrapper flex items-center relative ${
          navExpand && "grayscale"
        }`}
        ref={headerRef}
      >
        <Header
          onHamburgerClick={navExpandHandler}
          className={styles["menu-bar"]}
          navExpand={navExpand}
        />
      </div>
      <div className={`${styles["content-area-height"]} content-area`}>
        <div className={`${styles["min-content-height"]} flex h-full relative`}>
          <><div
            className={`flex h-full flex-col ${styles["nav-bar"]} nav-bar ${
              styles["desktop-nav"]
            } ${mainNavNotExpanded ? styles["active"] : ""}`}
            ref={sideMenuRef}
            onMouseEnter={mouseEnteronSidebarHandler}
            onMouseLeave={mouseLeavefromSidebarHandler}
          >
            <NavBar
              classList=" border-y-0 h-full"
              NavTitle="Home"
              mainNavNotExpanded={mainNavNotExpanded}
              sidebarNotPinned={sidebarNotPinned}
              onMainNavCollapse={mainNavExpandHandler}
              Elements={navElements}
              isMainNav={true}
              setSibebarNotPinned={setSibebarNotPinned}
              onPinSidebarHandler={onPinSidebarHandler}
              mainNavExpandHandleShow={mainNavExpandHandleShow}
            ></NavBar>
            
          </div>
          <div
            onClick={navExpandHandler}
            className={`${styles["nav-overlay"]} ${
              navExpand && styles["nav-active"]
            }`}
          ></div>
          <div
            className={`flex h-full flex-col ${styles["nav-bar"]} ${
              styles["mobile-nav"]
            } ${navExpand && styles["nav-active"]} ${
              isLoggedIn ? styles["nav-in"] : styles["nav-out"]
            }`}
          >
            <NavBar
              onNavCollapse={navExpandHandler}
              classList=" border-y-0 h-full"
              NavTitle="Home"
              Elements={navElements}
            ></NavBar>
          </div>
          </>               
          <div
            className={`${styles["content"]} ${isLoggedIn && styles["user-loggedin"]} ${
              pathname.includes("rewards") || pathname.includes("purchase")
                ? styles["pt-4-3"]
                : ""
            } ${!checkFilter && styles["filter-less"]} ${
              mainNavNotExpanded ? styles["active"] : ""
            }`}
            id="mainContent"
          >
            <RoutesMain />
            {process.env.REACT_APP_COMPANY !== 'anywall' && !!showMusicPlayerIcon && 
              <Draggable>
                <div className={`${styles["draggable-music-icon"]} ${styles["floating-section-wrapper"]} ${!showCreateGalleryFloatingButton&&"!right-[1.25rem]"} floating-section-wrapper`}>
                <MusicPlayer toplevel={true}></MusicPlayer>
                </div>
              </Draggable>
              
            }
            {showCreateGalleryFloatingButton&&<div className={`${styles["create-gallery-icon"]} ${styles["floating-section-wrapper"]} floating-section-wrapper`}>
              <FloatingCreateGallery disabled={createdGalleryButtonDisabled} onClickHandler={createGalleryButtonHandler}/>
            </div>}
            {!!showSharePopup && (
              <Popup
                contentClass={"share-social-pop"}
                handleClose={shareModalClose}
                title={""}
              >
                <ShareSocialMedia handleClose={shareModalClose} share={shareUrl} />
              </Popup>
            )}
            {/* {!hideAlphaMessage && (
              <div className={`${styles["footer-alpha-warning"]}`}>
                <img
                  className={`${styles["info-icon"]}`}
                  src={infoIcon}
                  alt="info icon"
                />
                <div>
                  Our site is in alpha testing. All collector and creator
                  accounts will be retained, but other data may be lost during
                  the beta migration. Thank you for your patience.
                </div>
                <img
                  src={closeIcon}
                  onClick={hideAlphaMessageHandler}
                  alt=""
                  className={`${styles["close-icon"]} cursor-pointer`}
                />
              </div>
            )}
            )} */}
          </div>
        </div>
      </div>
        <Footer/>
      </div>
      </>
  );
};
export default Main;
