import "../Icons.scss";

const FilterMobileIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M5.44444 8.75C5.44444 9.16421 5.78023 9.5 6.19444 9.5H7.80556C8.21977 9.5 8.55556 9.16421 8.55556 8.75C8.55556 8.33579 8.21977 8 7.80556 8H6.19444C5.78023 8 5.44444 8.33579 5.44444 8.75ZM0.75 0.5C0.335786 0.5 0 0.835787 0 1.25C0 1.66421 0.335786 2 0.75 2H13.25C13.6642 2 14 1.66421 14 1.25C14 0.835787 13.6642 0.5 13.25 0.5H0.75ZM2.33333 5C2.33333 5.41421 2.66912 5.75 3.08333 5.75H10.9167C11.3309 5.75 11.6667 5.41421 11.6667 5C11.6667 4.58579 11.3309 4.25 10.9167 4.25H3.08333C2.66912 4.25 2.33333 4.58579 2.33333 5Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default FilterMobileIcon;
