import { getCaptionControl } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

const GalleryCaption = (props) => {
    const captionControl = useSelector(getCaptionControl);

    const [breakWord, setBreakWord] = useState('');
    const [QRLink, setQRLink] = useState('');

    // const [captionCardAboveFrame, setCaptionCardAboveFrame] = useState(false);

    const isBottomFilled = useMemo(()=>(captionControl?.cardStyle === 'bottomFilled'),[captionControl?.cardStyle]);

    const captionBgColor = useMemo(() => {
        const opacityMap = {
            10: 0.1,
            9: 0.2,
            8: 0.3,
            7: 0.4,
            6: 0.5,
            5: 0.6,
            4: 0.7,
            3: 0.8,
            2: 0.9,
            1: 1,
        };
        const opacity = opacityMap[captionControl?.cardOpacity] || 0.5;
        return `rgba(13, 15, 17, ${opacity})`;

    }, [captionControl?.cardOpacity]);

    const isOneWord = (word) => {
        const words = word?.split(' ');
        if (words?.length === 1) {
            setBreakWord('break-all');
        } else {
            setBreakWord('');
        }
    }

    const handleSetQRLink = (collectionId, communitySlug, categorySlug, fullURL) => {

        let assetURL = `discover/collection/${categorySlug}/details`;

        if (communitySlug) {
            assetURL = `discover/all-creators/${communitySlug}/collection`;
        }

        setQRLink(`${fullURL}/${assetURL}/${collectionId}`);
    }
    
    useEffect(()=>{
        if(!!props?.cardData?.userName){
            isOneWord(props?.cardData?.userName)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.cardData?.userName])

    useEffect(()=>{
        const fullURL = process.env.REACT_APP_SITE_URL;
        if ((!!props?.cardData?.communitySlug || !!props?.cardData?.categorySlug) && !!props?.cardData?.collectionId && props?.isMono) {
            handleSetQRLink(props?.cardData?.collectionId, props?.cardData?.communitySlug, props?.cardData?.categorySlug, fullURL);
        } else if(props?.multiQRCode){
            setQRLink(`${fullURL}${props?.multiQRCode}`);
        }  else {
            setQRLink(`${fullURL}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.cardData?.communitySlug, props?.cardData?.categorySlug]);

    // useEffect(() => {
    //     const newParentDiv = document.querySelector('.mono-screen');
    //     const oldParentDiv = document.querySelector('.caption_cc_container_mono');
    //     const childDiv = document.querySelector('.active-caption-card');

    //     if (childDiv && newParentDiv && oldParentDiv && props?.isMono && props?.currentSelected) {
    //         if((captionControl?.cardStyle === 'bottomFilled') && props?.currentSelected){
    //             newParentDiv.appendChild(childDiv);
    //         } else {
    //             oldParentDiv.appendChild(childDiv);
    //         }
    //     }

    //     if(props?.isMono){
    //         setCaptionCardAboveFrame((captionControl?.cardStyle === 'bottomFilled') && props?.currentSelected)
    //     }
    //     return ()=> {
    //         setCaptionCardAboveFrame(false);
    //     }
// ${(captionCardAboveFrame) ? 'active-caption-card' : (!props?.currentSelected && props?.isMono) ? '!z-[-1]' : ''}
    // }, [captionControl?.cardStyle, props?.currentSelected, props?.isMono]);
    
    return (
        <div className={
            `${props?.captionCardClass ?? ''} caption-container ${captionControl?.defaultPos === 'left' ? 'left-[5%]' : 'right-[5%]'}
             absolute ${captionControl?.captionCard ? `show` : 'opacity-0'} 
             ${(captionControl?.cardStyle) ? captionControl?.cardStyle : ''} 
             ${((props?.isMono || props?.multiQRCode) && captionControl?.QRCodeShow) ? 'adjust_QRcode_width' : ''} 
           `}
            style={{background: captionBgColor}}
        >

            <div className="caption-inline-container">
                {(props?.isMono || props?.multiQRCode) && captionControl?.QRCodeShow ? <div className="p-1 qr-code-container" style={{ maxHeight: 100, maxWidth: 100 }}>
                    <QRCode
                        className={`scaleUp-effect-1 transition-all`}
                        style={{ height: "100%", width: "100%" }}
                        value={QRLink}
                        bgColor={`#000`}
                        fgColor={`#FFFFFF`}
                    />
                </div> : <div className="dummy_box" />}
                <div className="w-auto flex flex-col justify-between h-auto  gap-4">

                    <span className={`main_title ${!isBottomFilled && 'clamp_ellipsis'}`}>{props?.cardData?.cardName}</span>

                    {props?.cardData?.assetName ? <span className={`nft-name ${!isBottomFilled && 'clamp_ellipsis'}`}>{props?.cardData?.assetName}</span> : <span />}

                    <span className="created-name community-name">by
                        <span className={`capitalize ${breakWord}`}>{props?.cardData?.userName ? ` ${props?.cardData?.userName}` : ' Unknown'}</span>
                    </span>
                </div>
            </div>

        </div>
    )
}
export default memo(GalleryCaption);