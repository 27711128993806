import Card from "App/Components/Ui/Card"
import { useCallback, useEffect, useMemo, useState } from "react"
import GallerySettings from "../GallerySettings"
import "./CreateGallery.scss"
import GalleryPreview from "../GalleryPreview"
import NFTFrameGallery from "../NFTFrameGallery"
import { useStore } from "App/hooks-store/store"
import RoundedButton from "App/Components/Ui/RoundedButton"
import useHttp from "App/hooks/use-http"
import { scrollToTop } from "App/Helper/utilities"
import useScreenSize from "App/hooks/use-screen-size"
import { useSelector } from "react-redux"
import { getCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice"
import MonoSettings from "../MonoSettings"
import MetaSettings from "../MetaSettings"
import GalleryPieces from "../GalleryPieces"
import useGalleryAutoAddFrame from "App/hooks/use-galleryAutoAddFrame"
import { useNavigate } from "react-router-dom"

const CreateGallery = (props) => {
    const screenSize = useScreenSize()?.width;
    const currentForm = useSelector(getCurrentFormValues)
    const [colorFetched, setColorFetched] = useState(false);
    const [colorList, setColorList] = useState([]);
    const [color, setColor] = useState({color: currentForm?.thumbnailBackground?.color ?? "grey"});
    const [{currentSelectedNfts},dispatch] = useStore();
    const navigate = useNavigate()
    const { autoAddFramesToAssets } = useGalleryAutoAddFrame();

    const steps = useMemo(() => { 
        return [ {
                    id: 1,  key: 'Pieces', label: 'Pieces'
                }, {
                    id: 2,  key: 'Frames', label: 'Frames'
                }, {
                    id: 3,  key: 'Mono', label: 'Mono'
                }, {
                    id: 4,  key: 'Multi', label: 'Multi'
                }, {
                    id: 5,  key: 'Meta', label: 'Meta'
                }, {
                    id: 6, key: 'Settings', label: 'Settings'
                }
            ] 
    },[]);
    const [activeStep, setActiveStep] = useState(steps[0]);
    useEffect(() => {
        let tempStep = steps[0];
        if(props?.pgaction === 'save')
            tempStep = steps[1];
        setActiveStep(tempStep);
    }, [props?.pgaction, steps])
    const { triggerAPI } = useHttp();
    const autoFrame = useCallback(() => {
        autoAddFramesToAssets({assetList: currentSelectedNfts}, (autoAddedFrameAssetList) => {
            dispatch('setCurrentSelectedNfts', autoAddedFrameAssetList)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedNfts]);
    const handleNext = () => {
        let isPass = checkPieces();
        if(isPass) {
            const index = steps.findIndex(x => x.key === activeStep.key);
            let proceedNext = true;
            if(activeStep.key === 'Pieces') {
                if (currentSelectedNfts.length) {
                    if(currentSelectedNfts.length <= 30) {
                        autoFrame();
                        navigate(`${props?.galleryBaseUrl}/${props?.pageType}/save`)
                    } else {
                        dispatch("showToast", { toast: { toastMode: "warning", message: 'Select up to 30 pieces.' } });
                        proceedNext = false;
                    }                
                } else {
                    proceedNext = false;
                    dispatch('showToast', { toast: { toastMode: 'error', message: 'Please select at least one piece.' } })
                }                
            }
            if(proceedNext)
                setActiveStep(steps[index + 1]);
        }
    }
    const handleBack = () => {
        const index = steps.findIndex(x => x.key === activeStep.key);
        if (index === 0) return;
        if(index === 1)
            navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`)
        setActiveStep(steps[index - 1]);
    }
 
    const fetchMultiColorListResult = useCallback((res) => {
        const { data } = res;
        let list = data.map((clr) => {
            return { name: clr.name, id: clr.id };
        });
        setColorList(list);
    }, [])
    const fetchMultiColorList = useCallback(() => {
        triggerAPI({
                url: `frame-asset/color/get`,
                method: "get",
            },fetchMultiColorListResult);
    }, [fetchMultiColorListResult, triggerAPI])

    useEffect(() => {        
        if (screenSize < 551) {
            setTimeout(() => {
                scrollToTop();
            }, 1000);
        }
        fetchMultiColorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const checkPieces = useCallback(() => {
        if(!!currentSelectedNfts.length)
        return true;
        else {
            dispatch('showToast', { toast: { toastMode: 'error', message: 'Please select at least one piece.' } })
            return false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedNfts.length]);
    const onTabClick=(data)=>{
        let isPass = checkPieces();
        if(isPass) {
            if(activeStep.id+1===data?.id||activeStep.id>data?.id) {
                if(data?.key === 'Pieces') {
                    navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`)
                } 
                if(activeStep.key === 'Pieces') {
                    if(currentSelectedNfts.length <= 30) {
                        autoFrame();
                        navigate(`${props?.galleryBaseUrl}/${props?.pageType}/save`)
                    } else {
                        dispatch("showToast", { toast: { toastMode: "warning", message: 'Select up to 30 pieces.' } });
                        return ;
                    }                
                }
                setActiveStep(data);
            }
        }
        
    }

    const cursorHandler = (step) =>{
        return (((activeStep?.id+1 === step.id)||activeStep.id>step?.id||activeStep.id===step?.id))?"cursor-pointer":"cursor-not-allowed"
    }
    const navigateToHome = () => {
        props?.onToGallery();
    }
    useEffect(()=>{
        //only its works when edit gallery
        if(currentForm?.thumbnailBackground?.color) {
            setColor({color: currentForm?.thumbnailBackground?.color})
        }
    },[currentForm?.thumbnailBackground?.color])
    
    return (
        <div className="w-full curate-form">
            <Card className={` card-gallery`}>
                <div className="wizard flex">
                    <ul>
                        {steps.map((step, i) => {
                            return <li key={i} className={`${activeStep.key === step.key ? 'active-wizard' : ''}`}>
                                <div className={`gallery-create text-[0.75rem] ${cursorHandler(step)}`} onClick={()=>onTabClick(step)}><span>{step.label}</span></div>
                            </li>
                        })}
                    </ul>
                </div>
                <div className={` ${activeStep?.key === 'Settings' && screenSize < 551 ? 'settings-gal' : 'create-gallery'} ${activeStep?.key}  side-scroller  create-gallery-continer-sm sm:pt-3 pt-0 mt-0 step-component flex flex-col h-full gap-[1.25rem]`}>
                    {activeStep?.key === 'Pieces' && 
                        <GalleryPieces action={props?.action}
                        />
                    }
                    {activeStep?.key === 'Frames' && 
                        <NFTFrameGallery
                            onChangeFrame={props?.onChangeFrame}
                            frameAssetNew={props?.frameAsset}
                            selectedNfts={props?.selectedNfts}
                            onSmFramClick={props?.onSmFramClick}
                            setFrameAsset={props?.setFrameAsset}

                        />
                    }
                    {activeStep?.key === 'Mono' && 
                        <MonoSettings
                            frameAssetNew={props?.frameAsset}
                        />
                    }
                    {activeStep?.key === 'Multi' &&
                        <GalleryPreview colorFetched={colorFetched} 
                            setColorFetched={setColorFetched} 
                            color={color?.color} setColor={setColor}
                            selectedWall={props?.selectedWall} 
                            OnSelectWall={props?.OnSelectWall} 
                            selectedNfts={props?.selectedNfts} 
                            colorList={colorList} 
                        />
                    }
                    {activeStep?.key === 'Meta' &&
                        <MetaSettings frameAssetNew={props?.frameAsset}
                        />
                    }
                    {activeStep?.key === 'Settings' &&
                        <GallerySettings
                            setFormVaildState={props?.setFormVaildState}
                            isCreator={props?.isCreator}
                            showPrimaryDiscoverable={props?.showPrimaryDiscoverable}
                            isDiscoverableProfile={props?.isDiscoverableProfile}
                        />
                    }
                </div>

                <div className="flex items-center justify-between gap-3 px-3 pr-[1.125rem] pt-2 pb-3 create-gallery-btn">
                {activeStep.key === 'Pieces' && 
                    <RoundedButton Name="Cancel" Type="15" value="Back"  OnClick={navigateToHome}></RoundedButton>     }
                    {activeStep.key !== 'Pieces' &&

                        <RoundedButton Name="Previous" Type="15" value="Back" disabled={steps[0].key === activeStep.key} OnClick={handleBack}></RoundedButton>}

                    <RoundedButton Name={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Complete'} Type="14" value={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} OnClick={steps[steps.length - 1].key === activeStep.key ? props?.onClickAdd : () => handleNext()}></RoundedButton>

                </div>

            </Card>
        </div>
    )
}

export default CreateGallery