import React, { useEffect, useState } from 'react';
import './CommunityResults.scss';
import dummyImage from "Assets/Images/dummypic.svg";
import { useNavigate } from 'react-router-dom';
import CategoryBox from 'App/Components/CategoryBox';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { numberWithCommas } from 'App/Helper/utilities';
import { useStore } from 'App/hooks-store/store';

const CommunityResults = ({ loading, loaderCount, data, onReset, onSeeMoreClick, totalItems }) => {

  const navigate = useNavigate();
  const dispatch = useStore()[1];

  const handleNavigate = (communityId) => {
    onReset();
    dispatch("setFullPageLoader", true);
    navigate(`/discover/all-creators/${communityId}/overview`);
    
    setTimeout(() => {
      dispatch("setFullPageLoader", false);
    }, 4000);
  }

  return (
    <div className={`community_results-container results-and-btn-container`}>
      <div className={`results-wrapper`}>
        {/* Loader */}
        {loading && [...Array(loaderCount).keys()].map((loader) => <div key={loader} className='community_results-details-loader'>
          <SkeletonLoader width={40} height={40} count={1} inline={true} />
          <SkeletonLoader width={800} height={20} count={1} inline={true} />
        </div>)}
        {/* Results */}
        {data?.length && !loading ? data?.slice(0, 10)?.map((community, key) => <CommunityDetails key={community?.id ?? key} community={community} onNavigate={handleNavigate} />)
          : null
        }
      </div>
      {!loading && data && (totalItems > 10) ? <div className='community_results-see-more gs_see_more-Btn'>
        <button onClick={onSeeMoreClick}>See More Results</button>
      </div> : null}
      {/* No Results */}
      {!loading && !data?.length ? <div className='community_no-results-found no-results-found_message'>
        <p>No Creators Found</p>
      </div> : null}
    </div>
  )

}

function CommunityDetails({ community, onNavigate }) {

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(!community);
  }, [community]);

  return (<div className={`community_results-details-card`} onClick={() => onNavigate(community?.slug)}>
    <div className={`details_column-one`}>
      <div className='basis-2/10 community-image-box'>
        <img className={`w-10 h-10 object-cover`} src={community?.displayPicture256 ?? community?.thumbnailPicture256 ?? dummyImage} alt={community?.name} />
      </div>

      <div className={`basis-4/10 community-name-box`}>
        {loader ? <SkeletonLoader width={80} height={20} count={1} inline={true} /> :
          <h2 className='max-w-[12.5rem] truncate'>{community?.name}</h2>
        }
      </div>
      <div className={`basis-2/10 community-category-box`}>
        {community?.categoryCount && (
          <div
            className="flex gap-2 flex-wrap"
          >
            {Object.entries(community?.categoryCount)?.filter(([key, value]) => key?.toLowerCase() !== "other")
              ?.slice(0, 1)
              ?.map(([key, val], i) => (
                <CategoryBox key={i} data={key} value={val} showOnlyName />
              ))}
          </div>
        )}
      </div>
    </div>

    <div className={`details_column-two`}>
      <div
        className="community-count-box"
      >
        {community?.collectionCount && <div className='flex items-center gap-2 h-4 border-r pr-2 border-[var(--cu-black-10)]'>
          <h3>Collections</h3>
          <h2 className='mb-[2px]'>{numberWithCommas(community?.collectionCount)}</h2>
        </div>}

        {(community?.assetCount) && <div className='flex items-center gap-2 h-4 border-r pr-2 border-[var(--cu-black-10)]'>
          <h3>Pieces</h3>
          <h2 className='mb-[2px]'>{numberWithCommas(community?.assetCount)}</h2>
        </div>}

        {community?.galleryCount && <div className='flex items-center gap-2 h-4'>
          <h3>Galleries</h3>
          <h2 className='mb-[2px]'>{numberWithCommas(community?.galleryCount)}</h2>
        </div>}
      </div>
    </div>
  </div>)

}

export default CommunityResults