import Dexie from 'dexie';
export const db = new Dexie('database');  // database is database name with 1 version 
db.version(1).stores({
  state: 'id, value', // Primary key and indexed props
});

export const updateDocumentDbStates = async () => {
  try {
      const dbstate = await db.state.toArray();
      document.cur8dbstate = {};
      dbstate.forEach(item => {
          document.cur8dbstate[item.id] = item.value;
      });
      document.cur8dbstateminimal = {
          username: document.cur8dbstate?.username,
          schemaVersionNumber: document.cur8dbstate?.schemaVersionNumber
      };
  } catch (error) {
      console.error('Error updating DB state: ', error);
  }
}
export const putDbData =  async ({id, value}) => {
  await db.state.put({id,value});
}
export const clearDbState = async () => {
  await db.state.clear();
}
