import { getURL } from './utilities';

export const aspectRatioCalc = async (src) => {
    const img = new Image();
    let ar
    const getImageDetails = () => {

        return new Promise((resolve, reject) => {
            img.onload = function () {
                if (!!this.width && !!this.height) {
                    resolve(this.width / this.height)
                }
            }
        })
    }
    img.src = getURL(src);
    ar = await getImageDetails()
    return new Promise((resolve, reject) => {
        resolve(ar)
    })
}

  
export const getHtmlAR = (nft) => {
    return nft?.files?.[0]?.mType?.indexOf("html") > -1 ? 1 : null;
}
const groupingFrames = (frames = []) => {
    let frameList = [], basicList = new Map();
    frames.sort((a, b) => a?.variation - b?.variation); //sorting
    for (const frame of frames) {
        if (frame.categoryId === 3) {
            frameList.push(frame);
        } else if (frame.categoryId === 2) {
            const groupId = frame.groupId;
            if (!basicList.has(groupId)) {
                basicList.set(groupId, frame);
            }
        }
    }

    // Add the best fit from each group in category 2
    for (const frame of basicList.values()) {
        frameList.unshift(frame);
    }
    return frameList;
}
export const getFrameFilterList = async (image, list, ar) => {
    let offset = .1
    if (!ar)
    {
        ar = await aspectRatioCalc(image);
    }
    const filteredList = [];
    [...list]?.forEach((x) =>  {
        if(x.metaInfo) {            
            let portraitAR = x.metaInfo?.portrait?.image?.width / x.metaInfo?.portrait?.image?.height;
            let landscapeAR = x.metaInfo?.landscape?.image?.width / x.metaInfo?.landscape?.image?.height;
            let variation, isValid = false, isLandscape = false;

            if((ar - offset) < portraitAR && portraitAR < (ar + offset)) {
                isValid = true;
                variation = Math.abs(ar - portraitAR);
            } else if ((ar - offset) < landscapeAR && landscapeAR < (ar + offset)){
                isValid = true;
                isLandscape = true;
                variation = Math.abs(ar - landscapeAR);
            }
            if(isValid) {
                x['variation'] = variation;
                x['isLandscape'] = isLandscape;
                filteredList.push(x);
            }
        }
    })
    return groupingFrames(filteredList)
}

export const convertRemToPixels = (rem) => {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}