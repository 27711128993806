import styles from "./CommunityGallery.module.scss";
import { useEffect, useState } from "react";
import Popup from "App/Components/Ui/Popup";
import ShareSocialMedia from "App/Components/ShareSocialMedia";
import { useLocation } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import RoundedButton from "App/Components/Ui/RoundedButton";
import useScreenSize from "App/hooks/use-screen-size";
import Border from "App/Pages/Discover/Components/SubComponents/Border";
import useShapes from "App/hooks/use-shapes";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import Logo from "App/Components/Logo";
import ShareUrl from "App/Components/ShareUrl";
import { truncateText } from "App/Helper/utilities";

const CommunityGalleryDetails = (props) => {
  const screenSize = useScreenSize()?.width;
  const dispatch = useStore(false)[1];
  const [share, setShare] = useState(false);
  const { pathname } = useLocation();  
  const { getShapes } = useShapes();
  const [ shapes, setShapes] = useState(null);
  
  const shareModalClose = () => {
    setShare(false);
  };
  useEffect(() => {
    pathname.includes("collectors/details") &&
      screenSize <= 550 &&
      dispatch("enableTopIcons", { back: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, pathname]);
  useEffect(() => {
    let ratios = props?.nft?.Shapes?.map(s=>s.aspectRatio);
    setShapes(getShapes(ratios)); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.nft?.Shapes]);

  const handleDescText = (text) => {
    if (!text) return ''; 

    // const learnMore = () => <span
    //   className="learn-more-link clickable-links"
    //   onClick={() => props?.setActiveTab("details")}
    // >
    //   Learn More
    // </span>
  
    const words = text.split(' ');
    if(words?.length > 1){
      // Description with spaces
      return <p className="">{text}</p>
    }
    // No spaces
    return <p className="break-all">{text}</p>
  };
  return (
    <>
      <div className={`${styles["description-tab"]}`}>
        <div className={`${styles["details"]}`}>
          <div className={`${styles["details-box"]} overflow-y-auto overflow-x-hidden`}>
          {!!(props?.nft&&Object.keys(props?.nft)?.length)?<div className={`flex justify-between w-full `}>
              <div
                className={`${styles["details-sm"]} flex gap-1 justify-between items-center w-full`}
              >
                <div
                 className="flex gap-4 items-center"
                >
                  <p> 
                    <img className="object-cover" style={{borderRadius: "50%", height:"1.5rem", width:"1.5rem"}} src={props?.fromCommunity ? props?.communityData?.profilePicUrl : props?.communityData?.displayPicture256 || props?.nft?.profileData?.profilePicUrl || props?.nft?.userData?.profilePicUrl || Logo()} alt="" />
                    </p>
                  <div className="flex  text-left gap-[.75rem]">
                  <div className="flex text-left items-center gap-[.75rem]">
                  <div className="gallery-title ">{props?.nft?.name}</div>
                  <div className="text-[0.875rem]  text-[#B8C0CC] italic">
                    by {props?.communityData?.name || props?.nft?.profileData?.username || props?.nft?.userData?.username}
                  </div>
                  {!!props?.shareUrl && <ShareUrl url={props?.shareUrl} />}
                  </div>
                  </div>
                </div>
               <div className="flex flex-col h-full justify-start">
               <div 
                      onClick= {props.onClose}
                      className="clickable-links"
                      style={{
                         
                          fontSize: ".75rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                      }}
                  >
                      All Galleries
                  </div>
            </div>
              </div>
            </div>: 
              <DetailsSkeltonCard
                  show={true}
                  height={"100%"}
                  count={1}
                  inline={true}
                  noAbsolute
                  parentDiv="profile-img-with-2-lines"
                  details="profile-img-with-1-lines"
                  containerClassName={` flex pt-[1rem]`}
                />}
            <div
              className={`flex gap-[.5rem] w-fit ${styles["details-container"]}`}
            >
              <div className={`${styles["data-box"]} items-mob`}>
                <div className={`${styles["data-title"]}`}>Pieces</div>
                <div className={`${styles["data-value"]}`}>
                  {props?.nft?.assets?.length || "-"}
                </div>
              </div>
              <Border className={"right-panel-v-border hidden"} />
              <div className={`${styles["data-box"]} items-mob`}>
                <div className={`${styles["data-title"]}`}>Shapes</div>
                <div className={`${styles["data-value"]}`}>
                 {!!shapes&&<img
                    className={`cursor-pointer w-auto h-[1rem] ml-2.5 ${styles["shapes-img"]}`}
                    alt="Img"
                    src={shapes}
                  />}
                </div>
              </div>
              {/* <div className={`${styles["data-box"]} hidden-mo`}>
                <div className={`${styles["data-title"]}`}>Social</div>
                <div className={`${styles["data-value"]}`}>
                  <div className="flex gap-2 flex-wrap items-center pt-2">
                    {props?.nft?.profileData?.website && (
                      <div>
                        <a
                          className="flex gap-1 items-center cursor-pointer"
                          href={props?.nft?.profileData?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <img src={Website} alt="images" />
                        </a>
                      </div>
                    )}
                    {props?.nft?.profileData?.twitter && (
                      <div>
                        <a
                          className="flex gap-1 items-center cursor-pointer"
                          href={props?.nft?.profileData?.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <img src={Twitter} alt="images" />
                        </a>
                      </div>
                    )}
                    {props?.nft?.profileData?.discord && (
                      <div>
                        <a
                          className="flex gap-1 items-center cursor-pointer"
                          href={props?.nft?.profileData?.discord}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <img src={Discord} alt="images" />
                        </a>
                      </div>
                    )}
                    {props?.nft?.profileData?.youtube && (
                      <div>
                        <a
                          className="flex gap-1 items-center cursor-pointer"
                          href={props?.nft?.profileData?.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <img src={Youtube} alt="images" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
            <div className={`${styles["details-description"]}`}>
              {props?.nft?.description ? (
                <div className="">
                  {handleDescText(props?.nft?.description)}
                </div>
              ) : (
                ""
              )}
            </div>
            {props?.showOpenBtn && !!(screenSize > 550) && (
              <div className="openbtn px-4 w-full">
                <RoundedButton
                  OnClick={props?.openBtnAction}
                  Name="Open"
                  Type="7"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!!share && (
        <Popup contentClass={"share-social-pop"} handleClose={shareModalClose}>
          <ShareSocialMedia share={`${window.location.origin}${pathname}`} />
        </Popup>
      )}
    </>
  );
};

export default CommunityGalleryDetails;