import html2canvas from "html2canvas";
export const Html2Canvas = (input = null, callback, scale = 2) => {
    try {
        if(input) {
            html2canvas(input, {
                logging: true,
                allowTaint: false,
                useCORS: true,
                backgroundColor: null,
                scale: scale
            }).then((canvas) => {
                canvas.toBlob(async blob => {
                    callback(blob);
                });
            }).catch(error => {
                console.error('error on taking screen shot: inside', error);
            })
        }
    } catch (error) {
        console.error('error on taking screen shot', error);
    }    
}