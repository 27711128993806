import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect } from "react";
import { useState, useMemo } from "react";
import useHttp from './use-http';
import {
    AddressPurpose,
    BitcoinNetworkType,
    getAddress,
    getCapabilities,
    getProviders,
    getProviderById,
    getSupportedWallets,
    request,
    Wallet 
  } from "sats-connect";
import { loadSelector, selectWalletProvider, close } from "@sats-connect/ui";

const useWalletBitcoin = () => {    
    const [walletConnected, setWalletConnected] = useState(false);    
    const [walletNameConnected, setWalletNameConnected] = useState('');
    const [connectedAddress, setConnectedAddress] = useState('');        
    const dispatch = useStore(false)[1];
    const store = useStore()[0];    
    const { triggerAPI } = useHttp();
    let { blockchains, currentWallet, debugWalletAddress } = store;
    const [wallet, setWallet] = useState(null);           
    
    const connectWallet =  useCallback(async () => {        
        try {            

            // Call this once in your app to load the custom element.
            loadSelector();
            
            let wallets = getSupportedWallets();     
            
            let address = storage().get('cwa');
            if (address)
            {
                const decoder = new TextDecoder();                
                address = decoder.decode(new Uint8Array(Object.values(JSON.parse(address))).buffer);
            }
            address = debugWalletAddress??address;
            if (!address)
            {
                const userSelectedProviderId = await selectWalletProvider({options: wallets});            
                close();
            
                let prov = getProviderById(userSelectedProviderId);            
                if (prov.requestAccounts)
                {
                    let req = await prov.requestAccounts();            
                    address = req?.[0];
                }
                else
                {
                    await getAddress({
                        getProvider: ()=>getProviderById(userSelectedProviderId),
                        payload: {
                            purposes: [
                            AddressPurpose.Ordinals,
                            //AddressPurpose.Payment,
                            //AddressPurpose.Stacks,
                            ],
                            message: "Cur8",
                            network: {
                            type: BitcoinNetworkType.Mainnet,
                            },
                        },
                        onFinish: (response) => {                    
                            const ordinalsAddressItem = response.addresses.find(
                                (address) => address.purpose === AddressPurpose.Ordinals
                            );
                            address = ordinalsAddressItem.address;
                        }
                    });                
                }

                storage().set('currentWallet', "Bitcoin Ordinals");            
                const encoder = new TextEncoder();
                storage().set('cwa', JSON.stringify(encoder.encode(address)));
            }
            
            triggerAPI({
                url: `user/wallet/nftids?chain=${"bitcoin"}&wallet_address=${address}`, method: 'get'
            }, (res)=>{
                let assets = res.data.map(d=>{ let data = d.split("."); return { asset: data[1]+data[2], asset_name: data[2], policy_id: data[1], contractAddress: data[1], chain: data[0]}});
                
                dispatch('setCurrentWallet', { walletName: "Bitcoin Ordinals", address: address, chain: "bitcoin", NFTs: assets})
                dispatch('setWalletUpdatingStatus', true)    
                dispatch('setWalletConnectionStatus', 'finished')        
                //setWalletNameConnected(wallet.label);
                setWalletConnected(true);
                setConnectedAddress(address);
            });                                 
        } catch (error) {
            dispatch('showToast', { toast: { toastMode: 'error', message: error?.message } })
        }                
        
    },[dispatch, blockchains, debugWalletAddress]);
    const memoedValue = useMemo(
        () => ({
            wallet,            
            walletNameConnected,
            walletConnected,
            connectWallet,
            connectedAddress,
            debugWalletAddress                        
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet, walletNameConnected, walletConnected, connectedAddress, blockchains, debugWalletAddress]
    );

    return memoedValue;
}
export default useWalletBitcoin