import React, { useEffect, useState } from 'react';
import './CuratorsResults.scss';
import dummyImage from "Assets/Images/dummypic.svg";
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useStore } from 'App/hooks-store/store';
import { numberWithCommas } from 'App/Helper/utilities';

const CuratorsResults = ({ loading, loaderCount, data, onReset, searchValue, totalItems }) => {

    const navigate = useNavigate();
    const dispatch = useStore()[1];

    const handleNavigate = (curatorSlug) => {
        onReset();
        dispatch("setFullPageLoader", true);
        navigate(`/discover/curators/${curatorSlug}/collections`);

        setTimeout(() => {
            dispatch("setFullPageLoader", false);
        }, 4000);
    }
    const handleSeeMoreClick = () => {
        onReset();
        const params = { state: { pageType: 'view' }, replace: true };
        const search = encodeURIComponent(searchValue)
        navigate(`/discover/curators?query=${search}`, params);
    }

    return (
        <div className={`curators_results-container results-and-btn-container`}>
            <div className={`results-wrapper`}>
                {/* Loader */}
                {loading && [...Array(loaderCount).keys()].map((loader) => <div key={loader} className='curators_results-details-loader'>
                    <SkeletonLoader width={40} height={40} count={1} inline={true} />
                    <SkeletonLoader width={800} height={20} count={1} inline={true} />
                </div>)}
                {/* Results */}
                {data?.length && !loading ? data?.slice(0, 10)?.map((curator, key) => <CuratorsDetails key={curator?.id ?? key} curator={curator} onNavigate={handleNavigate} />)
                    : null
                }
            </div>
            {!loading && data && (totalItems > 10) ? <div className='curators_results-see-more gs_see_more-Btn'>
                <button onClick={handleSeeMoreClick}>See More Results</button>
            </div> : null}
            {/* No Results */}
            {!loading && !data?.length ? <div className='collectors_no-results-found no-results-found_message'>
                <p>No Curators Found</p>
            </div> : null}
        </div>
    )

}

function CuratorsDetails({ curator, onNavigate }) {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(!curator);
    }, [curator]);

    return (<div className={`curators_results-details-card`} onClick={() => onNavigate(curator?.slug)}>
        <div className={`details_column-one`}>
            <div className='basis-2/10 curator-image-box'>
                <img className={`w-10 h-10 object-cover`} src={curator?.displayPicture256 ?? curator?.bannerPicture ?? dummyImage} alt={curator?.name} />
            </div>

            <div className={`basis-4/10 curator-name-box`}>
                {loader ? <SkeletonLoader width={80} height={20} count={1} inline={true} /> :
                    <h2 className='max-w-[12.5rem] truncate'>{curator?.name}</h2>
                }
            </div>

        </div>

        <div className={`details_column-two`}>
            <div
                className="curator-count-box"
            >
                {curator?.collectionsCount && <div className='flex items-center gap-2 h-4 border-r pr-2 border-[var(--cu-black-10)]'>
                    <h3>Collections</h3>
                    <h2 className='mb-[-2px] sm:mb-[2px]'>{numberWithCommas(curator?.collectionsCount)}</h2>
                </div>}

                {(curator?.totalAssetCount) && <div className='flex items-center gap-2 h-4 border-r pr-2 border-[var(--cu-black-10)]'>
                    <h3>Pieces</h3>
                    <h2 className='mb-[-2px] sm:mb-[2px]'>{numberWithCommas(curator?.totalAssetCount)}</h2>
                </div>}

                {curator?.communityCount && <div className='flex items-center gap-2 h-4'>
                    <h3>Creators</h3>
                    <h2 className='mb-[-2px] sm:mb-[2px]'>{numberWithCommas(curator?.communityCount)}</h2>
                </div>}
            </div>
        </div>
    </div>)

}

export default CuratorsResults