import { useTexture, Text, Html, useVideoTexture } from '@react-three/drei';
import MetaGalleryFrameImage from "../MetaGalleryFrameImage";
import * as THREE from 'three';
import MetaGalleryFrameOnChain from '../MetaGalleryFrameOnChain';
import { useMemo, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { useDevice } from 'App/hooks/use-ui';

const MetaGalleryFrame = ({ position, rotation, nft, renderonchain, captionpos, captionshow, scale, shadowsign, endwall, rotatey, parentscale }) => {  
  const  device = useDevice();
    let frame ;
    let args ;
    if (nft?.isLandscape)
    {
      frame = nft?.frame?.s3ImageUrlLandscape??"/metagalleryassets/textures/transparent.png";
    }
    else
    {
      frame = nft?.frame?.s3ImageUrl??"/metagalleryassets/textures/transparent.png";
    }

    const scaleValue = useMemo(() => {
      if(scale) {
        if(device === "web") {
          return scale;
        } else {
          if(nft?.isLandscape){
            return [0.34,0.34,0.34];
          } else {
            return [0.52,0.52,0.52]
          }
        }
      } else {
        if(device === "web") {
          return [1.1,1.1,1.1];
        } else {
          if(nft?.isLandscape){
            return [0.34,0.34,0.34];
          } else {
            return [0.52,0.52,0.52]
          }
        }
      }
    },[device, nft?.isLandscape, scale])

if(position) args = [0.00000001,1,1];
// if (!scale) scale = [1.2,1.2,1.2];
  const frametexture = useTexture(frame);
  frametexture.anisotropy = 4096;//renderer.capabilities.getMaxAnisotropy(); 
  const lightmaptexture = useTexture(frame);
  lightmaptexture.anisotropy=2;

  let [videourl, setvideourl] = useState(null);  
  let [videotexture, setvideotexture] = useState(null);  

  let [widthstretch, setwidthstretch] = useState(1);  
  if (nft?.frame?.metaInfo?.portrait?.frame)
  {
    widthstretch = nft.frame.metaInfo.portrait.frame.width/nft.frame.metaInfo.portrait.frame.height;
  }

  const imagetexture = useTexture(nft?.details?.nftcdnimage??"/metagalleryassets/textures/wall3.jpg", (tex) => {    
    if (nft?.details?.onchain_metadata.files?.[0]?.mType?.indexOf("html") > -1)
    {
      setwidthstretch(1);    
    }
    else if (widthstretch == 1)
    {
      widthstretch = tex.source.data.width/tex.source.data.height;
      setwidthstretch(widthstretch);      
    }
    
  });

  const lightmapimagetexture = useTexture(nft?.details?.nftcdnimage??"/metagalleryassets/textures/wall3.jpg");
  lightmapimagetexture.anisotropy=2;

  let shadowposition = [];
  if (shadowsign)
  {
    shadowposition[0]=position[0]+Math.sign(shadowsign[0])*0.012;
    shadowposition[1]=position[1]-0.02;
    shadowposition[2]=position[2]+Math.sign(shadowsign[2])*0.01;      
  }
  else
  {
    shadowposition[0]=position[0]+Math.sign(position[0])*0.012;
    shadowposition[1]=position[1]-0.02;
    shadowposition[2]=position[2]+Math.sign(position[2])*0.01;      
  }
  
  if (endwall == null)
  {
    endwall = (rotation?.length>1?rotation[1]:0)==-Math.PI/2;
  }  

  let captionadj = 0.13;//0.65+0.55*widthstretch;

  let captionposition = [];
  captionposition[0]=position[0]+(endwall?captionadj*1:-Math.sign(position[0])*0.05)//+Math.sign(position[0])*0.012;
  captionposition[1]=1.56/(parentscale??1);
  captionposition[2]=position[2]+(!endwall?Math.sign(position[0])*captionadj*1:0.05)//+Math.sign(position[2])*0.01;

  let isviewingframe = useSelector((state) => state.appState.isviewingframe.payload)??0;  

  // function shapeipfs(ipfs) {    
  //   if (ipfs.join)
  //   {
  //     ipfs = ipfs.join('');
  //   }
  //   if (ipfs.startsWith("ipfs://"))
  //   {
  //     ipfs = ipfs.replace("ipfs://"," https://cyeepyhsfa.cloudimg.io/https://ipfs.io/ipfs/") + "?func=proxy";
  //   }
  //   return ipfs;
  // }

  // function getvideo(nft) {    
  //   let files = nft?.details?.onchain_metadata?.files;
  //   if (files?.length>0)
  //   {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       if (file.mediaType?.indexOf("video") > -1)
  //       {          
  //         return shapeipfs(file.src);
  //       }
  //     }      
  //   }  
       
  //   return null;
  // }

  //disabled, as videos are too big to download and play on the fly
  // useEffect(() => {
  //   async function getvideotexture() {
  //     let videosrc = getvideo(nft);
  //     console.log(videosrc);
  //     if (videosrc)
  //     {
  //       let response = await axios.head(videosrc);
  //       console.log(response);
  //       const file = response;
  //       if (file && file.headers) {
  //         console.log(file);
  //         let length = file.headers["content-length"];        
  
  //         //less than 20mb then load
  //         if (length<0 )
  //         {
  //           videourl = videosrc;
  //           setvideourl(videourl);            
  //         }
  //       }            
  //     }    
  //   }
    
  //   getvideotexture();
  // });  
  //videotexture = useVideoTexture(videourl??"./metagalleryassets/textures/blank.mp4");
  if (!videourl) videotexture = null;
  
    return (
      <>
      <mesh position={shadowposition} rotation={rotation} scale={[scaleValue[0],scaleValue[1],scaleValue[2]*widthstretch]} renderOrder={-1} >
        
      <boxGeometry args={[-0.001,1,1]} />
          <meshBasicMaterial map={lightmaptexture} transparent={true} color={new THREE.Color(0,0,0)} opacity={0.7}></meshBasicMaterial>  
          <meshBasicMaterial map={lightmapimagetexture} transparent={true} color={new THREE.Color(0,0,0)} opacity={0.7}></meshBasicMaterial>  
      </mesh>
      
        
      <mesh position={position} rotation={rotation} scale={[scaleValue[0],scaleValue[1],scaleValue[2]*widthstretch]} >
      
        <boxGeometry args={args} />
        <meshBasicMaterial map={frametexture} transparent={true}></meshBasicMaterial>  

        {/* rotatey hack for the end wall */}
        <MetaGalleryFrameOnChain nft={nft} renderonchain={renderonchain} rotatey={rotatey!=null?rotatey:endwall?-1:1} imagexpos={endwall?-0.003:0.003} imagezpos={0} videotexture={videotexture}/>
        <MetaGalleryFrameImage nft={nft} renderonchain={renderonchain} imagexpos={endwall?-0.002:0.002} imagetexture={imagetexture} imagezpos={0} videotexture={videotexture}/> 
      </mesh>
        
 
      {captionshow && <mesh position={captionposition} rotation={rotation} scale={[0.1,0.1,0.1]} renderOrder={-1} >
        
        <Html transform rotation={[0,(endwall?-1:1)*Math.PI*1.5,0]} scale={[0.5,0.5,0.5]} occlude>
        <div className={`caption-container flex flex-col gap-4 right-[5%] absolute opacity-${isviewingframe?1:0} show`}>
          <span className={`main_title clamp_ellipsis`}>{nft?.collectionName}</span>
          <span className="nft-name clamp_ellipsis">{nft?.details?.onchain_metadata?.name??nft?.details?.onchain_metadata?.Name}</span>
                                        {(nft?.details?.onchain_metadata?.artist??nft?.details?.onchain_metadata?.Artist) && <span className="created-name">by {nft?.details?.onchain_metadata?.artist??nft?.details?.onchain_metadata?.Artist} </span>}
                                        </div>
        </Html>
      {/* <boxGeometry args={[0,1,1]} />
      <Text font={"static/media/Poppins-Regular.35d26b781dc5fda684cc.ttf"}
        position={[-0.01,0.15,0]}
        scale={[0.06, 0.15, 1]}
        rotation={[0,-Math.PI/2,0]} 
        color="black" // default
        anchorX="center" // default
        anchorY="middle" // default
      >
        {nft?.details?.onchain_metadata?.name??nft?.details?.onchain_metadata?.Name}       
      </Text>
      <Text font={"static/media/Poppins-Regular.35d26b781dc5fda684cc.ttf"}
        position={[-0.01,-0.1,0]}
        scale={[0.06, 0.15, 1]}
        rotation={[0,-Math.PI/2,0]} 
        color="black" // default
        anchorX="center" // default
        anchorY="middle" // default
      >
        {nft?.details?.onchain_metadata?.artist??nft?.details?.onchain_metadata?.Artist}       
      </Text> */}
         
      </mesh> }
      </>    
    );
}

export default MetaGalleryFrame;