import styles from "./InformationDesk.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import InformationCard from "./Components/InformationCard";
import { useStore } from "App/hooks-store/store";
import twitterIcon from "Assets/Images/TwitterUser.svg";
import youtubeIcon from "Assets/Images/Youtube.svg";
import discordIcon from "Assets/Images/Discord.svg";
import useScreenSize from "App/hooks/use-screen-size";
import Banner from "App/Components/Banner";
import { NavLink, useLocation, useParams } from "react-router-dom";
import PartnersComponent from "./Components/Partners";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import InformationDetails from "./Components/InformationDetails";
import ModalOuter from "App/Components/Ui/ModalOuter";
import CloseIcon from "App/Components/IconsComponents/CloseIcon";

const InformationDesk = () => {
  const screenSize = useScreenSize()?.width;
  const [categoryList, setCategoryList] = useState([]);
  const [termsUrl, setTermsUrl] = useState("");
  const [policyUrl, setPolicyUrl] = useState("");
  const [{ topActiveMenu }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [spinnerShow, setSpinnerShow] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [categoryInfoId, setCategoryInfoId] = useState(null);
  const [categoryInfoName, setCategoryInfoName] = useState(null);
const {pathname} =useLocation();
const {tab} = useParams();
  const headerTabs = useMemo(() => {
    let tab = [
      { id: 1, element: "User Guide", key:"user-guide", url: `/knowledge-base/user-guide`,links:["user-guide","create-an-account"] },
      // { id: 1, element: "Partners" },
      { id: 2, element: "Terms of Use",key:"terms-of-use",url: `/knowledge-base/terms-of-use`,links:["terms-of-use"] },
      { id: 3, element: "Privacy Policy",key:"privacy-policy",url: `/knowledge-base/privacy-policy`,links:["privacy-policy"] },
    ];
    if(process.env.REACT_APP_COMPANY !== "anywall"){
      tab.unshift({ id: 0, element: "Video Tutorials",key:"tutorials",url: `/knowledge-base/tutorials`,links:["tutorials"] },)
    }
    // if (isPortrait && screenSize <= 550) {
    //   tab.shift();
    //   tab.push({ id: 4, element: "Contact",key:"contact",url: `/knowledge-base/contact`,links:["contact"] });
    // }
    return tab;
  }, []);
  const [tabSelected, setTabSelected] = useState("");
  useEffect(() => {
    if (tab) {
      let tabIndex = headerTabs?.findIndex((el)=>el?.links?.includes(tab))
      let tabKey = headerTabs?.find((el)=>el?.links?.includes(tab))?.key;
        dispatch("setActiveMenuOptions", tabIndex);
        setTabSelected(tabKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab,headerTabs]);
  const getInformationDeskResult = useCallback((res) => {
    setSpinnerShow(false);
    const categoryList = res.data.list;
    setCategoryList(categoryList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInformationDesk = useCallback(() => {
    setSpinnerShow(true);
    const params = {
      filter: { sortBy: "name", orderBy: "ASC" },
    };

    triggerAPI(
      {
        url: `information-desk/user/category/list`,
        method: "post",
        data: params,
      },
      getInformationDeskResult
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInformationDeskResult, triggerAPI]);

  useEffect(() => {
    getInformationDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTermsResponse = useCallback((res) => {
    const { data } = res;
    setTermsUrl(data?.fileUrl ?? data?.link);
  }, []);
  const getTerms = () => {
    triggerAPI(
      {
        url: `terms-conditions/get-active/1`,
        method: "get",
      },
      getTermsResponse
    );
  };
  const getPolicyResponse = useCallback((res) => {
    const { data } = res;
    setPolicyUrl(data?.fileUrl ?? data?.link);
  }, []);
  const getPolicy = () => {
    triggerAPI(
      {
        url: `terms-conditions/get-active/2`,
        method: "get",
      },
      getPolicyResponse
    );
  };
  useEffect(() => {
    getTerms();
    getPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Menu options
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      dispatch("setTopMenuBarOptions", {
        showMenuIcons: false,
        showTopMenus: true,
      });
      dispatch("setMenuOptions", headerTabs);
      dispatch("setInfoDeskIcons", false);
    } else {
      dispatch("setTopMenuBarOptions", {
        showMenuIcons: false,
        showTopMenus: true,
      });
      dispatch("setMenuOptions", headerTabs);
      dispatch("setInfoDeskIcons", false);
    }
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortrait, screenSize, headerTabs]);

  const handleOpenPopup = (id, name) => {
    setCategoryInfoId(id);
    setCategoryInfoName(name)
    setOpenPopup(true);

    // setTimeout(()=>{
    //   document.getElementById("selectedInfo").scrollIntoView({ behavior: 'smooth' })
    // }, 800)
  }
  const handleClosePopup = () => {
    setOpenPopup(false);
  }
  return (
    <div className={`${styles["info-list-wrp"]} page-shadow`}>
      {openPopup &&
        <ModalOuter openModal={openPopup}
          parentClass={openPopup ? '' : '!hidden !z-[10]'}
          contentClass={`animated-slideIn ${styles["info-video-tutorial-pop-up"]}`}>

          <div className={`${styles["info-video-tutorial-pop-up-close-box"]}`}>
            <span />
            <button onClick={handleClosePopup} className="h-[1rem]">
             <CloseIcon/>
            </button>
          </div>

          <div className={`${styles["knowledge-box-tutorial-popup-inline-wrapper"]}`}>

            <div className={`${styles["tutorial-popup_details-box"]}`}>
              <InformationDetails id={categoryInfoId} categoryInfoName={categoryInfoName}/>
            </div>

            {/* <div className={`${styles["tutorial-popup_list-box"]} w-[30%] flex flex-col h-[89dvh] overflow-hidden gap-[1rem]`}>

              <div className={`hidden sm:block text-[0.875rem] text-start`}>Video Tutorials</div>

              <div className="w-full flex flex-col gap-4 sm:min-h-[500px] sm:py-[1rem] pr-[1rem] overflow-y-auto">
                {categoryList?.map((info) => (
                  <InformationCard key={info.id} categoryInfo={info}
                    onOpenPopup={handleOpenPopup} isSelected={categoryInfoId === info?.id} fromPopup />
                ))}
              </div>

            </div> */}

          </div>
        </ModalOuter>
      }
      {tabSelected === "tutorials" && (
        <div
          className={`w-full ${styles["info-desk-mob"]} pt-4 py-2 pl-4 pr-4 ${
            !spinnerShow ? "overflow-flex-auto" : ""
          } flex flex-col justify-between`}
        >
          {spinnerShow ? (
                     <DetailsSkeltonCard
                     show={true}
                     height={"100%"}
                     count={18}
                     inline={true}
                     details={""}
                     noAbsolute
                     parentDiv="grid home-grid !mt-0 aspect-pt-six-seven"
                     containerClassName={`flex`}                 
                 /> 
            // <SkeletonLoader
            //   height="15rem"
            //   count={5}
            //   inline={true}
            //   containerClassName="leading-none  h-full  grid gap-2.5 home-grid"
            // />
          ) : (
            <div
              className={`sm:pt-0 pt-4 cursor-pointer grid gap-4 home-grid ${styles["info-desk-home-grid"]}`}
            >
              {categoryList?.map((info) => (
                <InformationCard key={info.id} categoryInfo={info} categoryList={categoryList} onOpenPopup={handleOpenPopup} />
              ))}
            </div>
          )}
          {!categoryList?.length && !spinnerShow && (
            <div className="flex justify-center items-start h-full pt-2 w-full">
              No data found!!
            </div>
          )}
        </div>
      )}
      {(tabSelected === "user-guide" || tabSelected === "terms-of-use" || tabSelected === "privacy-policy") && (!spinnerShow) && (
        <div className={`${styles["knowledge-box-content_inline-wrapper"]}`}>
          <div
            className={`${styles["terms"]} ${
              screenSize < 551 ? "p-4" : ""
            }  overflow-auto`}
          >
            {!!(tabSelected === "user-guide") &&
              (termsUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={`https://cur8.gitbook.io/cur8-knowledge-base${pathname?.includes("create-an-account")?"/getting-started/creating-your-cur8-account":""}`}
                  width="100%"
                  title="User Guide"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No active user guide
                </div>
              ))}
            {!!(tabSelected === "terms-of-use") &&
              (termsUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={termsUrl}
                  width="100%"
                  title="Terms and Conditions"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No active terms of use
                </div>
              ))}
            {!!(tabSelected === "privacy-policy") &&
              (policyUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={policyUrl}
                  width="100%"
                  title="Policy"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No active privacy policy
                </div>
              ))}
          </div>
        </div>
      )}
      {tabSelected === -2 && (
        <div className={`${styles[""]}`}>
          <PartnersComponent />
        </div>
      )}

      {tabSelected === "contact" && (
        <div
          className={`${styles["contact-container"]} flex flex-col m-4 gap-4`}
        >
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@CurateOfficial</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={twitterIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://twitter.com/Cur8Labs`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@CurateChannel</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={youtubeIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://www.youtube.com/@cur8app`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@Cur8</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={discordIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://discord.com/invite/vHqfQ5vPFd`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-center items-center">
              <div className={`${styles["info-name"]}`}>
                <span>Contact Us</span>
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>Send Message</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InformationDesk;