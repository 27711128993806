import React, { useCallback, useState } from 'react'
import ControlCard from '../SubComponents/ControlCard'
import ControlBody from '../SubComponents/ControlBody'
import ControlTitleCard from '../SubComponents/ControlTitleCard'
import ccCardStyle1Icon from "Assets/Icons/GalleryControls/ccCardStyle1None.svg";
import ccCardStyle2Icon from "Assets/Icons/GalleryControls/ccCardStyle2.svg";
import ccCardStyle3Icon from "Assets/Icons/GalleryControls/ccCardStyle3.svg";
import ToolTip from "App/Components/ToolTip";
import InformationIcon from 'App/Components/IconsComponents/InformationIcon';
import ControlContent from '../SubComponents/ControlContent';

const CcControl = ({ className, show, setSettingControls, settingControls, isMono, isMulti }) => {

    const [opacitySlider, setOpacitySlider] = useState(false);

    const handleCaptionStyleChange = useCallback((value) => {
        if (!settingControls?.captionCard?.disableControl) {
            setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, cardStyle: value } } })
            if (settingControls?.captionCard?.time === 0) {
                setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, time: -1 } } })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard])
    const handleCaptionChange = useCallback((e) => {
        if (!settingControls?.captionCard?.disableControl) {
            let value = Number(e?.currentTarget?.dataset?.value);
            setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, time: value } } })
            // if (value === 0) {
            //     setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, cardStyle: '' } } })
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard])

    const handleQRCodeVisibility = useCallback((show) => {
        if (!settingControls?.captionCard?.disableControl) {
            setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, QRCodeShow: show } } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard]);

    const colorChange = useCallback((e) => {
        let value = e?.target?.value;
        setSettingControls(prev => { return { ...prev, captionCard: { ...prev?.captionCard, cardOpacity: value } } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard]);

    const handleColorForBox = (colorValue) => {
      let colorOpacity = parseInt(colorValue);
      const opacity = colorOpacity / 10;

      if(colorOpacity > 5){
        return `rgba(255, 255, 255, ${opacity})`;
      } else if (colorOpacity === 5) {
        return `rgba(0, 0, 0, ${0.2})`;
      } else if (colorOpacity === 4) {
        return `rgba(0, 0, 0, ${0.4})`;
      } else if (colorOpacity === 3) {
        return `rgba(0, 0, 0, ${0.6})`;
      } else if (colorOpacity === 2) {
        return `rgba(0, 0, 0, ${0.8})`;
      } else if (colorOpacity === 1) {
        return `rgba(0, 0, 0, ${1})`;
      }
    }

    return (
        <ControlCard className={`${className} pop cc_control w-[15rem] ${show ? 'show-cc_control' : 'hidden-cc_control'}`}>

            <ControlBody >
                <ControlContent className={`cc_caption-card-box`}>
                    <ControlTitleCard>{`Caption Card`}</ControlTitleCard>
                    <button
                        disabled={settingControls?.captionCard?.time === 0}
                        onClick={() => setOpacitySlider(!opacitySlider)}
                        className={`gradient-slider_icon `}
                        style={(settingControls?.captionCard?.cardOpacity && opacitySlider) ? {
                            background: handleColorForBox(settingControls?.captionCard?.cardOpacity)
                        } : {}}
                    >
                    </button>
                </ControlContent>
                {opacitySlider && (settingControls?.captionCard?.time !== 0) && <input disabled={settingControls?.captionCard?.disableControl} type="range" min={1} max={10} value={settingControls?.captionCard?.cardOpacity} onChange={colorChange} step={1} className={`gradient-slider_card-opacity w-[100%]`} />}
            </ControlBody>

            <ControlBody className={`cc_caption-card-box bottom-border pb-8`}>
                <button
                    disabled={settingControls?.captionCard?.disableControl}
                    data-value={0}
                    onClick={handleCaptionChange}
                >
                    <img className={`${settingControls?.captionCard?.time === 0 ? 'active' : ''}`} src={ccCardStyle1Icon} alt='icon' />
                </button>
                <button
                    disabled={settingControls?.captionCard?.disableControl}
                    onClick={() => handleCaptionStyleChange('bottomFilled')}
                >
                    <img className={`${(settingControls?.captionCard?.cardStyle === `bottomFilled` && settingControls?.captionCard?.time !== 0) ? 'active': ''}`} src={ccCardStyle2Icon} alt='icon' />
                </button>
                <button
                    disabled={settingControls?.captionCard?.disableControl}
                    onClick={() => handleCaptionStyleChange('rightBottom')}
                >
                    <img className={`${(settingControls?.captionCard?.cardStyle === `rightBottom` && settingControls?.captionCard?.time !== 0) ? 'active' : ''}`} src={ccCardStyle3Icon} alt='icon' />
                </button>
            </ControlBody>

            <ControlBody className={`caption-section pb-4 ${(isMono || isMulti) && 'bottom-border  pb-8'} ${settingControls?.captionCard?.time === 0 ? 'opacity-40 !cursor-not-allowed' : ''}`}>
                <ControlTitleCard>
                    <div className="flex gap-4 items-center">Caption Display
                        {settingControls?.captionCard?.time !== 0 && <ToolTip className="tooltip-container-right-aligned mt-1" message={'Cyclic mode displays a caption card for a few seconds every minute. Keep fixed to display indefinitely.'}>
                            <InformationIcon />
                        </ToolTip>}
                    </div>
                </ControlTitleCard>
                <ControlContent className={`${settingControls?.captionCard?.time === 0 ? 'inactive' : ''}`}>
                    <button data-value={-1} onClick={handleCaptionChange}>
                        <span className={`click-link-tab ${settingControls?.captionCard?.time === -1 ? 'text-active' :''}`}>{`Fixed`}</span>
                    </button>
                    <button data-value={1} onClick={handleCaptionChange}>
                        <span className={`click-link-tab ${settingControls?.captionCard?.time === 1 ? 'text-active' : ''}`}>{`Cyclic`}</span>
                    </button>
                </ControlContent>
            </ControlBody>

            {(isMono || isMulti) && <ControlBody className={`${settingControls?.captionCard?.time === 0 ? 'opacity-40 !cursor-not-allowed' : ''}`}>
                <ControlTitleCard >
                    <span >QR Code</span>
                </ControlTitleCard>

                <ControlContent className={`${settingControls?.captionCard?.time === 0 ? 'inactive' : ''}`}>
                    <button onClick={() => handleQRCodeVisibility(false)}>
                        <span
                            className={`click-link-tab ${!settingControls?.captionCard?.QRCodeShow ? 'text-active': ''}`}>{`Off`}</span>
                    </button>
                    <button onClick={() => handleQRCodeVisibility(true)}>
                        <span
                            className={`click-link-tab  ${settingControls?.captionCard?.QRCodeShow ? 'text-active' : ''}`}>{`On`}</span>

                    </button>
                </ControlContent>
            </ControlBody>}

        </ControlCard>
    )
}

export default CcControl