import { useCallback, useEffect, useState } from "react";
import useFrames from "./use-frames";
import { getFrameFilterList, getHtmlAR } from "App/Helper/aspectRatioCalc";

const useGalleryAutoAddFrame = () => {
    const [basicFrameList, setBasicFrameList] = useState([]);
    const [isFrameListReady, setIsFrameListReady] = useState(false);
    const { getFrameList } = useFrames();
    const getBasicFrameList = useCallback(() => {
      const framePayload = {
          "items": 999999,
          filter: {categoryId: [2],sortBy:"modernWestern"}
      }
      getFrameList({framePayload}, ({list})=> {
        setBasicFrameList(list)
        setIsFrameListReady(true)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
      getBasicFrameList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const sortListByArray = useCallback((list = [], sortingArr = []) => {
      return [...list].sort((a, b) => {
        const aHasSpecificName = sortingArr.some(sortingItem => a?.color?.name.includes(sortingItem));
        const bHasSpecificName = sortingArr.some(sortingItem => b?.color?.name.includes(sortingItem));
    
        if (aHasSpecificName && !bHasSpecificName) {
            return -1; // a should come before b
        } else if (!aHasSpecificName && bHasSpecificName) {
            return 1; // b should come before a
        } else {
            return 0; // maintain the original order
        }
    });
    }, [])
    const autoAddFramesToAssets = useCallback(async ({ assetList = [] }, callBack) => {
      if(!isFrameListReady) return;
      let tempList = [];
      for (const item of assetList) {
          let temp = { ...item };
          if (!item?.frameId) {
              let filteredByARFrames = await getFrameFilterList(item?.nftcdnimage256, basicFrameList, getHtmlAR(item));
              if (filteredByARFrames?.length) {
                filteredByARFrames = sortListByArray(filteredByARFrames, ['black']);
                temp = { ...temp, frameId: filteredByARFrames[0]?.id, isLandscape: filteredByARFrames[0]?.isLandscape, frame: filteredByARFrames[0] };
              }
          }
          tempList.push(temp);
      }
      callBack(tempList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFrameList, isFrameListReady]);
    return { autoAddFramesToAssets, isFrameListReady }
}
export default useGalleryAutoAddFrame;