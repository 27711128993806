import "./CategoryBox.scss";
const CategoryBox = ({ value, data, showOnlyName }) => {
  if (showOnlyName) {
    return <div className="category-list-box">
      {data}
    </div>
  }
  return (
    <div className="category-list-box">
      {data}&nbsp;&nbsp; •&nbsp; {value}
    </div>
  );
};
export default CategoryBox;
