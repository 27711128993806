import { useStore } from "App/hooks-store/store"
import useHttp from "./use-http";

const useWall = () => {
    const [{ metaWallList = [] }, dispatch] = useStore();
    const { triggerAPI } = useHttp();

    const getMetaWallAPI = (callback) => {
        triggerAPI({
            url: `metaWall`, method: 'get'
        }, callback);
    }
    const getMetaWallFromStore = (callback) => {
        try {
            if (metaWallList?.length) {
                callback(metaWallList)
            } else {
                getMetaWallAPI(({ data }) => {
                    dispatch('setMetaWallList', data)
                    callback(data)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        getMetaWallFromStore,
        getMetaWallAPI
    }
}
export default useWall;