/* eslint-disable react-hooks/exhaustive-deps */
import styles from './Header.module.scss'
import hamburgerIcon from 'Assets/Icons/hamburger.svg'
import userAvatar from 'Assets/Icons/userAvatars.svg'
import userIcon from 'Assets/Icons/user-white.svg'
import userIconActive from 'Assets/Icons/user-blue.svg'
import logOutIcon from 'Assets/Icons/logout-white.svg'
import logOutIconActive from 'Assets/Icons/logout-blue.svg'
import creatorIcon from 'Assets/Icons/creator-icon.svg'
import creatorIconActive from 'Assets/Icons/creator_active.svg'
import closeCrossIcon from "Assets/Icons/close-cross.svg";
import { useLocation, useNavigate } from "react-router-dom";
import RoundedButton from 'App/Components/Ui/RoundedButton'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import useAuth from 'App/hooks/use-auth'
import DropDown from 'App/Components/Ui/DropDown'
import LogOutWarn from './Components/LogOutWarn/LogOutWarn'
import WalletSelector from './Components/WalletSelector'
import storage from 'App/Helper/storage'
import AuthPop from 'App/Components/Ui/AuthPop'
import WalletSelectPop from 'App/Components/Ui/WalletSelectPop'
import ImagePopup from 'App/Components/Ui/ImagePopup'
import Register from 'App/Pages/AuthLayout/Components/Register'
import useCollectionLoader from "App/hooks/use-collectionLoader"
import TopHeader from 'App/Components/TopHeader'
import useScreenSize from 'App/hooks/use-screen-size';
import curatoractiveIcon from "Assets/Icons/curators.svg";
import curatorinactiveIcon from "Assets/Icons/curator-inactive.svg";
import { arrangeAssetData, removeDuplicateByElement } from 'App/Helper/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { getShowWalletPop ,setShowWalletPop} from 'App/ReduxStore/Slicers/authSlice';
import useWalletConnect from "App/hooks/use-wallet-connect";
import GlobalSearch from 'App/Components/Ui/GlobalSearch';
import Logo from 'App/Components/Logo';
import LogoAndName from 'App/Components/LogoAndName'
import BorderRight from 'App/Components/TopHeader/Components/BorderRight';
import ToolTip from 'App/Components/ToolTip'
import ReloadIcon from 'App/Components/IconsComponents/ReloadIcon'
import { PublicKey } from '@solana/web3.js';
import { creatorRoutes, curatorRoutes } from 'App/Helper/routings'
import useLogout from 'App/hooks/use-logout'
import useProfileApiHelper from 'App/Pages/UserProfile/Components/Apis'

const Header = props => {
    const [{ currentWallet,isCurator, triggerCollection, collectionWalletData, isLoginInProgress, collectionLoadingStatus, singleCollectionLoadingStatus, collectionLoadTotal, collectionLoadProgress }, dispatch] = useStore(false);
    const { pathname } = useLocation()
    let { triggerAPI } = useHttp();
    const store = useStore()[0];
    const [showAuthPop, setShowAuthPop] = useState(false)
    const [dropDown, setDropDown] = useState(false);
    const [logOutWarning, setLogOutWarning] = useState(false)
    const [userData, setUserData] = useState({})
    const [showRegisterPop, setShowRegisterPop] = useState(false)
    const [profilePicUrl, setprofilePicUrl] = useState(Logo())
    const [communityId, setCommunityId] = useState(null)
    const [curatorId, setCuratorId] = useState(null)
    const navigate = useNavigate()
    const { getProfile } = useProfileApiHelper();
    const [walletAuthPop,setWalletAuthPop] = useState(false)
    const logoutMenu = {name: 'Logout', value:'Logout', icon: logOutIcon, activeIcon: logOutIconActive}
    const {logoutHandler,logOutWarningCloseHandler} = useLogout();
    const isCollectionFetched = useMemo(() => {
        return collectionLoadingStatus === 'finished' && (singleCollectionLoadingStatus === 'finished' || singleCollectionLoadingStatus === '');
    }, [collectionLoadingStatus,singleCollectionLoadingStatus])
    const dropDownElements = useMemo(()=>{
        
            let items = [];
            if (process.env.REACT_APP_COMPANY === 'anywall')
            {
                items.push({name: 'Collector Profile',value: 'ProfileSubscription', icon: userIcon, activeIcon: userIconActive})                    
            }
            else
            {
                items.push({name: 'Collector Profile',value: 'Collector', icon: userIcon, activeIcon: userIconActive})        
                items.push({name: 'Creator Profile', value: 'Creator', icon: creatorIcon, activeIcon: creatorIconActive});  
                if(isCurator){
                    items.push({name: 'Curator Profile', value: 'Curator', icon: curatorinactiveIcon, activeIcon: curatoractiveIcon});   
                }          
            }
            items.push(logoutMenu);
            return items;
        
    },[process.env.REACT_APP_COMPANY,isCurator]);
    const dropDownElementsGuest = [{name: 'Login',value: 'Login', icon: logOutIcon, activeIcon: logOutIconActive}];
    const { profilePic, enableScrollMenu, blockchains } = store    
    const { sliceCollectionAPI,sliceTokenAPI,refreshWallets } = useCollectionLoader()
    const isLoggedIn = useAuth()[0]
    const isLoggedInGuest = useAuth()[4];    
    const handleB2CLogin = useAuth()[6];    
    const screenSize = useScreenSize()?.width;
    const isCommunity = pathname === '/community';
    const isCuratorModule = pathname === '/curator';
    const isGalleryPreview = pathname.includes('preview-gallery');
    const dispatchRedux = useDispatch();
    const showWalletAuthPop = useSelector(getShowWalletPop);
    const [{collections}] = useStore();
    const [showLinkWallet,setShowLinkWallet] = useState(false);    
    const [isSearchExpanded,setIsSearchExpanded] = useState(false);    
    const { walletAddHandler } = useWalletConnect();
    
    const getConnectedWallets = useCallback((data) => {
        triggerAPI({
          url: `user/connected-wallet/get`, method: 'get'
        }, (res)=>{
            getTokenAssetIdsListUpdate(data,res?.data)
        },()=>{
            getTokenAssetIdsListUpdate(data,[])
        });
      }, [triggerAPI, dispatch])    

    const getWallResult = (res) => {
        dispatch('setCurrentWallList', res.data)
       
    }
    const getWallDetails = () => {
        triggerAPI({
            url: `background-thumbnail/user/get`,data:{}, method: 'post'
        }, getWallResult);
    }
    useEffect(()=> {
        if (!isLoggedIn)
        {
            //try a silent login            
            handleB2CLogin(true);
            dispatch("setFullPageLoader", true);  
        }
    }, [isLoggedIn])
    useEffect(() => {
        if ((isLoggedIn || isLoggedInGuest) && store?.isUserSet) {            
            if (collections?.length === 0)
            {
                dispatch("setCollections", { loading: false, data: [], count: 0 })
                dispatch("setTokenCollection", { loading: false, data: [], count: 0 })
                dispatch("updateCollectionLoadingStatus", 'idle')
                dispatch("updateTokenLoadingStatus", 'idle')
            }
        }
        if(isLoggedIn) {
            getWallDetails()
        }
        if(!isLoggedIn && currentWallet?.address) {
            setprofilePicUrl(Logo())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, currentWallet?.address, store?.isUserSet])    
 
    useEffect(() => {        
        if ((isLoggedIn && !isGalleryPreview && store?.isUserSet)) {                              
            if (currentWallet?.address)
            {
                dispatch("updateCollectionLoadingStatus", 'finished')
                // removed this code, as it triggers every time a wallet is connected, not just upon wallet link
                // setTimeout(function() {             
                //     getTokenAssetIdsList()
                // }, 2000);
            }
            else
                if (collections?.length === 0)//token fetching
                    dispatch('setTriggerCollection', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, currentWallet?.address, store?.isUserSet])
    useEffect(() => {
        //to fetch collection once wallet linked to account from popup and profile wallet selection and if already connected then inital load
        if(!!triggerCollection && (isLoginInProgress !== undefined && !isLoginInProgress)){ 
            //isLoginInProgress - is for to know login in progress state or not
            dispatch('setTriggerCollection', false);
            dispatch("resetCollections"); dispatch("resetTokenCollection"); dispatch("updateTokenAssetCount", 0); //to reset collection & token whenever we calling this api
            setTimeout(() => {
                getTokenAssetIdsList()
            }, 500);
        }
    }, [triggerCollection, isLoginInProgress])
    useEffect(() => {
        if (isLoggedIn)
            getProfileAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePic,isLoggedIn])
    useEffect(() => { 
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //token asset

    const getTokenAssetIdsListUpdate=(data, wallets)=>{
        if (typeof data?.wallets === "object" && Object.values(data?.wallets)?.length > 0) {
            let assets = [], connectedWallets = [];
            wallets.forEach(walletData => {
                let tempAssetDetails = walletData?.asset_details ?? [];
                let blockchainCode = walletData?.blockchainType?.code; 
                let tempWallet = {
                    assetCount: tempAssetDetails?.length ?? 0,
                    walletAddress: walletData?.walletAddress,
                    walletId: walletData?.id,
                    id: walletData?.id,
                    blockchainTypeId: walletData?.blockchainTypeId,
                    walletName: walletData?.walletName,
                    chain: blockchainCode
                };
                connectedWallets = [...connectedWallets, tempWallet]; 
                tempAssetDetails?.forEach(asset => {
                    let policy_id = asset?.policy_id?.toLowerCase(), asset_name = asset?.asset_name?.toLowerCase();
                    let tempAsset ={
                        asset: (blockchainCode === 'cardano' ? '' : blockchainCode) + policy_id + asset_name,
                        asset_name: asset_name,
                        policy_id: policy_id,
                        contractAddress: asset?.policy_id, //dont change the case for this 
                        quantity: asset?.quantity,
                        stakeAddress: walletData?.stakeAddress,
                        walletId: walletData?.id,
                        createdAt: asset?.createdAt,
                        walletAddress: walletData?.walletAddress,
                        chain: blockchainCode
                    }
                    assets = [...assets, tempAsset]
                });
            });
            if (assets.length > 0) {
                //all user asset are setting in new redux store
                let allUserAssets = [...assets];
                dispatch("setAllUserCollection", allUserAssets)       
                dispatch("updateCollectionWalletData", connectedWallets)                 
                
                const arrangedData = arrangeAssetData(assets)                
                sliceCollectionAPI(assets)
                dispatch("updateCollectionAssetCount", arrangedData?.assetCount)
            }
            else {
                dispatch("resetCollections");
                dispatch("updateCollectionLoadingStatus", 'finished')  
            }
        } else {    
            dispatch("updateCollectionWalletData", []) 
            dispatch("resetCollections");
        }
        if (data?.tokens?.length) {
            data.tokens = removeDuplicateByElement(data.tokens, 'asset_name')     
           let arrangedData = arrangeAssetData(data?.tokens)
           let tokenFetchAsset = arrangedData?.fetchAssets?.map(item => { return {...item, asset: `${item?.policy_id}${item?.asset}`}})
           dispatch("setAllUserCollection", tokenFetchAsset) 
           arrangedData = {...arrangedData, fetchAssets: tokenFetchAsset}
           sliceTokenAPI(arrangedData?.fetchAssets)
            dispatch("updateTokenAssetCount", arrangedData?.assetCount)
        }
        else {
            dispatch("resetTokenCollection");
            dispatch("updateTokenAssetCount", 0)
            dispatch("updateTokenLoadingStatus", 'finished')
            dispatch("updateCollectionLoadingStatus", 'finished')
        }
        
        dispatch("updateCollectionAssetCount", data?.totalAssetsCount ?? 0)
        dispatch("reFetchTokenDone");
    }
    const getTokenAssetIdsListResult = useCallback((res) => {
        try {
            const { data } = res;
            getConnectedWallets(data)
        } catch (error) {
            console.log(error)
        }
        // setTimeout(()=>{
        // dispatch("updateTokenLoadingStatus", 'finished')
        // dispatch("updateCollectionLoadingStatus", 'finished')
        // },[3000])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getTokenAssetIdsList = useCallback(() => {
        const token = storage().get('token');
        if(token) {
            dispatch("updateCollectionLoadingStatus", 'in-progress')
            dispatch("setTokenCollection", { loading: true, data: [], count: 0 })
            triggerAPI({
                url: 'user/wallets/assets/get', method: 'get'
            }, getTokenAssetIdsListResult, (er) => {
                console.log("ERROR ",er)
                dispatch("updateTokenLoadingStatus", 'finished')
                dispatch("updateCollectionLoadingStatus", 'finished')
                dispatch("reFetchTokenDone");
            })
        }
    }, [dispatch, getTokenAssetIdsListResult, triggerAPI])

    const profileNavHandler = () => {
        navigate("collector/profile/my-galleries")
    }
    const profileSubscriptionNavHandler = () => {
            navigate("collector/subscription")
    }
    const homeNavHandler = () => {
        navigate("home")
    }
    
    const dropDownHandlerClose = () => {
        setDropDown(false)
    }
    const selectHandler = selected => {
        dispatchRedux(setShowWalletPop(false))
        switch (selected) {
            case "Collector":
                profileNavHandler()
                break
            case "ProfileSubscription":
                profileSubscriptionNavHandler()
                break
            case "Register":
                setShowRegisterPop(true)
                break
            case "Creator":
                if (!isCommunity) {
                    if (communityId) {
                        navigate(creatorRoutes('overviewWithId', communityId))
                    } else {
                        navigate(creatorRoutes())
                    }
                }
                break
                case "Curator":
                    if(!isCuratorModule){
                        if (curatorId) {
                            navigate(curatorRoutes('curatorIdWithTab', curatorId,"collections"))
                        } else {
                            navigate(curatorRoutes())
                        }
                    }
                    break
            case "Logout":
                setWalletAuthPop(showWalletAuthPop)
                setLogOutWarning(true)
                break
            case "Login":
                handleB2CLogin();
                break;
            default:
        }
    }

    //Logout Warning Closer
    const logOutWarnCloseHandler = (params) => {
        logOutWarningCloseHandler(walletAuthPop,setLogOutWarning)
    }




    const getCommunityList = useCallback((currentUserId) => {
        if(currentUserId) {
            triggerAPI({
                url: `community/get`, method: 'post', data: {
                    page: 1,
                    items: 1,
                    filter: { userId: currentUserId },
                    search: ""
                  }
            }, (res)=>{
                setCommunityId(res?.data?.list[0]?.id)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getCurator = useCallback(() => {
            triggerAPI({
                url: `curator/get-user`, method: 'get', 
            }, (res)=>{
                setCuratorId(res?.data?.id)
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getProfileAPI = () => {
            getProfile((data) => {
                setUserData(data)
                setprofilePicUrl(data.profilePicUrl256 ?? Logo())
                getCommunityList(data?.id);
                getCurator()
            })
    }
    const handleTrialClick = (e) => {
        dispatchRedux(setShowWalletPop(true))
    }

    const getCategories = () => { 
        triggerAPI({
            url: `category/get`, method: 'post',
            data:{
                "page": 1,
                "items": 100,
                "filter": {},
                "search": ""
              }
        }, ((response)=>{
            // setCategoriesOption(response.data.list);
            response.data.list.sort((a,b)=>a.name>b.name?1:-1);            
            for (let i = 0; i < response.data.list.length; i++) {
                const element = response.data.list[i];
                element.subcategories.sort((a,b)=>a.name>b.name?1:-1);
            }
            dispatch('setCategoryOptions' ,response.data.list)
        }));
    }

    const addLinkWalletHandler = () => {
        if(process.env.REACT_APP_COMPANY !=='anywall')
            navigate("/curate/collections/my-collections");        
        walletAddHandler(currentWallet.address, currentWallet.chain, () => {
            dispatch('setTriggerCollection', true);
        });
    }
    const handleDecodeSolonaWalletAddress = (address) => {
        try {
          const publicKey = new PublicKey(address);
          const decodedBase58 = publicKey.toBase58();
          return decodedBase58
        } catch (error) {
            return address;
        }
    };
    useEffect(()=> {
        if (currentWallet?.address)
        {
            let walletAddress = currentWallet?.chain === 'solana' ? handleDecodeSolonaWalletAddress(currentWallet?.address) : currentWallet?.address;
            let chainId = blockchains?.filter(b=>b.code === currentWallet.chain)?.[0]?.id??1;
            if (collectionWalletData?.findIndex(w=> w.walletAddress === walletAddress && w.blockchainTypeId === chainId) === -1)
            {
                setShowLinkWallet(true);
            }
            else
            {
                setShowLinkWallet(false);
            }
        }
        else
        {
            setShowLinkWallet(false);
        }
    }, [collectionWalletData, currentWallet, blockchains])    

    const searchExpanded = (expanded) => {
        setIsSearchExpanded(expanded);
    }
    const handleCollectionRefresh = async () => {        
        refreshWallets();
      }
const onUserDetailsHandler=()=>{
    getProfileAPI();
}
const logoutHandlerMethod=()=>{
    logoutHandler(walletAuthPop, setLogOutWarning)
  }
    return (
    <>  
        {!isLoggedIn && 
            <>    
                <AuthPop show={showAuthPop} className={styles['home-auth-pop']} onTrialClick={handleTrialClick} onClose={setShowAuthPop} />
                <WalletSelectPop show={showWalletAuthPop} className={styles['home-wallet-pop']} />
            </>
        }              
        <ImagePopup show={{mode: showRegisterPop} } onClose={setShowRegisterPop} cssClass={styles['register-pop']}>
            <Register isGuestRegister={true} onClose={setShowRegisterPop} />
        </ImagePopup>  
        <div id="header-bar" className={`${styles['title-bar']} flex w-full ${enableScrollMenu&&styles["scroll-menu-header"]} ${styles['nav-bar']} ${isLoggedIn ? '' : 'z-10'} 
    
        `}> 
           {/* ${
            leftMenuCollapse && styles['collpased']
        } */}
           <div className={`${enableScrollMenu&&styles["scroll-menu-div"]} flex items-center w-full h-full`}>
            <div className={`${styles['nav-wrapper']}`}>
                <div className={`${styles['logo-box']}`}>
                    <div className={`${styles['head-logo']} flex gap-2 items-center`}>
                        {/* <img className={`${leftMenuCollapse && styles['collpased-logo']} ${!leftMenuCollapse && 'opacity-0'}`} onClick={homeNavHandler} alt="No Img" src={ process.env.REACT_APP_COMPANY=='anywall'?miniLogoAnywall:miniLogo} /> */}
                        <img className={`${
                            // !leftMenuCollapse &&
                             styles['expanded-logo']}`} onClick={homeNavHandler} alt="No Img" src={ LogoAndName()} />
                             <h5 className={`${styles["beta"]} sm:inline-block hidden`}>beta</h5>
                        {/* {!leftMenuCollapse && <div className={`${!leftMenuCollapse && styles['alpha-text']}`}>Alpha</div>} */}
                    </div>
                </div>
            <div className={`${styles['web-filter']}`}>
                <TopHeader />
            </div>
            <div className={`${styles["title-btns"]} flex flex-row justify-content-center items-center gap-2.5 ml-auto mr-8`}>
                {process.env.REACT_APP_COMPANY !== 'anywall' && 
                    <div className='sm:inline-block hidden'>
                        <GlobalSearch searchExpanded={searchExpanded}/>
                    </div>
                }
                {/* {showLinkWallet &&  */}
                {!!isLoggedIn && 
                    <div className={`${styles["link-wallet-message"]} ${isSearchExpanded?styles["link-wallet-message-contract"]:''}`}>
                        <div className={`${styles['search-bar-lg']} flex h-full ${styles['wallet-icons']} ${styles['wallet-icons-1']} ${!isCollectionFetched && styles['hide-icon']}`}>
                            <WalletSelector />
                        </div>
                        {isCollectionFetched && <BorderRight/>}
                        <ToolTip message={isCollectionFetched ? 'Reload all linked wallets.' : ((collectionLoadingStatus==='finished' ? 'Loading pieces from connected wallet' : 'Loading pieces from all linked wallets') + (collectionLoadTotal>0?`\r\n${collectionLoadProgress} / ${collectionLoadTotal}`:''))} className={"sm:flex hidden items-center h-full top-[3px] pb-[1px]"}>
                            <div className='reload-icon-joy'>
                                <ReloadIcon onClick={handleCollectionRefresh} className={`${!isCollectionFetched && styles['collection-loading']} ${styles['wallet-icons']} ${styles['wallet-icons-2']} mb-[5px]`} />
                            </div>
                        </ToolTip>
                        {showLinkWallet && 
                            <>
                                {isCollectionFetched && <BorderRight/>}
                                <ToolTip message="Link your wallet to save your curations." className={`flex items-center h-full ${!isCollectionFetched && styles['hide-icon']} ${styles['wallet-icons']}`}>
                                    <div  className={`${styles["link-message"]} mb-[1px] relative `}>
                                        <RoundedButton className={`custom-button curate-gallery-btn curate-gradient-button ${styles["link-btn"]}`} Name="Link" Type="6" OnClick={addLinkWalletHandler}></RoundedButton>                                    
                                    </div>  
                                </ToolTip>
                            </>
                        }
                    </div>
                }
                {/* }                                 */}
                
                {/* Log in */}
                {!isLoggedIn &&
                    <>
                        <div className={`${styles['signIn-button']} sm:inline-block hidden`}>                    
                            <RoundedButton OnClick={handleB2CLogin} className={styles['register-btn']} Name="Login/Sign up" Type={screenSize > 550 ? '13' : '7'}></RoundedButton>                                                        
                        </div>
                    </>
                }
                {/* Profile menu */}
                {(isLoggedIn) &&
                    // <div className={`${styles['user-avatar-box']}`}
                    //     onMouseOverCapture={onMouseFocus} onMouseLeave={onMouseBlur} 
                    // >
                   
                    // {<div className={`${styles["profile-box"]} ${!profilePicUrl ? styles["no-profile-box"] : ""} cursor-pointer`} onClick={dropDownHandler}>
                    //     <div className={`${styles['profile-pic']}`} style={{ backgroundImage: `url(${profilePicUrl ?? userAvatar})` }}>
                    //     </div>
                    // </div>}
                    <div className='sm:inline-block hidden'>
                    <DropDown className={`profile-dropdown`} data={userData} open={dropDown} onAction={onUserDetailsHandler} onBlur={dropDownHandlerClose} onSelect={selectHandler} elements={isLoggedIn ? dropDownElements : dropDownElementsGuest} profilePicUrl={profilePicUrl} isLoggedIn={isLoggedIn} userAvatar={userAvatar} />
                    </div>
                }
                <div className={`${styles['mob-head']}`}>
                        <div className={props?.className}>
                            <button
                                onClick={props.onHamburgerClick}
                            >
                                <img className='h-6 w-6' alt='NoImg' src={props?.navExpand?closeCrossIcon:hamburgerIcon} />
                            </button>
                        </div>
                    </div>
                {logOutWarning &&
                    <LogOutWarn onLogOut={logoutHandlerMethod} onCancel={logOutWarnCloseHandler} />
                }
            </div>
            </div>
            </div>
            <div className={`${styles['mob-filter']}`}>
                <TopHeader />
            </div>
        </div >
        {/* <div id="shadow" className={`${styles['shadow-div']}`}></div> */}
    </>
)
}

export default Header