import "../Icons.scss";

const WalletSmIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="heroicons-outline/wallet">
          <path
            id="Vector"
            d="M15.75 9.5C15.75 8.56802 14.9945 7.8125 14.0625 7.8125H11.25C11.25 9.05514 10.2426 10.0625 9 10.0625C7.75736 10.0625 6.75 9.05514 6.75 7.8125H3.9375C3.00552 7.8125 2.25 8.56802 2.25 9.5M15.75 9.5V14C15.75 14.932 14.9945 15.6875 14.0625 15.6875H3.9375C3.00552 15.6875 2.25 14.932 2.25 14V9.5M15.75 9.5V7.25M2.25 9.5V7.25M15.75 7.25C15.75 6.31802 14.9945 5.5625 14.0625 5.5625H3.9375C3.00552 5.5625 2.25 6.31802 2.25 7.25M15.75 7.25V5C15.75 4.06802 14.9945 3.3125 14.0625 3.3125H3.9375C3.00552 3.3125 2.25 4.06802 2.25 5V7.25"
            stroke="#F7F8F9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default WalletSmIcon;
