
import React from 'react';
import PropTypes from 'prop-types';

const MobileControlClose = ({ 
  children, 
  onClick, 
  type = 'button', 
  disabled = false, 
  className = '', 
  style = {} 
}) => {
  return (
    <button 
      type={type} 
      onClick={onClick} 
      disabled={disabled} 
      className={`mobile_controls_close_btn ${className}`} 
      style={style}
    >
      {children}
    </button>
  );
};

MobileControlClose.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
}

export default MobileControlClose