import ShareIcon from "../IconsComponents/ShareIcon";
import { useStore } from "App/hooks-store/store";
const ShareUrl = ({ url = "" }) => {
  const [{ showSharePopup }, dispatch] = useStore();
  const shareModalClose = (e) => {
    e.stopPropagation()
    if(showSharePopup){
      dispatch("setShareUrl","")
    }else{
      dispatch("setShareUrl",url)
    }
    dispatch("setSharePopUp",!showSharePopup)
  };

  return (
    <div className="relative social-icons share-icon" onClick={shareModalClose}>
      <div>
      <ShareIcon />
      </div>
    </div>
  );
};
export default ShareUrl;
