import "./DiscoverTopPanelMob.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import rightArrow from "Assets/Icons/right-arrow.svg";
import userBanner from "Assets/Icons/community_background.png";
import ShareUrl from "App/Components/ShareUrl";
import CommunityHoverWrapper from "App/Pages/HomeV2/Components/Creators/Components/CommunityHoverWrapper";
import useAuth from "App/hooks/use-auth";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import LogoAndName from "App/Components/LogoAndName";
import useFilteredDataWithCount from "App/hooks/use-filtered-list-with-count";
import BlockchainIconsListing from "App/Components/BlockchainIconsListing";

const DiscoverTopPanelMob = (props) => {
  const [{ currentUserId }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const { userId } = useParams();
  const isLoggedIn = useAuth()[0];
  const [profileData, setProfileData] = useState(null);
  const [isFollowed, setIsFollowed] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (
      !profileData ||
      !props?.profileData ||
      profileData?.id !== props?.profileData?.id
    ) {
      if (props?.fromOther || props?.dontCallApi) {
        setProfileData(props?.profileData);
      } else {
        getCreatorDetail();
      }
      setIsFollowed(props?.profileData?.isFollowing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.profileData, props?.fromOther, props?.dontCallApi, userId]);
  const getCreatorDetailResponse = useCallback(
    (res) => {
      // dispatch("hideSpinner");
      setProfileData({...res?.data,blockchains:res?.data?.blockchain});
      setIsFollowed(res?.data?.isFollowed);
      let timer = setTimeout(() => {
        setLoader(false);
      }, 200);
      clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getCreatorDetail = useCallback(() => {
    // dispatch("showSpinner");
    setLoader(true);
    triggerAPI(
      {
        url: `user/collector/get/${userId}`,
        method: "get",
      },
      getCreatorDetailResponse
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAPI, getCreatorDetailResponse, dispatch, userId]);
  const handleFollowForCollector = (collectorId, isFollowedCollector) => {
    const data = { collectorId };
    const url = isFollowedCollector
      ? "user/collector-unFollow"
      : "user/collector-follow";
    dispatch("showSpinner");
    triggerAPI(
      {
        url: url,
        data,
        method: "post",
      },
      (res) => {
        dispatch("hideSpinner");
        setIsFollowed((pre) => !pre);
      },
      () => {
        dispatch("hideSpinner");
      }
    );
  };
  const handleFollowForCurator = (curatorId, isFollowedCurator) => {
    const data = { curatorId };
    const url = isFollowedCurator ? "curator/unFollow" : "curator/follow";
    dispatch("showSpinner");
    triggerAPI(
      {
        url: url,
        data,
        method: "post",
      },
      (res) => {
        dispatch("hideSpinner");
        setIsFollowed((pre) => !pre);
      },
      () => {
        dispatch("hideSpinner");
      }
    );
  };
  const handleFollow = (e) => {
    e.stopPropagation();
    if (props?.fromCollector) {
      handleFollowForCollector(profileData?.id, isFollowed);
    } else if (props?.fromCurator) {
      handleFollowForCurator(profileData?.id, isFollowed);
    } else {
      const data = {
        owner: profileData?.user?.id,
      };
      const url = isFollowed ? "user/unfollow" : "user/follow";
      const method = isFollowed ? "DELETE" : "POST";
      dispatch("showSpinner");
      triggerAPI(
        {
          url: url,
          method,
          data,
        },
        (res) => {
          dispatch("hideSpinner");
          setIsFollowed((pre) => !pre);
        },
        () => {
          dispatch("hideSpinner");
        }
      );
    }
  };

  const onDetailsHandler = (e) => {
    e.stopPropagation();
    props?.onDetailsInfoHandler && props?.onDetailsInfoHandler(profileData);
  };
  const handleCardClick = (e) => {
    e.stopPropagation();
    const id = parseInt(e.currentTarget?.dataset?.id);
    props?.onCardClick && props?.onCardClick(id, props?.id);
  };

  const collectionImages = useFilteredDataWithCount(
    profileData?.collectionFilePath256,
    5
  );

  const profileImg = useMemo(() => {
    if (profileData) {
      setLoader(false);
      return profileData?.profilePicUrl || LogoAndName();
    } else {
      setLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.profilePicUrl]);
  const blockchainImagesToList = useFilteredDataWithCount(
    profileData?.blockchains,
    5
  );

  return (
    <>
      {loader || props?.fetchingDataFromParent ? (
        <div className={`${props?.className}`}>
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={1}
            inline={true}
            details={""}
            noAbsolute
            parentDiv={`grid home-grid w-full mt-0 aspect-pt-six-six`}
          />
        </div>
      ) : (
        <div
          id="discover-overview"
          className={`discover-details-profile ${props?.className}`}
          onClick={handleCardClick}
          data-id={profileData?.id}
        >
          <div className="profile-section">
            <div className="profile-details flex flex-col gap-2 justify-between items-center w-full">
              <div
                className="profile-img background-banner flex items-center gap-[0.71429rem] w-full"
                style={{
                  backgroundImage: `url(${
                    profileData?.bannerPicUrl || userBanner
                  })`,
                }}
              >
                <div className={`z-1 profile-img-wrapper`}>
                  <img className="pro-img " src={profileImg} alt="" />
                </div>
              </div>
              <div className="flex w-full justify-between pr-2 pl-[5rem] items-center">
              <div className="">
                {!!props?.showData?.blockchains && (
                    <BlockchainIconsListing
                      blockchainImagesToList={blockchainImagesToList}
                      blockchains={profileData?.blockchains}
                    />
                )}
                 </div>
                <div className="flex gap-2">
                  {isLoggedIn &&
                    currentUserId !== profileData?.user?.id &&
                    (props?.fromCollector
                      ? currentUserId !== profileData?.id
                      : true) &&
                    (props?.self || props?.readOnly) && (
                      <button className="flex follow-section ml-[1.375rem]">
                        <div
                          className="follow-btn-container"
                          onClick={handleFollow}
                        >
                          <span>{isFollowed ? "Following" : "Follow"}</span>
                        </div>
                      </button>
                    )}
                  {props?.fromOther && !props?.self && !props?.readOnly && (
                    <button
                      className="flex follow-section ml-[1.375rem]"
                      onClick={props?.editClick}
                    >
                      <div className="follow-btn-container">
                        <span>Edit</span>
                      </div>
                    </button>
                  )}
                  {!!props?.shareUrl && <ShareUrl url={props?.shareUrl} />}
                </div>
              </div>
              <div className="details-wrapper w-full px-2">
                <span className="text-left name-text">
                  {profileData?.name || profileData?.username}
                </span>
              </div>
            </div>
            {!!props?.showData?.description && (
              <div
                className="details-desc-short w-full sm:hidden flex px-2"
                onClick={onDetailsHandler}
              >
                <p>{profileData?.description}</p>
                <img src={rightArrow} alt="" />
              </div>
            )}
            <CommunityHoverWrapper
              show={{ ...props?.showData, blockchains: false }}
              collectionCount={
                profileData?.collectionsCount ||
                profileData?.collectionCount ||
                0
              }
              communitiesCount={profileData?.communityCount || 0}
              galleryCount={profileData?.galleryCount || 0}
              blockchains={profileData?.blockchains || []}
              assetsCount={profileData?.totalAssetCount || 0}
            />
            {!!props?.showData?.collectionImages &&
              collectionImages?.list?.length && (
                <div className="collection-img-list px-2">
                  {collectionImages?.list?.map((el, i) => {
                    return (
                      <div className="collection-image" key={i}>
                        <img src={el} alt="" />
                      </div>
                    );
                  })}
                  {!!collectionImages?.remainingDataCount && (
                    <div className="collection-image remaining-count-box">
                      <img src={profileData?.collectionFilePath256[5]} alt="" />
                      <div className="count-text">{`+${collectionImages?.remainingDataCount}`}</div>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};
export default DiscoverTopPanelMob;
