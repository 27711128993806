import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect } from "react";
import { useState, useMemo } from "react";
import useHttp from './use-http';
import { WalletMultiButton, useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useWalletMultiButton } from '@solana/wallet-adapter-base-ui';

const useWalletSolana = () => {    
    const [walletConnected, setWalletConnected] = useState(false);    
    const [walletNameConnected, setWalletNameConnected] = useState('');
    const [connectedAddress, setConnectedAddress] = useState('');        
    const dispatch = useStore(false)[1];
    const store = useStore()[0];    
    const { triggerAPI } = useHttp();
    let { blockchains, debugWalletAddress } = store;
    const [wallet, setWallet] = useState(null); 
    const { setVisible: setModalVisible } = useWalletModal();
    
    const { buttonState, onDisconnect, publicKey } = useWalletMultiButton({
        onSelectWallet() {
            //setModalVisible(true);
        },
    });
    
    useEffect(()=>{        
        if (buttonState==='connected')
        {
            let currentWallet = storage().get('currentWallet');    
            if (currentWallet==='Solana')
            {
                let address = publicKey;
                address = debugWalletAddress??address;
                triggerAPI({
                    url: `user/wallet/nftids?chain=${"solana"}&wallet_address=${address}`, method: 'get'
                }, (res) => {
                    let assets = res.data.map(d => { 
                        let data = d.split("."); 
                        return { 
                            asset: data[1] + data[2], 
                            asset_name: data[2], 
                            policy_id: data[1], 
                            contractAddress: data[1], 
                            chain: data[0]
                        }
                    });
                    
                    dispatch('setCurrentWallet', { walletName: "Solana", address: address, chain: "solana", NFTs: assets});
                    dispatch('setWalletUpdatingStatus', true);  
                    dispatch('setWalletConnectionStatus', 'finished')          
                    //setWalletNameConnected(wallet.label);
                    setWalletConnected(true);
                    setConnectedAddress(address);
                });          
            }   
            else
            {
                onDisconnect?.();
            }         
        }
        else if (buttonState === 'no-wallet')
        {
            let currentWallet = storage().get('currentWallet');    
            if (currentWallet==='Solana')
            {
                //probably disconnected from the wallet extension, so update cur8 to match 
                dispatch('setCurrentWallet', {});
                setWalletConnected(false);
                setConnectedAddress(null);
                storage().set('currentWallet', "");
            }
        }
    }, [buttonState,debugWalletAddress])

    const connectWallet =  useCallback(async () => {        
        try { 

            // case 'no-wallet':
            //     setModalVisible(true);
            //     break;
            // case 'has-wallet':
            //     if (onConnect) {
            //         onConnect();
            //     }
            //     break;
            // case 'connected':            
            //if (buttonState=='has-wallet')
            //{                     
            let currentWallet = storage().get('currentWallet');    
            if (currentWallet!=='Solana')
            {
                onDisconnect?.();
                storage().set('currentWallet', "Solana");      
                setModalVisible(true);                                                                                      
            }                         
            //}            
        } catch (error) {
            dispatch('showToast', { toast: { toastMode: 'error', message: error?.message } });
        }                
    }, [dispatch, blockchains]);

    const memoedValue = useMemo(
        () => ({
            wallet,            
            walletNameConnected,
            walletConnected,
            connectWallet,
            connectedAddress,
            debugWalletAddress                        
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet, walletNameConnected, walletConnected, connectedAddress, blockchains, debugWalletAddress]
    );

    return memoedValue;

    <WalletMultiButton></WalletMultiButton>
};

export default useWalletSolana;
