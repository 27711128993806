export function creatorRoutes(route, id, childId1, tab) {
  const routes = {
    idWithTab: `/creator/${id}/${tab}`,
    overviewWithId: `/creator/${id}/overview`,
    subscriptionWithId: `/creator/${id}/wallet-authentication`,

    collectionWithCollectionId: `/creator/${id}/collection/${childId1}`,
    collectionWithId: `/creator/${id}/collection`,
    collections: `/creator/collection`,

    myGalleries: `/creator/${id}/my-galleries`,
    galleriesWithGalleryId: `/creator/${id}/my-galleries/view/${childId1}`,
    galleriesWithAddCollection: `/creator/${id}/my-galleries/add/collection`,
    galleriesWithSave: `/creator/${id}/my-galleries/edit/${childId1}/save`,

    default: `/creator`,
  };

  return routes[route] || routes.default;
}

export function collectorProfileRoutes(route, tab) {
  const routes = {
    subscription: `/collector/subscription`,
    profileWithTab: `/collector/profile/${tab}`,
    wallets: `/collector/wallet-authentication`,
    purchase: `/collector/purchase`,
    preferences: `/collector/preferences`,
    collections: `/creator/collection`,
    rewards: `/collector/rewards`,
    profileViewWithTab: `/collector/profile/${tab}/view`,
    default: `/collector/profile/my-galleries`,
  };

  return routes[route] || routes.default;
}

export function curatorRoutes(route, id,  tab,childId1) {
  const routes = {
    curatorIdWithTab: `/curator/profile/${id}/${tab}`,
    myGalleries: `/curator/profile/${id}/my-galleries`,
    curatorGalleriesWithGalleryId: `/curator/${id}/my-galleries/view/${childId1}`,
    galleriesWithAddCollection: `/curator/${id}/my-galleries/add/collection`,
    galleriesWithSave: `/curator/${id}/my-galleries/edit/${childId1}/save`,
    curatorIdWithCollectionId: `/curator/profile/${id}/collections/${childId1}`,

    discoverCuratorIdWithTab: `/discover/curators/${id}/${tab}`,
    discoverCuratorGalleriesWithGalleryId: `/discover/curators/${id}/my-galleries/view/${childId1}`,
    discoverCuratorIdWithCollectionId: `/discover/curators/${id}/collections/${childId1}`,

    default: `/curator`,
  };

  return routes[route] || routes.default;
}