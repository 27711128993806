import React, { useEffect, useMemo, useState } from 'react'
import './PreviewChanges.scss';
import useResizeObserver from "@react-hook/resize-observer";

const PreviewChanges = ({ change, renderDelay = 600, isMulti= false }) => {
    const [screenDimension, setScreenDimension] = useState({});

    const handleThePreviewComponentRender = () => {
        const newParentDiv = document.querySelector('.preview_changes-inline-box');
        const childDiv = document.querySelector('.preview_changes-for-MR');
        newParentDiv.innerHTML = '';

        const clonedChildDiv = childDiv.cloneNode(true);
        if (newParentDiv && clonedChildDiv) {
            newParentDiv.appendChild(clonedChildDiv);
        }
    }

    useResizeObserver(document.getElementsByClassName(`preview_changes-inline-box`)[0], (entry) => {
        if (screenDimension?.width !== entry.contentRect.width || screenDimension?.height !== entry.contentRect.height) {
            setScreenDimension({ width: entry.contentRect.width, height: entry.contentRect.height });
        }
    }, [change]);

    const styles = useMemo(() => {
        let style = {
            '--image-size-padding': '0%',
            '--multi-slide-transform': `scale(1)`,
            '--gallery-width': screenDimension?.width ,
            '--gallery-height': screenDimension?.height
        }
        return style
    }, [screenDimension?.height, screenDimension?.width]);

    useEffect(() => {
        const clearTimer = setTimeout(() => {
            handleThePreviewComponentRender();
        }, renderDelay)

        return () => {
            clearTimeout(clearTimer)
        }
    }, [screenDimension.height, screenDimension.width, change, renderDelay]);

    return (<div className='preview_changes-container'>
        <div className={`preview_changes-inline-box ${isMulti && 'multi-preview-changes-active'}`} style={styles}>
        </div>
    </div>)
}


export default PreviewChanges