import  { getShortDate } from "./getDate";
import nftFormat from "./nftFormat";
import image from "Assets/Icons/image.svg";
import audio from "Assets/Icons/music.svg";
import video from "Assets/Icons/video.svg";
import model from "Assets/Icons/model.svg";
import pdf from "Assets/Icons/pdf.svg";
import html from "Assets/Icons/html.svg";
import epub from "Assets/Icons/read-book.svg";
import MetaDummy from "Assets/Images/meta.png";
export const getSplice = (array, targetCount) => {
    const totalCount = Math.ceil(array.length / targetCount);
    const temp = [];
    for (let k = 0; k < totalCount; k++) {
        const spl = k * targetCount;
        const list = array.slice(spl, spl + targetCount);
        temp.push(list);
    }
    return temp;
}

export const getSplice5 = (array) => {
    return [array.slice(0, 2), array.slice(2, 3), array.slice(3, 5)]
}

export const getSplice7 = (array) => {
    return [array.slice(0, 3), array.slice(3, 4), array.slice(4, 7)]

}
export const thousandSeparator = (number) => {
    return Number(number)?.toLocaleString('en-US')
}
export const isDataURL = (url) => {
    url.includes('data:')
}
export const getBlobFromUrl = (url, callBack) => {
    try {
        const myRequest = new Request(url);
        if (isDataURL(url)) {
            callBack(url)
        } else {
            fetch(myRequest, { headers: { "Cache-Control": 'no-cache' } })
                .then(response => response.blob())
                .then(blob => {

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        callBack(base64data)
                    }
                });
        }
    } catch (error) {
        console.error('Error getBlobFromUrl', error);
    }    
}
export const geReWriteURL = (url) => {
    return new Promise((resolve) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const r = URL.createObjectURL(blob)
                resolve(r)
            })
            .catch(e => {
                resolve('')
            })
    })
}
export const sortObject = (arr, sortVal, originalArr = [], allCollectionData = []) => {
    sortVal = Array.isArray(sortVal) ? sortVal[0] : sortVal;
    switch (sortVal) {
        case ("Name A-Z"):
            return arr?.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() > b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);

        case ("Name Z-A"):
            return arr?.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() < b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);
        case ("Size L-H"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) > Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case ("Size H-L"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) < Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case "Collection A-Z":
            return arr.sort((a, b) =>
                a?.collectionName ?? a?.name
                ? b?.collectionName ?? b?.name
                    ? (a?.collectionName ?? a?.name)?.toLowerCase() > (b?.collectionName ?? b?.name)?.toLowerCase()
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Collection Z-A":
            return arr.sort((a, b) =>
                a?.collectionName ?? a?.name
                ? b?.collectionName ?? b?.name
                    ? (a?.collectionName ?? a?.name)?.toLowerCase() < (b?.collectionName ?? b?.name)?.toLowerCase()
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Count H-L":
            return arr?.sort((a, b) =>
                Number(a["quantity"])
                ? Number(b["quantity"])
                    ? Number(a["quantity"]) < Number(b["quantity"])
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Count L-H":       
            return arr.sort((a, b) =>
                Number(a["quantity"])
                ? Number(b["quantity"])
                    ? Number(a["quantity"]) > Number(b["quantity"])
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Floor Price H-L":
            arr = arr.map((pieces) => ({ ...pieces, floorPrice: pieces?.metrics?.length ? pieces?.metrics[0]?.floorPrice ?? 0 : 0 }))
            return arr.sort((a, b) => Number(a["floorPrice"]) ? Number(b["floorPrice"]) ? Number(a["floorPrice"]) < Number(b["floorPrice"]) ? 1 : -1 : -1 : 1);
        case "Release Date L-F":                      
            return arr.sort((a, b) =>
                a["mintDateFull"]
                ? b["mintDateFull"]
                    ? a["mintDateFull"] < b["mintDateFull"]
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Release Date F-L":
            return arr.sort((a, b) =>
                a["mintDateFull"]
                ? b["mintDateFull"]
                    ? a["mintDateFull"] > b["mintDateFull"]
                    ? 1
                    : -1
                    : 1
                : -1
            );
        case "Recent Additions":       
            let oldest = allCollectionData?.sort((x,y) => new Date(x?.createdAt) > new Date(y?.createdAt) ? 1 : -1)[0]?.createdAt ?? 0;               
            let sorted = arr.sort((a, b) =>
            {              
                if ((new Date(a?.createdAt)-new Date(oldest))<60000 && (new Date(b?.createdAt)-new Date(oldest))<60000)
                {                
                    return a["mintDateFull"]
                            ? b["mintDateFull"]
                            ? new Date(a["mintDateFull"]) < new Date(b["mintDateFull"])
                                ? 1
                                : -1
                            : -1
                            : 1
                }
                else
                {
                    return new Date(b?.createdAt)-new Date(a?.createdAt)
                }              
            });                      
            return sorted;    
        default:
            if(originalArr.length){
                return originalArr;
            } else {
                sortVal = sortVal?.toLowerCase()
                return arr.sort((a, b) => a[sortVal] ? b[sortVal] ? a[sortVal]?.toLowerCase() > b[sortVal]?.toLowerCase() ? 1 : -1 : -1 : 1);
            }        
    }

}

//Nft collection assetId list Api

export const getMetadata = (data, favKey) => {
    let image = '';
    if (data.onchain_metadata?.asset?.ipfs) {
        image = `${data.onchain_metadata?.asset?.url}${data.onchain_metadata?.asset?.ipfs}`
    } else if (data.metadata?.logo) {
        image = `data:image/png;base64,${data.metadata?.logo}`;
    }
    let files = [];
    if(data?.onchain_metadata?.files?.length) {
        let thumbimage = nftFormat(data.onchain_metadata?.image || image)
        files = data?.onchain_metadata?.files?.map((item => {
            let src = JSON.parse(JSON.stringify(item?.src??item?.fileFromsUrl??""))
            let mediaType = JSON.parse(JSON.stringify(item?.mediatype??item?.mediaType??item?.mimeType??""))
            if(Array.isArray(src)) {
                src = src.join('');
            }
            if(src?.includes('html')) {
                item.sType = 'html';
            } else if(src?.includes('ipfs')) {
                src = nftFormat(src, '?func=proxy');
                item.sType = 'ipfs';
            } else if(src?.includes('base64')) {
                item.sType = 'base64';
            }

            if(mediaType?.includes('html')) {
                item.mType = 'html';
            } else if(mediaType?.includes('image')) {
                item.mType = 'image';
            } else if(mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if(mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if(mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if(mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            item.newsrc = src;
            item.thumbimage = thumbimage;
            return item
        }))
    } else if (data?.onchain_metadata?.chain) {
        if (data?.onchain_metadata?.extra_metadata?.artifactUri && data?.onchain_metadata?.extra_metadata?.formats?.length)
        {
            let f = data?.onchain_metadata?.extra_metadata?.formats.filter(f=>f.uri === data?.onchain_metadata?.extra_metadata?.artifactUri)?.[0];
            if (f)
            {
                let item = {
                    "src": f.uri,
                    "mediaType": f.mimeType,                    
                    "nftcdnfile": f.uri
                }                    
                if (item.nftcdnfile.startsWith("ipfs://"))
                {
                    item.nftcdnfile = item.nftcdnfile.replace("ipfs://", "https://cur8.myfilebase.com/ipfs/");                    
                }
                let src = f.uri;
                if(src?.includes('html')) {
                    item.sType = 'html';
                } else if(src?.includes('ipfs')) {                        
                    item.sType = 'ipfs';
                } else if(src?.includes('base64')) {
                    item.sType = 'base64';
                }
    
                let mediaType = f.mimeType;
                if(mediaType?.includes('html') || mediaType === 'application/x-directory') {
                    item.mType = 'html';
                } else if(mediaType?.includes('image')) {
                    item.mType = 'image';
                } else if(mediaType?.includes('video')) {
                    item.mType = 'mp4';
                } else if(mediaType?.includes('pdf')) {
                    item.mType = 'pdf';
                } else if(mediaType?.startsWith('model')) {
                    item.mType = 'model';
                } else if(mediaType?.includes('epub')) {
                    item.mType = 'epub';
                }
                files.push(item);
            }            
        }
        if (data?.onchain_metadata?.extra_metadata?.animation_original_url)
        {
            files.push({
                "src": data?.onchain_metadata?.extra_metadata?.animation_original_url,
                "nftcdnfile": data?.onchain_metadata?.extra_metadata?.animation_original_url.replace("ipfs://", "https://cf-ipfs.com/ipfs/"),                    
                "mediaType": "html",
                "mType": "html"                    
            });
        }
    } else{
        let src = nftFormat(data.onchain_metadata?.image || image);
        files = [ {
            "src": src,
            "name": data.onchain_metadata?.name || data.metadata?.name || data.name,
            "mediaType": "image/png",
            "mType": "image",
            "sType": "ipfs",
            "newsrc": src,
            nftcdnfile: data?.nftcdnimage
        } ]
    }
    return {
        collectionName: data.collectionName??data.onchain_metadata?.collectionName,
        collectionImage: data.collectionImage,
        collectionImage256: data.collectionImage256,
        collectionId: data.collectionId,
        assetCount: data.assetCount,
        ownerCount: data.ownerCount,
        countDate: getShortDate(data.countDate),
        asset: data.asset,
        policy: data.policy_id,
        asset_name: data.asset_name,
        name: data?.onchain_metadata?.name || data?.metadata?.name || data?.onchain_metadata?.title || data?.metadata?.title || data?.onchain_metadata?.["Asset Title"] || data?.metadata?.["Asset Title"] || data?.name,
        image: nftFormat(data.onchain_metadata?.image || image),
        description: data.onchain_metadata?.description || data.metadata?.description,
        type: data.onchain_metadata?.type ?? data.onchain_metadata?.Type,
        files: files,
        quantity: 1,
        isCollectionFavourite: data[favKey],
        isFavourite: data.isFavouriteNFT,
        networkType: data.networkType,
        complexity: data.onchain_metadata?.complexity,
        value: data.onchain_metadata?.value,
        color: data.onchain_metadata?.color,
        circle: data.onchain_metadata?.circle,
        priceSource: data.onchain_metadata?.priceSource,
        mintDate: getShortDate(data.mintDate),
        lockDate: getShortDate(data.lockDate),
        mintDateFull: data.mintDate,
        lockDateFull: data.lockDate,
        walletId: data?.walletId,
        ownersCount: data?.ownersCount,
        items: data?.items,
        mint_or_burn_count: data?.mint_or_burn_count,
        category: data?.category??{name: data.categoryName},
        categoryId: data?.categoryId,
        firstSubcategoryId: data?.firstSubcategoryId,
        secondSubcategoryId: data?.secondSubcategoryId,
        onchain_metadata: data.onchain_metadata,
        community: {id: data.communityId, name: data.communityName, displayPicture256: data.communityDisplayPicture256, communitySlug: data.communitySlug },
        aspectRatios: data.aspectRatios,
        formats: data.formats,
        collectionEditionsId: data.collectionEditionsId,
        collectionStorageId: data.collectionStorageId,
        tags: data.tags,
        types: data.types,
        nftcdnimage: data.nftcdnimage,
        nftcdnimage256: data.nftcdnimage256,
        nftcdnimage512: data.nftcdnimage512,
        nftcdnimage1024: data.nftcdnimage1024,
        termsId: data.termsId,
        subCategoryName:data?.subCategoryName,
        showcase: data.showcase,
        collage: data.collage,
        metrics: data?.metrics,
        marketplace: data?.marketplace,
        minted: data?.minted,
        nfttype: data?.nfttype,
        collectionCollectionId: data?.collectionCollectionId,
        blockchainType: data?.blockchainType,
        contractAddress: (data?.blockchainType?.code !== 'solana' && data?.blockchainType?.code !== 'bitcoin') ? data?.contractAddress : (data?.onchain_metadata?.contract_address ?? data?.contractAddress),
        createdAt: data?.createdAt
    }
}

export const getURL = (url = '', size = 256) => {    
    return url;
}
export const getFrameURL = (url, size) => {
    return url;
}
export const getFrameURLFromCDN = (url) => {
    return url;
}
export const convertPriceToAda = (priceValue) => {
    const price = (priceValue / 1000000);
    return price?thousandCommaSeparator(price?.toFixed(2)):price;
}

export const thousandCommaSeparator =(amount)=>{
   return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0; 
}

export const convertJsonToArray = (data) => {
    try {
        const convertData = JSON.parse(data);
        return typeof convertData === 'object' ? convertData : [];
    } catch (error) {
        return [];
    }
}
export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return "Safari";
    }
    return "unknown";
}
export const updateViewPortMeta = () => {
    const v = getMobileOperatingSystem();
    const el = document.querySelector('meta[name=viewport]');
    if (v === 'Android') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1')
        }
    }
    if (v === 'iOS') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
        }
    }
}
export const dataURLtoBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};
export const getBLOBUrl = (blob) => {
    try {
        return URL.createObjectURL(blob)
    } catch (error) {
        return blob
    }
}
export const scrollToTop = (x = 0, y = 0) => {
    window.scrollTo(x, y)
}
export const togglePaddingMain = (mode) => {
    // const os = getMobileOperatingSystem();
    // const isMobile = window.innerWidth <= 768
    // const main = document.getElementById('mainContent');
    // if (main && isMobile && os === 'iOS') {
    //     if (mode) {
    //         main.style.paddingTop = 0;
    //     } else {
    //         main.style.paddingTop = '4.9375rem';
    //     }
    // }
}
export const toggleOverflow = (mode) => {
    const isMobile = window.innerWidth <= 768
    const main = document.body
    if (main && isMobile) {
        if (mode) {
            main.style.overflow = 'hidden';
        } else {
            main.style.overflow = 'auto';
        }
    }
}
export const popupMobileScrollAdj = (mode) => {
    togglePaddingMain(mode);
    toggleOverflow(mode)
}

export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);

}

export const removeDuplicateByElement = (array, key) => {
    const uniqueIds = [];
    // eslint-disable-next-line array-callback-return
    array.filter(ele => {
        const includeArr = uniqueIds.some(x => x[key] === ele[key]);
        if(!includeArr)
            uniqueIds.push(ele);
    })
    return uniqueIds;
}

export const objectToFormdata = (payload = {}, overrideKey) => {
    const formData = new FormData();
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            if (element && typeof element === 'object' && !(element instanceof Blob)) {
                if (Array.isArray(element))
                {
                    for (let i = 0; i < element.length; i++) {
                        const elementItem = element[i];
                        if (elementItem instanceof Blob)
                        {
                            formData.append(`${key}`, elementItem) 
                        }
                        else
                        {
                            formData.append(`${key}`, JSON.stringify(elementItem)) 
                        }                        
                    }
                }
                else
                {
                    formData.append(key, JSON.stringify(element))                
                }                
            }
            else {
                formData.append(key, element)
            }

        }
    }
    return formData
}

export const objectToNull = (payload = {}) => {
    let obj={}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
        obj[key] = element??null

        }
    }
    return obj
}

export  const arrangeAssetData = (data) => {
    const result = data.reduce(function (r, a) {
        r[a.policy_id] = r[a.policy_id] || [];
        r[a.policy_id].push(a);
        return r;
    }, {});
    var groups = Object.keys(result).map(function (key) {
        return {policy_id: key, data: result[key], assetCount: result[key].length};
    });
    let firstAssetList = [];
    for (const iterator in result) {
        if(result[iterator].length) {
            firstAssetList.push(result[iterator][0]);
        }
    }
    let assetList = [];
    assetList = [...firstAssetList, ...assetList]
    const fetchAssets = [];
    assetList.forEach((item) => {
        const include = fetchAssets.some(x => x.asset === item.asset)
        if (!include) {
            if(!item?.asset) {
                item.asset = item?.asset_name;
            }
            fetchAssets.push(item)
        }
    })
    const assetCount = groups && groups.reduce((accumulator, object) => {
        return accumulator + object.assetCount;
      }, 0);
    return {groups, fetchAssets, assetCount}
}

export const filterCollections = (filterArray = [], filter) => {
    let tempArray = [...filterArray];
    if(filter?.categoryIds && filter?.categoryIds?.length) {
        tempArray = tempArray?.filter((val) => (filter?.categoryIds?.includes(val?.categoryId) || filter?.categoryIds?.includes(val?.firstSubcategoryId) || filter?.categoryIds?.includes(val?.secondSubcategoryId)))
    }  
    if(filter?.blockchains && filter?.blockchains?.length){
        tempArray =  tempArray?.filter((val) =>{
            return filter?.blockchains?.includes(val?.blockchainType?.code);
        });
    }         
    if(filter?.shapes && filter?.shapes?.length) {
        tempArray = tempArray?.filter((val) => {
                let aspectRatiosArray = val?.aspectRatios?.split(",") ?? [];
                if (aspectRatiosArray.length === 0) return false;
                const hasLandscape = aspectRatiosArray.filter(ar => parseFloat(ar) > 1).length > 0;
                const hasPortrait = aspectRatiosArray.filter(ar => parseFloat(ar) < 1).length > 0;
                const hasSquare = aspectRatiosArray.filter(ar => parseFloat(ar) === 1).length > 0;
                return (hasLandscape && filter.shapes.includes('landscape')) ||
                    (hasPortrait && filter.shapes.includes('portrait')) ||
                    (hasSquare && filter.shapes.includes('square'));
            }
        );            
    }
    if(filter?.formatIds && filter?.formatIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.formats?.split(",").filter(v => 
                filter?.formatIds.filter(f=> 
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if(filter?.storageIds && filter?.storageIds?.length) {                        
        tempArray = tempArray?.filter((val) =>                 
            filter?.storageIds.filter(f=> 
                f === val.collectionStorageId
            ).length > 0                
        );
    }
    if(filter?.editionIds && filter?.editionIds?.length) {                        
        tempArray = tempArray?.filter((val) =>                 
            filter?.editionIds.filter(f=> 
                f === val.collectionEditionsId
            ).length > 0                
        );
    }
    if(filter?.typeIds && filter?.typeIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.types?.split(",").filter(v => 
                filter?.typeIds.filter(f=> 
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if(filter?.tagIds && filter?.tagIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.tags?.split(",").filter(v => 
                filter?.tagIds.filter(f=> 
                    f.toString() === v.toString()
                ).length > 0
            ).length > 0
        );
    }    
    if(filter?.termsIds && filter?.termsIds?.length) {        
        tempArray = tempArray?.filter((val) =>                 
            filter?.termsIds.filter(f=> 
                f === val.termsId
            ).length > 0                
        );
    }
    return tempArray;
}

export const categorySort = (data) => {
    let updatedList = data
      ?.sort((fs, sec) => fs.name.localeCompare(sec.name))
      ?.map((el) => {
        return {
          ...el,
          subcategories: el?.subcategories?.length
            ? el?.subcategories?.sort((fs, sec) =>
                fs.name.localeCompare(sec.name)
              )
            : el?.subcategories,
        };
      });
  
    return updatedList;
  };

export const getPreviewGalleryLink = (id, previewType) => {
    const type =  0;
    if (previewType === '3d') {
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'meta'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    } else if(previewType === '2d'){
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'mono'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    }else if(previewType === '2ds'){
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'multi'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    }
}
export const deepCopy = (arr) => {
    return JSON.parse(JSON.stringify(arr));
}

export const copyClipHandler = (value) => {
    var textArea = document.createElement("textarea");
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  export const splitWord=(value,num)=>{
    return value?.length < num
        ? value
        : value?.slice(0, num/2) +
        "..." +
        value?.slice(-(num/2))
  }

  export const queryGenerator=(data)=>{
    let queryLink="";
    const queryData=Object.entries(data).filter(([key,val])=>(val&&val?.length)||(val&& typeof val==="number"));
    queryData.forEach(([key,val],i)=>{
        queryLink=`${queryLink}${key}=${typeof val==="object"?`[${val}]`:val}${(i+1!==queryData?.length)?"&":""}`
    })
    return queryLink
  }
export const getAvailableGallery = (collections, assets) => {
    return  assets?.filter(x => collections?.some(y => y.asset === x?.asset));
}
export const findNumberFromString = (str) => {
    let matches = str.match(/\d+/g);
    if(matches)
        matches=parseInt(matches[0])
    return matches;
}

export const sequenceUpdate =(list, from, to) => {
    const listClone = [...list];
    if (from < to) {
      listClone.splice(to + 1, 0, listClone[from]);
      listClone.splice(from, 1);
    } else if (to < from) {
      listClone.splice(to, 0, listClone[from]);
      listClone.splice(from + 1, 1);
    }
    let listCloneUpdate = [...listClone]?.map((el,i)=>{
        return {...el,sequence:i+1}
    })
    return listCloneUpdate;
  }

export const findSelectedSubcategory = (categoryArray) => {
    const selectedCategory = categoryArray?.find(item => item?.isSelected);

    if (selectedCategory) {
        const selectedSubcategory = selectedCategory?.subcategories?.find(sub => sub?.isSelected);
        return selectedSubcategory?.name || '';
    }

    return '';
};

export function appendUserName(value, social) {
    if(!value) return;
    const urls = {
        twitter:`https://twitter.com/`,
        youtube:`https://www.youtube.com/`,
        discord:`https://discord.gg/`,
        instagram:`https://www.instagram.com/`,
    }
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (!urlRegex.test(value)) {
        // If not a full URL, append the userName at the end
        const fullUrl = `${urls[social]}${value}`;
        return fullUrl;
    }
    // If it's already a full URL, return it as is
    return value;
}

export const MediaType=(media)=>{
    if(media?.includes('html')) {
       return  'html';
    } else if(media?.includes('image')) {
        return 'image';
    } else if(media?.includes('video')) {
        return 'mp4';
    } else if(media?.includes('pdf')) {
        return 'pdf';
    } else if(media?.startsWith('model')) {
        return 'model';
    } else if(media?.includes('audio')) {
        return 'mp3';
    }
}

export const getMediaImage = (type)=>{
    let mediaImg = ""
    switch (type) {
        case 'html':
            mediaImg = html;
            break;
        case 'image':
            mediaImg = image;
            break;
        case 'mp4':
            mediaImg = video;
            break;
        case 'pdf':
            mediaImg = pdf;
            break;
        case 'model':
            mediaImg = model;
            break;
        case 'mp3':
            mediaImg = audio;
            break;
        case 'epub':
            mediaImg = epub;
            break;
        default:
            break;
    }
    return mediaImg
}

export const getCeilValue = (input) => {

    if (!input) return null;

    const number = parseFloat(input);
    if (!isNaN(number)) {
        return Math.ceil(number);
    } else {
        return null;
    }
}

export const getMetadataValue = (sources, keys) => {
    for (let j = 0; j < sources.length; j++) {
        const source = sources[j];                                        
        if (source)
        {
            let props = Object.entries(source).map(([k,v])=>[k.toLowerCase(),v]);
            let value = props.filter(([k,v]) => keys.includes(k))?.[0]?.[1];
            if (!value || value === "")
            {
                value = props.filter(([k,v]) => {
                    let match = false;
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        if (k.indexOf(key) > -1)
                        {
                            match = true;
                            break;
                        }
                    }     
                    return match;                                               
                })?.[0]?.[1];
            }      
            if (value && value !== "")
            {
                return value;
            }                                                  
        }                        
    }
}

export const getDurationOfAudio=(duration)=>{
      let  formatDuration = duration?.replace("PT","");
        let minsIndex = formatDuration?.indexOf("M");                            
        let secsIndex = formatDuration?.indexOf("S");
        let secs = 0;
        if (secsIndex > -1) {
            secs = formatDuration?.substring(minsIndex+1, secsIndex - minsIndex+1);
        }                            
        let mins = formatDuration?.substring(0, minsIndex);
        formatDuration = `${mins}:${(parseInt(secs)<10?"0":"") + parseInt(secs)}`;
    return formatDuration
}

export function formatLargeNumber(num) {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        // Define the threshold for each unit
        const thresholds = [
            { value: 1e12, symbol: 't' }, // trillion
            { value: 1e9, symbol: 'b' },  // billion
            { value: 1e6, symbol: 'm' },  // million
            { value: 1e3, symbol: 'k' }   // thousand
        ];

        // Loop through the thresholds to find the appropriate symbol
        for (let i = 0; i < thresholds.length; i++) {
            if (num >= thresholds[i].value) {
                // If the number is greater than or equal to the threshold, format it accordingly
                return (num / thresholds[i].value).toFixed(1) + thresholds[i].symbol;
            }
        }

        // If the number is smaller than the first threshold, return it as is
        return num.toString();
    } else {
        return null;
    }
}

export const numberWithCommas = (num) => {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        return number.toLocaleString();
    } else {
        return null;
    }
};

export const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

export const metaWallListData = () => {
    return [
        {
            id: 1,
            // imageUrl256: "/metagalleryassets/images/meta1256.jpg",
            imageUrl256: "/metagalleryassets/images/meta1.webp",
            isFavourite: false,
            name: "Small Gallery",
        }, {
            id: 2,
            // imageUrl256: "/metagalleryassets/images/meta2256.jpg",
            imageUrl256: "/metagalleryassets/images/meta2.webp",
            isFavourite: false,
            name: "Large Gallery",
        }, {
            id: 3,
            // imageUrl256: "/metagalleryassets/images/meta3256.jpg",
            imageUrl256: "/metagalleryassets/images/meta3.webp",
            isFavourite: false,
            name: "Cocktail Bar",
        }
    ]
}

export const getMetaThumbImageById = (id) => {
    let tempImage = MetaDummy, metaWallList = metaWallListData();
    if(id) {
        tempImage = metaWallList?.find(bg => Number(bg?.id) === Number(id))?.imageUrl256
    }
    return tempImage;
}

export const getBlockChainLable = ({blockChainList = [], blockChainId = '', blockChainCode = ''}) => {
    const selectedBlockchain = blockChainList.find((item)=> (item?.id === blockChainId || item?.code === blockChainCode));
    return selectedBlockchain?.code === 'cardano' ? 'Policy ID' : (selectedBlockchain?.code === 'solana' ? 'Collection ID' : 'Smart Contract');    
}

export const ImageLoader=(image)=>{
    const img = new Image();
    img.src = image;
    img.onload = () => {
 };
}

export const oddAndEvenSplitHandler=(data)=>{
    let oddSet = data?.filter((el,i)=>(i+1)%2!==0);
    let evenSet = data?.filter((el,i)=>(i+1)%2===0);
    return {oddSet,evenSet}
}
export const interpolate = (color1, color2, percent) => {
    let r1;
        let g1, b1, r2, g2, b2;
        if (color1.startsWith("#")) {
            // Convert the hex colors to RGB values
            r1 = parseInt(color1.substring(1, 3), 16);
            g1 = parseInt(color1.substring(3, 5), 16);
            b1 = parseInt(color1.substring(5, 7), 16);    
        } else if (color1.startsWith("rgb")) {
            let rgbVals = color1.replace(")","").substring(color1.indexOf("(")+1).split(",");            
            r1 = parseInt(rgbVals[0]);
            g1 = parseInt(rgbVals[1]);
            b1 = parseInt(rgbVals[2]);
        }
        
        if (color2.startsWith("#")) {
            // Convert the hex colors to RGB values
            r2 = parseInt(color2.substring(1, 3), 16);
            g2 = parseInt(color2.substring(3, 5), 16);
            b2 = parseInt(color2.substring(5, 7), 16);    
        } else if (color2.startsWith("rgb")) {            
            let rgbVals = color2.replace(")","").substring(color2.indexOf("(")+1).split(",");            
            r2 = parseInt(rgbVals[0]);
            g2 = parseInt(rgbVals[1]);
            b2 = parseInt(rgbVals[2]);
        }                      
      
        // Interpolate the RGB values
        const r = Math.round(r1 + (r2 - r1) * percent);
        const g = Math.round(g1 + (g2 - g1) * percent);
        const b = Math.round(b1 + (b2 - b1) * percent);
              
        // Convert the interpolated RGB values back to a hex color
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export const truncateText = (text, maxWords) => {
    if (!text) return '';

    const words = text.split(' ');
    if (words.length > maxWords) {
        return words.slice(0, maxWords).join(' ') + '... ';
    }
    return text;
};

export const getBgImageByWidth = (width, bgImageObj) => {
    let bgImage = '';
    if (width > 2048) { 
        bgImage = bgImageObj?.bgImage ?? bgImageObj?.bgImage2048; 
    } else if (width <= 2048 && width > 1024) {
        bgImage = bgImageObj?.bgImage2048; 
    } else if (width <= 1024 && width > 512) {
        bgImage = bgImageObj?.bgImage1024;   
    } else if (width <= 512 && width > 256) {
        bgImage = bgImageObj?.bgImage512;    
    } else if (width <= 256) {
        bgImage = bgImageObj?.bgImage256; 
    } else {
        bgImage = bgImageObj?.bgImage256; 
    } 
    return bgImage
}
export const getAccessGalleryCreation = (available, current) => {
    return available > current;
}
export const sortValueChangeHandlerForGroupByFilter = (sortType) => {
    if(sortType === 'Recency') {
      return "Recent Additions";
    }
    if(sortType === 'Name') {
      return "Collection A-Z";
    }
    if(sortType === 'Price') {
      return "Floor Price H-L";
    }
    if(sortType === 'Collection Size') {
      return "Count H-L";
    }
    return sortType;
  }

  export const removeDataHavingZeroCountOrUpdateTheCount=(count,key,setValue)=>{
    if (!count) {
        setValue((pre) => {
          return pre.filter((el) => !el?.status === key);
        });
      } else {
        setValue((pre) => {
          return pre.map((el) => {
            if (el?.status === key) {
              return { ...el, count: count };
            }
            return el;
          });
        });
      }
  }


  export const truncateTextWithLetters = (text, maxWords) => {
    if (!text) return '';

    if (text?.length > maxWords) {
        return text.slice(0, maxWords) + '... ';
    }
    return text;
};


export const getUpdatedCollectionList=(allNftCollections)=>{
    let policyList = [];
let policy = {};
allNftCollections.forEach((item) => {
  if (!policy[item.policy]) {
    policy[item.policy] = 1;
  } else {
    policy[item.policy] += 1;
  }
});
allNftCollections.forEach((item) => {
  if (policy[item.policy] && policy[item.policy] > 1) {
    const included = policyList.some((x) => x.policy === item.policy);
    if (!included) {
      //altered to include the latest NFT as the collection NFT, so we can use it for sorting most recent
      const latest = allNftCollections.filter((x) => x.policy === item.policy).sort((a,b)=>new Date(b.createdAt)-new Date(a.createdAt))?.[0];          
      const object = { ...latest, quantity: policy[item.policy] };
      policyList.push(object);
    }
  } else {
    const object = { ...item, quantity: 1 };
    policyList.push(object);
  }
});

return policyList
}

export const hasActivePath=(activeLinkBase,pathname)=>{
    return  activeLinkBase?.some((el)=>pathname?.includes(el))
}