import { useDispatch } from "react-redux";
import {
  setLoggingOutStatus,
  setShowWalletPop,
} from "App/ReduxStore/Slicers/authSlice";
import { useCallback } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "./use-http";
import useAuth from "./use-auth";

const useLogout = () => {
  const reduxDispatch = useDispatch();
  const dispatch = useStore(false)[1];
  let { triggerAPI } = useHttp();
  const logout = useAuth()[1];
  const logOutWarningCloseHandler = (walletAuthPop, setLogOutWarning) => {
    if (walletAuthPop) reduxDispatch(setShowWalletPop(true));
    setLogOutWarning(false);
  };
  const logoutApiResponse = useCallback(
    (res,walletAuthPop,setLogOutWarning) => {
      dispatch("hideSpinner");
      logOutWarningCloseHandler(walletAuthPop,setLogOutWarning);
      logout();
      setTimeout(() => {
        reduxDispatch(setLoggingOutStatus(false));
      }, 200);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, reduxDispatch, logout]
  );
  const logoutHandler = (walletAuthPop,setLogOutWarning) => {
    reduxDispatch(setLoggingOutStatus(true));
    reduxDispatch(setShowWalletPop(false));
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `user/logOut`,
        method: "post",
      },
      (res)=>logoutApiResponse(res,walletAuthPop,setLogOutWarning)
    );
  };
  return {
    logOutWarningCloseHandler,
    logoutHandler,
  };
};
export default useLogout;
