/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import useScreenSize from "./use-screen-size";

export const useDevice = () => {
  const screenSize = useScreenSize()?.width;

  const device = useMemo(() => {
    if (screenSize <= 550) {
      return "mobile";
    } else {
      return "web";
    }
  }, [screenSize]);
  return device;
};
