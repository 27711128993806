import { useStore } from "App/hooks-store/store";
import { useMemo, useCallback } from "react";
import useAuth from './use-auth';
import useWalletEVM from "./use-wallet-evm";
import storage from "App/Helper/storage";
import useBlockchain from "./use-blockchain";
const useConnectedWallet = () => {
    const dispatch = useStore()[1];
    const removeItem = useAuth()[5];
    const walletConnectorEVM = useWalletEVM();
    const {get: getLocalStorage} = storage();
    const { getEVMBlcokchainByElement } = useBlockchain();

    const disConnectConnectedWallet = useCallback(() => {
        let currentWallet = getLocalStorage('currentWallet')
        const isEvmChain = getEVMBlcokchainByElement(currentWallet);
        if(isEvmChain)
            walletConnectorEVM.disConnectWallet();
        dispatch('setCurrentWallet', {})
        removeItem('currentWallet');
        removeItem('cwa');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const memoedValue = useMemo(() => ({
            disConnectConnectedWallet
        }),
    [disConnectConnectedWallet]);

    return memoedValue
}
export default useConnectedWallet