import { Fragment, useCallback } from "react";
import { useState, useEffect } from "react";
import "./WalletSelect.scss";
import { useRef } from "react";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import WalletIconNew from 'Assets/Images/wallet-manage.svg';
import activeWallet from "Assets/Icons/active-wallet.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import WalletSelectPop from "../WalletSelectPop";
import WalletIcon from "App/Components/IconsComponents/WalletIcon";
import useBlockchain from "App/hooks/use-blockchain";
import useConnectedWallet from "App/hooks/use-connect-wallet";
import useWalletEVM from "App/hooks/use-wallet-evm";
import { useDevice } from "App/hooks/use-ui";
const WalletSelect = ({ current, availble = [], onSelect, loading = false }) => {
    const device = useDevice();
    const popupRef = useRef();
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const isLoggedIn = useAuth()[0];
    const removeItem = useAuth()[5];
    const dispatch = useStore(false)[1];
    const { pathname } = useLocation();
    const store = useStore()[0];        
    let { blockchains, collectionLoadingStatus, walletConnectionStatus } = store;
    const [showWalletAuthPop, setShowWalletAuthPop] = useState(false);
    const { getEVMBlcokchainByElement } = useBlockchain()
    const {disConnectConnectedWallet} = useConnectedWallet();
    const walletConnectorEVM = useWalletEVM();
    const focusHandler = () => {
        setShow(prev => !prev);
    }

    const blurHandler = () => {
        if(device === 'mobile') return;
        setShow(false);
    }
    
    const selectWallet = (e) => {
        e.stopPropagation();
        setShow(false);
        const { dataset: { value, id } } = e.currentTarget;
        if(collectionLoadingStatus !== 'finished') {
            dispatch('showToast', { toast: { toastMode: 'warning', message: 'Loading collections form all linked wallets' } })
            return;
        }
        if (id) {
            window.open(id);
        } else {
            dispatch('setWalletConnectionStatus', 'connecting')  
            let currentWalletName = storage().get('currentWallet') || selectedWallet?.walletName;
            const isEvmChain = getEVMBlcokchainByElement(value);
            if(currentWalletName?.toLowerCase() !== value?.toLowerCase()){
                if(value?.toLowerCase() === "cardano") {
                    setShowWalletAuthPop(true);
                } else {
                    removeItem('cwa');
                    const isLastWalletEVM = getEVMBlcokchainByElement(currentWalletName);
                    if(isLastWalletEVM) {
                        walletConnectorEVM.disConnectWallet()
                    }
                    onSelect(value, isEvmChain);
                }
            } else {
                disConnectConnectedWallet();
                if(!isLoggedIn && pathname.includes("curate/collections/my-collections")) navigate("/home")
                dispatch('setWalletConnectionStatus', 'finished')
            }           
        }        
    }

    useEffect(() => {                       
        let walletList = [];
        if (blockchains?.length)
        {
            walletList.push(...blockchains?.sort((a,b)=>a.sortOrder>b.sortOrder?1:-1).map(c => { return {
                name: c.name,
                icon: c.icon
            }}));
        }
        setWallets(walletList);
        if (current) {
            const { walletName, roundBalance } = current;
            const foundIcon = walletList?.find(w => w?.name?.toLowerCase() === walletName?.toLowerCase());
            setSelectedWallet({ walletName, balance: roundBalance, icon: foundIcon?.icon??blockchains?.filter(b=>b.code === 'cardano')?.[0]?.icon });
        }
    }, [availble, current, blockchains]);

    const onMouseBlur = useCallback(() => {
        if(device === 'mobile') return;
        setShow(false);
    }, [device]);

    const onMouseFocus = useCallback(() => {
        if(device === 'mobile') return;
        setShow(true);
    }, [device]);

    const navToWallet = () => {
        navigate("collector/wallet-authentication");
    }

    const walletLinkClicked = () => {
        setShowWalletAuthPop(false);
    }
    useEffect(() => {
        if(walletConnectionStatus === 'finished') {
            dispatch('setWalletConnectionStatus', 'idle')
            if(pathname?.includes('/curate/collections/my-collections/')) 
                navigate("/curate/collections/my-collections");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletConnectionStatus, pathname])
    return (
        <>
            <div className={`wallet-select ${show ? 'open-popup' : 'close-popup'} ${loading ? 'loading' : ''}`}
                onClick={focusHandler}
                onMouseOverCapture={onMouseFocus} onMouseLeave={onMouseBlur} onBlur={blurHandler}
                tabIndex={-1} ref={popupRef}>
                {loading ? <SkeletonLoader height={18} count={1} inline={true} /> :
                    <Fragment>
                        <div className="flex gap-2 items-center">
                        {(selectedWallet?.walletName) ?
                            <div className={`wallet-select-label wallet-icon-wrapper flex items-center justify-center  h-full  rounded-[8px] link-wallet-section`}>
                                <img src={selectedWallet?.icon} alt={selectedWallet?.name} />
                            </div> :<></>
                            // <div className={`wallet-icon-wrapper cursor-pointer flex items-center  gap-2 justify-center w-[3rem] h-full  rounded-[8px]`}>
                            //     {/* <img src={WalletIconWhite} alt="wallet icon" className=""/> */}
                            //     <WalletIcon/>
                            // </div>
                        }
                        {!selectedWallet?.walletName&& <span className="connect-text sm:mb-[0.125rem] mb-0">Connect</span>}
                        {!selectedWallet?.walletName&& <span className="wallet-icon-wrapper-mob"><WalletIcon/></span>}
                        </div>
                        <div className="wallet-select-available walletlist-drop-drown" style={{ display: wallets.length ? 'block' : 'none' }}>
                            <ul className={`wallets-lists-ui-box`}>
                                {isLoggedIn && <><li className="link-wallet">
                                    <span className="li_main_inside-text">Linked Wallets</span>
                                    {/* <div className="flex items-center gap-[.625rem] px-[.5rem]" onClick={handleCollectionRefresh}>
                                        <img src={refresh} alt="refresh icon" />
                                    </div> */}
        
                                    <button key={''} data-id={''} onClick={navToWallet} data-value={''} className="manage-wallet-btn">
                                        <img src={WalletIconNew} alt="wallet icon" />
                                        <span className="">Manage</span>
                                    </button>
            
                                </li>
                                <hr/>
                                </>
                                }
                                <li className="connect-wallet-txt-box">
                                    <span className="li_main_inside-text">Connect Wallet</span>
                                </li>  
                                {
                                    wallets?.map((wallet) => {
                                        return (
                                            <li key={wallet.name} data-id={wallet?.installUrl} onClick={selectWallet} data-value={wallet.name} className={`${wallet?.name?.toLowerCase()===selectedWallet?.walletName?.toLowerCase() ? 'activeWallet' : ''}`}>
                                                <div className="flex gap-[0.625rem]">
                                                <div className="wallet-select-available-icon">
                                                    <img src={wallet.icon} alt={wallet.name} />
                                                </div>
                                                <div className="wallet-select-available-label">{wallet.name}</div>
                                                </div>
                                                {!!(wallet?.name?.toLowerCase()===selectedWallet?.walletName?.toLowerCase())&&<div className="active-icon"><img src={activeWallet} alt=""/></div>}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </Fragment>
                }
            </div>
            <WalletSelectPop show={showWalletAuthPop} onClose={() => setShowWalletAuthPop(false)} className="walletcardanopopup" linkClicked={walletLinkClicked} />
        </>
    );
};

export default WalletSelect;
