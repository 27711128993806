import InformationIcon from "App/Components/IconsComponents/InformationIcon";
import ToolTip from "App/Components/ToolTip";
import GalleryImages from "App/Components/Ui/GalleryFrameImages";
import { useStore } from "App/hooks-store/store";
import closeIcon from "Assets/Images/close-circle-icon.svg"

const GalleryPieces = (props) => {
    const [{ currentSelectedNfts }] = useStore();
    const onImageClick = (e) => {
        // if (screenSize < 551) {
        //     e.stopPropagation();
        // }

        props?.action(e);
    }
    return (
        <div className="steps-container nft-gallery-full-card selected-nfts w-full !pr-0">
            <div className='flex gap-4 items-center'>
                <span>
                    Pieces ({currentSelectedNfts?.length ?? 0}) 
                </span>
                <ToolTip className="mt-[3px]" message={'Select up to 30 pieces.'}>
                    {/* <span className="info-tooltip">i</span> */}
                    <InformationIcon/>
                </ToolTip>
            </div>
            <div className="py-4 pr-4 grid selected-wrapper selected-pieces">
            {currentSelectedNfts?.length?
                currentSelectedNfts?.map((selectedNfts, i) =>
                        <div className={`${selectedNfts?.isLandscape && ''} selected-pop-boxcard collection-card min-view flex flex-col`} data-id={`${selectedNfts?.asset}${i}`} key={`${selectedNfts?.asset}${i}`}>
                            <GalleryImages
                                key={`${selectedNfts?.asset}${i}`}
                                onClick={onImageClick}
                                actionImage={closeIcon}
                                action='close'
                                index={i} galleryNew={selectedNfts}
                                src={selectedNfts?.nftcdnimage256}
                                dataId={selectedNfts?.asset}
                                className="nft-gallery-card-image view-gal-mob" nftGalleryNew={true} />
                        </div>
                ):
                <div className={`selected-pop-boxcard collection-card min-view flex flex-col`} key={'empty-box'}></div>}
            </div>
        </div>
    )
}
export default GalleryPieces;