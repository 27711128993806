import { useStore } from "App/hooks-store/store"
import useHttp from "./use-http";
import { getBlockchainIcon } from "App/Helper/blockchain";
import { useCallback } from "react";

const useBlockchain = () => {
    const [{ blockchains = [] }, dispatch] = useStore();
    const { triggerAPI } = useHttp();

    const getBlockchainList = (callback) => {
        triggerAPI({
            url: `blockchain/getall`, method: 'get'
        }, callback);
    }
    const getBlockchainListFromStore = (callback = null) => {
        try {
            if (blockchains?.length) {
                callback && callback(blockchains)
            } else {
                getBlockchainList(({ data }) => {
                    let blockchains = data.map(b=> { return {
                        ...b,
                        icon: getBlockchainIcon(b.code)
                    }})
                    dispatch('setBlockchains', blockchains);
                    callback && callback(blockchains)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getEVMBlcokchainByElement = useCallback((testCase = '') => {
        return blockchains?.findIndex(c => (c?.name === testCase || c?.code === testCase || c?.id === testCase) && c.isEVM) > -1 ? true: false;
    }, [blockchains])
    return {
        getBlockchainListFromStore,
        getBlockchainList, getEVMBlcokchainByElement
    }
}
export default useBlockchain;