import { useEffect, useMemo, useRef } from "react";
import GalleryImages from "../../../../../../Components/Ui/GalleryFrameImages";
import { useState } from "react";
import GalleryTableView from "../../../GalleryTableView";
import { sortObject, sortValueChangeHandlerForGroupByFilter, thousandCommaSeparator } from "App/Helper/utilities";
import { useStore } from "App/hooks-store/store";
import RoundedButton from "App/Components/Ui/RoundedButton";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AccessPlans from "App/Pages/UserProfile/Components/AccessPlans";
import useScreenSize from "App/hooks/use-screen-size";
import { filterCollections } from 'App/Helper/utilities';
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import useCollectionCount from "App/hooks/use-collection-count";
import Logo from "App/Components/Logo";
import GroupByGalleryCollections from "./GroupBy";
import { getBlockchainIcon } from "App/Helper/blockchain";
import BlockChainIcon from "App/Components/Ui/BlockchainIcon";

const Gallery = (props) => {
    const { communityid, pgaction } = useParams();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [displayChange, setDisplayChange] = useState('lg')
    const [newCollection, setNewCollection] = useState(null)
    const [{ tokenAssetCount, activeMenuIcons, collections }, dispatch] = useStore(false);
    const [searchedValue, setSearchedValue] = useState('')
    const [newCollectionGallery, setNewCollectionGallery] = useState([])
    const [originalList, setOriginalList] = useState([])
    const [clicked, setClicked] = useState(false);
    const [isGroupByActive, setIsGroupByActive] = useState(true);

    const [wordLimit, setWordLimit] = useState(35)
    const targetRef = useRef();
    const listRef = useRef();
    const screensize = useScreenSize()?.width;
    const { collectionCount } = useCollectionCount();
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const collectionsOptions = useMemo(()=>{
        return[
        {
          id: 1,
          value: "My Collections",
          count: collectionCount?.collection ?? 0,
          showSubDrop: true,
          walletData: collectionCount?.walletData,
          url: `${props?.galleryBaseUrl}/${props?.isEditPage}/collection`
        },
        {
          id: 2,
          value: "My Tokens",
          count: tokenAssetCount ?? 0,
          url: `${props?.galleryBaseUrl}/${props?.isEditPage}/token`
        },
      ]},[collectionCount, tokenAssetCount, props?.isEditPage, props?.galleryBaseUrl]);

    const group = [
        { id: 0, value: "Collections" },
        { id: 1, value: "Single" }]
    // const sortOptions = [
    //     { id: 6, value: "Recent Additions" },
    //     { id: 0, value: "Collection A-Z" },
    //     { id: 1, value: "Collection Z-A" },
    //     { id: 2, value: "Count H-L" },
    //     { id: 3, value: "Count L-H" },
    //     { id: 4, value: "Release Date L-F" },
    //     { id: 5, value: "Release Date F-L" },

    // ]
    const multiSortOptions = useMemo(() => {
        let temp = [
            { id: 'sort', value: '', isHeading: true, headingLabel: 'Sort By', isMultiFilter: true },
            { id: 4, value: "Collection Size", subOf: 'sort', defaultValue: true },
            { id: 1, value: "Recency", subOf: 'sort'},
            { id: 2, value: "Name", subOf: 'sort'},
            { id: 3, value: "Price", subOf: 'sort' },
            { id: 'group', value: '', isHeading: true, headingLabel: 'Group By' },
            { id: 5, value: "Category", subOf: 'group', defaultValue: true},
            { id: 6, value: "Sub-Category", subOf: 'group'},
            { id: 7, value: "Creator", subOf: 'group' },
            { id: 8, value: "No Grouping", subOf: 'group' },
        ]
        return temp;
    }, []);
    const sortTokensOptions = [
        { id: 6, value: "Recent Additions" },
        { id: 0, value: "Collection A-Z" },
        { id: 1, value: "Collection Z-A" },
        { id: 4, value: "Release Date L-F" },
        { id: 5, value: "Release Date F-L" },

    ]
    const categories = [
        { id: 0, value: "All Categories" },
        { id: 1, value: "Favourites" }
    ]

    const [filter, setFilter] = useState({
        group: group[0].value,
        categories: categories[0].value,
        sort: multiSortOptions[1].value
    })

    const onClickHandler = (e) => {
        setClicked(true)
        if (communityid)
        {
            props.onSelected({ policy: e?.currentTarget?.dataset.policy, source: "community"})
        }
        else
        {
            props.onSelected(e?.currentTarget?.dataset.policy)
        }
       
    }    
    useEffect(() => {
        props?.setCollectionLevelLoading(true)
        setTimeout(() => {
            props?.setCollectionLevelLoading(false)
        }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pgaction])
    const setDisplay = (e) => {
        setDisplayChange(e)
        props?.setDisplayMode(e)
    }

    const getCollectionList = (collections) => {
        let list = [];
        if (collections?.length) {
            let policyList = []
            let policy = {}
            if (typeof (collections) !== "string") {
                collections.forEach(item => {
                    if (!policy[item.policy]) {
                        policy[item.policy] = 1
                    }
                    else {
                        policy[item.policy] += 1
                    }

                })
                collections.forEach((item) => {
                    if (policy[item.policy] && policy[item.policy] > 1) {
                        const included = policyList.some(x => x.policy === item.policy)
                        if (!included) {
                            const object = { ...item, quantity: policy[item.policy] }
                            policyList.push(object)
                        }
                    }
                    else {
                        const object = { ...item, quantity: 1 }
                        policyList.push(object)
                    }

                })
            }
            for (let item of policyList) {
                list.push(item)
            }
        }
        return list;
    }
    
    const onFilterChange = (e) => {
        setFilter((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    useEffect(() => {
        let searchedArray = [];
        if (filter?.group !== 'Collections') {
            searchedArray = newCollectionGallery?.filter((val) => filter?.group === 'Single' ? props?.onSelected('Single') : filter?.group === 'Collections' && val.quantity >= 1)
            if (filter?.categories !== 'All Categories') {
                searchedArray = searchedArray?.filter((val) => val?.isCollectionFavourite
                    === true)
            }
            if (filter?.sort) {
                searchedArray = searchedArray?.filter(item => item.name = item?.collectionName ?? item?.name)
                searchedArray = sortObject([...searchedArray], filter.sort, searchedArray, collections)
            }
            if (searchedValue !== '') {
                searchedArray = searchedArray?.filter((val) => val?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase()) || val?.onchain_metadata?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase()) || val?.community?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase()) || val?.asset?.toLowerCase()?.includes(searchedValue?.toLowerCase()))
            }
            setAssetQty(searchedArray);
            setNewCollection([...searchedArray])
        }

        else {
            searchedArray = filter?.sort === 'None' ? originalList : newCollectionGallery;
            // props?.setCollectionLevelLoading(true)
            if (filter?.categories !== 'All Categories') {
                searchedArray = searchedArray?.filter((val) => val?.isCollectionFavourite === true)

            }
            if (!!(searchedValue)) {
                searchedArray = searchedArray?.filter((val) => val?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase()) || val?.onchain_metadata?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase())|| val?.community?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase()) || val?.asset?.toLowerCase()?.includes(searchedValue?.toLowerCase()))
              
            }
            searchedArray = filterCollections(searchedArray, activeMenuIcons?.filter);
            if (filter?.sort) {
                searchedArray = searchedArray?.filter(item => item.name = item?.collectionName ?? item?.name)
                searchedArray = sortObject(searchedArray, filter.sort, searchedArray, collections)
            }
            setAssetQty(searchedArray);
            setNewCollection([...searchedArray])
            // props?.setCollectionLevelLoading(false)
        }
        if(listRef.current && !isGroupByActive){
            listRef.current.scroll({
                top: 0,
                behavior: 'smooth'
              });
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchedValue, newCollectionGallery, activeMenuIcons?.filter])

    useEffect(() => {
        if (communityid)
        {            
            setNewCollectionGallery(props?.nftCollections)
            const list =props?.nftCollections && props?.nftCollections?.map( x => x);
            setOriginalList(list);
        }
        else
        {
            const GalleryCollection = getCollectionList(props?.nftCollections)     
            setNewCollectionGallery(GalleryCollection)
            //for duplicating the arry
            const list = GalleryCollection.map( x => x);
            setOriginalList(list);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nftCollections])

    const setAssetQty = (assetList) => {
        // let rawData = communityid ? communityCollectionRawData : collectionRawData;
        // eslint-disable-next-line array-callback-return
        // assetList?.map((aData) => {
        //     const isAssetData = rawData?.find(x => x.policy_id === aData.policy)
        //     if(isAssetData) {
        //         aData.quantity = isAssetData?.assetCount
        //     } 
        // })
        
    }

    //Top Menu Options
    useEffect(() => {
        dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false});
        dispatch('enableTopIcons', {
            dropDown: !communityid , sorting: true, search: true,
            favourite: !communityid, lgView: false,
            smView: false, filter: pgaction!=="token"
        });

        dispatch('setDropDownOptions', collectionsOptions);
        dispatch('setSortingOptions',props?.isToken? sortTokensOptions: multiSortOptions);
        dispatch('setActiveIcons', {
            dropDown: searchParams.get('walletid')? searchParams.get('walletid') : collectionsOptions?.find(item => item?.url === pathname)?.value,
            search: ''
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPortrait,screensize, pathname,props?.isToken,pgaction, searchParams.get('walletid'), collectionsOptions, multiSortOptions])
    useEffect(() => {
        dispatch('setActiveIcons', { sort: props?.isToken ? sortTokensOptions[0].value : [multiSortOptions[1].value, multiSortOptions[6].value] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.isToken, multiSortOptions])
    useEffect(() => {
        dispatch('setActiveIcons', {displayType: 'sm'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        return () => {
            dispatch('resetAll');
            dispatch("setPage",communityid?"community-gallery":"gallery")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[communityid])

    useEffect(() => {
        if(activeMenuIcons?.displayType) {
            dispatch('setActiveIcons', {
                displayType: activeMenuIcons?.displayType
            });
            setDisplay(activeMenuIcons?.displayType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.displayType])

    // useEffect(() => {
    //     props?.updateCollectionFilter(activeMenuIcons?.dropDown, activeMenuIcons?.filterByWallet);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [activeMenuIcons?.dropDown, activeMenuIcons?.filterByWallet])
    useEffect(() => {
        if(!!(searchParams.get('walletid'))) {            
            props?.updateCollectionFilter(null, searchParams.get('walletid'))            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchParams.get('walletid')])
    useEffect(() => {
        if(activeMenuIcons?.dropDown === 'My Tokens' || activeMenuIcons?.dropDown === 'My Collections') {
            props?.updateCollectionFilter(activeMenuIcons?.dropDown, null)
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.dropDown])
    useEffect(() => {
        if (activeMenuIcons?.favourite) {
            onFilterChange({ categories: 'Favourites' })
        }
        else {
            onFilterChange({
                categories: 'All Categories'
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.favourite])


    useEffect(() => {
        if (activeMenuIcons?.sort) {
            if (!props?.isToken) {
                const isMultiSort = Array.isArray(activeMenuIcons?.sort);
                setIsGroupByActive(isMultiSort);
                onFilterChange({ sort: isMultiSort ? sortValueChangeHandlerForGroupByFilter(activeMenuIcons?.sort[0]) : sortValueChangeHandlerForGroupByFilter(activeMenuIcons?.sort) });
            } else {
                setIsGroupByActive(false);
                onFilterChange({ sort: activeMenuIcons?.sort });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.sort, props?.isToken])

    useEffect(() => {
        setSearchedValue(activeMenuIcons?.search);
    }, [activeMenuIcons?.search])

    useEffect(() => {
       const optionValue = activeMenuIcons?.folderOption;       
       if(optionValue && optionValue !== 'Collections') {
            const selectedValue = {
                group: 'Single'
            }
            onFilterChange(selectedValue)
            props?.setIsSingle && props?.setIsSingle(true);
       } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.folderOption])
    useEffect(() => {
        if(displayChange === 'lg') {
            setWordLimit(35);
        } else if(displayChange === 'sm'){
            setWordLimit(20);
        }
    }, [displayChange])
    
    return (
        <div className="w-full">
             {!!props?.collectionLevelLoading&&
             <div className="px-4">
                <DetailsSkeltonCard 
                    show={true}
                    height={"100%"}
                    count={20}
                    inline={true}
                    details={"img-2-line"}
                    noAbsolute
                    parentDiv={`${activeMenuIcons?.displayType==="lg"?"large-view":"small-view"} collection-loader`}
                    containerClassName={` flex pt-[1rem]`}
                />
            </div>}  
            {!props?.collectionLevelLoading && 
                <div>
                    {newCollection&&newCollection?.length ? <div ref={listRef} className={`nft-gallery-full-card  nft-gallery nft-gallery-sm ${displayChange}`}>
                        {(displayChange === "sm" || displayChange === "lg") && isGroupByActive &&
                            <GroupByGalleryCollections
                                listRef={listRef}
                                nftList={newCollection}
                                hoverlayClass={'collection-card-overlay'}
                                className="nft-gallery-card-image-selected"
                                clicked={clicked}
                                onClickHandler={onClickHandler}
                                displayChange={displayChange}
                                targetRef={targetRef}
                                isToken={props?.isToken}
                                wordLimit={wordLimit}
                                userAvatar={Logo()}
                            />}
                        {(displayChange === 'sm' || displayChange === 'lg') && !isGroupByActive ? <div className={`${displayChange === 'lg' ? 'grid-lg' : 'grid grid-sm'} full-album pl-4 pt-4 sm:pb-4 pb-[3.5rem] overflow-y-auto gap-2`}>
                            {newCollection&&newCollection?.map((gallery, i) => {
                                return <div key={`${gallery?.asset}-${gallery?.frameId ?? -1}`} className="cursor-pointer flex flex-col grid-card">
                                    <div className="collection-lvl-gallery" ref={targetRef}>
                                        <GalleryImages
                                            gallery={true}
                                            nftGalleryNew={true}
                                            src={gallery?.collectionImage256 || gallery?.nftcdnimage256}
                                            hoverlayClass={'collection-card-overlay'}
                                            className="nft-gallery-card-image-selected"
                                            showBorder={true}
                                            policy={gallery?.policy} onClick={onClickHandler} clicked={clicked} />
                                    </div>
                                    <div className="flex flex-col px-[.25rem] py-1 gap-[.375rem]">
                                    {!props?.isToken&&<div className="flex gap-[.75rem]">
                                            <img style={{borderRadius: "50%", height:"1.875rem", width:"1.875rem"}} src={gallery?.community?.displayPicture256 || Logo()} alt="icon" />
                                            <div className="mt-[.2rem]">
                                            <div className="nft-gallery-head mr-3  nft-name-gallery">
                                                {gallery?.collectionName ? 
                                                    (gallery?.collectionName?.length > wordLimit ? `${gallery?.collectionName.substring(0,(wordLimit - 1))}...` : gallery?.collectionName)
                                                    :
                                                    (gallery?.name?.length > wordLimit ? `${gallery?.name.substring(0,(wordLimit - 1))}...` : gallery?.name)
                                                }
                                            </div>
                                            <div className="text-left text-[#A0ABBB] text-[.875rem]">
                                                {gallery?.community?.name || 'Unknown'}
                                            </div>
                                           <div className="flex items-center gap-6 mt-2">
                                            {gallery?.blockchainType?.code ? 
                                                <img
                                                    className={`w-[1.2rem]`}
                                                    alt="Img"
                                                    src={getBlockchainIcon(gallery?.blockchainType?.code)}
                                                />
                                                :
                                                <BlockChainIcon classes={`w-[1.2rem]`} loaderH={'1.05rem'} policyId={gallery?.policy} />
                                                }
                                                <div className={`nft-price flex`}>
                                                <span className={`dot-separator`}>{`${thousandCommaSeparator(gallery?.quantity)} piece${(gallery?.quantity!==1)?"s":""}`}</span>
                                                </div>
                                                </div>
                                            </div>
                                            {/* {!props?.isToken&&<div className="flex gap-1  ml-auto">
                                                <div className="nft-gallery-head nft-gallery-count mt-2">
                                                    <span>{gallery?.quantity}</span>
                                                </div>
                                            </div>} */}
                                                    
                                        </div>}
                                    </div>
                                </div>
                            }

                            )}
                        </div>
                            : !isGroupByActive ?
                            <div className="w-full">
                                <GalleryTableView list={newCollection?newCollection:[]} gallery={true} onClickHandler={onClickHandler} />
                            </div>
                        : null}
                    </div> :
                        <div className={`flex justify-center h-full w-full items-center no-data ${filter?.categories === categories[1]?.value && 'hidden'} ${props?.currentSelectedTab==="The Art Bank"&&"items-baseline mt-[0.25rem]"}`}>
                            {props?.currentSelectedTab==="The Art Bank"?<div className="ml-4 w-full sm:mr-[2rem] mr-4">
                            <AccessPlans />
                            </div>:
                            (   <>
                                    {(newCollection&&!newCollection?.length && !!props?.nftCollections?.length) && filter?.categories === categories[0]?.value &&
                                        <span className="visibleSlowly">No collections to display.</span>
                                    }
                                </>)
                            }
                        </div>
                    }
                    {newCollection&&!newCollection?.length && filter?.categories === categories[1]?.value &&
                        <div className="flex justify-center h-full w-full items-center no-data">

                            <span>No favourites found !!</span>

                        </div>
                    }
                </div>
            }
             
            <div className="selected-gallery-btn-sm absolute bottom-4 left-4 right-4 mt-4">
                <RoundedButton className="custom-button" Name="Curate" Type="7" OnClick={props?.onClickPreview}></RoundedButton>
            </div>
        </div>
    )
}

export default Gallery